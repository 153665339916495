import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import AddBlogPopup from './AddBlogPopup';
import AddKycPopup from './AddKycPopup';
import SuccessPop from '../JoinNow/SuccessPop';
import moment, { duration } from 'moment'
import Select from "react-select";
import csc from "country-state-city";
import validator from "validator";
import axios from "axios";
import { toast } from "react-toastify";
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import AddJobPop from './AddJobPop';
import {configData} from "../../Config"


 
const AddNewsPopup = props => {
  

  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const filearr=[];
    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...serviceList];
      list[index] = e.target.files[0];
      setServiceList(list);
  debugger;

  const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml'];

  // Validate file type
  if (file && !allowedTypes.includes(file.type)) {
    toast.error('Only JPEG, PNG, and SVG files are allowed.');
    return; // Exit the function if file type is invalid
  }
  
  if(serviceList.length==1){
    setAttachment(preState => ({
         ...preState,
         "profilePhoto" : e.target.files[0]
       }))
  }
  if(serviceList.length==2){
    setAttachment(preState => ({
         ...preState,
         "photo1" : e.target.files[0]
       }))
  }
  if(serviceList.length==3){
    setAttachment(preState => ({
         ...preState,
         "photo2" : e.target.files[0]
       }))
  }if(serviceList.length==4){
    setAttachment(preState => ({
         ...preState,
         "photo3" : e.target.files[0]
       }))
  }if(serviceList.length==5){
    setAttachment(preState => ({
         ...preState,
         "photo4" : e.target.files[0]
       }))
  }
      // setAppointment(preState => ({
      //   ...preState,
      //   "attachFiles" : e.target.files[0]
      // }))
      filearr.push(e.target.files[0]);
    };
  
    const handleServiceRemove = (index) => {
      const list = [...serviceList];
      list.splice(index, 1);
      setServiceList(list);
    };
  
    const handleServiceAdd = () => {
      setServiceList([...serviceList, { service: "" }]);
    };

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     setEventData(preState => ({
      ...preState,
      "eventDate": (moment(e).format("DD MMM yyyy")),

    }))
    }

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);


    const [eventdata, setEventData] = useState({
      hostId:uerAuthId,
      hostName:authlocalstoragedata?.name2,
      eventType:"",
      eventDate:"",
      eventTime:"",
      address:"",
      countryId:"",
      country:"",
      stateId:"",
      state:"",
      cityId:"",
      city:""
  
      });

      const handlechange =(event)=>{
        const {name,value,type,checked}=event.currentTarget;
        debugger
        setEventData(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))

        // if(event.currentTarget.files != null)
        // {
        //   setEventData(event.target.files[0]);
        // }
      }

      const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
         if (validator.isEmpty(eventdata.eventType)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventDate)) {
             setError(true);
         }
         else if (validator.isEmpty(eventdata.eventTime)) {
           setError(true);
         }
         else if (validator.isEmpty(eventdata.address)) {
           setError(true);
    
         }
         else if (validator.isEmpty(eventdata.country)) {
             setError(true);
    
         }
        
         else if (validator.isBoolean(eventdata.state)) {
         setError(true);

        }
        else if (validator.isEmpty(eventdata.city)) {
          
         setError(true);

        }
       
       
        
     else {
      
      addEventDetails();      
      
       }
    };

    const addEventDetails =()=>{
debugger
      // setEventData(preState => ({
      //   ...preState,
      //   attachFiles : serviceList
      // }))
      const formData = new FormData();

      formData.append('event',JSON.stringify(eventdata));
      
      formData.append('profilePhoto', attachment.profilePhoto);
      formData.append('photo1', attachment.photo1);
      formData.append('photo2', attachment.photo2);
       formData.append('photo3', attachment.photo3);
       formData.append('photo4', attachment.photo4);

       const response = axios.post(configData.ApiUrl+'/CommunityPost/createEvent',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
}
)
      response.then(() => {
        toast.success("Event Added Successfully!");
        props.handleClose(togglePopup)

      })
      response.catch((err) => {
        alert("Getting error in featching data")

      });
         console.log("response", response?.data)
          console.log("event",eventdata)
     }


     const [attachment, setAttachment] = useState({
      profilePhoto: "",
      photo1: "",
      photo2: "",
      photo3: "",
      photo4: "",
      
     })


     
      const countries = csc.getAllCountries();
      ////console.log(csc.getAllCountries())
    
      const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.id,
        ...country
      }));
      const updatedStates = (countryId) => csc.getStatesOfCountry(countryId)
      .map((state) => ({ label: state.name, value: state.name, ...state }));

  const updatedCities = (stateId) =>
    csc
      .getCitiesOfState(stateId)
      .map((city) => ({ label: city.name, value: city.name, ...city }));

    const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  
  const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }

  const [selected, setSelected] = useState('event');
  const handleChange = event => {
    setSelected(event.target.value);
  };

  //const [fileList, setFileList] = useState([]);
  
// var elementsd ="";

//   const handleFileChange = (e) => {
//     debugger;
//     setFileList(e.target.files);
   
//   };

//   //const [files,setfiles]=useState([]);
//   const files = fileList ? [...fileList] : [];
// var arraysFile=[];
//   const handleFileChange = (e) => {
//     debugger;
//     arraysFile=[];
//     setFileList(e.target.files);
//     //setfiles(e.target.files);
//      //files = fileList ? [...fileList] : [];
//      for(var i=0;i<e.target.files.length;i++){
//       arraysFile.push(e.target.files[i]);
//      }
//     // previewData()
//   };
  
  // function previewData ()
  // {
  //   debugger
  //   return files.map((file, i) => {
        
  //     return (<div key={i}>
  //       {file.name} - {file.type}
  //     </div>)
      
  // })
  // }
  // useEffect(() =>{
  //   // files = fileList ? [...fileList] : [];
  //  // previewData()
  // },[]);

  const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
  //for close popup when click on outside
// const popupEventdivRef = useRef(null);
// const handleClickOutsideEventdiv = (event) => {
//   if (popupEventdivRef.current && !popupEventdivRef.current.contains(event.target)) {
//     props.handleClose();
//   }
// };
// useEffect(() => {
//   document.addEventListener('mousedown', handleClickOutsideEventdiv);
//   return () => {
//     document.removeEventListener('mousedown', handleClickOutsideEventdiv);
//   };
// }, []);


  return (
    <div className="popup-boxnews">
      <div className="boxnews" >
                  <div className="topnewsrow"style={{paddingTop:"11px",paddingBottom:"2px"}}>
                  <div className="row " id="sharediv">
                    <div className="col-2"style={{marginTop:"-2px"}}><span>Share an</span></div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'event'}
                   value='event'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Event</label> */}
                     
                     <label class="radiodivcust radiobtn-text1" >Event
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'event'}
                   value='event'
                    name="example4"/> 
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'blog'}
                   value='blog'
                     name="example4" onClick={togglePopupBlog}/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Blog</label> */}
                     <label class="radiodivcust radiobtn-text1" >Blog
                     <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'blog'}
                   value='blog'
                     name="example4" onClick={togglePopupBlog}/>
                      <span class="checkmark"></span>
           </label>
                     {isOpenBlog && <AddBlogPopup
      
      handleClose={togglePopupBlog}
     />}
                    </div>
                    {/* <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                   value='recipie'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Recipie</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Recipie
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                   value='recipie'
                    />  
                      <span class="checkmark"></span>
           </label> */}
                    {/* </div>
                    <div className="col-2 whitenowrap  textleft"> */}
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                   value='vlog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Vlog</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Vlog
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                   value='vlog'
                    />  
                      <span class="checkmark"></span>
           </label>
                    </div> */} 
                    <div className="col-1 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                   value='kyc'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1">KYC
                     <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                   value='kyc'
                    name="example4"/> 
                      <span class="checkmark"></span>
           </label>
                     {isOpenkyc && <AddKycPopup
      
                  handleClose={togglePopupKyc}
                />}
                    </div>
                    <div className="col-2 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{marginLeft:"10px"}} >Looking for Job Post
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopuptopJobPop}
                    onChange={handleChange}
                    checked={selected === 'job'} 
                    value='job'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpentopjobPop && <AddJobPop
      
      handleClose={togglePopuptopJobPop}
     />}
                    </div>

                  </div>
                  </div>
                  <form onSubmit={submitFormData}>

                  <div className='row'style={{overflowY:"scroll",height:"246px"}}>
                    <div className='col-6'>
                    
                    <div className="row margintop3">
                    <div className="col-4 textright whitenowrap"><span className="lbl ">Host Name</span></div>
                    <div className="col-8 textleft "><span className="complet-name"style={{fontSize:"13px"}}>{authlocalstoragedata?.name2}</span></div>
                </div>
                <div className="row margintop3">
                    <div className="col-4 textright margintop1"><span className="lbl whitenowrap ">Event Type</span></div>
                    <div className="col-8 textleft">
                    <select   id="inputState" name='eventType' 
            className="form-control inputwidth" onChange={handlechange} style={{fontSize:"14px"}}>
            <option selected value=""> Select Event Type</option>
            <option value="House Warming Ceremony">House Warming Ceremony</option>
            <option value="Birthday">Birthday</option>
            <option value="Marrige Anniversary">Marrige Anniversary</option>
            <option value="Death Anniversary"> Death Anniversary</option>
            <option value="Others">Others</option>
       </select>
                    </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.eventType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Type  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                 
                <div className="row margintop3">
                    <div className="col-4 textright margintop1"><span className="lbl ">Date</span></div>
                    <div className="col-8 textleft">
                    <DatePicker
          className="adreservdate inputwidth"
          name='eventDate'
            selected={startDate}
             onChange={date => handleChangedate(date)}

            selectsStart // tells this DatePicker that it is part of a range*
            minDate={moment().toDate()}

            startDate={startDate}
            dateFormat="dd  MMM yyyy"
          />                       
           </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.eventDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                <div className="row margintop3">
                    <div className="col-4 textright margintop1"><span className="lbl ">Time</span></div>
                    <div className="col-8 textleft">
                        <input type="time" name='eventTime' class=" inputwidth form-control" onChange={handlechange}/>
                        </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.eventTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                <div className="row margintop3">
                    <div className="col-4 textright margintop1"><span className="lbl ">Address</span></div>
                    <div className="col-8 textleft form-group">
                    <textarea class="form-control inputwidth" name='address' id="exampleFormControlTextarea1" rows="2" placeholder="Enter Address"
                     onChange={handlechange}/>
                        </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.address == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Address  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                <div className="row margintop1">
                    <div className="col-4 textright margintop1"><span className="lbl ">Country</span></div>
                    <div className="col-8 textleft form-group">
                    <Select 
       id="country"
      name="country"
      label="country"
     className="countrydrp"
     rules={{ required: true }} 
    options={updatedCountries}
   
   onChange={(event) =>
    setEventData({...eventdata, country:event.name, countryId:event.id})
   }
  />              </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.country == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                
                <div className="row margintop1">
                    <div className="col-4 textright margintop1"><span className="lbl ">State</span></div>
                    <div className="col-8 textleft form-group">
                    <Select 
      name="state"
      id="state"
      className="countrydrp"
      
       options={updatedStates(eventdata.countryId ? eventdata.countryId : null)}
      
       onChange={(event) =>
        setEventData({...eventdata, state:event.name,stateId:event.id})
       }
    

      />                 </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.state == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            State  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                
                <div className="row margintop1">
                    <div className="col-4 textright margintop1"><span className="lbl ">City</span></div>
                    <div className="col-8 textleft form-group">
                    <Select 
      //required
id="city"
className="countrydrp"
name="city"
options={updatedCities(eventdata.stateId ? eventdata.stateId : null)}

 onChange={(event) =>
  setEventData({...eventdata, city:event.name,cityId:event.id})
 }

      />                 
       </div>
                </div>
                 <div className='row '>
                  <div className='col-4'></div>
               <div className="col-7">
                                    {error && eventdata.city == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            City  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                    </div>
                    
                    
                    
                    
                    <div className='col-6'>
                    <div className='row margintop'></div>
                    <div className='row margintop1'></div>
                    <div className='row margintop1'></div>


                         {/* <div className='row margintop'>
                            <div className='col-2'></div>
                            <div className='col-10 margintop3'>
                            <label for="selectFile" name="fileupload" className="uplod" style={{ backgroudColor: "white" }}   >
          
                           </label>
                     <input id="selectFile" type="file"  className="fileupload hide" multiple
                       name="fileupload"
                        />
                       <div><span className='photoupload-lbl'>Add Photos to your Post(optional)</span>
                       
                       <div>
      
      </div>
      </div> 
                              </div>

                            </div>  */}

{serviceList.map((singleService, index) => (
      <div className='row margintop1' key={index}  style={{whiteSpace:'nowrap'}}>

      <div  className='margintop1 col-lg-9 col-sm-9 col-md-9'>
      <input type="file" 
      accept="image/jpeg, image/png, image/svg+xml"
      class="form-control  textboxback" name='attachFiles'
     id="attachFiles" placeholder="" 
     value={singleService.service}
                onChange={(e) => handleServiceChange(e, index)}
     />
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
      {serviceList.length - 1 === index && serviceList.length < 5 && (
        <BsPlusLg onClick={handleServiceAdd} size='1.2em'/>
                
              )}
      </div>
      <div className='margintop3 col-lg-1 col-sm-1 col-md-1'>
              {serviceList.length !== 1 && (
                <MdOutlineDeleteOutline size='1.7em' onClick={() => handleServiceRemove(index)}/>
                
              )}
      </div>
      </div>

        ))}
                         

                            
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>





                   
                    </div>
                    
                  </div>
                  <div className='popupbtn-div margintop3'>
                  <div className='row '>
                    <div className='col-3'></div>
                    <div className='col-3'>
                        <button className='cancelbtn'  onClick={props.handleClose} style={{height:"42px",width:"140px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                    <button className='createeventbtn' type='submit' style={{height:"42px",width:"140px"}}>Create Event</button>
                  </div>
                  {/* {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />} */}

                  </div>
                  </div>
                  </form>


                  
      </div>
    </div>
  );
};
 
export default AddNewsPopup;