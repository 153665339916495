 import './MessagePopup.css'
 import conprof from '../../images/coneect.svg';
 import {FiMaximize2} from 'react-icons/fi'
 import {AiOutlineCloseSquare} from 'react-icons/ai'
 import React, { useState, useEffect ,useRef} from "react";
 import {over} from 'stompjs';
 import SockJS from 'sockjs-client';
 import axios from "axios";
 import { toast } from "react-toastify";
import { BiBorderRadius } from 'react-icons/bi';
import {configData} from "../../Config"
import ReactTooltip from "react-tooltip";

 var stompClient =null;

const MessagePopup = (props ,styleForMessgid,styleForMessgBlockstatus,uerAuthId,userlist,setuserlist)=> {

  //for messenger
  const [privateChats, setPrivateChats] = useState(new Map());     
  const [publicChats, setPublicChats] = useState([]); 
  const [tab,setTab] =useState("CHATROOM");
  // const [userData, setUserData] = useState({
  //     username: props.uerAuthId,
  //     receivername: props.styleForMessgid,
  //     connected: false,
  //     message: ''
  //   });
 


//   const sendPrivateValue=()=>{
//     if (stompClient) {
//       var chatMessage = {
//         senderId: userData.username,
//         receiverId:tab,
//         message: userData.message,
//         status:"MESSAGE"
//       };
      
//       if(userData.username !== tab){
//         privateChats.get(tab).push(chatMessage);
//         setPrivateChats(new Map(privateChats));
//       }
//       stompClient.send("/messenger/private-message", {}, JSON.stringify(chatMessage));
//       setUserData({...userData,"message": ""});
//     }
// }

// const handleMessage =(event)=>{
//   const {value}=event.target;
//   setUserData({...userData,"message": value});
// }
     const [userData, setUserData] = useState({
      senderId: props.uerAuthId,
      receiverId: props.styleForMessgid,
     message:"" 
     })

    //  const [msgData,setMsgData] = useState({
    //   toUserIdMsg:props.userId,
    //   userAuthId: props.uerAuthId

    //  })
 

     useEffect(() => {
      console.log(userData);
    }, [userData]);

     const handleMessage =(e)=>{
      const {name,value,type,checked}=e.currentTarget;
    debugger
    setUserData(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    }

    const [userDatamsgClear, setuserDatamsgClear] = useState(userData.message)

    const sendPrivateValue =()=>{
      debugger
    if(userData.message !=""&& userData.message != null){

if(userData.message.length >200){
  alert("Message length 200 charecters only")
}
else{
  const response = axios.post(configData.ApiUrl+'/messenger/sendMessage', userData)
      response.then(() => {
            //toast.success("Messege sent Successfully!");
            loadMesseges();
    loadUserListUpdate();

            setUserData(preState =>({
              ...preState,
              "message": ""
            }))
     })
         response.catch((err) => {
           alert("Getting error in featching data")
         });
         console.log("response", response?.data)
        
  console.log("meegsend",userData)
}
  
      
    }
       
          
    }

  const [expand, setExpand] = useState(false);
  const toggleExpand = () => {
    setExpand(!expand);
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.key === "Enter") {
    sendPrivateValue();

  }
};

  //for list of messeges
  const [listMesseges, setlistMesseges] = useState([]);
  const [MessegesData, setMessegesData] = useState([]);


  useEffect(() => {
    loadMesseges();
  },[listMesseges]);

  
  const loadMesseges = async () => {
    
    const result = await axios.get(configData.ApiUrl+'/messenger/listmessage?from='+userData.senderId+'&to='+userData.receiverId);

    setlistMesseges(result.data.data.messages);
    //setlistMesseges(result.data.data.messages.sort((a, b) => a.id - b.id));
    console.log("msgtdt",result.data.data.messages);

    setMessegesData(result.data.data);
    console.log("getmessegeslists",result.data.data)
debugger
    const el = document.getElementById('chat-feed');
    // id of the chat container ---------- ^^^
    if (el) {
      el.scrollTop = el.scrollHeight+10;
    }

   };

   //for scorll down auto
   const messageEl = useRef(null);
   const scrollToElement = () => messageEl?.current?.scrollIntoView();
  useEffect(()=>{
  if(messageEl){
    debugger
    scrollToElement()
  }
  }, [messageEl])
 
  //  const messageEl = useRef(null);

  //  useEffect(() => {
  //   if (messageEl) {
  //     messageEl?.current?.addEventListener('DOMNodeInserted', event => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
  //     });
  //   }
  // }, [])

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11", authlocalstoragedata)



  useEffect(() => {
    loadUserListUpdate();
  }, []);


  const loadUserListUpdate = async () => {
    const result = await axios.get(`${configData.ApiUrl}/messenger/getUsersListMessenger?hostId=${props.uerAuthId}`);

    props.setuserlist(result.data.data);
    console.log("getuserlistUpdated", result.data.data);
    // const rootElement17 = document.getElementById('profpicdisablesave17');

    // if(rootElement17){
    //   rootElement17.addEventListener('contextmenu', handleContextMenuimgdisable);
    // }
    // if(rootElement17){
    //   return () => {
    //     rootElement17.removeEventListener('contextmenu', handleContextMenuimgdisable);
    //   };
    // }
  };

//for close popup when click on outside
const popupMessagdivRef = useRef(null);
const handleClickOutsideMessagediv = (event) => {
  if (popupMessagdivRef.current && !popupMessagdivRef.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideMessagediv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideMessagediv);
  };
}, []);

  return (
    <div className="popup-boxmessage">
      {/* {listMesseges?.map((messglistdata,index)=>( */}
        <div className={expand?"boxmessageexp":"boxmessage"} ref={popupMessagdivRef}>
        <div className="border1pxformessge marginrightminusformefssg " >

          <div className="row">
            <div className="col-lg-3 col-md-5 col-sm-12 margintop1">
            <img src={MessegesData.profilePic}  className='' style={{width:"45px",height:"38px",paddingBottom:"2px"}}/>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className=""><span className="radius-fourth-card1-lefttext " >{MessegesData.name}</span>        
              <div>
              <span className=" message-text" style={{lineHeight:'80%'}}>
            {MessegesData.status}
                </span>
                </div>
                
     </div>
    
 
            </div>
            <div className='col-lg-1 col-sm-12 col-md-12'>
     <AiOutlineCloseSquare color='rgba(238, 118, 0, 1)' size='1.5em' className={expand?"closemegpopiconexp":'closemegpopicon'} onClick={props.handleClose} style={{marginLeft:'0px', marginTop:'10px',cursor:"pointer"}}/>

     </div>
            
            </div>
          
            </div>
            <div className={props.styleForMessgBlockstatus==true?'blurmsg':""}>
            <div id={"chat-feed"} className={expand?"messageDiv-divexp":"messageDiv-div"} >

            {listMesseges.map((messglist,index)=>(
               <div className={expand?"messagemain-divexp":"messagemain-div"}  style={{height:"auto"}}>
               <div className="row margintop1" style={{justifyContent:"center"}} >
                <div className="col-lg-2 "></div>
                <div className="textCenter col-lg-6 col-md-12 col-sm-12"  style={{justifyContent:"center"}}>
                  {
                    (listMesseges[index-1]?.date == messglist.date)  ?
                    (<span className="messg-date-text hide"></span>)
                    :
                    (<span className="messg-date-text">{messglist.date}</span>)

                  }
                  </div>
                </div>  

                <div className="radius-fourth-card1 bordernonemessg margintop3">
          <div  className={messglist.side=="Left"?"row margintop1":"hide"}>
            
            <div className="col-lg-9 col-sm-12 col-md-12">
              <div className=""><span className="radius-fourth-card1-lefttext " key={messglist.senderId}>{messglist.senderName}</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'10px'}}>{messglist.time}</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%',backgroundColor:"#F2F2F2" ,borderRadius:"3px",paddingLeft:"5px",paddingRight:"6px",padding:"3px"}}>
               {messglist.message}
                </span>
              </div>
 
            </div>
            
            </div>

            <div className={messglist.side=="Right"?"row margintop1":"hide"} >
            <div className="col-lg-5 col-sm-12 col-md-12 margintop1">
            {/* <img src={conprof}  className=''/> */}
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className=""><span className="radius-fourth-card1-lefttext " key={messglist.senderId}>{messglist.senderName}</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'10px'}}>{messglist.time}</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%',backgroundColor:"rgb(175, 250, 175)" ,borderRadius:"3px",paddingLeft:"5px",paddingRight:"6px",padding:"3px"}} ref={messageEl}>
               {messglist.message}
                </span>
              </div>
 
            </div>
            
            </div>
            </div>
            {/* <div className="row margintop2" style={{justifyContent:"center"}}>
                <div className="col-2"></div>
                <div className="textCenter col-6"  style={{justifyContent:"center"}}><span className="messg-date-text">15 Aug 2022</span></div>
                </div>  

                <div className="radius-fourth-card1 bordernonemessg margintop3">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'10px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div>
            

                <div className="radius-fourth-card1 bordernonemessg margintop3 ">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Vivek Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'10px'}}>2:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
              </span>
              <span className="message-text ">consectetur adipiscing elit. Aenean euismod bibendum</span>
              </div>
 
            </div>
            
            </div>
            </div>

            <div className="row margintop2" style={{justifyContent:"center"}}>
                <div className="col-2"></div>
                <div className="textCenter col-6"  style={{justifyContent:"center"}}><span className="messg-date-text">25 Feb 2022</span></div>
                </div>  

                <div className="radius-fourth-card1 bordernonemessg margintop3">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'10px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div> */}
               </div>
                     ))}
               </div>
               </div>
               {
                authlocalstoragedata?.directory==false?(
                  <div className="border1pxformessge margintop3">
               <div className="row margintop3" >
               <div className="col-lg-10 col-sm-12 col-md-12 textleft form-group">
  <input type="text" className="disableMessageText form-control" id="fst"
         name="message"
         placeholder="Go For Premium"
        //  onChange={handleMessage} 
        //  value={userData.message}
        //  onKeyPress={handleKeypress}

         style={{height:'32px',fontSize:"12px"}}
    />
  </div>
  <div className="col-lg-1 col-sm-10 col-md-10">
    <FiMaximize2  style={{cursor:'pointer'}}/>
  </div>
               </div>

               
               </div>

                ):
                (<div className="border1pxformessge margintop3">
                <div className="row margintop3" >
               {props.styleForMessgBlockstatus==true?(
               <div className="col-lg-10 col-sm-12 col-md-12 textleft form-group">

<input type="text" className=" form-control" id="fst"
       name="message"
       placeholder="Please Unblock First"
       value={userData.message}

       style={{height:'32px',fontSize:"12px"}}
  />
</div>):
(<div className="col-lg-10 col-sm-12 col-md-12 textleft form-group">

<input type="text" className=" form-control" id="fst"
       name="message"
       placeholder="Type Your Message"
       onChange={handleMessage} 
       value={userData.message}
       onKeyPress={handleKeypress}

       style={{height:'32px',fontSize:"12px"}}
  />
</div>)
}
                
   <div className="col-lg-1 col-sm-10 col-md-10">
     <FiMaximize2 onClick={toggleExpand} style={{cursor:'pointer'}}/>
   </div>
                </div>
 
                
                </div>)
               }

{
                authlocalstoragedata?.directory==false?(
               <div className="row margintop5">
                
                <div className="col-lg-8 col-sm-12 col-md-12"></div>
                
                <button className="disableMessageText messgsend-btn" data-tip data-for="registerTip1"       
           type='submit'>Send</button>
           <ReactTooltip id="registerTip1" place="right" backgroundColor='#B5924C' class='tooltip width' effect="solid">
                 Go for Premium
                 </ReactTooltip>
               </div>
                ):(
                  <div className="row margintop5">
                
                <div className="col-lg-8 col-sm-12 col-md-12"></div>
                {props.styleForMessgBlockstatus==true?(
               <button className="messgsend-btn disableMessageText"       
           type='submit' data-tip data-for="registerTip2" style={{backgroundColor:"lightgray"}}>Send</button>
           
           ):
           (<button className="messgsend-btn" onClick={sendPrivateValue}      
           type='submit'>Send</button>)
                }
               </div>
                )
}

      </div>
      {/* ))} */}
    </div>
  );
};
 
export default MessagePopup;