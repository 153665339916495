import './SignUpBusiness';
import React, { useState, useEffect } from "react";
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
import { event } from 'jquery';
import OtpInput from 'react-otp-input';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {configData} from "../../Config"


const BusinessDetail=({ setbusinessSign, businessSign, handleNext, handleBack,confirmPass,setConfirmPass,confirmPassErr,setconfirmPassErr,resendOtp,setOtpNew,otpNew })=> {

  


  

  

 let history = useHistory();


 const redirectoLogin = () => {
   history.push("/Login") 
 };
  
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)
    
const sentOtp = async()=>{

  setCounter(30)
      setenableResend(false);
        const result = await axios.get(configData.ApiUrl+'/buisness/sendOTP?mobileNo='+
        authlocalstoragedata?.mobileNumber+ '&emailId='+authlocalstoragedata?.emailId+'&countryCode='+authlocalstoragedata?.countryCode);
      console.log("sendotp",result.data.data)
        if(result.data.code==200){
          toast.success("Otp Sent Successfully!");
    
          //newStepValue=1;
        }
       
        else{
          //newStepValue=0;
      
        
        
      }    
       
      
    }

    const handleChange=(e)=>{
     const {name,value,type,checked}=e.currentTarget;
    
    //  setLastname(preState =>({
    //    ...preState,
    //    [name]:type ==='checkbox'?checked: value
    //  }))
     
    
    setbusinessSign(preState =>({
       ...preState,
       [name]:type ==='checkbox'?checked: value
     }))
    
//     setConfirmPass(preState =>({
//       ...preState,
//       [name]:type ==='checkbox'?checked: value
//     }))
  
   }

    const [error, setError] = useState(false);
    const submitFormData = (e) => {
     debugger

    e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
     
      if (validator.isBoolean(businessSign.womenEntrepreneur)) {
      setError(true);

     }
     else if (businessSign.buisnessName==""||businessSign.buisnessName==null) {
                setError(true);
           }
    else if (businessSign.designation==""||businessSign.designation==null) {
            setError(true);
       }
    else if (businessSign.buisnessType==""||businessSign.buisnessType==null) {
        setError(true);
    }
    else if (businessSign.buisnessName.length > 75) {
        setError(true);

      }
     
    
    else {
      debugger
      
   handleNext();
}
    
   };
  

const [enablejoin, setenableResend] = useState(false);


  const counterandbtn =()=>{
    setCounter(30);
    setenableResend(false);
   resendOtp() ;
  }
  
      const [counter, setCounter]= useState(0)
;
    useEffect(() =>{
      if(counter === 0){
        setenableResend(true);

    
      }
      
      const timer =
      counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
      return () => clearInterval(timer);
    },[counter]);


return (
        <div className="container">
          {/* <form > */}
         
          <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Business Name

<span className="asteric"></span></span>
  </div>
 
  <div className="col-6  textleft">

  <input type="text" className=" form-control" id="fst"
                      name="buisnessName"
                      placeholder="Type Business Name 75 char max"
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                      value={businessSign.buisnessName}
                 />  </div>


   
    </div>
</div>

            </div>
            <div className='row '>
    <div className='col-3'></div>
    <div className=" col-6 textcenter">
                                    {error && businessSign.buisnessName == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  businessSign.buisnessName.length >75 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Business Name  cannot exceed more than 75 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
            <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Business Type

<span className="asteric"></span></span>
  </div>
 
  <div className="col-6  textleft">

  <select   id="inputState" name='buisnessType' onChange={handleChange}  value={businessSign.buisnessType}
            className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}} >
              <option selected value=""> Select</option>
              <option  value="Proprietorship">Proprietorship</option>

             <option value="Partnership">Partnership</option>
            <option  value="Limited Liability PartnerShip"> Limited Liability PartnerShip</option>
            <option  value="Private Limited Company"> Private Limited Company</option>
            <option  value="Public Limited Company">Public Limited Company</option>
            <option  value=" One-Person Company"> One-Person Company</option>
            <option  value="Non Government Organization"> Non Government Organization</option>
            </select>

   
    </div>
</div>

            </div>
          </div>
          <div className='row '>
    <div className='col-3'></div>
    <div className=" col-6 textcenter">
                                    {error && businessSign.buisnessType == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
          <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Your Designation<span className="asteric"></span></span>
  </div>
 
  <div className="col-6  textleft">

  <select   id="inputState" name='designation' onChange={handleChange} value={businessSign.designation}
            className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}} >
              <option selected value=""> Select</option>
              <option  value="Proprietorship">Owner</option>

             <option value="Partnership">Managing Director</option>
            <option  value="Partner">Partner</option>
            <option  value="Founder">Founder</option>
            <option  value="CEO">CEO</option>
            <option  value="Others">Others</option>
            </select>

   
    </div>
</div>

            </div>
          </div>
          <div className='row '>
    <div className='col-3'></div>
    <div className=" col-6 textcenter">
                                    {error && businessSign.designation == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> 
         
      <div className="row margintop">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Are You Women Enterpreneur<span className="asteric"></span></span>
  </div>
 
  <div className="col-2  textleft" style={{marginTop:"4px"}}>

<label class="radiodivcust radiobtn-text1">Yes
<input type="radio" id="defaultRadio2" className=' radiobtn1' name="womenEntrepreneur" checked={businessSign.womenEntrepreneur=="Yes"?true:false} radioGroup="A"  value='Yes' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>
<div className="col-2  textleft" style={{whiteSpace:"nowrap",marginTop:"4px"}}>

    <label class="radiodivcust radiobtn-text1">No
    <input type="radio" id="defaultRadio3" className='radiobtn1' name="womenEntrepreneur" checked={businessSign.womenEntrepreneur=="No"?true:false} radioGroup="A"  value='No' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>

   
    </div>
</div>

            </div>
             <div className='row '>
    <div className='col-3'></div>
    <div className=" col-6 textcenter">
                                    {error && businessSign.womenEntrepreneur == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
         
         


<div className='row margintop3'>
  <div className='col-5'></div>
  <div className='col-2'>
  <button className='messgsend-btn' onClick={sentOtp}>Send Otp</button>

  </div>

  <div className='col-3'></div>

  </div>

<div className="row margintop3">
<div className="col-11">
  <div className='row'>
    <div className='col-3'></div>
  <div className='col-8'>
    <span className='lbl'>Enter the OTP sent to your mobile number {authlocalstoragedata?.mobileNumber}</span>

    </div>

  </div>
</div>
 </div>
 <div className="row margintop3">
<div className="col-11">
  <div className='row'>
  <div className='col-4'></div>
  <div className='col-7' style={{marginLeft:"16px"}}>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>
  </div>
 

</div>
 </div>

 <div className="row margintop3">
<div className="col-11">
 <div className=' row margintop1 '>
              <div className='col-4'></div>
              <div className=' col-8'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'}  onClick={()=>counterandbtn()}>Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            </div>
            </div>
            
           <div className="margintop1 row ">
            <div className="col-4 "></div>
            <div className="col-3 margintop textright">
            <button
               className="displynoneprev"
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop1 ">
          <button
            className="nextbtn "
              variant="contained"
              color="primary"
               onClick={submitFormData}
              //type="submit"
            >
              Next
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className="margintop1 textcenter row">
            <div className="col-5"></div>
            <div className="margintop col-4">
            <span className="alreadyspn marginleftmins1">Already a registered member ?</span>
            <span className="signin-stepper marginleft5" onClick={redirectoLogin} style={{cursor:"pointer",marginLeft:"-3px"}}>Sign In</span>
            </div>
           
            </div>

            <div className="margintop  row"></div>

            
          </div>  
          {/* </form> */}
           

            
            

     
</div>
    );
  }
  export default BusinessDetail;