import '../ViewProfile/ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import MessagePopupDirectory from './MessegePopupDirectory';
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop,MdEmail,MdCheckBox } from 'react-icons/md';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark,FaPhoneSquareAlt,FaRegCalendar,FaUsers } from 'react-icons/fa';
import { BsBookmark } from 'react-icons/bs';
import {configData} from "../../Config"

const ViewProfCommunityDirectPop = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


     const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
     console.log("authdata11",authlocalstoragedata)
    
     const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
     const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

     let history = useHistory();

     //for new session for userlogin
  

//  const [referData ,setreferData] = useState({
  
//   referralCode: uerAuthReferralCode,
//   firstName: "",
//   lastName: "",
//   countryCode: "",
//   emailId: "",
//   mobileNumber: "",
  
//  });

//  const referChange = (e) => {
//   debugger;
//   const { name, value, type, checked } = e.currentTarget;


//   setreferData(preState => ({
//     ...preState,
//     [name]: type === 'checkbox' ? checked : value
//   }));
// }
// const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
// const [error, setError] = useState(false);
// //for email
//    const submitFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
   
//      if (validator.isEmpty(referData.firstName)) {
//         setError(true);
//     }
//    else if (validator.isEmpty(referData.lastName)) {
//       setError(true);
//    }
//    else if (validator.isEmpty(referData.countryCode)) {
//     setError(true);
//   }
//    else if (validator.isEmpty(referData.emailId)) {
//     setError(true);
//    }
//    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
//     setemailEroor(true);
//   }
//    else if (validator.isEmpty(referData.mobileNumber)) {
//     setError(true);
//    }
//    else if (referData.mobileNumber.length != 10) {
//     setError(true);
  
//   }
//   else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
//     setContactValidEroor(true);
    
//   }
    
//     else {
//       debugger
//       addReferalinvite();
//     }
// };

// const addReferalinvite =()=>{
  
// const response = axios.post('/subscriber/inviteMember', referData)
//     response.then(() => {
//           toast.success("Innvite Member Successfully!");

//       })
//        response.catch((err) => {
//          toast.warn("Something went wrong")
//        });
//        console.log("response", response?.data)
//        console.log("InviteMember",referData)
      
// }

//for commDirectry list
const [communityData, setcommunityData] = useState([]);

    useEffect(() => {
      loadCommunityData();
    }, []);
  
    const loadCommunityData = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/viewProfileById?hostId=' +props.uerAuthId+ '&userId=' +props.idforcommpop);
      setcommunityData(result.data.data);
      console.log("communityData",result.data.data);
    };

if(communityData.mobileVisibility==0){
 var mobileNumberformat = communityData.mobileNumber.substr(0, 2) + 'xxxxxx'
          + communityData.mobileNumber.substr(8, 9);
}
else{
 var mobileNumberformat=communityData.mobileNumber
}

    //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);
  const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);

  
  const togglePopupMessageDirect = (id,blockStatus) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id);
    setstyleForMessgBlockstatusCommView(blockStatus)

  }

  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommunityData();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }
  
  const addCommDirectoryBookmark = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
    if (response.status == 200 && response.data.code) {
      //toast.success("Bookmarked Successfully!")
      loadCommunityData();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  //for close popup when click on outside
const popupMessagdivRef = useRef(null);
const handleClickOutsideMessagediv = (event) => {
  if (popupMessagdivRef.current && !popupMessagdivRef.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideMessagediv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideMessagediv);
  };
}, []);
  return (
    <div className="popup-boxrefmember1" ref={popupMessagdivRef}>
      <div className="boxrefmember1" style={{width:"47%",height:"420px"}}>
      <span className="close-commdirect" onClick={props.handleClose}>x</span>

      <div className=" ">
                <div className="row  cardfirst-toprow11 topnewsrow">
                  <div className="col-lg-4 col-sm-12 col-md-4 margintop1"><span className="community-direct-nametxt">{communityData.firstName} {communityData.lastName}</span></div>
                  <div className="col-lg-1  col-sm-5 col-md-4 textleft">
                    <img src={flag} className='margintop2 marginleftmins' />
                  </div>
                  <div className="col-lg-4 col-sm-5 col-md-4 margintop1"><span className="marginleftmins6 community-country">{communityData.currentCountry}</span><span className="communi-field marginleft1">{communityData.natureOfBuisness}</span></div>
                  <div className="col-lg-3 col-md-6 col-sm-12 whitenowrap messg-div" onClick={() => togglePopupMessageDirect(communityData.id,communityData.blockStatus)}> <RiSendPlaneFill size='1.3em' className='marginleftmins5' />  Message {communityData.firstName}
                  </div>
                  {isOpenMessagePopDirect && <MessagePopupDirectory

handleClose={togglePopupMessageDirect} uerAuthId={uerAuthId} idforpop={idforpop} styleForMessgBlockstatusCommView={styleForMessgBlockstatusCommView} 
/>}
                </div>
                

                
<div >

<div className='row mt-3'>
    <div className='col-3'></div>
                   <div className="col-8"><span className=" communtdrc-cit-text">About {communityData.firstName} {communityData.lastName}</span>  <span className=" marginleft4 comudrct-digitxt">                    
                    {communityData.bookmarkStatus == true ? <FaBookmark color='#555555' size='1.5em'  onClick={() => addCommDirectoryBookmark(communityData.id)}   style={{ cursor: "pointer" }} /> : <BsBookmark  style={{ cursor: "pointer" }} color="#555555"  onClick={() => addCommDirectoryBookmark(communityData.id)}  size='1.5em'/>} Bookmark Profile
</span></div>

    </div>
    <div className='row mt-1'>
    <div className='col-3'></div>
                   <div className="col-9"> 
                    <span className=" marginleft4 comudrct-digitxt">                    
                    {/* IT Professional | Chess Enthusiast | Spiritual | Passionate About Culture */}
                    {communityData.bio}

</span>
</div>
    </div>
<div className="row ">
                  <div className="col-3">
                    <img src={communityData.profilePicturePath} className='communitydir-prof11' />
                    <span className="ml-2 menu-veryfyprof textcenter"><FaUserClock color="#FF8427" size="0.9em" className="menu-veryfyprof" /> Verified Profile</span>
                 <div>
                  <div className='row' style={{whiteSpace:"nowrap",display:"inline"}}>
                    <div className='col-8' style={{whiteSpace:"nowrap"}}><span className='comudrct-digitxt'><MdCheckBox color="#FF8427" size="0.9em"/> Mobile No</span></div>
                    <div className='col-5' style={{whiteSpace:"nowrap"}}><span className='comudrct-digitxt'><MdCheckBox color="#FF8427" size="0.9em"/> KYC</span></div>
                 </div>
                  </div>
                  </div>

                  <div className="col-4 margintop1">
                   
                   
                  <div className="row margintop3">
                      <div className="col-1">
                        <img src={from} className='communitydrect-img' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">{communityData.homeCity}</span>
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                      <FaPhoneSquareAlt color="#555555" size='1.2em'/>
                      </div>
                      <div className="col-9  whitenowrap textleft">
                          <span className=" comudrct-digitxt">{mobileNumberformat}, {communityData.secondaryMobileNumber}</span>
                      </div>
                    </div>
            <div className="row margintop3">
                      <div className="col-1">
                        <img src="" className='communitydrect-img' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">{communityData.id}</span>
                      </div>
                    </div>
                    
                    <div className="row margintop3">
                      <div className="col-1">
                        <FaRegCalendar size='1.2em' color='#555555' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className="communtdrc-cit-text"> DOB -</span>  <span className="comudrct-digitxt" style={{ textTransform: "capitalize" }}> {communityData.birthDate}</span>
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                        <img src={refferal} className='communitydrect-img' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">{communityData.referralCount}</span>
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                        <MdWaterDrop size='1.3em' color='#555555' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt" style={{ textTransform: "capitalize" }}>{communityData.bloodGroup}</span>
                      </div>
                    </div>
                    
                  
                  </div>

                  <div className="col-4 margintop1">
                    
                    <div className="row margintop3">
                      <div className="col-1">
                        <MdLocationOn size='1.4em' color='#555555' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">{communityData.currentCity},{communityData.currentState}</span>
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                      <MdEmail color="#555555" size='1.2em'/>
                     </div>
                      <div className="col-9  whitenowrap textleft">
                          <span className="comudrct-digitxt">{communityData.emailId}</span>
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                        <img src="" className='communitydrect-img' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Member Since- -{communityData.joinDate}</span>  
                      </div>
                    </div>
                    <div className="row margintop3">
                      <div className="col-1">
                        <img src={subcast} className=' communitydrect-img' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt" style={{ textTransform: "capitalize" }}>{communityData.subCaste}</span>
                      </div>
                    </div>
                   
                    <div className="row margintop3">
                      <div className="col-1">
                        <RiCupFill color="#555555" size='1.3em' />
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">{communityData.coffeeMeetsAttended}</span>
                      </div>
                    </div>
                    
                    <div className="row margintop3">
                      <div className="col-1">
                        <FaUsers size='1.5em' color="#555555"/>
                      </div>
                      <div className="col-9  whitenowrap textleft">
                        <span className=" communtdrc-cit-text">Family Members -</span>  <span className="comudrct-digitxt">{communityData.familyCount}</span>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div className="row margintop">
                  <div className="col-1"></div>
                  {/* <div className="col-2">
                    <span className="community-viewprof">View Profile</span>

                  </div> */}

                  <div className="col-6"></div>

                   <div className="col-3 margintop1 textcenter">
                    <button className={communityData.connectionStatus == false && communityData.blockStatus == 0 ? "com-conect-btn" : "hide"} style={{ marginLeft: "10px", color: "#F2F2F2", backgroundColor: "#FF8427" }} onClick={() => addCommDirectoryConnect(communityData.id)}>Connect</button>
                    <span className={communityData.blockStatus == 1 ? "communtdrc-cit-text" : "hide"} style={{ marginLeft: "10px", fontSize: "13px", color: "#555555" }} >Blocked</span>

                    <span className={communityData.connectionStatus == true ? "community-direct-nametxt11" : "hide"}>Connection Request Sent</span>

                  </div> 
                  {/* <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div> */}
                </div>
              </div>
    
                  </div>

                  
      </div>
    </div>
  );
};
 
export default ViewProfCommunityDirectPop;