import './SignUpBusiness.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import gethighlighted from '../../images/gethighlighted.png';
import growyrnetwork from '../../images/growyournetwk.png';
import expanreach from '../../images/expandurreach.png';
import supoortbusness from '../../images/supportlocalbusness.png';
import {configData} from "../../Config"




import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop,MdEmail,MdCheckBox } from 'react-icons/md';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark,FaPhoneSquareAlt,FaRegCalendar,FaUsers } from 'react-icons/fa';
import { BsBookmark } from 'react-icons/bs';
import SignUpBusiness from './SignUpBusiness';
 
const BusinessFirstPage = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

     const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
     console.log("authdata11",authlocalstoragedata)
    
     const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
     const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);


//  const [referData ,setreferData] = useState({
  
//   referralCode: uerAuthReferralCode,
//   firstName: "",
//   lastName: "",
//   countryCode: "",
//   emailId: "",
//   mobileNumber: "",
  
//  });

//  const referChange = (e) => {
//   debugger;
//   const { name, value, type, checked } = e.currentTarget;


//   setreferData(preState => ({
//     ...preState,
//     [name]: type === 'checkbox' ? checked : value
//   }));
// }
// const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
// const [error, setError] = useState(false);
// //for email
//    const submitFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
   
//      if (validator.isEmpty(referData.firstName)) {
//         setError(true);
//     }
//    else if (validator.isEmpty(referData.lastName)) {
//       setError(true);
//    }
//    else if (validator.isEmpty(referData.countryCode)) {
//     setError(true);
//   }
//    else if (validator.isEmpty(referData.emailId)) {
//     setError(true);
//    }
//    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
//     setemailEroor(true);
//   }
//    else if (validator.isEmpty(referData.mobileNumber)) {
//     setError(true);
//    }
//    else if (referData.mobileNumber.length != 10) {
//     setError(true);
  
//   }
//   else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
//     setContactValidEroor(true);
    
//   }
    
//     else {
//       debugger
//       addReferalinvite();
//     }
// };

// const addReferalinvite =()=>{
  
// const response = axios.post('/subscriber/inviteMember', referData)
//     response.then(() => {
//           toast.success("Innvite Member Successfully!");

//       })
//        response.catch((err) => {
//          toast.warn("Something went wrong")
//        });
//        console.log("response", response?.data)
//        console.log("InviteMember",referData)
      
// }

//for commDirectry list
const [communityData, setcommunityData] = useState([]);

    useEffect(() => {
      loadCommunityData();
    }, []);
  
    const loadCommunityData = async () => {
      debugger
      const result = await axios.get('/subscriber/viewProfileById?hostId=' +props.uerAuthId+ '&userId=' +props.idforcommpop);
      setcommunityData(result.data.data);
      console.log("communityData",result.data.data);
    };

    //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);

  
  const togglePopupMessageDirect = (id) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id)
  }

  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post('/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommunityData();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }

  const [isOpenSignBusinessPopup, setisOpenSignBusinessPopup] = useState(false);
  const togglePopupSignUpBusiness = () => {
    setisOpenSignBusinessPopup(!isOpenSignBusinessPopup);
  }

  return (
    <div className="popup-boxSign">
      <div className="boxSign" style={{height:"470px"}}>
      <span className="close-iconSign" onClick={props.handleClose}>x</span>

      <div className="margingleftrightminus topnewsrow" style={{backgroundColor:"#214B0D",color:"#F2F2F2",paddingTop:"24px",paddingBottom:"24px",}}>
                <div className="row  margingleftrightminus ">
                    <div className='col-lg-4'></div>
                  <div className="col-lg-4 col-sm-12 col-md-4 mt-2"><span className="community-direct-nametxt" style={{color:"#F2F2F2"}}>Calling All Entrepreneurs and Business Owners</span></div>
                  
                </div>    
                  </div>
<div className='row mt-4'>
    <span className='unlock-text'>"Subscribe to Naidu Business Directory in 3 easy steps and unlock a world of opportunities

</span>
</div>
<div className='row mt-5'>
  <div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={gethighlighted} className='' />
  <div className=''><span className='communtdrc-cit-text'>Get Highlighted</span></div>
</div>
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={growyrnetwork} className='' />
  <div className=''><span className='communtdrc-cit-text'>Grow Your Network</span></div>
</div>
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={expanreach} className='' />
  <div className=''><span className='communtdrc-cit-text'>Expand Your Reach</span></div>
</div> 
<div className='col-lg-3 col-sm-12 col-md-6 textcenter'>
  <img src={supoortbusness} className='' />
  <div className=''><span className='communtdrc-cit-text'>Support Local Businesses</span></div>
</div>
</div>

      <div className='row mt-4 textcenter'>
        <div className='col-lg-12 col-sm-12 col-md-12'>
          <span className='comudrct-digitxt'> Subscribe to the Naidu Community Business Directory today and watch your business</span>
        </div>
        </div>  
        <div className='row textcenter'>
        <div className='col-lg-12 col-sm-12 col-md-12'>
      <span className='comudrct-digitxt'>
      flourish within our community. Let's grow together!" 
      </span>
      </div>
          </div> 
         

        <div className='row mt-4'>
          <div className='col-lg-2'></div>
          <div className='col-lg-3 col-sm-12 col-md-6 '>
            <button className='nidudircancelbtn' onClick={props.handleClose}>I will do this later</button>
          </div>
          <div className='col-lg-4 col-sm-12 col-md-6 ml-2'>
          <button className='nidudircanNextbtn' style={{width:"220px"}} onClick={togglePopupSignUpBusiness}>Join Business Listing Now</button>
          {isOpenSignBusinessPopup && <SignUpBusiness
                    handleClose={togglePopupSignUpBusiness}
                  />
                  }
          </div>

          </div>        
      </div>
    </div>
  );
};
 
export default BusinessFirstPage;