
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"


const BusinessAboutBusiness = ({additional,setadditional,handleBack,handleNext}) => {

  //for session

  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setadditional(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(additional.birthday);
    }
    
      const [error, setError] = useState(false);
      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (additional.aboutBuisness==""||additional.aboutBuisness==null) {
          setError(true);
        }
        
        else {
          debugger
          handleNext()
        }
    };

      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setadditional(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
    
  
return(
    
<div className="container">
<form >
        <div className="row margintop">
          <div className="col-10" id="completeprofradio">
            <div className="row textLeft">
                <div className="col-lg-2 col-sm-2 col-md-2"></div>
                <div className="col-lg-7 col-sm-12-col-md-12"> 
                     <span className="lbl popinsfont"style={{fontSize:'14px'}}>About Business</span>
           </div>

</div>
         <div className="row margintop1">
            <div className="col-lg-2 col-sm-2 col-md-2"></div>

<div className="col-lg-10 col-sm-12 col-md-12 textleft">
<textarea class="form-control" name='aboutBuisness'  id="exampleFormControlTextarea1" rows="15" onChange={handleChange} placeholder="" style={{fontSize:"12px"}}/>
</div>
         </div>
        
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.aboutBuisness == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            About Buisness  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
          </div>  
          <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              onClick={submitFormData}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>    
      </div>
</form>
    </div>
)
}
export default BusinessAboutBusiness