import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Avatar,Box,Paper } from "@material-ui/core";
import {AiOutlineLeft,AiOutlineRight} from "react-icons/ai"
import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from 'react-grid-carousel';
import { useHistory } from "react-router-dom";
import {configData} from "../../Config"


const SliderPageNew = () => {

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11",authlocalstoragedata)
  
  let history = useHistory();

  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
//for new session for userlogin


  const [bloodReqSlider, setbloodReqSlider] = useState([]);
  
       useEffect(() => {
         loadBloodReqSlider();
       }, []);
    
      const loadBloodReqSlider = async () => {
        
        const result = await axios.get(`${configData.ApiUrl}/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
        setbloodReqSlider(result.data.data);
        console.log("bldreqslider",result.data.data)
      };

  
  return (
    <div>
        <Carousel cols={1} rows={1} gap={10} loop autoplay={2000} hideArrow={true} >
        {
                  bloodReqSlider.map((getmenu, index) => (
             <Carousel.Item>
          <div className="row" style={{marginTop:'3px'}}>
        <div className="col-2">
         <span className="slider-location-name"> {getmenu.reqCity}</span>
        </div>
        <div className="col-5"></div>
        <div className="col-5 textleft"><span className="slider-name "> {getmenu.raisedBy}</span>
      </div>
      </div>
      <div className="row">
        <div className="col-3">
        </div>
        <div className="col-5" style={{marginLeft:"10px"}}>
          <span className="bpositiv">{getmenu.bloodGroup}</span>
        </div>
        <div className="col-4 textright ">
      </div>
      </div>
      <div className="row margintop3" style={{whiteSpace:"nowrap"}}>
        <div className="col-6 " >
          <span className="slider-name " style={{textAlign:"right"}}>Date Required:</span>
        </div>
        <div className="col-5 textleft">
          <span className="slider-name marginleftmins4"> {getmenu.reqDate}</span>
        </div>
        <div className="col-1 ">
      </div>
      </div>
      <div className="row">
        <div className="col-4 ">
          <span className="slider-name "style={{textAlign:"right"}}>Contact:</span>
        </div>
        <div className="col-4 textleft">
          <span className="slider-name " style={{marginLeft:"24px"}}>{getmenu.contactNo}</span>
        </div>
        <div className="col-1">
      </div>
      </div>     
       </Carousel.Item>
       
                  ))
}
{
     bloodReqSlider  && bloodReqSlider.length== 0 &&(
      <span className="slider-name textcenter">No Blood Requirements Currently</span>
     )
}
                    {/* {members.map((image, index) => (

                      <Carousel.Item onClick={openLightbox}>
                        <img width="100%" src={image.profilePhotoPath} title={image.name} style={{ cursor: "pointer" }} />
                      </Carousel.Item>
                    ))
                    }
                    {
                      members && members.length == 0 && (
                        <Carousel.Item >
                          <div style={{ width: "100%", height: "20px", color: "gray", marginLeft: "90px", paddingTop: '10px' }}>0 Members</div>
                        </Carousel.Item>
                      )
                    } */}
                   
                  </Carousel>
    </div>
  );
};


// const Card1 = ({ img }) => {
//   return (
//     <div
//       style={{
//         color: "gray",
//       }}
//     >
//       <div className="row">
//         <div className="col-2">
//          <span className="slider-location-name"> Pune</span>
//         </div>
//         <div className="col-5"></div>
//         <div className="col-5 textleft"><span className="slider-name whitenowrap">Sagar Naidu</span>
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-3">
//         </div>
//         <div className="col-5">
//           <span className="bpositiv">B+Ve</span>
//         </div>
//         <div className="col-4 textright ">
//       </div>
//       </div>
//       <div className="row margintop1">
//         <div className="col-6 ">
//           <span className="slider-name ">Date Required:</span>
//         </div>
//         <div className="col-5 textleft">
//           <span className="slider-name marginleftmins4"> 05 Nov 2022</span>
//         </div>
//         <div className="col-1 ">
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-4 ">
//           <span className="slider-name ">Contact:</span>
//         </div>
//         <div className="col-4 textleft">
//           <span className="slider-name ">9923474723</span>
//         </div>
//         <div className="col-1">
//       </div>
//       </div>
//       <div className="row margintop3"></div>
//       <div className="row margintop3"></div>

      
      
//     </div>
//   );
// };
// const Card2 = ({ img }) => {
//   return (
//     <div
//       style={{
//         color: "gray",
//       }}
//     >
//       <div className="row">
//         <div className="col-2">
//          <span className="slider-location-name"> Pune</span>
//         </div>
//         <div className="col-5"></div>
//         <div className="col-5 textleft"><span className="slider-name whitenowrap">Sagar Naidu</span>
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-3">
//         </div>
//         <div className="col-5">
//           <span className="bpositiv">B+Ve</span>
//         </div>
//         <div className="col-4 textright ">
//       </div>
//       </div>
//       <div className="row margintop1">
//         <div className="col-6 ">
//           <span className="slider-name ">Date Required:</span>
//         </div>
//         <div className="col-5 textleft">
//           <span className="slider-name marginleftmins4 "> 05 Nov 2022</span>
//         </div>
//         <div className="col-1 ">
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-4 ">
//           <span className="slider-name ">Contact:</span>
//         </div>
//         <div className="col-4 textleft">
//           <span className="slider-name ">9923474723</span>
//         </div>
//         <div className="col-1">
//       </div>
//       </div>
//       <div className="row margintop3"></div>    
//         <div className="row margintop1"></div>
//         <div className="row margintop3"></div>

      
      
      
//     </div>
//   );
// };

export default SliderPageNew;