import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import imgupload from '../../images/imageupload.svg'
import {configData} from "../../Config"

const MatrimonyAlbum = ({imageDetails,setAttachment,attachment ,setimageDetails,handleBack,handleNext,attachmentphotoOne,setattachmentphotoOne,attachmentphotoTwo,setattachmentphotoTwo,attachmentphotoThree,setattachmentphotoThree,attachmentphotoFour,setattachmentphotoFour}) => {
    
  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

  const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setimageDetails(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(imageDetails.birthday);
    }
    
      const [error, setError] = useState(false);

      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setimageDetails(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
//for img preview
const [imgData, setImgData] = useState(null);
const fileref =useRef(null);
const [imgData1, setImgData1] = useState(null);
const fileref1 =useRef(null);
const [imgData2, setImgData2] = useState(null);
const fileref2 =useRef(null);
const [imgData3, setImgData3] = useState(null);
const fileref3 =useRef(null);


const handlephotoOneChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoOne" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoOne(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoTwoChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoTwo" : e.target.files[0]
      //  }))
      if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoTwo(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData1(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoThreeChange = (e, index) => {
      const { name, value } = e.target;
  
    // setAttachment(preState => ({
    //      ...preState,
    //      "photoThree" : e.target.files[0]
    //    }))
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      setattachmentphotoThree(e.target.files[0]);
     
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData2(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoFourChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoFour" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoFour(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData3(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };

    const submitFormData5 = (e) => {
      debugger
      e.preventDefault();
  
      // checking if value of first name and last name is empty show error else take to step 2
      
      
       if (imageDetails.aboutYourself==""||imageDetails.aboutYourself==null) {
        setError(true);
      }
      
      else if (imageDetails.familyBackground==""||imageDetails.familyBackground==null) {
          setError(true);
  
      }
      
     
      
  else {
        debugger
        handleNext()
      }
  };

  return(
    
<div className="container">
    <form >
            <div className="row margintop">
              <div className="col-10" id="completeprofradio">
     <div className="row textLeft">
                    <div className="col-lg-6 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >About YourSelf</span>
               </div>
               <div className="col-lg-6 col-sm-12 col-md-12">
               <span className="lbl popinsfont"style={{fontSize:'14px'}} >Photo Album</span>

               </div>
    
    </div>
    <div className="row textLeft margintop3">
                    <div className="col-lg-6 col-sm-12-col-md-12" style={{marginTop:"5px"}}>
                    <textarea class="form-control" name='aboutYourself'  id="exampleFormControlTextarea1" rows="7" placeholder="" onChange={handleChange} style={{fontSize:"12px"}}/>
                    <div className='row '>
    <div className=" col-12 ">
                                    {error && imageDetails.aboutYourself == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            About Yourself is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                    </div>
                    <div className="col-lg-3 col-sm-12-col-md-12">
                    <div>  <span className="comudrct-digitxt"style={{fontSize:'13px'}}>(Optional)</span></div>

                        <label class=" btn-primary1" for="selectFile" name="photoOne" role="button" tabindex="0" style={{fontSize:"12px"}}>
                        {
                  imgData !=null ?(
                    <img id='image' className="playerProfilePic_home_tile"
                    src={imgData} 
               
                />
                  ):
                       ( <img src={imgupload}  className='imagePreview' style={{height:"120px"}} onChange={handlephotoOneChange}/>)
                  }
                </label>
                <input type="file" class="hide" ref={fileref}  id="selectFile" aria-invalid="false" onChange={handlephotoOneChange}/>
                
               

      </div>
      <div className="col-lg-3 col-sm-12-col-md-12">
      <div>  <span className="comudrct-digitxt"style={{fontSize:'13px'}}>(Optional)</span></div>

                        <label class=" btn-primary1" name="photoTwo" for="selectFile1" role="button" tabindex="0" style={{fontSize:"12px"}}>
                        {
                  imgData1 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile"
                    src={imgData1} 
               
                />
                  ):(
                        <img src={imgupload}  className='imagePreview' style={{height:"120px"}} onChange={handlephotoTwoChange}/>
                  )
}
                </label>
                <input type="file" class="hide" ref={fileref1}  id="selectFile1" aria-invalid="false" onChange={handlephotoTwoChange}/>
               
      </div>
  </div>
  <div className="row textLeft">
                    <div className="col-lg-6 col-sm-12-col-md-12" style={{marginTop:"15px"}}> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Family Background</span>
               </div>
               <div className="col-lg-6 col-sm-12 col-md-12">

               </div>
    
    </div>
    <div className="row textLeft margintop3">
                    <div className="col-lg-6 col-sm-12-col-md-12" style={{marginTop:"5px"}}>
                    <textarea class="form-control" name='familyBackground'  id="exampleFormControlTextarea1" rows="7" placeholder="" onChange={handleChange} style={{fontSize:"12px"}}/>
                    <div className='row '>
    <div className=" col-12">
                                    {error && imageDetails.familyBackground == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Family Background is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                    </div>
                    <div className="col-lg-3 col-sm-12-col-md-12">
                    <div>  <span className="comudrct-digitxt"style={{fontSize:'13px'}}>(Optional)</span></div>

                        <label class=" btn-primary1" for="selectFile2" name="photoThree" role="button" tabindex="0" style={{fontSize:"12px"}}>
                        {
                  imgData2 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile"
                    src={imgData2} 
               
                />
                  ):(
                        <img src={imgupload}  className='imagePreview' style={{height:"120px"}} onChange={handlephotoThreeChange}/>
                  )
}

                        </label>
                <input type="file" class="hide" ref={fileref2}  id="selectFile2" aria-invalid="false" onChange={handlephotoThreeChange}/>
                
</div>
<div className="col-lg-3 col-sm-12-col-md-12">
<div>  <span className="comudrct-digitxt"style={{fontSize:'13px'}}>(Optional)</span></div>

                        <label class=" btn-primary1" for="selectFile3" name="photoFour" role="button" tabindex="0" style={{fontSize:"12px",justifySelf:"center"}}>
                        {
                  imgData3 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile"
                    src={imgData3} 
               
                />
                  ):(
                        <img src={imgupload}  className='imagePreview' style={{height:"120px"}}  onChange={handlephotoFourChange}/>
                  )
}
                        </label>
                <input type="file" class="hide" ref={fileref3}  id="selectFile3" aria-invalid="false" onChange={handlephotoFourChange}/>
               
</div>
  </div>          
             
            
      
              </div>  
              <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-3 margintop3" >
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              onClick={submitFormData5}
              style={{width:"180px"}}
            >
               Complete Profile
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
          </div>
    </form>
        </div>)
}
export default MatrimonyAlbum