import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";




const BusinessTermsConditions  =()=>{
   
   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

let history = useHistory();

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//    if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//      history.push("/");

//    }
//  }
    
//     useEffect(() => {
//      redirecToLandingdata();
//   });
    return(
        <div className="container-fluid  ">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                <Link to='/LandingPage'> <img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                 {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
           <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
            <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
             <div className="row">
            <span className="event-sub-detail">
            Naidu Directory Business Directory - Terms and Conditions

                        
            </span>
           </div>
            
           <div className="row margintop3">
            <span className="event-sub-detail">
            Business Listing and Eligibility:
              </span>
            <span className="event-sub-detail margintop1">
            1.1. Business Eligibility: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Only businesses owned or operated by members of the Naidu community are eligible to be listed in the Business Directory.            </span>
            <span className="event-sub-detail margintop1">
            1.2. Accurate Information:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Businesses must provide accurate and up-to-date information while creating their listings.
             Naidu Sangham reserves the right to verify the authenticity of the business information provided.
            </span>
            
        
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Business Promotion and Content:
              </span>
            <span className="event-sub-detail margintop1">
            2.1. Listing Content:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Businesses are responsible for the accuracy and completeness of the content shared in their listings, 
            including business name, description, contact information, and other details. 
            </span>
            <span className="event-sub-detail margintop1">
            2.2. Legal Compliance:             
         </span>
            <span className="event-sub-detail1 margintop1">
            Businesses must comply with all applicable laws and regulations, 
            including those related to licensing, permits, taxation, and advertising.
            </span>
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            User Interaction and Reviews:
              </span>
            <span className="event-sub-detail margintop1">
            3.1. User Interaction: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Community members may interact with businesses through the Business Directory.
             Users are encouraged to engage respectfully and responsibly with businesses and other users.
            </span>
            <span className="event-sub-detail margintop1">
            3.2. Reviews and Feedback:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Users may provide reviews and feedback about businesses based on their experiences. 
            Naidu Sangham is not responsible for the content of user reviews. 
            </span>
        </div>
          
        <div className="row margintop3">
            <span className="event-sub-detail">
            Business Directory Moderation:
              </span>
            <span className="event-sub-detail margintop1">
            4.1. Content Moderation:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham reserves the right to moderate and approve business listings to ensure they adhere to c
            ommunity guidelines and these Terms and Conditions
            </span>
            <span className="event-sub-detail margintop1">
            4.2. Removal of Listings:
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may remove or suspend business listings that violate community guidelines, are found to be misleading, or engage in fraudulent practices.
             </span>
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Privacy and Data Protection:
              </span>
            <span className="event-sub-detail margintop1">
            5.1. Business Information:  
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Information shared in the Business Directory may be visible to other community members and visitors. 
            Business owners are responsible for protecting sensitive information shared in their listings.
            </span>
            <span className="event-sub-detail margintop1">
            5.2. Data Usage:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham will handle business data in accordance with the Privacy and Data Policy outlined for the community platform.                       </span>
        </div>
        
        <div className="row margintop3">
            <span className="event-sub-detail">
            Business Transactions and Agreements:
              </span>
            <span className="event-sub-detail margintop1">
            6.1. Transactions:     
         </span>
            <span className="event-sub-detail1 margintop1">
            The Business Directory serves as a promotional platform, and any transactions or agreements
             between businesses and customers are conducted independently of Naidu Sangham.     
                    </span>
                    <span className="event-sub-detail margintop1">
                    6.2. Disputes:      
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham is not liable for any disputes, claims, or damages arising from business transactions facilitated through the Business Directory.      
                    </span>
            
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Fees and Promotions:
              </span>
            <span className="event-sub-detail margintop1">
            7.1. Listing Fees:     
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may charge fees for premium business listings or additional promotional services. 
            Details regarding fees and services will be outlined separately.       
                   </span>
                  
        </div> 
           
        <div className="row margintop3">
            <span className="event-sub-detail">
            Termination: 
              </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham reserves the right to remove or suspend business listings that violate these Terms and Conditions or engage in dishonest practices.        
               </span>
            
            
        </div>
           
        <div className="row margintop3">
            <span className="event-sub-detail">
            Disclaimer:  
              </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham provides the Business Directory as a platform for businesses within the community to promote their services. We do not endorse or guarantee the quality, reliability, or authenticity of any business listed in the directory.
       
               </span>
            
            
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Governing Law and Jurisdiction:
              </span>
            <span className="event-sub-detail1 margintop1">
            These Terms and Conditions specific to the Business Directory shall be governed by and construed in accordance with the laws of Pune jurisdiction. Any disputes arising from or related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Pune.
     
               </span>
            
            
        </div>

          
          

            </Card>
            <div className="row margintop"></div>
            <div className="row mt-0 textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12"><Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>
           
            
            </div>

           </div>
          
            
            
           


        </div>
    );
}
export default BusinessTermsConditions