import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import ViewProfCommunityDirectPop from "../UserLandingPages/ViewProfCommunityDirectPop.js";
import {configData} from "../../Config"


 
const ContactInvitationsent=(props)=>{
  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

 

const columns = [
  
    
      {
        name: "Name.",
       selector: "senderName",
        sortable: true,
        

        
    },
    {
      name: "Blood Group",
     selector: "bloodGroup",
      sortable: true,
       
      
  },
  {
      name: "Age",
     selector: "age",
      sortable: true,
     className:"texttransformcapitalize"
      
  },
  {
      name: "Live In",
     selector: "currentCity",
      sortable: true,

      
  },
  {
      name: "From",
     selector: "homeCity",
      sortable: true,
      className:"texttransformcapitalize"

      
  },
  {
      name: "Request Sent On",
     selector: "requestDate",
      sortable: true,
      className:"texttransformcapitalize"

      
  }
  ,{
      name: "Action",
     selector: "status",
      sortable: true,
      className:"texttransformcapitalize",
      cell: (d) => (
        <span onClick={()=>addCancelConnection(d.senderId)} style={{color:"#D30F45"}}>Cancel</span>
       
        ),


  }
    
    ];
   

    // const data = [
    //     {
    //       name: "Hyperlink to profile",
    //       bloodgroup: "",
    //       age: "",
    //       livein: "",
    //       from: "",
    //       requestsenton: "",
    //       action: "Cancel",


            
            
    //       },
          
    //   ]
    


   
    
    const addCancelConnection = async (senderId)=>{
  
      debugger;
     const response =  await axios.post(configData.ApiUrl+'/connection/cancelConnection?userId='+senderId+'&hostId='+uerAuthId)
     if(response.status == 200 && response.data.code ){
      //setcommDirectoryconReq(true)
      toast.success("Successfully Canceled  Connection")
      loadConnects();
    
    }
    else{
      toast.warn("Something Went Wrong..!")
    }
    }

    

    

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    
    //const [data, setData] = useState([]);

    // const tableData = {
    //   columns,
    //   data,
      
    // };

     useEffect(() => {
      loadConnects();
     },[]);
  
     const loadConnects = async (value) => {
      const pageSize = value ? value : showRecord;
      if(value !=null)
      {
       const result = await axios.get(configData.ApiUrl+'/connection/getListSentContacts?pageNo='+page+'&pageSize='+pageSize+'&id='+uerAuthId)

       //setData(result.data.data);
       props.setsentData(result.data.data);

       
       console.log("getListSentContacts",result.data.data)
      }
      else{
        const result = await axios.get(configData.ApiUrl+'/connection/getListSentContacts?pageNo='+page+'&pageSize='+pageSize+'&id='+uerAuthId)

       //setData(result.data.data);
       props.setsentData(result.data.data);

       
       console.log("getListSentContacts",result.data.data)
      }

     };

    
     const [showRecord, setshowRecord] = useState(10);

     const  handleChanegeShowRec = (e) => {
       debugger;
       const { name, value, type, checked } = e.currentTarget;
    
       setshowRecord( e.currentTarget.value);
       loadConnects(value);

      }
     //for table records per page
     const [recordCount, setrecordCount] = useState(10);
    
    
     //for pagination
    
     const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    
    
    // handle next
    const handleNext = () => {
       if (page === pageCount) return page;
       setPage(page + 1)
    }
    
    // handle previous
    const handlePrevios = () => {
       if (page === 1) return page;
       setPage(page - 1)
    }
    
    useEffect(() => {
     loadConnects()
    }, [page])
    
    useEffect(() => {
       const pagedatacount = Math.ceil(props.sentData.length / showRecord);
       setPageCount(pagedatacount);
    
       if (page) {
           const LIMIT = showRecord;
           const skip = LIMIT * page // 5 *2 = 10
           const dataskip = props.sentData.slice(page === 1 ? 0 : skip - LIMIT, skip);
           setPageData(dataskip)
       }
    }, [props.sentData])
    
   
    const handleSearchChange = (e) => {
      debugger
      if (e.currentTarget.value === "") {
        loadConnects();
        return;
      }
      const filterBySearch = props.sentData.filter((item) => {
        if (item != null && item.senderName != null) {
          if (item?.senderName?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        if (item != null && item.bloodGroup != null) {
          if (item?.bloodGroup?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        
        if (item != null && item.currentCity != null) {
          if (item?.currentCity?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        if (item != null && item.homeCity != null) {
          if (item?.homeCity?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        if (item != null && item.status != null) {
          if (item?.status?.toLowerCase().includes(e.currentTarget.value)) { return item; }
        }
        // if ( item != null && item.profession != null){
        //   if (item?.profession?.toLowerCase()
        //       .includes(e.currentTarget.value)) { return item; }}
      })
      props.setsentData(filterBySearch);
    }
   

    //for view prof popup
   const [isOpenMessagePopViewDirect, setisOpenMessagePopViewDirect] = useState(false);
   const [idforcommpop, setidforcommpop] = useState(0);
 
   
   const toggleviewProfDiPop = (id) => {
     setisOpenMessagePopViewDirect(!isOpenMessagePopViewDirect);
     setidforcommpop(id);
   }

  return (
    <div className="table-responsive fileexport pos-relative">
    {/* <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available"        
            striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions> */}

<div>
<div className='row margintop1' >
            <div className='col-2' style={{float:'left'}}>
        <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
                  className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                  <option  value="10">Show 10</option>
                  <option value="25">Show 25</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>

                  </select>
                  </div>
                  <div className='col-8'></div>
                  <div className='form-group col-2'  style={{float:'right'}}>
                  <input
className="form-control"
placeholder="Search..."
type="search"
onChange={handleSearchChange}
style={{height:"32px",marginTop:"4px",fontSize:"12px"}}
/>
                  </div>

        </div>
<table class="table  table-hove">
  <thead>
    <tr>      <th scope="col" className="tableroww">Sr.No.</th>

      <th scope="col" className="tableroww">Name</th>
      <th scope="col" className="tableroww">Blood Group</th>
      <th scope="col" className="tableroww">Age</th>
      <th scope="col" className="tableroww">Live In</th>
      <th scope="col" className="tableroww">From</th>
      <th scope="col" className="tableroww">Request Sent On</th>
      <th scope="col" className="tableroww">Action</th>


    </tr>
  </thead>
  <tbody>
   
    {
        pageData.map((data,index)=>( 
            <tr>
            <th scope='row' className="tableroww1">{index + 1}</th>
            <td className="tableroww1 capitalizetxxxt" onClick={()=>toggleviewProfDiPop(data.senderId)} style={{cursor:"pointer"}}>{data.senderName}</td>
            <td className="tableroww1 capitalizetxxxt">{data.bloodGroup}</td>
            <td className="tableroww1 capitalizetxxxt">{data.age}</td>
            <td className="tableroww1 capitalizetxxxt">{data.currentCity}</td>
            <td className="tableroww1 capitalizetxxxt">{data.homeCity}</td>
            <td className="tableroww1 capitalizetxxxt">{data.requestDate}</td>
            <td className="tableroww1">
            <span onClick={()=>addCancelConnection(data.senderId)}  style={{color:"#D30F45",cursor:"pointer"}}>Cancel</span>
            </td>

           

            {/* <td>
                <Link className='btn btn-primary mr-2'to= {`/View/${data.id}`}>View</Link>
                <Link className='btn btn--outline-primary mr-2' to= {`/EditUser/${data.id}`}>Edit</Link>
                <Link className='btn btn-outline-danger' onClick={() =>deleteUser(data.id)}>Delete</Link>
            </td> */}
            </tr>


         ))
    } 
  </tbody>
</table>
{isOpenMessagePopViewDirect && <ViewProfCommunityDirectPop

handleClose={toggleviewProfDiPop} uerAuthId={uerAuthId} idforcommpop={idforcommpop}
/>}
<div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div>
    </div>
    </div>

           );
}
 
export default ContactInvitationsent;