
import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import imgupload from '../../images/imageupload.svg'
import {configData} from "../../Config"


const BusinessAboutPhoto = ({additional,setadditional,attachment,setAttachment,attachmentphotoOne,setattachmentphotoOne,attachmentphotoTwo,setattachmentphotoTwo,attachmentphotoThree,setattachmentphotoThree,attachmentphotoFour,setattachmentphotoFour,attachmentphotoFive,setattachmentphotoFive,attachmentphotoSix,setattachmentphotoSix,attachmentProf, setAttachmentProf, handleBack,handleNext}) => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setadditional(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(additional.birthday);
    }
    
      const [error, setError] = useState(false);
      const submitFormData5 = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (attachmentProf==""||attachmentProf==null) {
          setError(true);
        }
        
    else {
          debugger
          handleNext()
        }
    };

      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setadditional(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
    const [imgData, setImgData] = useState(null);
const fileref =useRef(null);
const [imgData1, setImgData1] = useState(null);
const fileref1 =useRef(null);
const [imgData2, setImgData2] = useState(null);
const fileref2 =useRef(null);
const [imgData3, setImgData3] = useState(null);
const fileref3 =useRef(null);
const [imgData4, setImgData4] = useState(null);
const fileref4 =useRef(null);
const [imgData5, setImgData5] = useState(null);
const fileref5 =useRef(null);
const [imgData6, setImgData6] = useState(null);
const fileref6 =useRef(null);

    const handlephotoProfileChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "profilePhoto" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setAttachmentProf(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}


    };
    const handlephotoOneChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoOne" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoOne(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData1(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}


    };
    const handlephotoTwoChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoTwo" : e.target.files[0]
      //  }))
  
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoTwo(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData2(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}
    };
    const handlephotoThreeChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoThree" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoThree(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData3(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoFourChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoFour" : e.target.files[0]
      //  }))
      if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoFour(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData4(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoFiveChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoFive" : e.target.files[0]
      //  }))
       if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoFive(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData5(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}

    };
    const handlephotoSixChange = (e, index) => {
      const { name, value } = e.target;
  
      // setAttachment(preState => ({
      //    ...preState,
      //    "photoSix" : e.target.files[0]
      //  }))
  
      if (e.target.files[0]) {
        console.log("picture: ", e.target.files);
        setattachmentphotoSix(e.target.files[0]);
       
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData6(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);}
    };
  
return(
    
<div className="container">
<form >
        <div className="row margintop">
          <div className="col-10" id="completeprofradio">
            <div className="row textLeft">
                <div className="col-lg-7 col-sm-12-col-md-12"> 
                     <span className="lbl popinsfont"style={{fontSize:'14px'}} >Business Photos</span>
           </div>

</div>
         <div className="row margintop3">
<div className="col-lg-4 col-sm-4 col-md-4">
            <label class=" btn-primary1" for="selectFile" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoProfileChange}>
            {
                  imgData !=null ?(
                    <img id='image' className="playerProfilePic_home_tile1"
                    src={imgData} 
               
                />
                  ):
                       ( <img src={imgupload}  className='imagePreview' onChange={handlephotoProfileChange} style={{height:"210px"}}/>
                       )
                  }
            </label>
            <input type="file" class="hide" ref={fileref} name="profilePhoto" onChange={handlephotoProfileChange} id="selectFile" aria-invalid="false"/>
            <div className="  ">
                                    {error && attachmentProf == null ?(
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Profile Photo  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
</div>

<div className="col-lg-3 col-sm-12-col-md-12">
    <div>
    <label class=" btn-primary1" for="selectFile1" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoOneChange}>
    {
                  imgData1 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData1} 
               
                />
                  ):
                       ( 
                       <img src={imgupload}  className='imagePreview' style={{height:"100px"}} onChange={handlephotoOneChange}/>

                       )
                  } 
                </label>
                <input type="file" class="hide" ref={fileref1} name="photoOne" onChange={handlephotoOneChange} id="selectFile1" aria-invalid="false"/>
    </div>
        <div>
        <label class=" btn-primary1" for="selectFile2" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoTwoChange}>
        {
                  imgData2 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData2} 
               
                />
                  ):
                       ( 
                        <img src={imgupload}  className='imagePreview' style={{height:"100px"}} onChange={handlephotoTwoChange}/>

                       )
                  } 
                </label>
                <input type="file" class="hide" name="photoTwo" ref={fileref2} id="selectFile2" aria-invalid="false" onChange={handlephotoTwoChange}/>
         </div>                

      </div>
<div className="col-lg-2 col-sm-12 col-md-12">
    <div>
    <label class=" btn-primary1" for="selectFile3" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoThreeChange}>
    {
                  imgData3 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData3} 
               
                />
                  ):
                       ( 
                        <img src={imgupload}  className='imagePreview' style={{height:"100px"}}  onChange={handlephotoThreeChange}/>

                       )
                  }   
                </label>
                <input type="file" class="hide" name="photoThree" ref={fileref3} id="selectFile3" aria-invalid="false" onChange={handlephotoThreeChange}/>
    </div>
                       
    <div>
    <label class=" btn-primary1" for="selectFile4" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoFourChange}>
    {
                  imgData4 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData4} 
               
                />
                  ):
                       ( 
                        <img src={imgupload}  className='imagePreview' style={{height:"100px"}} onChange={handlephotoFourChange}/>

                       )
                  }     
                </label>
                <input type="file" class="hide" name="photoFour" ref={fileref4} id="selectFile4" aria-invalid="false" onChange={handlephotoFourChange}/>
    </div>
      </div>
      <div className="col-lg-2 col-sm-12 col-md-12">
        <div>
        <label class=" btn-primary1" for="selectFile5" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoFiveChange}>
        {
                  imgData5 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData5} 
               
                />
                  ):
                       ( 
                        <img src={imgupload}  className='imagePreview' style={{height:"100px"}} onChange={handlephotoFiveChange}/>

                       )
                  }       
                </label>
                <input type="file" class="hide" name="photoFive" ref={fileref5}  id="selectFile5" aria-invalid="false" onChange={handlephotoFiveChange}/>
        </div>
             <div>
        <label class=" btn-primary1" for="selectFile6" role="button" tabindex="0" style={{fontSize:"12px"}} onChange={handlephotoSixChange}>
        {
                  imgData6 !=null ?(
                    <img id='image' className="playerProfilePic_home_tile2"
                    src={imgData6} 
               
                />
                  ):
                       ( 
                        <img src={imgupload}  className='imagePreview' style={{height:"100px"}} onChange={handlephotoSixChange}/>

                       )
                  }  
                </label>
                <input type="file" class="hide" name="photoSix" ref={fileref6} id="selectFile6" aria-invalid="false" onChange={handlephotoSixChange}/>
        </div>          

      </div>

         </div>
        
        
          </div>  
          <div className="margintop row">
            <div className="col-2"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              onClick={submitFormData5}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>    
      </div>
</form>
    </div>
)
}
export default BusinessAboutPhoto