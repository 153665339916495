
import { useEffect, useState } from 'react';
import './JoinNow.css';
import validator from "validator";
import OtpInput from 'react-otp-input';
import {configData} from "../../Config"


const ContactVerify=({otp,setOtp,basicInfo,setBasicInfo,handleVerifyOptp,resendOtp,handleBack,handleNext,otpNew,setOtpNew})=> {
  
  
  const [enablejoin, setenableResend] = useState(false);

  const counterandbtn =()=>{
    setCounter(30);
    setenableResend(false);
   resendOtp() ;
  }
  
      const [counter, setCounter]= useState(30)
;
    useEffect(() =>{
      if(counter === 0){
        setenableResend(true);

    
      }
      
      const timer =
      counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
      return () => clearInterval(timer);
    },[counter]);


  //for verify otpfor basic detail
  
  
   const handlechangeOtp =(e)=>{
    const {name,value,type,checked}=e.currentTarget;
    setOtp(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
  }
  
  const mobileNumberFormate1 = basicInfo.mobileNumber.substr(0, 2) + 'xxxxxx' 
  + basicInfo.mobileNumber.substr(8, 9);


  //for lastname emeil
  let email = basicInfo.emailId
  let chunks = email.split("@");
  let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`
  
  const [error, setError] = useState(false);

  const submitBasicFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (otp.otpNum1==""||otp.otpNum1==null) {
        setError(true);
    }
    else if (otp.otpNum2==""||otp.otpNum2==null) {
        setError(true);
    }
    
    else if (otp.otpNum3==""||otp.otpNum3==null) {
      setError(true);

    }
    else if (otp.otpNum4==""||otp.otpNum4==null) {
      setError(true);

    }
    
 else {
      debugger
      handleNext();
    }
};

const validateOtp = async(e) =>{
  e.preventDefault();
if(otpNew=='' || otpNew.length<4){
  setError(true);
}
else{
  handleVerifyOptp();
}
}


    return (
      <div className="container" >
                    
          <form >

        <div className="row margintop">
          <div className="col-lg-3 "> </div>
          <div className="margintop3 ml-2 col-lg-8 col-md-12 col-sm-12">
            <span className="enterotptxt" style={{marginLeft:"15px"}}>Enter the OTP send to your mobile number {mobileNumberFormate1}</span>
            <div className='margintop3'>
              <span className='nri-text'>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2'>
              <input type="number" className="otpbox form-control" id="fst" 
               name="otpNum1" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum1 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox1  form-control" id="fst" 
               name="otpNum2" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum2 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"-15px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox3  form-control" id="fst" 
               name="otpNum3" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum3 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" ,marginLeft:"-30px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox4  form-control" id="fst" 
               name="otpNum4" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum4 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"-45px"  }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
            
            </div> */}
<div className='row margintop3'>
  <div className='col-lg-2 '></div>
  <div className='col-lg-6  col-sm-12 col-md-12' style={{marginLeft:'-15px'}}>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
     {error ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"5px"  }}>
                                            Please enter OTP.
                                        </span>
                                    ) : (
                                        ""
                                    )}
  </div>


</div>

            <div className=' row margintop '>
              <div className=' col-lg-9 col-sm-12 col-md-12' style={{marginLeft:"50px"}}>
              <span className='dindopt-txt'>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtn()}>Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
          </div>
        </div>
  <div className="margintop1 row ">
            <div className="col-lg-3 "></div>
            <div className="col-lg-3 col-md-12 col-sm-12 margintop textright">
            <button
               className="prevbtn "
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-sm-12 col-md-12 margintop ">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
               onClick={validateOtp}
              //type="submit"
            >
              Next
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

         

            <div className=" textcenter row">
            <div className="col-lg-3 col-sm-12 col-md-12"></div>
            <div className="margintop col-lg-6 col-md-12 col-sm-12">
            <span className="alreadyspn1 ">Mobile verified badge is shown on your profile once you verify your mobile </span>
            </div>
           
            </div>

            
            
          </div> 
</form>
      </div>
    );
  }
  export default ContactVerify;