import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"

const MatrimonyHoroscope = ({horoscopeDetails,sethoroscopeDetails,handleBack,handleNext}) => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        sethoroscopeDetails(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(horoscopeDetails.birthday);
    }
    
      const [error, setError] = useState(false);
      const [FirstNameEroor ,setFirstNameEroor] = useState(false);

      const submitFormData3 = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (horoscopeDetails.time==""||horoscopeDetails.time==null) {
          setError(true);
        }
        
        else if (horoscopeDetails.placeOfBirth==""||horoscopeDetails.placeOfBirth==null) {
            setError(true);
    
        }
        else if (!/^[a-zA-Z]*$/g.test(horoscopeDetails.placeOfBirth)) {
          setFirstNameEroor(true);
        }
        else if (horoscopeDetails.raashi==""||horoscopeDetails.raashi==null) {
          setError(true);
  
        }
        else if (horoscopeDetails.goatra==""||horoscopeDetails.goatra==null) {
          setError(true);
  
        }
        
        else if (horoscopeDetails.nakshatra==""||horoscopeDetails.nakshatra==null) {
          setError(true);
  
        }
        else if (horoscopeDetails.manglik==""||horoscopeDetails.manglik==null) {
          setError(true);
  
        }
        
       
        
    else {
          debugger
          handleNext()
        }
    };

      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        sethoroscopeDetails(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
   
return(
    
<div className="container">
    <form >
            <div className="row margintop">
              <div className="col-9" id="completeprofradio">
                <div className="row textLeft">
                    <div className="col-lg-7 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Horoscope</span>
               </div>
    
    </div>
             <div className="row margintop3">
    <div className="col-4 textright">
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >DOB</span>
    </div>
    <div className="col-7 textleft">
    <span className="comudrct-digitxt"style={{fontSize:'13px'}}>26 Dec 1979</span>

                    </div>
                    
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"4px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Time</span>
    </div>
    <div className="col-7 form-group textleft">
    <input type="time" class=" form-control" placeholder='' onChange={handleChange} 
      value={horoscopeDetails.time}

    name='time' style={{fontSize:"13px"}}/>

    <div className='row '>
              <div className=" col-12 ">
                                    {error && horoscopeDetails.time == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>  
                    </div>
             </div>
             <div className="row margintop3">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Place of Birth</span>
    </div>
    <div className="col-7 textleft">
    <input type="text" class=" form-control"     value={horoscopeDetails.placeOfBirth}

    placeholder='Birth Place' onChange={handleChange} name='placeOfBirth' style={{fontSize:"13px"}}/>
  
    <div className='row '>
    <div className="col-12 ">
      <span className={FirstNameEroor?"":'hide'}style={{ color: "red",fontSize:"12px" }} >Place Of Birth contains only charecters</span>
                                    {error && horoscopeDetails.placeOfBirth == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Place Of Birth is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                    </div>
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Rashi</span>
    </div>
    <div className="col-7 form-group textleft">
    <select  id="inputState" value={horoscopeDetails.raashi}
     name='raashi' onChange={handleChange}
                  className=" form-control" style={{height:"34px",fontSize:"13px"}}>
                  <option selected value="">Select</option>
                  <option  value="Maish">Maish</option>
                  <option value="Vrish">Vrish</option>
                  <option value="Mithun">Mithun</option>
                  <option value="Kark">Kark</option>
                  <option value="Singh">Singh</option>
                  <option value="Kanya">Kanya</option>
                  <option value="Tula">Tula</option>
                  <option value="Vrishchik">Vrishchik</option>
                  <option value="Dhanu">Dhanu</option>
                  <option value="Makar">Makar</option>
                  <option value="Kumbh">Kumbh</option>
                  <option value="Meen">Meen</option>
               </select>
               <div className='row '>
              <div className=" col-12 ">
                                    {error && horoscopeDetails.raashi == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Raashi is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>      
                    </div>
             </div>
            
             
             <div className="row margintop3">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Goatra</span>
    </div>
    <div className="col-7 textleft">
    
    <select  id="inputState" name='goatra' onChange={handleChange}  value={horoscopeDetails.goatra}
                  className=" form-control" style={{height:"34px",fontSize:"13px"}}>
                  <option selected value="">Select</option>
                  <option  value="Atri">Atri</option>
                  <option value="Bharadvaj">Bharadvaj</option>
                  <option value="Bhrigu">Bhrigu</option>
                  <option value="GoTama">GoTama</option>
                  <option value="Vashishta">Vashishta</option>
                  <option value="VishvaMitra">VishvaMitra</option>
                  <option value="Other">Other</option>
                </select>     
                <div className='row '>
              <div className=" col-12 ">
                                    {error && horoscopeDetails.goatra == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Goatra is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Nakshatra</span>
    </div>
    <div className="col-7 textleft">
    <select  id="inputState" name='nakshatra' value={horoscopeDetails.nakshatra} onChange={handleChange}
                  className=" form-control" style={{height:"34px",fontSize:"11px"}}>
                  <option selected value="">Select</option>
                  <option  value="Ashwin">Ashwin</option>
                  <option value="Kartik">Kartik</option>
                  <option value="Punarvasu">Punarvasu</option>
                  <option value="GoTama">GoTama</option>
                  <option value="Ashlesha">Ashlesha</option>
                  <option value="Hasta">Hasta</option>
                  <option value="Krttika(Pleiades)">Krttika(Pleiades)</option>
                  <option value="Rohini(Aldebaran)">Rohini(Aldebaran)</option>
                  <option value="MrigShirsha">MrigShirsha</option>
                  <option value="Ardra(Betelgeuse)">Ardra(Betelgeuse)</option>
                  <option value="PunarVasu">PunarVasu</option>
                  <option value="Pushya">Pushya</option>
                  <option value="Magha(Regulus)">Magha(Regulus)</option>
                  <option value="Purva Phalguni">Purva Phalguni</option>
                  <option value="Uttara Phalguni(Denebola)">Uttara Phalguni(Denebola)</option>
                  <option value="Chitra(Spica)">Chitra(Spica)</option>
                  <option value="Svati(Arcturus)">Svati(Arcturus)</option>
                  <option value="Vishakha">Vishakha</option>
                  <option value="Jyeshtha">Jyeshtha</option>
                  <option value="Mula">Mula</option>
                  <option value="Purva Ashadha">Purva Ashadha</option>
                  <option value="Uattra Ashadha">Uattra Ashadha</option>
                  <option value="Shravana">Shravana</option>
                  <option value="Satabhishak(Sadachbia)">Satabhishak(Sadachbia)</option>
                  <option value="Purva Bhadrapada">Purva Bhadrapada</option>
                  <option value="Uttara Bhadrapada">Uttara Bhadrapada</option>
                  <option value="Revati">Revati</option>
                  <option value="Ashvini">Ashvini</option>
                </select>  
                <div className='row '>
              <div className=" col-12 ">
                                    {error && horoscopeDetails.nakshatra == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Nakshatra is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                    </div>
             </div>
             
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Manglik</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='manglik'
                    value={horoscopeDetails.manglik}
                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Yes">Yes</option>
                    <option  value="No">No</option>
                    
                    </select> 
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && horoscopeDetails.manglik == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Manglik is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
             </div>
      
            
             
      
            
              </div>  
              <div className="margintop row">
            <div className="col-2"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn sourcesansfont"
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 sourcesansfont"
              variant="contained"
              color="primary"
              onClick={submitFormData3}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
          </div>
    </form>
        </div>
        )
}
export default MatrimonyHoroscope