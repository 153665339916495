import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"

const MatrimonypartnerPrefrence = ({preferenceDetails,setpreferenceDetails,handleBack,handleNext}) => {
//for session
// let history = useHistory();

// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);



    const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setpreferenceDetails(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(preferenceDetails.birthday);
    }
    
      const [error, setError] = useState(false);
      const submitFormData4 = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (preferenceDetails.education==""||preferenceDetails.education==null) {
          setError(true);
        }
        
        else if (preferenceDetails.complexion==""||preferenceDetails.complexion==null) {
            setError(true);
    
        }
        
        else if (preferenceDetails.caste==""||preferenceDetails.caste==null) {
          setError(true);
  
        }
        else if (preferenceDetails.ageFrom==""||preferenceDetails.ageFrom==null) {
          setError(true);
  
        }
        
        else if (preferenceDetails.ageTo==""||preferenceDetails.ageTo==null) {
          setError(true);
  
        }
        else if (preferenceDetails.heightFeetFrom==""||preferenceDetails.heightFeetFrom==null) {
          setError(true);
  
        }
        else if (preferenceDetails.heightFeetTo==""||preferenceDetails.heightFeetTo==null) {
          setError(true);
  
        }
        else if (preferenceDetails.otherExpectations==""||preferenceDetails.otherExpectations==null) {
          setError(true);
  
        }
        
       
        
    else {
          debugger
          handleNext()
        }
    };


      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setpreferenceDetails(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
   

return(
    
<div className="container">
    <form >
            <div className="row margintop">
              <div className="col-9" id="completeprofradio">
                <div className="row textLeft">
                    <div className="col-lg-7 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Partner Prefrence</span>
               </div>
    
    </div>
             <div className="row margintop3">
    <div className="col-3 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Education</span>
    </div>
    <div className="col-9 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='education'
                    value={preferenceDetails?.education}
                    onChange={handleChange} >
                        <option selected value="">Select</option>

                    <option  value="B.Arch.">B.Arch.</option>
                    <option  value="B.Com">B.Com</option>
                    <option  value="B.E.">B.E.</option>
                    <option  value="B.Ed.">B.Ed.</option>
                    <option  value="B.Pharma">B.Pharma</option>
                    <option  value="B.Tech">B.Tech</option>
                    <option  value="BAMS">BAMS</option>
                    <option  value="BBA">BBA</option>
                    <option  value="BCA">BCA</option>
                    <option  value="BDS">BDS</option>
                    <option  value="BFA">BFA</option>
                    <option  value="BHM">BHM</option>
                    <option  value="BHMS">BHMS</option>
                    <option  value="High School">High School</option>
                    <option  value="ICWA">ICWA</option>
                    <option  value="M.Arch.">M.Arch.</option>
                    <option  value="M.Com.">M.Com.</option>
                    <option  value="M.D.">M.D.</option>
                    <option  value="M.Des.">M.Des.</option>
                    <option  value="M.E.">M.E.</option>
                    <option  value="M.Ed.">M.Ed.</option>
                    <option  value="M.Phil.">M.Phil.</option>
                    <option  value="M.S(Medicine)">M.S(Medicine)</option>
                    <option  value="M.Tech">M.Tech</option>
                    <option  value="MBA">MBA</option>
                    <option  value="MBBS">MBBS</option>
                    <option  value="Ph.d">Ph.d</option>
                    <option  value="Trade School">Trade School</option>
                    <option  value="Others">Others</option>

                    </select>
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails?.education == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Education is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                    </div>
                    
             </div>
             <div className="row margintop" style={{marginTop:"5px"}}>
    <div className="col-3 textright">
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Complexion</span>
    </div>
    <div className="col-9 form-group textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='complexion'
                    value={preferenceDetails?.complexion}
                    onChange={handleChange} >
                    <option selected value="">Select Skin Tone</option>
                    <option  value="Light">Light</option>
                    <option  value="Fair">Fair</option>
                    <option  value="Medium">Medium</option>
                    <option  value="Dark">Dark</option>

                    
                    </select> 
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails.complexion == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Complexion is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
             </div>

             <div className="row margintop3">
    <div className="col-3 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont" style={{fontSize:'13px'}} >Caste</span>
    </div>
    <div className="col-9 form-group textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='caste'
                    value={preferenceDetails?.caste}
                    onChange={handleChange} >
                    <option selected value="">Select</option>
                  <option value="Brahmin">Brahmin</option>
                  <option value="Bharia">Bharia</option>
                  <option value="Bhuinhar Bhumia">Bhuinhar Bhumia</option>
                  <option value=" Bhattra">Bhattra</option>
                  <option value="Ad Dharmi">Ad Dharmi</option>
                  <option value="Agri">Agri</option>
                  <option value="Ahom">Ahom</option>
                  <option value="Bhumia">Bhumia</option>
                  <option value="Vaishya">Vaishya</option>
                  <option value="Kshatriya">Kshatriya</option>
                  <option value="Dhobi">Dhobi</option>
                  <option value="Pasi">Pasi</option>
                  <option value="Rajput">Rajput</option>
                  <option value="Maratha">Maratha</option>
                  <option value="Yadav">Yadav</option>
                  <option value="Patidar">Patidar</option>
                  <option value="Mahar">Mahar</option>
                  <option value="Kunbi">Kunbi</option>
                  <option value="Kapu">Kapu</option>
                  <option value="Shudra">Shudra</option>
                  <option value="Khatri">Khatri</option>
                  <option value="Batwal">Batwal</option>


                    </select>  
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails.caste == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Caste is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>     
                    </div>
             </div>

             <div className="row margintop3">
    <div className="col-3 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont" style={{fontSize:'13px'}} >Age from</span>
    </div>
    <div className="col-4 textleft">
    <select  id="inputState" name='ageFrom' 
        onChange={handleChange}
        value={preferenceDetails?.ageFrom}
                  className=" form-control" style={{fontSize:"11px",marginLeft:"px"}}>
                  <option selected value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>

                             </select>  
                             <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails.ageFrom == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Age From is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div> 
                    </div>
                    <div className="col-1 textright">
      <span className="lbl popinsfont" style={{fontSize:'13px',whiteSpace:"nowrap"}} >Age To</span>
    </div>
    <div className="col-4 textleft" style={{marginTop:"5px"}}>
    <select  id="inputState" name='ageTo' value={preferenceDetails?.ageTo}
    onChange={handleChange}
                  className=" form-control" style={{fontSize:"11px",marginLeft:"px"}}>
                  <option selected value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>

                             </select> 
                             <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails.ageTo == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Age To is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>  
                    </div>
             </div>
             <div className="row margintop">
    <div className="col-3 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >height From</span>
    </div>
    <div className="col-2 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightFeetFrom' value={preferenceDetails?.heightFeetFrom}
                    onChange={handleChange} >
                    <option selected value="">In Ft</option>
                    <option  value="1">1ft</option>
                    <option  value="2">2ft</option>
                    <option  value="3">3ft</option>
                    <option  value="4">4ft</option>
                    <option  value="5">5ft</option>
                    <option  value="6">6ft</option>
                    <option  value="7">7ft</option>
                    <option  value="8">8ft</option>
                    <option  value="9">9ft</option>
                    <option  value="10">10ft</option>


                    
                    </select> 
                         
                    </div>
                  <div className="col-2 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightInchesFrom' value={preferenceDetails?.heightInchesFrom}
                    onChange={handleChange} >
                    <option selected value="">In Inch</option>
                    <option  value="1">1inch</option>
                    <option  value="2">2inch</option>
                    <option  value="3">3inch</option>
                    <option  value="4">4inch</option>
                    <option  value="5">5inch</option>
                    <option  value="6">6inch</option>
                    <option  value="7">7inch</option>
                    <option  value="8">8inch</option>
                    <option  value="9">9inch</option>
                    <option  value="10">10inch</option>


                    </select>    
                  </div>
                  <div className="col-1 textright">
      <span className="lbl popinsfont"style={{fontSize:'13px'}} > To</span>
    </div>
    <div className="col-2 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightFeetTo' value={preferenceDetails?.heightFeetTo}
                    onChange={handleChange} >
                    <option selected value="">In Ft</option>
                    <option  value="1">1ft</option>
                    <option  value="2">2ft</option>
                    <option  value="3">3ft</option>
                    <option  value="4">4ft</option>
                    <option  value="5">5ft</option>
                    <option  value="6">6ft</option>
                    <option  value="7">7ft</option>
                    <option  value="8">8ft</option>
                    <option  value="9">9ft</option>
                    <option  value="10">10ft</option>


                    
                    </select>  
                          
                    </div>
                  <div className="col-2 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightInchesTo' value={preferenceDetails?.heightInchesTo}
                    onChange={handleChange} >
                    <option selected value="">In Inch</option>
                    <option  value="1">1inch</option>
                    <option  value="2">2inch</option>
                    <option  value="3">3inch</option>
                    <option  value="4">4inch</option>
                    <option  value="5">5inch</option>
                    <option  value="6">6inch</option>
                    <option  value="7">7inch</option>
                    <option  value="8">8inch</option>
                    <option  value="9">9inch</option>
                    <option  value="10">10inch</option>


                    </select>    
                  </div>
             </div>
            
             <div className='row '>
             <div className=" col-3 "></div>

              <div className=" col-4 ">
                                    {error && preferenceDetails.heightFeetFrom  == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             heightFeet  From is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-1"></div>
                                <div className=" col-4 ">
                                    {error && preferenceDetails?.heightFeetTo == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             heightFeet To is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                                
             <div className="row margintop">
    <div className="col-3 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Other Expectations</span>
    </div>
    <div className="col-9 textleft">
    
    <textarea class="form-control" name='otherExpectations' value={preferenceDetails?.otherExpectations}    onChange={handleChange}
  id="exampleFormControlTextarea1" rows="7" placeholder="" style={{fontSize:"12px"}}/>
   
   <div className='row '>
              <div className=" col-12 ">
                                    {error && preferenceDetails.otherExpectations == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             Other Expectations  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div> 
                    </div>
             </div>
           
      
            
              </div>  
              <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn sourcesansfont"
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-3 margintop3">
          <button
            className="nextbtn1 sourcesansfont"
              variant="contained"
              color="primary"
              onClick={submitFormData4}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
          </div>
    </form>
        </div>
        )
}
export default MatrimonypartnerPrefrence