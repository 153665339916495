import React from "react";
import "./Footer.css";
import logo from '../images/footerimg.svg';
import { Link } from "react-router-dom";

const Footer=()=> {
 

    return (
        <div className="footer-main row">
      <div className="row footer-div">
        <div className="margintop paddingleft5 col-lg-3 col-sm-12 col-md-12">
            <span class ="footer-heading sourcesansfont">Naidu Directory</span>
            <div className="margintop3">
                <span class='footer-text sourcesansfont'>
                At Naidu Directory, we take pride in nurturing and strengthening the bonds of our vibrant Naidu community. 
Our platform is designed to serve as a dynamic hub for community members, where you can connect, grow,
 and celebrate our shared heritage.
                </span>
                </div>
        </div>
        <div className="col-lg-3 col-sm-12 col-md-6 margintop">
        <span class="footer-heading sourcesansfont">Community Services</span>
          <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Community Listing</span></div>
          <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Community Matrimonial Services</span></div>
          <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Community Business Listing</span></div>
          <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Community Women Empowerment</span></div>
          <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Community Social Services</span></div>

        </div>
        <div className="col-lg-2 col-sm-12 col-md-4 margintop">
        <span class="footer-heading sourcesansfont">We Are Social</span>
        <div class='margintop3'><a href='https://www.facebook.com/naiducommunitydirectory' target="_blank"><span class='margintop1 footer-text sourcesansfont'>Facebook</span></a></div>
          {/* <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Instagram</span></div> */}
          {/* <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Twitter</span></div> */}
          <div class='margintop3'><a href='https://www.linkedin.com/company/naiducommunitydirectory/'  target="_blank"><span class='margintop1 footer-text sourcesansfont'>Linkedin</span></a></div>
          {/* <div class='margintop3'><span class='margintop1 footer-text sourcesansfont'>Telegram</span></div> */}
        </div>
        <div className="col-lg-2 col-sm-12 col-md-4 margintop">
        <span class="footer-heading sourcesansfont">Policies</span>
        <div class='margintop3'><Link to="/JoinNowPrivacy"><span class='margintop1 footer-text '>Privacy Policy</span></Link></div>
          <div class='margintop3'><Link to="/JoinNowPrivacy"><span class='margintop1 footer-text '>Data Policy</span></Link></div>
          <div class='margintop3'><Link to="/NaiduSanghamCommunityPledge"><span class='margintop1 footer-text '>Community Pledge</span></Link></div>
          <div class='margintop3'><Link to="/JobListingPrivacy"><span class='margintop1 footer-text '>Job Listing Policy</span></Link></div>
          <div class='margintop3'><Link to="/TermsConditionsNaiduSangham"><span class='margintop1 footer-text '>Terms And Conditions</span></Link></div>
        </div>
        <div className="col-lg-2 col-sm-12 col-md-6 margintop">
        <span class="footer-heading sourcesansfont">Contact Us</span>
        <div class='margintop3'><span class='margintop1 footer-text '>admin@naidudirectory.org</span></div>
        {/* <div class='margintop'><span class='margintop1 footer-text '></span></div> */}
        <span class='margintop1 footer-text '>+91/9823423424</span>
        </div>
</div>
<div class='margintop bordertop textcenter'>
<span> <img src={logo} /></span>
<span class='allrightspn  marginleft5'>All rights reserved @ Naidu Directory Services</span> 
</div>

      </div>
    );
  }
  
  export default Footer;
  