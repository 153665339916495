import './LastNameSpellPop.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SuccessPop from './SuccessPop';

 
const SubCast = props => {

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  
  const [isOpendiv, setIsOpendiv] = useState(true);
  const [isOtpDiv, setIsotpDiv] = useState(false);

  const onNext = (isback) => {
   
    if(isback){
      setIsOpendiv(true);
      setIsotpDiv(false);
    }
    else{
      setIsOpendiv(false);
      setIsotpDiv(true);
    }
  }

  const [enablejoin, setenableResend] = useState(false);



    const [counter, setCounter]= useState(46)
;
  useEffect(() =>{
    if(counter === 0){
      setenableResend(true);
  
    }
    const timer =
    counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
    return () => clearInterval(timer);
  },[counter]);

  const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    }
  return (
    <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop">
      <div className={isOpendiv?"":"hide"}>

        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12"></div>
                <div className="col-lg-8 col-sm-12 col-md-12">
                <span className="lastpop-top-text ">Caste Not Found!</span>
            
                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-lg-11 col-md-12 col-sm-12">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

        <div className="margintop row">
  <div className="col-lg-3 col-md-12 col-sm-12 textright ">
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Spelling</span>
  </div>
  <div className="col-lg-7 col-md-12 col-sm-12 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="firstname"
         placeholder="Required Subcast "
         style={{fontSize:"14px",height:'32px'}}
    />
  </div>
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-lg-3 col-md-12 col-sm-12 textright ">
  <span for="inputState" className="lbl " style={{fontSize:"14px"}}>Email Id </span>
  <FaInfoCircle size='0.8em' className="margintpmins1" data-tip data-for="registerTip"/>:
            <ReactTooltip id="registerTip" place="right"  class='tooltip1' effect="solid" width="300px">
                    We will Notify on This email id once required Subcast is added
                 </ReactTooltip>
  </div>
  <div className="col-lg-7 col-md-12 col-sm-12 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="mob"
         placeholder="Your Email Id"
         style={{fontSize:"14px",height:'32px'}}
    />
  </div>
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright">
  <span for="inputState" className="lbl " style={{fontSize:"14px"}}>Mobile No </span>
  </div>
  <div className="col-lg-2 col-md-12 col-sm-12 textleft form-group">
  <select   id="inputState" name='lastnme' 
            className="form-control " 
            style={{fontSize:"14px",height:'32px',paddingBottom:'6px',width:"67px"}}
            >
            <option selected value="" style={{fontSize:"14px",height:'30px'}}> +91</option>
            
            </select>
    </div>

  <div className="col-lg-5 col-md-12 col-sm-12 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mob"
         placeholder="Mobile No"
         style={{fontSize:"14px",height:'32px'}}
    />
  </div>
  
    
   
    </div>
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-lg-4 col-sm-12 col-md-12'>
                        <button className='cancelbtn'  onClick={props.handleClose} style={{width:'130px',height:"42px"}}>Cancel</button>
                    </div>
                    <div className='col-lg-3 col-md-12 col-sm-12'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px"}} onClick={handleSubmit(() => onNext(false))}>Next</button>
                  </div>
                    <div className='col-1'></div>

                  </div>
                  </div>
                  <div className={isOtpDiv?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-lg-4 col-sm-12 col-md-12"></div>
                <div className="col-lg-8 col-md-12 col-sm-12">
                <span className="lastpop-top-text ">Caste Not Found!</span>
            
                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-lg-11 col-sm-12 col-md-12">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number 99xxxxxx23</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id saxxxx26@gmail.com
              </span>
            </div>
            </div>
            </div>
            <div className='row margintop ' style={{marginLeft:"0px"}}>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 '>
              <input type="number" className="otpbox form-control" id="fst"
               name="" />
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox1  form-control" id="fst"
               name="" style={{marginLeft:2}}/>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox3  form-control" id="fst"
               name="" style={{marginLeft:4}}/>
              </div>
              <div className='col-2'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="" style={{marginLeft:6}}/>
              </div>
            
            </div>
            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counter > 0 && setInterval(() =>setCounter(counter-1), 1000)}>Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'130px',height:"42px"}} onClick={handleSubmit(() => onNext(true))}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px",justifySelf:"right"}} onClick={togglePopup}>Submit</button>
                  </div>
                  {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />}
                    <div className='col-1'></div>

                  </div>
        </div>

      </div>
    </div>
  );
};
 
export default SubCast;