import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";




const JoinNowPrivacy =()=>{
   
//for session
let history = useHistory();

// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);

    return(
        <div className="container-fluid  ">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                <Link to='/LandingPage'> <img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
           <div className="row margintop1">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
            <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
            <div className="row">
            <span className="event-sub-detail">
            Privacy and Data Policy Naidu directory Community Platform
<div className="margintop1">Effective Date: 22 Apr 2023</div>

                        
            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail1">
            At Naidu Directory, we are committed to protecting your privacy and maintaining the confidentiality of your personal information. 
            This Privacy and Data Policy outlines how we collect, use, store, and disclose your data when you use the Naidu Sangham Community Platform ("the Platform"). 
            By accessing or using the Platform, you agree to the terms and practices described in this policy.

                        
            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
           Information Collection and Use:            </span>
            <span className="event-sub-detail1 margintop1">
           1.1 Personal Information: We may collect personal information, including but not limited to your name, email address, location,
            and other details voluntarily provided by you during registration or while using the Platform.
             
            </span>
            <span className="event-sub-detail1 margintop1">
            1.2. Usage Data: The Platform may collect non-personal information about your interactions with it, such as log data, device information, IP address,
             browser type, and pages visited, to improve our services and user experience.
            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Information Storage and Security:            </span>
            <span className="event-sub-detail1 margintop1">
            2.1. Data Security: We employ industry-standard security measures to protect your personal information from unauthorized access, loss, misuse, or alteration. However, no data transmission over the internet or electronic storage method is entirely secure, 
            and we cannot guarantee absolute security.
             
            </span>
            <span className="event-sub-detail1 margintop1">
            2.2. Data Retention: We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this policy and as required by applicable laws. Once the information is no longer needed,
             we will securely dispose of it.
            </span>
           </div>
           
           
           <div className="row margintop3">
            <span className="event-sub-detail">
            Use of Information:             </span>
            <span className="event-sub-detail1 margintop1">
            3.1. Personalization: The information collected may be used to personalize your experience on the Platform and provide content, products, 
            or services tailored to your interests and preferences.
            </span>
            <span className="event-sub-detail1 margintop1">
            3.2. Communication: We may use your email address to send you relevant updates, newsletters, announcements, or other communications related to the Naidu Sangham community.
             You can opt-out of such communications at any time by following the provided instructions.
            </span>
            <span className="event-sub-detail1 margintop1">
            3.3. Community Engagement: Your data may be used to enable interactions with other members of the Naidu Sangham community, promoting networking and knowledge sharing among users.

            </span>
           </div>

           <div className="row margintop3">
            <span className="event-sub-detail">
            Sharing of Information:    </span>
            <span className="event-sub-detail1 margintop1">
            4.1. Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating and managing the Platform. These providers are bound by strict confidentiality 
            agreements and will only process your data as instructed by us. 
            </span>
            <span className="event-sub-detail1 margintop1">
            4.2. Legal Obligations: We may disclose your personal information if required to do so by law or in response to valid legal requests, such as subpoenas, court orders, or government investigations.
           </span>
            <span className="event-sub-detail1 margintop1">
            4.3. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of Naidu Sangham's assets, your personal information may be transferred as part of the transaction.
            </span>
           </div>

           <div className="row margintop3">
            <span className="event-sub-detail">
            Cookies and Tracking Technologies:    </span>
            <span className="event-sub-detail1 margintop1">
            5.1. Cookies: The Platform may use cookies and similar tracking technologies to enhance user experience, collect usage data, and customize content.
             You can manage cookie preferences through your browser settings.
 
            </span>
            <span className="event-sub-detail1 margintop1">
            5.2. Third-Party Analytics: We may use third-party analytics services to analyze user behavior and improve the Platform. These services may collect and store usage data, subject to their respective privacy policies.

            </span>
           </div>
           
           <div className="row margintop3">
            <span className="event-sub-detail">
            Children's Privacy:    </span>
            <span className="event-sub-detail1 margintop1">
            The Naidu Sangham Community Platform is not intended for use by individuals under the age of 13.
             We do not knowingly collect personal information from children, and if we become aware that a user is under 13, we will take steps to remove their data from our records.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Your Rights:   </span>
            <span className="event-sub-detail1 margintop1">
            You have the right to access, correct, update, or delete your personal information held by Naidu Sangham. To exercise these rights or if you have any questions or concerns regarding your data,
             please contact us at support@naidusangham.com. 
            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Changes to the Privacy and Data Policy:  </span>
            <span className="event-sub-detail1 margintop1">
            We reserve the right to modify or update this Privacy and Data Policy at any time. Changes will be posted on this page, and the "Effective Date" will indicate when the policy was last updated.

</span>
            
           </div>

           <div className="row margintop3">
            <span className="event-sub-detail">
            By using the Naidu Sangham Community Platform, you acknowledge and agree to the terms of this Privacy and Data Policy. If you do not agree with this policy, please refrain from using the Platform.
 
            </span>
            
            
           </div>
          

            </Card>
            <div className="row mt-1 textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12"><Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>
           
            
            </div>

           </div>
          
            
            
           


        </div>
    );
}
export default JoinNowPrivacy