import React, { useState, useEffect } from "react";
import './ContactinfoPopup.css'
import './UserLanding.css'
import axios from "axios";

import {ImCross,ImMobile} from 'react-icons/im'
import {TbCheckbox} from 'react-icons/tb'
import imgupload from '../../images/imageupload.svg'
import {FaWhatsapp,FaArrowRight} from 'react-icons/fa'
import {MdEmail} from 'react-icons/md'
import {RiCupFill,RiSendPlaneFill} from 'react-icons/ri';
import {configData} from "../../Config"


 
const ContactInfoPopup = props => {
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  //for community directory
const [commDirectory, setcommDirectory] = useState([]);

useEffect(() => {
 loadCommDirectory();
}, []);

const loadCommDirectory = async () => {
 
 debugger;
 const result = await axios.get(`/subscriber/getAllSubscribers?id=${uerAuthId}`);
 setcommDirectory(result.data.data);
 console.log("comdirectory",result.data.data)
};
  return (
    <div className="popup-boxcontact">
      <div className="boxcontact">
        <div className="row margintpmins">
            <div className="col-3 textleft"><span className="contactpop-txt textleft">Sagar Naidu</span></div>
            <div className="col-8"></div>
            <div className="col-1"> 
            <ImCross onClick={props.handleClose} size='0.9em' color='black'/>            
       </div>

        </div>
        <div className="cont-verify-div margintop2">
        <div className="row ">
            <div className="col-1">       
                 <TbCheckbox color="" size='1.1em'/>
             </div>
            <div className="col-3">
            <span className="cont-verifytxt">Verified Account</span>

            </div>
        </div>
        <div className="row">
            <span className=" cont-verifytext">A Phone number associated with this aacount has been Verified. Contact info will be displayed as per members contact sharing prefrences. 
            No contact detailes are shared ny XYZ Community portal. As per the XYZ Community guidelines we strongly recomends to avoid Spam
            calls. XYZ Community reserves rights to suspend access 0 contact details if reported for spam calls.  </span>
        </div>
        <div className="row margintop1"></div>
        </div>
        <div className=""><span className="cont-continfotext">Contact Info</span></div>
        <div className="row margintop2">
     <div className="col-6">
        <div className="row">
            <div className="col-1">
            <img src={imgupload}  className=' communitydrect-img'/>
         </div>
            <div className="col-10">
                <span className="cont-contactinfo-txt">Sagar Naidu's Profile</span>
                <div className=""><span className="cont-contactinfo-subtxt">xyzcommunity.com/sagarnaidu26</span></div>
            </div>
          </div>
          <div className="row margintop2">
            <div className="col-1">
            <MdEmail  size='1.2em' color="#B5924C"/>
         </div>
            <div className="col-10">
                <span className="cont-contactinfo-txt">Email</span>
                <div className=""><span className="cont-contactinfo-subtxt">saxxxxxxxxx26@xxx.com</span></div>
            </div>
          </div>

        </div>  
     
     
     <div className="col-6">
     <div className="row">
            <div className="col-1">
            <FaWhatsapp size='1.2em' color="#B5924C"/>
         </div>
            <div className="col-10">
                <span className="cont-contactinfo-txt">Whatsapp</span>
                <div className=""><span className="cont-contactinfo-subtxt">99xxxxxx23</span></div>
            </div>
          </div>
          <div className="row margintop2">
            <div className="col-1">
            <ImMobile size='1.2em' color="#B5924C"/>
         </div>
            <div className="col-10">
                <span className="cont-contactinfo-txt">Mobile</span>
                <div className=""><span className="cont-contactinfo-subtxt">99xxxxxx23</span></div>
            </div>
          </div>

    </div>       
     
     </div> 
     
     <div className="row margintop2"></div>

     <div className="row margintop">
        <div className="col-6"></div>
     <div className="col-3 textcenter">
     <button className="com-conect-btn">Connect</button>
            </div>
            <div className="col-3 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
        </div>         
      </div>
    </div>
  );
};
 
export default ContactInfoPopup;