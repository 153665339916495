import '../ViewProfile/ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';
import { RiCupFill, RiSendPlaneFill, RiTimeLine } from 'react-icons/ri';
import { MdOutlineArrowDropDown, MdLocationOn, MdNotifications, MdWaterDrop,MdEmail,MdCheckBox } from 'react-icons/md';
import { FaRegComments, FaUserClock, FaFilter, FaInfoCircle,FaSearch, FaRegHandshake, FaEdit, FaBookmark,FaPhoneSquareAlt,FaRegCalendar,FaUsers } from 'react-icons/fa';
import { BsBookmark } from 'react-icons/bs';
import {configData} from "../../Config"

const CoffeemeetPopup = (props) => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


     const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
     console.log("authdata11",authlocalstoragedata)
    
     const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
     const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

     let history = useHistory();

     //for new session for userlogin
  

//  const [referData ,setreferData] = useState({
  
//   referralCode: uerAuthReferralCode,
//   firstName: "",
//   lastName: "",
//   countryCode: "",
//   emailId: "",
//   mobileNumber: "",
  
//  });

//  const referChange = (e) => {
//   debugger;
//   const { name, value, type, checked } = e.currentTarget;


//   setreferData(preState => ({
//     ...preState,
//     [name]: type === 'checkbox' ? checked : value
//   }));
// }
// const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
// const [error, setError] = useState(false);
// //for email
//    const submitFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
   
//      if (validator.isEmpty(referData.firstName)) {
//         setError(true);
//     }
//    else if (validator.isEmpty(referData.lastName)) {
//       setError(true);
//    }
//    else if (validator.isEmpty(referData.countryCode)) {
//     setError(true);
//   }
//    else if (validator.isEmpty(referData.emailId)) {
//     setError(true);
//    }
//    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
//     setemailEroor(true);
//   }
//    else if (validator.isEmpty(referData.mobileNumber)) {
//     setError(true);
//    }
//    else if (referData.mobileNumber.length != 10) {
//     setError(true);
  
//   }
//   else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
//     setContactValidEroor(true);
    
//   }
    
//     else {
//       debugger
//       addReferalinvite();
//     }
// };

// const addReferalinvite =()=>{
  
// const response = axios.post('/subscriber/inviteMember', referData)
//     response.then(() => {
//           toast.success("Innvite Member Successfully!");

//       })
//        response.catch((err) => {
//          toast.warn("Something went wrong")
//        });
//        console.log("response", response?.data)
//        console.log("InviteMember",referData)
      
// }

//for commDirectry list
const [communityData, setcommunityData] = useState([]);

    useEffect(() => {
      loadCommunityData();
    }, []);
  
    const loadCommunityData = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+'/subscriber/viewProfileById?hostId=' +props.uerAuthId+ '&userId=' +props.idforcommpop);
      setcommunityData(result.data.data);
      console.log("communityData",result.data.data);
    };

if(communityData.mobileVisibility==0){
 var mobileNumberformat = communityData.mobileNumber.substr(0, 2) + 'xxxxxx'
          + communityData.mobileNumber.substr(8, 9);
}
else{
 var mobileNumberformat=communityData.mobileNumber
}

    //for community popup
  const [isOpenMessagePopDirect, setIsOpenMessagePopDirect] = useState(false);
  const [idforpop, setidforpop] = useState(0);
  const [styleForMessgBlockstatusCommView, setstyleForMessgBlockstatusCommView] = useState(null);

  
  const togglePopupMessageDirect = (id,blockStatus) => {
    setIsOpenMessagePopDirect(!isOpenMessagePopDirect);
    setidforpop(id);
    setstyleForMessgBlockstatusCommView(blockStatus)

  }

  const [commDirectoryconReq, setcommDirectoryconReq] = useState(false);

  const addCommDirectoryConnect = async (id) => {
    debugger
    const response = await axios.post(configData.ApiUrl+'/connection/sendConnection?senderId=' + uerAuthId + '&recieverId=' + id)
    if (response.status == 200 && response.data.code) {
      //setcommDirectoryconReq(true)
      loadCommunityData();

    }
    else {
      setcommDirectoryconReq(false)
      alert("Something Went Wrong..!")
    }
  }
  
  const addCommDirectoryBookmark = async (id) => {

    const response = await axios.post(configData.ApiUrl+'/subscriber/bookMark?hostId=' + uerAuthId + '&userId=' + id)
    if (response.status == 200 && response.data.code) {
      //toast.success("Bookmarked Successfully!")
      loadCommunityData();

    }
    else {
      alert("Something Went Wrong..!")
    }
  }

  //for close popup when click on outside
const popupMessagdivRef = useRef(null);
const handleClickOutsideMessagediv = (event) => {
  if (popupMessagdivRef.current && !popupMessagdivRef.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideMessagediv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideMessagediv);
  };
}, []);
  return (
    <div className="popup-boxrefmember1" ref={popupMessagdivRef}>
      <div className="boxrecoffee" style={{width:"67%",height:"98vh",marginTop:"150px"}}>
      {/* <span className="close-commdirect" onClick={props.handleClose}>x</span> */}

      <div className="row margintop3">
            <span className="event-sub-detail textcenter" style={{fontSize:"16px"}}>
            Host and Guest Responsibilities
                </span>
            <span className="event-sub-detail margintop3">
            Disclaimer for Hosts
            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> a. Event Details :</span> sure all event details are accurate and up-to-date. The accuracy of the venue, time, and date is your responsibility.
            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> b. Women’s Safety :</span> Be aware of and implement safety measures to protect all participants, particularly women. If you notice any concerns, address them promptly and contact local authorities if needed.

            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> c. Privacy : </span> Do not share personal contact details or sensitive information outside the app.

            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> d. Compliance : </span>  Follow all local regulations and guidelines regarding public gatherings and events.

            </span>
            <span className="event-sub-detail margintop3">
            Disclaimer for Guests
            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> a. Event Participation:</span> Attend events as per the details provided in the app. 
            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> b. Personal Safety:</span> Be cautious when meeting new people. Always prioritize your personal safety and inform a friend or family member of your whereabouts.: Attend events as per the details provided in the app. 

            </span>
            <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> c. Privacy:</span> Respect the privacy of other attendees. Do not share personal contact information without consent.
           </span>
           <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> d. Conduct: </span> Maintain respectful behavior throughout the event. Report any concerns or inappropriate behavior to the host or app support.
           </span>
           <span className="event-sub-detail1 margintop3">
            <span className="event-sub-detail "> e. Women’s Safety: </span> If you feel uncomfortable or unsafe at any time, leave the event and contact local authorities if necessary. The app provides features to help ensure safety and security for all users.
           </span>
            
           </div>
           <div className='popupbtn-div margintop3'>

<div className='row '>
      <div className='col-3'></div>
      <div className='col-3'>
          <button className='cancelbtn'   style={{width:'130px',height:"42px",fontSize:"13px"}}>Disagree</button>
      </div>
      <div className='col-3'>
      <button className='createeventbtn' style={{width:'130px',height:"42px",fontSize:"13px",marginLeft:"15px"}} onClick={props.handleClose}>Agree</button>
    </div>

    </div>
    </div>
                  
      </div>
    </div>
  );
};
 
export default CoffeemeetPopup;