import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect ,useRef} from "react";
import AddKycPopup from "./AddKycPopup";
import AddNewsPopup from "./AddNewsPopup";
import moment, { duration } from 'moment'
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import imgupload from '../../images/imageupload.svg'

import { toast } from "react-toastify";
import AddJobPop from './AddJobPop';
import {configData} from "../../Config"

 
const AddBlogPopup = props => {

  //for session
  let history = useHistory();

  const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
     setBlog(preState => ({
      ...preState,
      "scheduledDate": (moment(e).format("DD MMM yyyy")),

    }))
    }

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

    const [blog, setBlog] = useState({
      hostId:authlocalstoragedata?.userAuthenticationId,
      authorName:authlocalstoragedata?.name2,
      blogTitle:"",
      imageSource:"",
      blogContent:"",
      publishStatus:"",
      scheduledDate:"",
      scheduledTime:"",
      
      })

      const [imgData, setImgData] = useState(null);
      const fileref = useRef(null);
      
      const [attachment, setAttachment] = useState(null)

      const [error, setError] = useState(false);

      const submitFormData = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
         if (validator.isEmpty(blog.blogTitle)) {
             setError(true);
         }
         else if (blog.blogTitle.length > 70) {
          setError(true);

        }
         else if (validator.isEmpty(blog.imageSource)) {
             setError(true);
         }
         else if (blog.imageSource.length > 70) {
          setError(true);

        }
         else if (validator.isEmpty(blog.blogContent)) {
           setError(true);
         }
         else if (blog.blogContent.length > 7500) {
          setError(true);

        }
         else if (validator.isEmpty(blog.publishStatus)) {
           setError(true);
    
         }
         else if (validator.isEmpty(blog.scheduledDate) && blog.publishStatus=='schedulelater') {
             setError(true);
         }
         else if (validator.isEmpty(blog.scheduledTime) && blog.publishStatus=='schedulelater') {
             setError(true);

        }
       
       
     else {
      
      addBlogDetails();      
      
       }
    };
   
    const addBlogDetails =()=>{
      
      const formData = new FormData();

      if(blog.authorName!=undefined){
        setBlog(preState =>({
          ...preState,
          "authorName":authlocalstoragedata?.name2
        }))
      }

      if(blog.authorName!=undefined){
        setBlog(preState =>({
          ...preState,
          "hostId":authlocalstoragedata?.userAuthenticationId
        }))
      }
debugger;
      formData.append('blog',JSON.stringify(blog));
      formData.append('bannerImage', attachment);

      const response = axios.post(configData.ApiUrl+'/CommunityPost/createBlog', formData)
      response.then(() => {
            toast.success("Blog Added Successfully!");
            props.handleClose(togglePopupBlog)

          })
         response.catch((err) => {
           alert("Getting error in featching data")
         });
         console.log("response", response?.data)
          console.log("blog",blog)
     }

    const [selected, setSelected] = useState('blog');

const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const handleChange = event => {
    setSelected(event.target.value);
    const {name,value,type,checked}=event.currentTarget;
        debugger
        
        setBlog(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))

        if(event.currentTarget.files != null)
        {
            setBlog(event.target.files[0]);
        }

        if(blog.authorName!=undefined){
          setBlog(preState =>({
            ...preState,
            "authorName":authlocalstoragedata?.name2
          }))
        }
  
        if(blog.authorName!=undefined){
          setBlog(preState =>({
            ...preState,
            "hostId":authlocalstoragedata?.userAuthenticationId
          }))
        }
  };

  const banerImageChange = event=>{

    //  if(event.currentTarget.files != null)
    //     {
    //       setAttachment(event.target.files[0]);
    //     }

        if (event.currentTarget.files[0]) {
          console.log("picture: ", event.target.files);
          setAttachment(event.currentTarget.files[0]);
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setImgData(reader.result);
          });
          reader.readAsDataURL(event.currentTarget.files[0]);
        }
  }


  //for opening fields depends on radio cheked
  const [showhide, setShowhide]= useState("");


  const handleshow= e=>{
    const getshow= e.target.value;
    setShowhide(getshow);
  }
  
  const [isOpentopjobPop, setisOpentopjobPop] = useState(false);
  const togglePopuptopJobPop = () => {
    setisOpentopjobPop(!isOpentopjobPop);
  }
  //for close popup when click on outside
const popupBlogdivRef = useRef(null);
const handleClickOutsideBlogtdiv = (event) => {
  if (popupBlogdivRef.current && !popupBlogdivRef.current.contains(event.target)) {
    props.handleClose();
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideBlogtdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideBlogtdiv);
  };
}, []);

return (
    <div className="popup-boxnews">
      <div className="boxnews" ref={popupBlogdivRef}>
     <div className="topnewsrow" style={{paddingTop:"11px",paddingBottom:"2px"}}>
                  <div className="row " id="sharediv">
                    <div className="col-2" style={{marginTop:"-2px"}}><span>Share an</span></div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopup}
                    checked={selected === 'event'}
                    onChange={handleChange}
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Event</label> */}
                     
                     <label class="radiodivcust radiobtn-text1" >Event
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopup}
                    checked={selected === 'event'}
                    onChange={handleChange}
                    />  
                      <span class="checkmark"></span>
           </label>
           {isOpen && <AddNewsPopup
      
                    handleClose={togglePopup}
                  />}
                    </div>
                    <div className="col-2  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4" 
                    onChange={handleChange}
                    onClick={togglePopupBlog}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Blog</label> */}
                     <label class="radiodivcust radiobtn-text1" >Blog
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4" 
                    onChange={handleChange}
                    onClick={togglePopupBlog}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpenBlog && <AddBlogPopup
      
                handleClose={togglePopupBlog}
               />}
                    </div>
                    {/* <div className="col-2  textleft"> */}
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                       onChange={handleChange}
                       checked={selected === 'recipie'}
                       value='recipie'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Recipie</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Recipie
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                       onChange={handleChange}
                       checked={selected === 'recipie'}
                       value='recipie'
                    />  
                      <span class="checkmark"></span>
           </label> */}
                    {/* </div> */}
                    {/* <div className="col-2 whitenowrap  textleft"> */}
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Vlog</label> */}
                     {/* <label class="radiodivcust radiobtn-text1" >Vlog
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    />  
                      <span class="checkmark"></span>
           </label> */}
                    {/* </div> */}
                    <div className="col-1 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1" >KYC
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpenkyc && <AddKycPopup
      
      handleClose={togglePopupKyc}
     />}
                    </div>
                    <div className="col-2 whitenowrap  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{marginLeft:"10px"}} >Looking for Job Post
                     <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onClick={togglePopuptopJobPop}
                    onChange={handleChange}
                    checked={selected === 'job'} 
                    value='job'
                    />  
                      <span class="checkmark"></span>
           </label>
                     {isOpentopjobPop && <AddJobPop
      
      handleClose={togglePopuptopJobPop}
     />}
                    </div>

                  </div>
                  </div>
                  <form onSubmit={submitFormData}>

                  <div className='row'style={{overflowY:"scroll",height:"245px"}}>
                    
                    <div className="row margintop3">
                    <div className="col-3 textright whitenowrap"><span className="lbl ">Author Name</span></div>
                    <div className="col-7 textleft "><span className="complet-name" style={{fontSize:"13px"}}>{authlocalstoragedata?.name2}</span></div>
                </div>
                <div className="row margintop">
                    <div className="col-3 mt-1 textright"><span className="lbl whitenowrap ">Blog Title</span></div>
                    <div className="col-7 textleft form-group">
                 <input type="text" class=" form-control" placeholder='Add Blog Title' onChange={handleChange} name='blogTitle' style={{fontSize:"13px"}}/>
                  </div>
                </div>
               <div className='row '>
                  <div className='col-3'></div>
               <div className="col-8">
                                    {error && blog.blogTitle == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Title is required .
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  blog.blogTitle?.length >70 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Blog Title  cannot exceed more than 70 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
                                
  </div> 
                <div className="row margintop3">
                    <div className="col-3 mt-1 textright"><span className="lbl ">Image Source</span></div>
                    <div className="col-7 textleft form-group">
                    <input type="text" class=" form-control" placeholder='Add Image Source' onChange={handleChange} name='imageSource' style={{fontSize:"13px"}} />

                   </div>
                </div>
                 <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && blog.imageSource == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Image Source is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  blog.imageSource?.length >70 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Image Source  cannot exceed more than 70 characters.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
                
                <div className="row margintop">
                    <div className="col-3 textright"><span className="lbl ">Banner Image</span></div>
                    <div className='col-7 margintop1'>
                            <label for="selectFile" name="bannerImage" className="" style={{ backgroudColor: "white" }}   >
                            {
                  imgData !=null ?(
                    <img id='image' className="playerProfilePic_home_tileblog"
                    src={imgData} 
               
                />
                  ):
                       ( <img src={imgupload}  className='imagePreview' style={{height:"160px"}} onChange={banerImageChange}/>)
                  }
                           </label>
                     <input id="selectFile" type="file" ref={fileref}  className="fileupload hide" 
                       name="bannerImage"  onChange={banerImageChange}
                        />
                        <div className={imgData ==null?'margintpmins1':"hide"} style={{marginLeft:'50px'}}><span className='photoupload-lbl textcenter'>Add Banner Image to your blog</span></div>
                              </div>
                </div>
               
                <div className="row margintop3">
                    <div className="col-3 textright"><span className="lbl ">Add Blog Content</span></div>
                    <div className="col-9 textleft form-group">
                    <textarea class="form-control" name='blogContent' onChange={handleChange} id="exampleFormControlTextarea1" rows="10" placeholder="Blog Content" style={{fontSize:"13px"}}/>
                        </div>
                </div>
                
                 <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && blog.blogContent == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Content is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error && blog.blogContent.length >7500  ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Blog Content  cannot exceed more than 7500 characters..
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
                <div className="row ">
                    <div className='col-3'></div>
                <div className="col-3  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews'  
                    name="addblogradio" value="publishnow" onClick={ handleshow }
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Publish Now</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{color:""}}>Publish Now
                     <input type="radio" id="radio1" className=''  
                    name="publishStatus" value="publishnow" onClick={ handleshow } onChange={handleChange}
                    />
                      <span class="checkmark"></span>
           </label>
                    </div>
                    <div className="col-4  textleft">
                    {/* <input type="radio" id="radio1" className='radiobtnnews' onClick={ handleshow }
                    name="addblogradio" value="schedulelater"
                    />  
                     <label for="defaultRadio1" className='marginleft2 radiobtn-textblog'>Schedule for later</label> */}
                     <label class="radiodivcust radiobtn-text1" style={{color:""}}>Schedule for later
                     <input type="radio" id="radio1" className='radiobtnnews' onChange={handleChange} onClick={ handleshow }
                    name="publishStatus" value="schedulelater"
                    /> 
                      <span class="checkmark"></span>
           </label>
                    </div>

                 </div>
                
                  <div className='row '>
                  <div className='col-3'></div>
               <div className="col-6">
                                    {error && blog.publishStatus == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>   
                    
                    
                    
                  {
                    showhide==='schedulelater' && (
                    <div className='row'>
                      <div className='col-2'></div>
                    <div className='col-4 marginleftmins4 textright'>
                     <label  className="form-label schedulforlbl"> Schedule Date Time</label> 
                     </div>
                     <div className='col-3 textleft'>
                     <DatePicker
                    className="adreservdate heightfordt inputwidth"
                    name='scheduledDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    dateFormat="dd  MMM yyyy"
                    minDate={moment().toDate()}

                />    
                 <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {error && blog.scheduledDate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Date is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>        
                     </div>
                     <div className="col-3 textleft"> 
                     <input type="time" name='scheduledTime' onChange={handleChange} className='heightfordt form-control'/> 
                      <div className='row '>
                  <div className=''></div>
               <div className="col-12">
                                    {error && blog.scheduledTime == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Time  is is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>                                                        
                    </div>
                    </div>

                    )

                    } 

                  </div>
                  <div className='popupbtn-div margintop3'>

                  <div className='row  '>
                    <div className='col-3'></div>
                    <div className='col-3'>
                        <button className='cancelbtn'  onClick={props.handleClose}style={{height:"42px",width:"140px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>

                    {
                    showhide==='publishnow' && (
                     <button className='createeventbtn' type='submit' style={{height:"42px",width:"140px"}}>Publish Blog</button> 
                  )

                }

               {
                    showhide==='schedulelater' && (
                      <div>
                     <button className='createeventbtn' type='submit' style={{height:"42px",width:"140px"}}>Schedule Blog</button> 
                      </div>
                      )

                }
                                  </div>


                  </div>
</div>
</form>
         
      </div>
    </div>
  );
};
 
export default AddBlogPopup;