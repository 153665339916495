import { Breadcrumb,Badge, Button, Card, Col, Form, FormGroup, Dropdown ,ButtonGroup, InputGroup, Row ,Carousel,Pagination} from 'react-bootstrap';
import event1 from '../../images/event1.png'
import event2 from '../../images//event2.png'
import event3 from '../../images//event3.png'
import kycimg from '../../images//kyc.png';
import { Link } from "react-router-dom";
//import imgupload from '../../images//imgupload.svg';
import blog from '../../images//blog.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import imgupload from '../../images/imageupload.svg'
import {BiLike,} from 'react-icons/bi';
import {AiOutlineDislike,AiFillLike,AiOutlineFilePdf} from 'react-icons/ai'
import blogimg from '../../images/blog.png'
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit} from 'react-icons/fa';
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import axios from "axios";
import { useParams } from "react-router-dom";
import {MdClose} from 'react-icons/md'
import { toast } from "react-toastify";
import pdf from '../../images/Resume - 2.pdf'
import {configData} from "../../Config"
import Avatar from '@mui/material/Avatar';


const {  useReducer } = React;
const initialState = {
  likes: 21,
  dislikes: 5
}



function valuetext(value) {
  return `${value}`;
}

const Post = () => {

    const appReducer = (state, action) => {
        switch(action.type) {
          case 'HANDLE_LIKE':
            return {
              ...state,
              likes: state.likes + action.payload
            }
          case 'HANDLE_DISLIKE':
            return {
              ...state,
              dislikes: state.dislikes + action.payload
            }
          default:
            return state
        }
      }
      
        
        const handleClickLike = () => {
          if (status==='like') {
            setStatus(null)
            dispatch({
              type: 'HANDLE_LIKE',
              payload: -1,
            })
          } else {
            setStatus('like')
            if (status==='dislike') {
              dispatch({
                type: 'HANDLE_DISLIKE',
                payload: -1,
              })
            }
            dispatch({
              type: 'HANDLE_LIKE',
              payload: 1,
            })
          }
        }
        
        const handleClickDislike = () => {
          if (status==='dislike') {
            setStatus(null)
            dispatch({
              type: 'HANDLE_DISLIKE',
              payload: -1,
            })
          } else {
            setStatus('dislike')
            if (status==='like') {
              dispatch({
                type: 'HANDLE_LIKE',
                payload: -1,
              })
            }
            dispatch({
              type: 'HANDLE_DISLIKE',
              payload: 1,
            })
          }
        }
        const [state, dispatch] = useReducer(appReducer, initialState)
      const { likes, dislikes } = state
      const [status, setStatus] = useState(null)


  const [searchDiv ,setSearchDiv] = useState(false);
  function searchpoupopen (isSearch){
    if(isSearch){
      setSearchDiv(true);
    }
    else{
      setSearchDiv(false);
    }
  }
  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());


  const handleChangeDate =(e)=>{
    
    setStartDate(e);
    
   }
   const handleChangeDate1 =(e)=>{
    
    setStartDate1(e)
    
 }
//  const [comment ,setComment] = useState(false);
//   const commentClick = () => {
//     setComment(true);
//   }

//  const [eventData, setEventData] = useState([]);

//  useEffect(() => {
//   loadEvent();
//  }, []);

//  const loadEvent = async () => {
//    const result = await axios.get("/CommunityPost/getListOfEvents");
//    setEventData(result.data.data);
//    debugger;
//    console.log(eventData);
//  };
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

const [loadPost, setloadPost] = useState([]);

     useEffect(() => {
      loadPostList();
     }, []);
  
    const loadPostList = async () => {
      
      debugger;
      const result = await axios.get(`${configData.ApiUrl}/CommunityPost/getListPostByHostId?id=${uerAuthId}`);
      setloadPost(result.data.data);
      console.log("postlist",result.data.data)
    };


    //for getlikesbyid
const [LikeDiv ,setLikeDiv] = useState(false);
function Likepoupopen (isLike){
  if(isLike){
    setLikeDiv(true);
  }
  else{
    setLikeDiv(false);
  }
}
const { id } = useParams();

const [likeData, setlikeData] = useState([]);

useEffect(() => {
//loadlikes();
}, []);

const loadLikesInfo = async(id) => {
console.log(id)
 const result =  await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfLikesById?id=${id}`);
 setlikeData(result.data.data);
//  debugger;
 console.log("likesdt",result.data.data);

 Likepoupopen(true);
};

const addLikes= async (id)=>{
  
  debugger;
 const response =  await axios.post(configData.ApiUrl+'/CommunityPost/like?postId='+id+'&userId='+uerAuthId)
 if(response.status == 200 && response.data.code ){
  loadPostList();

}
else{
    alert("Something Went Wrong..!")
}
}


//for comments
const [comment ,setComment] = useState(false);
const [cmntEventId,setCmntEventId]=useState(0);
function Commentpoupopen (commentopen){
   if(commentopen){
     setComment(true);
   }
   else{
     setComment(false);
   }
}


const [commentData, setcommentData] = useState([]);
 const [cmntValue, setcmntValue] = useState({
  txtCmnt:""
 });


useEffect(() => {
//loadlikes();
}, []);
const losdUnlodCmnt = (idd)=>{
  setCmntEventId(idd);
  if(comment)
  {
   Commentpoupopen(false);
   return false;
  }
  else{
    commentInfo(idd);
  }
}
const commentInfo = async(id) => {
  setCmntEventId(id);
  
  
  console.log(id)
  debugger
   const result =  await axios.get(`${configData.ApiUrl}/CommunityPost/getListOfCommentsById?id=${id}`);
   setcommentData(result.data.data.sort((a, b) => b.id - a.id));
  //  debugger;
   console.log("commentdt",result.data.data.sort((a, b) => b.id - a.id));
   setCmntEventId(id);
   Commentpoupopen(true);
   setcmntValue(preState =>({
          ...preState,
          "txtCmnt": ""
        }))
        cmntValue.txtCmnt="";
 };

 const [commentValiderr,setcommentValiderr]=useState(false)


 const saveComent = async(id)=>{
  if(cmntValue.txtCmnt ==""){
    setcommentValiderr(true)
    return false;
  }
  else{
    setcommentValiderr(false)
  const response =  await axios.post(configData.ApiUrl+'/CommunityPost/comment?postId='+id+'&userId='+uerAuthId+'&comment='+cmntValue.txtCmnt)
  setcmntValue(preState =>({
    ...preState,
    "txtCmnt": ""
  }))
  cmntValue.txtCmnt="";
  debugger;
  loadPostList();
  commentInfo(id);
}
}
 
const handleChange = event => {

  const {name,value,type,checked}=event.currentTarget;
      debugger
      
      setcmntValue(preState =>({
        ...preState,
        [name]:type ==='checkbox'?checked: value
      }))

     
}

const deleteComment=(id)=>{
  
  debugger;
 const response = axios.delete(`${configData.ApiUrl}/CommunityPost/deleteComment?commentId=${id}`)
response.then(() => {
  toast.success("Comment deleted Successfully!");
  commentInfo(id);


})
response.catch((err) => {
  alert("Getting error in featching data")
});


}

//for search post
const handleSearchChangePost=(e)=> {
  debugger
    if (e.currentTarget.value === "") 
    {  loadPostList();
       return; }
    const filterBySearch = loadPost.filter((item) => {
      if ( item != null && item.type != null){
        if ( item?.type?.toLowerCase().includes(e.currentTarget.value)) { return item; }
      }
       if ( item != null && item.hostName != null){
         if (item?.hostName?.toLowerCase()
             .includes(e.currentTarget.value)) { return item; }
            }
            if ( item != null && item.city != null){
            if (item?.city?.toLowerCase()
                  .includes(e.currentTarget.value)) { return item; }
                }
    })
    setloadPost(filterBySearch);
}

//for event blog showall 
const handledropSearchChangePost=(e)=> {
  debugger
  const {name,value,type,checked}= e.currentTarget;

    if(value=='showAll'){
      loadPostList()
    }
    else{
      const filterBySearch = loadPost.filter((item) => {
        if ( item != null && item.type != null){
          if ( item?.type?.toLowerCase().includes(value)) { return item; }
        }
        
      })
      setloadPost(filterBySearch);
    }
    
}

const attendStatusCofeeMeet = async (id) => {
  


  debugger;
  const response = await axios.post(configData.ApiUrl+'/coffeeMeet/editGuestStatus?guestId=' + uerAuthId + '&coffeeMeetId=' + id)
  if (response.status == 200 && response.data.code) {
    toast.success("Update Status  Successfully!")
    loadPostList();

  }
  else {
    alert("Something Went Wrong..!")
  }
}



//for filter
const [spamDIv ,setspamDIv] = useState(false);
 const [ activeDiv,setactiveDiv] = useState(false);
 const [archiveDiv ,setarchiveDiv] = useState(false);
  const [allDiv ,setallDiv] = useState(false);


 const [filter, setfilter] = useState({
  filterstatus:""
});

 const handleChangeFilter = (e) => {
  debugger
  const { name, value, type, checked } = e.currentTarget;


  setfilter(preState => ({
        ...preState,
        [name]: type === 'checkbox' ? checked : value
    }));
  
    if(e.target.value=="Active"){
    //setisfilterdata( e.target.value )
    //setAadharDIv(true);
    setactiveDiv(true);
    setspamDIv(false)
    setarchiveDiv(false)
    setallDiv(false)

  }
  else if (e.target.value=="Spam") {
    //setAadharDIv(false);
    setspamDIv(true)
    setactiveDiv(false);
    setarchiveDiv(false)
    setallDiv(false)
  }
  else if (e.target.value=="Archived"){
    //setAadharDIv(false);
    setarchiveDiv(true)
    setactiveDiv(false);
    setspamDIv(false)
    setallDiv(false)
  }
  else{
    setallDiv(true)
    setactiveDiv(false);
    setspamDIv(false)
    setarchiveDiv(false)
  }


 }

 //for read more
 const [showFullDescription, setShowFullDescription] = useState(false);
  
    // Function to toggle description display
    const toggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };
//for close popup when click on outside
const popupLikeRef = useRef(null);
const handleClickOutsideLikediv = (event) => {
  if (popupLikeRef.current && !popupLikeRef.current.contains(event.target)) {
    setLikeDiv(false);
  }
};
useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideLikediv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideLikediv);
  };
}, []);
//for randm color
const colors = ["#ff8427", "#3498db", "#2ecc71", "#e74c3c", "#9b59b6", "#f1c40f", "#e67e22"];
const getRandomColor = () => {
 return colors[Math.floor(Math.random() * colors.length)];
};

  return(
<div>
    {/* <!-- breadcrumb --> */}
    
    <Row className="row-sm " style={{overflowY:"scroll",height:"450px"}}>
      <Card.Body>
      <Col className='col-1'></Col>
      <Col className='col-11'>
      <div className="example" style={{backgroundColor:"#fff"}}>
 <div className='row'>
    <div className='col-2 textright'>
        <span className='event-sub-detail'>Posts:</span>
    </div>
    <div className='col-3'>
    <select   id="inputState" name='serchdropdown' onChange={handledropSearchChangePost}
            className="form-control" 
            style={{fontSize:"13px",height:'',marginTop:"-6px",paddingBottom:'6px'}}
            >
                <option selected value="" style={{fontSize:"13px",height:'30px'}}>Please Select</option>

            <option  value="showAll" style={{fontSize:"13px",height:'30px'}}>Show All</option>
            <option value="event"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Event</option>
            <option value="blog"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Blog</option>
            <option value="kyc"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>KYC</option>
            <option value="coffeemeet"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Coffee Meet</option>
            <option value="jobPost"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Job Post</option>



            </select>
    </div>
    <div className='col-2 textright'>
    <span className='event-sub-detail'>Sort By:</span>
   </div>
    <div className='col-2'>
    <select   id="inputState" name='filterstatus' onChange={handleChangeFilter} 
            className="form-control " 
            style={{fontSize:"13px",height:'',marginTop:"-6px",paddingBottom:'6px'}}
            >
            <option selected value="Active" style={{fontSize:"13px",height:'30px'}}>Active</option>
            <option value="Archived"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Archived</option>
            <option value="Spam"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Spam</option>
            <option value="Scheduled"  style={{fontSize:"13px",height:'30px', marginTop:'-4px'}}>Scheduled</option>

            </select>
    </div>
    <div className='col-3'>
    <input type="text" class="form-control" placeholder="Search" onChange={handleSearchChangePost} style={{fontSize:"13px",marginTop:"-4px"}}/>

    </div>

 </div>
 {
 loadPost.map((getmenu,index)=>(

      <div>
      <div className={ activeDiv && getmenu.event?.activeStatus=='Active'?"":"hide"}>

      <div className={getmenu.event?"cardiv-event border1px":"hide"}>
          <div className="row margintop">
              <div className="col-1">
              {/* <img src={getmenu.event?.profilePic}  className='eventlogoimg'/> */}
              {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
              </div>
              <div className="col-1 textright"><span className="evntxt ">Event</span></div>
              <div className="col-6" style={{whiteSpace:'nowrap'}}>
                <span className="marginleftmins5 event-type-text"key={getmenu.event?.id}>{getmenu.event?.eventType}</span><span className="marginleft2 event-location">{getmenu.event?.city}</span>
              {getmenu.event?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap"}} >Active</span>
              )
              }
              </div>
            <div className="col-6  event-date-div"><span className="event-date">{getmenu.event?.createdDate} at {getmenu.event?.createdTime}</span><span className="event-publisher ">Published by ABC Admin</span></div>
            </div>
          <div className="margintop row">
          <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                    <div className="row margintop3">
                      {
                        getmenu.images?.map((gname, index) => (
                          <div className="col-6">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
                        ))
                      }
                      {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                    </div>
                  </div>
                  <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
 ))
}
                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                  <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-12 col-md-12 col-sm-12 margintop1">
                            <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                          </div>
                           ))
                          }

                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                 <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                    <div className="row margintop">
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoOnePath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoTwoPath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>

                    </div>
                    <div className="row margintop1">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoThreePath} className='' style={{ width: "670px", height: "100%" }} />

                      </div>


                    </div>
                  </div>

          </div>
          <div className="row margintop1">
            <div className="col-6 marginleftmins3">
              <div className="row">
              <div className="col-6 textright"><span className="event-sub-detail">Event Type</span></div>
              <div className="col-6"><span className="event-sub-detail1 whitenowrap">{getmenu.event?.eventType} </span></div>

              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Event Date</span></div>
                <div className="col-6 "><span className="event-sub-detail1"> {getmenu.event?.eventDate}</span></div>
              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
                <div className="col-6"><span className="event-sub-detail1">{getmenu.event?.eventTime}</span></div>
              </div>

            </div>
            
            
            <div className="col-6">
            <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
                <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.hostName}</span></div>
              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
                <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.address} 
               </span>
               {/* <div><span className="event-sub-detail1"> Address Line2 </span></div> */}
               <div><span className="event-sub-detail1"> {getmenu.event?.city} </span></div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {getmenu.event?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.event?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.event?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.event?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.event?.commentCount} Comments</span></div>

            <div className="col-2"></div>
            <div className="col-3 report-spm-div borderorangeforevent" >
                <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
                 </div>

          </div>
          <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.event?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
          </div>
          </div>

          <div className={spamDIv && getmenu.event?.userSpamStatus==true?"":"hide"}>

<div className={getmenu.event?"cardiv-event border1px":"hide"}>
    <div className="row margintop">
        <div className="col-1">
        {/* <img src={getmenu.event?.profilePicturePath}  className='eventlogoimg'/> */}
        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor:"#ff8427",color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
        </div>
        <div className="col-1 margintop1 textright"><span className="evntxt ">Event</span></div>
        <div className="col-6 margintop1" style={{whiteSpace:'nowrap'}}><span className="marginleftmins5 event-type-text"key={getmenu.event?.id}>{getmenu.event?.eventType}</span><span className="marginleft2 event-location">{getmenu.event?.city}</span>
        
        </div>
        <div className='col-1'>
        {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        )
        }
        </div>
      <div className="col-6  event-date-div"><span className="event-date">{getmenu.event?.createdDate} at {getmenu.event?.createdTime}</span><span className="event-publisher ">Published by ABC Admin</span></div>
      </div>
    <div className="margintop row">
    <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                    <div className="row margintop3">
                      {
                        getmenu.images?.map((gname, index) => (
                          <div className="col-6">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
                        ))
                      }
                      {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                    </div>
                  </div>
                  <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
 ))
}
                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                  <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-12 col-md-12 col-sm-12 margintop1">
                            <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                          </div>
                           ))
                          }

                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                 <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                    <div className="row margintop">
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoOnePath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoTwoPath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>

                    </div>
                    <div className="row margintop1">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoThreePath} className='' style={{ width: "670px", height: "100%" }} />

                      </div>


                    </div>
                  </div>

    </div>
    <div className="row margintop1">
      <div className="col-6 marginleftmins3">
        <div className="row">
        <div className="col-6 textright"><span className="event-sub-detail">Event Type</span></div>
        <div className="col-6"><span className="event-sub-detail1 whitenowrap">{getmenu.event?.eventType} </span></div>

        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Date</span></div>
          <div className="col-6 "><span className="event-sub-detail1"> {getmenu.event?.eventDate}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
          <div className="col-6"><span className="event-sub-detail1">{getmenu.event?.eventTime}</span></div>
        </div>

      </div>
      
      
      <div className="col-6">
      <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.hostName}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.address} 
         </span>
         {/* <div><span className="event-sub-detail1"> Address Line2 </span></div> */}
         <div><span className="event-sub-detail1"> {getmenu.event?.city} </span></div>

          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-2">
        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
        {getmenu.event?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.event?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.event?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} Likes</span>
        </div>
      <div className="col-2"></div>
      <div className="col-3" onClick={()=>commentInfo(getmenu.event?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.event?.commentCount} Comments</span></div>

      <div className="col-2"></div>
      <div className="col-3 report-spm-div borderorangeforevent" >
          <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
           </div>

    </div>
    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.event?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
    </div>
    </div> 

    <div className={ archiveDiv && getmenu.event?.activeStatus=='Archived'?"":"hide"}>
    <div className={getmenu.event?"cardiv-event border1px":"hide"}>
    <div className="row margintop">
        <div className="col-1">
        {/* <img src={getmenu.event?.profilePic}  className='eventlogoimg'/> */}
        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor:"#ff8427",color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
        </div>
        <div className="col-1 margintop1 textright"><span className="evntxt ">Event</span></div>
        <div className="col-6 margintop1" style={{whiteSpace:'nowrap'}}>
          <span className="marginleftmins5 event-type-text"key={getmenu.event?.id}>{getmenu.event?.eventType}</span><span className="marginleft2 event-location">{getmenu.event?.city}</span>
          
        </div>
        <div className='col-1 margintop1'>
        {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Archived</span>
        )
        }
        </div>
      <div className="col-6 event-date-div"><span className="event-date">{getmenu.event?.createdDate} at {getmenu.event?.createdTime}</span><span className="event-publisher ">Published by ABC Admin</span>
      
        </div>
      </div>
    <div className="margintop row">
    <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                    <div className="row margintop3">
                      {
                        getmenu.images?.map((gname, index) => (
                          <div className="col-6">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
                        ))
                      }
                      {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                    </div>
                  </div>
                  <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
 ))
}
                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                  <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-12 col-md-12 col-sm-12 margintop1">
                            <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                          </div>
                           ))
                          }

                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                 <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                    <div className="row margintop">
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoOnePath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoTwoPath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>

                    </div>
                    <div className="row margintop1">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoThreePath} className='' style={{ width: "670px", height: "100%" }} />

                      </div>


                    </div>
                  </div>

    </div>
    <div className="row margintop1">
      <div className="col-6 marginleftmins3">
        <div className="row">
        <div className="col-6 textright"><span className="event-sub-detail">Event Type</span></div>
        <div className="col-6"><span className="event-sub-detail1 whitenowrap">{getmenu.event?.eventType} </span></div>

        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Date</span></div>
          <div className="col-6 "><span className="event-sub-detail1"> {getmenu.event?.eventDate}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
          <div className="col-6"><span className="event-sub-detail1">{getmenu.event?.eventTime}</span></div>
        </div>

      </div>
      
      
      <div className="col-6">
      <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.hostName}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.address} 
         </span>
         {/* <div><span className="event-sub-detail1"> Address Line2 </span></div> */}
         <div><span className="event-sub-detail1"> {getmenu.event?.city} </span></div>

          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-2">
        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
        {getmenu.event?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.event?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.event?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} Likes</span>
        </div>
      <div className="col-2"></div>
      <div className="col-3" onClick={()=>commentInfo(getmenu.event?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.event?.commentCount} Comments</span></div>

      <div className="col-2"></div>
      <div className="col-3 report-spm-div borderorangeforevent" >
          <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
           </div>

    </div>
    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.event?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
    </div>
    </div> 
    <div className={archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.event ?'example margintop3':'hide'}>
    <div className={getmenu.event?"cardiv-event border1px":"hide"}>
    <div className="row margintop">
        <div className="col-1">
        {/* <img src={getmenu.event?.profilePic}  className='eventlogoimg'/> */}
        {getmenu.event?.profilePic == "" || getmenu.event?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor:"#ff8427",color:"#ffffff" }}>{getmenu.event?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.event?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
        </div>
        <div className="col-1  textright"><span className="evntxt ">Event</span></div>
        <div className="col-9 " style={{whiteSpace:'nowrap'}}><span className="marginleftmins5 event-type-text"key={getmenu.event?.id}>{getmenu.event?.eventType}</span><span className="marginleft2 event-location">{getmenu.event?.city}</span>
        {getmenu.event?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
          <span>
        {/* <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Spam</span> */}
        <span  className={getmenu.event?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange"}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap"}}  className={getmenu.event?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.event?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        </span>
        )
        }
        </div>
      <div className="col-6 event-date-div"><span className="event-date">{getmenu.event?.createdDate} at {getmenu.event?.createdTime}</span><span className="event-publisher ">Published by ABC Admin</span>
      
      </div>
      </div>
    <div className="margintop row">
    <div className={getmenu.event?.imagesCount == 4 ? "" : "hide"}>
                    <div className="row margintop3">
                      {
                        getmenu.images?.map((gname, index) => (
                          <div className="col-6">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
                        ))
                      }
                      {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                    </div>
                  </div>
                  <div className={getmenu.event?.imagesCount == 2 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-lg-6 col-sm-12 col-md-12 margintop1">
                            <img src={gname} className='' style={{ width: "326px", height: "100%" }} />

                          </div>
 ))
}
                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                  <div className={getmenu.event?.imagesCount == 1 ? "" : "hide"}>
                    
                        <div className="row margintop">
                        {
                      getmenu.event?.images?.map((gname, index) => (
                          <div className="col-12 col-md-12 col-sm-12 margintop1">
                            <img src={gname} className='' style={{ width: "100%", height: "90%" }} />

                          </div>
                           ))
                          }

                          {/* <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div> */}

                        </div>
                     
                  </div>
                 <div className={getmenu.event?.imagesCount == 3 ? "" : "hide"}>

                    <div className="row margintop">
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoOnePath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoTwoPath} className='' style={{ width: "326px", height: "100%" }} />

                      </div>

                    </div>
                    <div className="row margintop1">
                      <div className="col-lg-12 col-sm-12 col-md-12">
                        <img src={getmenu.event?.photoThreePath} className='' style={{ width: "670px", height: "100%" }} />

                      </div>


                    </div>
                  </div>

    </div>
    <div className="row margintop1">
      <div className="col-6 marginleftmins3">
        <div className="row">
        <div className="col-6 textright"><span className="event-sub-detail">Event Type</span></div>
        <div className="col-6"><span className="event-sub-detail1 whitenowrap">{getmenu.event?.eventType} </span></div>

        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Date</span></div>
          <div className="col-6 "><span className="event-sub-detail1"> {getmenu.event?.eventDate}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
          <div className="col-6"><span className="event-sub-detail1">{getmenu.event?.eventTime}</span></div>
        </div>

      </div>
      
      
      <div className="col-6">
      <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.hostName}</span></div>
        </div>
        <div className="row">
          <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
          <div className="col-6 "><span className="event-sub-detail1">{getmenu.event?.address} 
         </span>
         {/* <div><span className="event-sub-detail1"> Address Line2 </span></div> */}
         <div><span className="event-sub-detail1"> {getmenu.event?.city} </span></div>

          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-2">
        {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
        {getmenu.event?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.event?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.event?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.event?.id)}> {getmenu.event?.likeCount} Likes</span>
        </div>
      <div className="col-2"></div>
      <div className="col-3" onClick={()=>commentInfo(getmenu.event?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.event?.commentCount} Comments</span></div>

      <div className="col-2"></div>
      <div className="col-3 report-spm-div borderorangeforevent" >
          <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
           </div>

    </div>
    <Card className={comment && getmenu.event?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.event?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
    </div>
</div>
         
<div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.blog?"":"hide"}>

          <div className={getmenu.blog?"cardiv-event border1px":"hide"}>
          <div className="row margintop">
              <div className="col-1">
              {/* <img src={getmenu.blog?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      } 
              </div>
              <div className="col-1 textright margintop1"><span className="evntxt ">Blog</span></div>
              <div className="col-9 margintop1">
                <span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.blog?.id}>{getmenu.blog?.blogTitle}</span>
                {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'120px'}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'120px'}}  className={getmenu.blog?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.blog?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'120px'}} >Spam</span>
              </span>
              )
              }
                </div>
            <div className="col-6 event-date-div" style={{whiteSpace:"nowrap",paddingTop:"5px"}}><span className="event-date">{getmenu.blog?.scheduledDate} at {getmenu.blog?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.blog?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.blog?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.blog?.imageSource}</div>
            {/* <div className="blog-textdiv margintop1">
              <span className="blog-text">Experience the Diversity of Diwali celebrations this season.</span>
            </div> */}
            <div className="blog-textdiv margintop3">
              <span className="blog-text">
              {/* {getmenu.blog?.blogContent} */}
              {getmenu.blog?.blogContent.split(' ').slice(0, 2).join(' ')}

              </span>
              {showFullDescription && (
        <div>
          {getmenu.blog?.blogContent.split(' ').slice(2).join(' ')}
        </div>
      )}
              <div className="textright margintop">
              {getmenu.blog?.blogContent.split(' ').length > 10 && (
        <span className="read-more-blog" onClick={toggleDescription}>
          {showFullDescription ? 'Read Less' : 'Read More'}
        </span>
      )}                </div>
              

            </div>
 
            </div>
            <div className="row margintop1">
               <div className="col-2">              
              {getmenu.blog?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.blog?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.blog?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span style={{cursor:"pointer"}} className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
            </div>
            <div className="col-3"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.blog?.id)} style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.blog?.commentCount} Comments</span></div>
            <div className="col-1"></div>

               </div>
               <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.blog?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
            </div>
            </div>
            <div className={ archiveDiv && getmenu.blog?.activeStatus=='Archived'?"":"hide"}>
            <div className={getmenu.blog?"cardiv-event border1px":"hide"}>
          <div className="row margintop">
              <div className="col-1">
              {/* <img src={getmenu.blog?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      } 
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">Blog</span></div>
              <div className="col-9 margintop1">
                <span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.blog?.id}>{getmenu.blog?.blogTitle}</span>
                {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'120px'}}>Archived</span>
              
              </span>
              )
              }
                </div>
            <div className="col-6  event-date-div" style={{whiteSpace:"nowrap",paddingTop:"5px"}}><span className="event-date">{getmenu.blog?.scheduledDate} at {getmenu.blog?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.blog?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.blog?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.blog?.imageSource}</div>
            {/* <div className="blog-textdiv margintop1">
              <span className="blog-text">Experience the Diversity of Diwali celebrations this season.</span>
            </div> */}
            <div className="blog-textdiv margintop3">
              <span className="blog-text">
              {getmenu.blog?.blogContent.split('\n').slice(0, 2).join('\n')}

              </span>
              {showFullDescription && (
        <div>
          {getmenu.blog?.blogContent.split('\n').slice(2).join('\n')}
        </div>
      )}
              <div className="textright margintop">
              {getmenu.blog?.blogContent.split('\n').length > 2 && (
        <span className="read-more-blog" onClick={toggleDescription}>
          {showFullDescription ? 'Read Less' : 'Read More'}
        </span>
      )}                </div>
              

            </div>
 
            </div>
            <div className="row margintop1">
               <div className="col-2">              
              {getmenu.blog?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.blog?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.blog?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span style={{cursor:"pointer"}} className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
            </div>
            <div className="col-3"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.blog?.id)} style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.blog?.commentCount} Comments</span></div>
            <div className="col-1"></div>

               </div>
               <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.blog?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
            </div>
</div>          
<div className={ activeDiv && getmenu.blog?.activeStatus=='Active'?"":"hide"}>
<div className={getmenu.blog?"cardiv-event border1px":"hide"}>
          <div className="row margintop">
              <div className="col-1">
              {/* <img src={getmenu.blog?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      } 
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">Blog</span></div>
              <div className="col-9 margintop1">
                <span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.blog?.id}>{getmenu.blog?.blogTitle}</span>
                {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'120px'}}  className={getmenu.blog?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              </h6>
              )
              }
                </div>
            <div className="col-6  event-date-div" style={{whiteSpace:"nowrap",paddingTop:"5px"}}>
              <span className="event-date">{getmenu.blog?.scheduledDate} at {getmenu.blog?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.blog?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.blog?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.blog?.imageSource}</div>
            {/* <div className="blog-textdiv margintop1">
              <span className="blog-text">Experience the Diversity of Diwali celebrations this season.</span>
            </div> */}
            <div className="blog-textdiv margintop3">
              <span className="blog-text">
              {getmenu.blog?.blogContent.split('\n').slice(0, 2).join('\n')}

              </span>
              {showFullDescription && (
        <div>
          {getmenu.blog?.blogContent.split('\n').slice(2).join('\n')}
        </div>
      )}
              <div className="textright margintop">
              {getmenu.blog?.blogContent.split('\n').length > 2 && (
        <span className="read-more-blog" onClick={toggleDescription}>
          {showFullDescription ? 'Read Less' : 'Read More'}
        </span>
      )}                </div>
              

            </div>
 
            </div>
            <div className="row margintop1">
               <div className="col-2">              
              {getmenu.blog?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.blog?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.blog?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span style={{cursor:"pointer"}} className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
            </div>
            <div className="col-3"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.blog?.id)} style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.blog?.commentCount} Comments</span></div>
            <div className="col-1"></div>

               </div>
               <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.blog?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
            </div>
</div> 
<div className={ spamDIv && getmenu.blog?.userSpamStatus==true?"":"hide"}>
<div className={getmenu.blog?"cardiv-event border1px":"hide"}>
          <div className="row margintop">
              <div className="col-1">
              {/* <img src={getmenu.blog?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.blog?.profilePic == "" || getmenu.blog?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.blog?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.blog?.profilePic} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      } 
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">Blog</span></div>
              <div className="col-9 margintop1">
                <span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.blog?.id}>{getmenu.blog?.blogTitle}</span>
                {getmenu.blog?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
              {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
              <span  className={getmenu.blog?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'120px'}} >Spam</span>
              </span>
              )
              }
                </div>
            <div className="col-6 mt-1 event-date-div" style={{whiteSpace:"nowrap"}}><span className="event-date">{getmenu.blog?.scheduledDate} at {getmenu.blog?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.blog?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.blog?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.blog?.imageSource}</div>
            {/* <div className="blog-textdiv margintop1">
              <span className="blog-text">Experience the Diversity of Diwali celebrations this season.</span>
            </div> */}
            <div className="blog-textdiv margintop3">
              <span className="blog-text">
              {getmenu.blog?.blogContent.split('\n').slice(0, 2).join('\n')}

              </span>
              {showFullDescription && (
        <div>
          {getmenu.blog?.blogContent.split('\n').slice(2).join('\n')}
        </div>
      )}
              <div className="textright margintop">
              {getmenu.blog?.blogContent.split('\n').length > 2 && (
        <span className="read-more-blog" onClick={toggleDescription}>
          {showFullDescription ? 'Read Less' : 'Read More'}
        </span>
      )}                </div>
              

            </div>
 
            </div>
            <div className="row margintop1">
               <div className="col-2">              
              {getmenu.blog?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.blog?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.blog?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span style={{cursor:"pointer"}} className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.blog?.id)}> {getmenu.blog?.likeCount} Likes</span>
            </div>
            <div className="col-3"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.blog?.id)} style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.blog?.commentCount} Comments</span></div>
            <div className="col-1"></div>

               </div>
               <Card className={comment && getmenu.blog?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.blog?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
            </div>
</div>   

<div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.kyc?"":"hide"}>
<div className={getmenu.kyc?"cardiv-event border1px":"hide"}>
          <div className="row margintop" >
              <div className="col-1">
              {/* <img src={getmenu.kyc?.profilePic}  className='eventlogoimg1'/> */}
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">KYC</span></div>
              <div className="col-9 margintop1"><span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.kyc?.id}>Gopalswamy DoraiSwamy Naidu</span><span className="marginleft2 event-location whitenowrap"></span>
              {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'10px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <span>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'120px'}}>Archived</span>
                <span style={{float:'right',whiteSpace:"nowrap",marginLeft:'120px'}}  className={getmenu.kyc?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
                <span  className={getmenu.kyc?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2",marginLeft:'120px'}} >Spam</span>
                </span>              )
              }</div>
            <div className="col-6  event-date-div" style={{whiteSpace:"nowrap"}}><span className="event-date" style={{marginLeft:'17px'}}>{getmenu.kyc?.scheduledDate} at {getmenu.kyc?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.kyc?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.kyc?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.kyc?.imageSource}</div>
            <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div>
            <div className="margintop1">
              <span className="blog-text">
              {getmenu.kyc?.kycContent}

              </span>
              </div>
              <div className="textright margintop3"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {getmenu.kyc?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.kyc?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.kyc?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} Likes</span>
            </div>
            <div className="">
              <span className="kyc-ref">References:</span>
              {
                getmenu.kyc?.reference?.map((gname, index) => (
              <div>
              <div className="kyc-ref">1. <Link><span className="link-refrence">{gname}</span></Link></div>
              {/* <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link>
             </div> */}
             </div>
                ))
       }
              </div>
            </div>
            
            </div>
</div>

  <div className={ archiveDiv && getmenu.kyc?.activeStatus=='Archived'?"":"hide"}>
  <div className={getmenu.kyc?"cardiv-event border1px":"hide"}>
          <div className="row margintop" >
              <div className="col-1">
              {/* <img src={getmenu.kyc?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.kyc?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">KYC</span></div>
              <div className="col-9 margintop1"><span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.kyc?.id}>Gopalswamy DoraiSwamy Naidu</span><span className="marginleft2 event-location whitenowrap"></span>
              {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'10px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange",marginLeft:'120px'}}>Archived</span>
                </h6>              )
              }
              </div>
            <div className="col-6  margintop1 event-date-div" style={{whiteSpace:"nowrap"}}><span className="event-date" style={{marginLeft:'17px'}}>{getmenu.kyc?.scheduledDate} at {getmenu.kyc?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.kyc?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.kyc?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.kyc?.imageSource}</div>
            <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div>
            <div className="margintop1">
              <span className="blog-text">
              {getmenu.kyc?.kycContent}

              </span>
              </div>
              <div className="textright margintop3"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {getmenu.kyc?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.kyc?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.kyc?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} Likes</span>
            </div>
            <div className="">
              <span className="kyc-ref">References:</span>
              {
                getmenu.kyc?.reference?.map((gname, index) => (
              <div>
              <div className="kyc-ref">1. <Link><span className="link-refrence">{gname}</span></Link></div>
              {/* <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link>
             </div> */}
             </div>
                ))
       }
              </div>
            </div>
            
            </div>
</div>          
<div className={ activeDiv && getmenu.kyc?.activeStatus=='Active'?"":"hide"}>
<div className={getmenu.kyc?"cardiv-event border1px":"hide"}>
          <div className="row margintop" >
              <div className="col-1">
              {/* <img src={getmenu.kyc?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.kyc?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
              </div>
              <div className="col-1 margintop1 textright"><span className="evntxt ">KYC</span></div>
              <div className="col-9 margintop1"><span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.kyc?.id}>Gopalswamy DoraiSwamy Naidu</span><span className="marginleft2 event-location whitenowrap"></span>
              {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'10px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{marginLeft:'120px'}}>Active</span>
                </h6>              )
              }</div>
            <div className="col-6  event-date-div" style={{whiteSpace:"nowrap"}}>
              <span className="event-date" style={{marginLeft:'17px'}}>{getmenu.kyc?.scheduledDate} at {getmenu.kyc?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Author - {getmenu.kyc?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.kyc?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.kyc?.imageSource}</div>
            <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div>
            <div className="margintop1">
              <span className="blog-text">
              {getmenu.kyc?.kycContent}

              </span>
              </div>
              <div className="textright margintop3"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {getmenu.kyc?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.kyc?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.kyc?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} Likes</span>
            </div>
            <div className="">
              <span className="kyc-ref">References:</span>
              {
                getmenu.kyc?.reference?.map((gname, index) => (
              <div>
              <div className="kyc-ref">1. <Link><span className="link-refrence">{gname}</span></Link></div>
              {/* <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link>
             </div> */}
             </div>
                ))
       }
              </div>
            </div>
            
            </div>
 </div>
 <div className={ spamDIv && getmenu.kyc?.userSpamStatus==true?"":"hide"}>
 <div className={getmenu.kyc?"cardiv-event border1px":"hide"}>
          <div className="row margintop" >
              <div className="col-1">
              {/* <img src={getmenu.kyc?.profilePic}  className='eventlogoimg1'/> */}
              {getmenu.kyc?.bannerImagePath == "" || getmenu.kyc?.bannerImagePath == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.kyc?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.kyc?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
              </div>
              <div className="col-1 margintop textright"><span className="evntxt ">KYC</span></div>
              <div className="col-9 margintop"><span className="marginleftmins5 event-type-text whitenowrap"key={getmenu.kyc?.id}>Gopalswamy DoraiSwamy Naidu</span><span className="marginleft2 event-location whitenowrap"></span>
              {getmenu.kyc?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{marginLeft:'10px',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
                <h6>
                {/* <span className='viewsub-activ-spn'style={{marginLeft:'120px',whiteSpace:"nowrap"}} >Active</span> */}
                <span  className={getmenu.kyc?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{marginLeft:'120px',backgroundColor:"rgba(211, 15, 69, 1)"}}>Spam</span>
                </h6>              )
              }
              </div>
            <div className="col-6  margintop1 event-date-div" style={{whiteSpace:"nowrap"}}><span className="event-date" style={{marginLeft:'17px'}}>{getmenu.kyc?.scheduledDate} at {getmenu.kyc?.scheduledTime}</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - {getmenu.kyc?.authorName}</span>
            
            </div>
            </div>

            <div className="row margintop3">
            <img src={getmenu.kyc?.bannerImagePath}  className='blogimg'/>
            <div className="img-source-spn">Image Source - {getmenu.kyc?.imageSource}</div>
            <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div>
            <div className="margintop1">
              <span className="blog-text">
              {getmenu.kyc?.kycContent}

              </span>
              </div>
              <div className="textright margintop3"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {getmenu.kyc?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.kyc?.id)}  style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.kyc?.id)} style={{cursor:"pointer"}} color="#FF8427"/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.kyc?.id)}> {getmenu.kyc?.likeCount} Likes</span>
            </div>
            <div className="">
              <span className="kyc-ref">References:</span>
              {
                getmenu.kyc?.reference?.map((gname, index) => (
              <div>
              <div className="kyc-ref">1. <Link><span className="link-refrence">{gname}</span></Link></div>
              {/* <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link>
             </div> */}
             </div>
                ))
       }
              </div>
            </div>
            
            </div>
</div>
            {/* <div className="cardiv-event1 border1px">
          <div className="row margintop" >
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-5 "><span className="evntxt-birthday ">Birthday Greetings!</span></div>
            <div className="col-7 event-date-div"><span className="event-date-birthday">August 3 at 4:00 PM</span></div>
            </div>
            
            <div className="textcenter">
              <span className="birdtday-date-text">23 December 2022</span>
            </div>
            <div className="textcenter">
              <span className="birdtday-date-text">Heartiest Birthday wish from Naidu Directory</span>
            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>



            </div> */}

            <div className=''>
            <div className={ archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.coffeeMeet?"":"hide"}>

 <div className={getmenu.coffeeMeet?"cardiv-coffee margintop border1px":"hide"}>
 <div className="row mt-3">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
<div className="col-11" style={{marginTop:"0px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='evntxt'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className=" text-muted " style={{fontSize:"11px"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} {getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px text-muted " style={{fontSize:"11px"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>


</div>
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail"key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.date} At {getmenu.coffeeMeet?.time} </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>{getmenu.coffeeMeet?.city}</span>
            </div>
            <div className="col-2" style={{float:"right"}}>
                            <button className={getmenu.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute",width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>Attend</button>
                            {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                            <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                              <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>
                                You are Attending
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"  style={{ fontSize: '14px',  }}>Withdraw Attendance</a>
                              </div>
                            </div>
                          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px',textTransform:"capitalize"}}>{getmenu.coffeeMeet?.venue}</span>
                <Link to={`/${getmenu.googleMapsLink}`}><span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span></Link>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>{getmenu.coffeeMeet?.attendeesCount} Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
     {
          getmenu.guests?.map((gname, index) => (
        // <div className="col-2">
        // <img src={gname.profilePicture}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        //     <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>{gname.name}</span></div>
        // </div>
        <div className="col-2">
                                                             
        <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
        <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
        <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



{/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
{/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
<div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

<span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
</div>
<div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
<span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
<span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
<span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
</div>
 {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
 <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
 <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

</div>
          ))
}
        {/* <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div> */}
        

     </div>
          <div className="row margintop3"></div>
          </div>

</div>
<div className={ archiveDiv && getmenu.coffeeMeet?.activeStatus=='Archived'?"":"hide"}>
<div className={getmenu.coffeeMeet?"cardiv-coffee margintop border1px":"hide"}>
<div className="row mt-3">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
<div className="col-11" style={{marginTop:"0px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='evntxt'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className=" text-muted " style={{fontSize:"11px"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} {getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px text-muted " style={{fontSize:"11px"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>


</div>
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail"key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.date} At {getmenu.coffeeMeet?.time} </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>{getmenu.coffeeMeet?.city}</span>
            </div>
            <div className="col-2" style={{float:"right"}}>
                            <button className={getmenu.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute",width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>Attend</button>
                            {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                            <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                              <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>
                                You are Attending
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"  style={{ fontSize: '14px',  }}>Withdraw Attendance</a>
                              </div>
                            </div>
                          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px',textTransform:"capitalize"}}>{getmenu.coffeeMeet?.venue}</span>
                <Link to={`/${getmenu.googleMapsLink}`}><span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span></Link>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>{getmenu.coffeeMeet?.attendeesCount} Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
     {
          getmenu.guests?.map((gname, index) => (
        // <div className="col-2">
        // <img src={gname.profilePicture}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        //     <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>{gname.name}</span></div>
        // </div>
        <div className="col-2">
                                                             
                                         <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
                                         <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
                                         <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



                                {/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
                                {/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
                                <div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

    <span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
    </div>
    <div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
    <span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
    <span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
    <span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
  </div>
                                  {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
                                  <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
                                  <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

                              </div>
          ))
}
        {/* <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div> */}
        

     </div>
          <div className="row margintop3"></div>
          </div>
</div>
<div className={ activeDiv && getmenu.coffeeMeet?.activeStatus=='Active'?"":"hide"}>
<div className={getmenu.coffeeMeet?"cardiv-coffee margintop border1px":"hide"}>
<div className="row mt-3">
  
  <div className="col-1">

  {getmenu.coffeeMeet?.bannerImagePath == "" || getmenu.coffeeMeet?.bannerImagePath == null ?
                        (<Avatar className="" style={{ width: "54px",marginTop:"3px", height: "52px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.coffeeMeet?.hostName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.coffeeMeet?.bannerImagePath} id='profpicdisablesave1' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
    </div>
<div className="col-11" style={{marginTop:"0px"}}><h6 className="card-title mb-1" style={{whiteSpace:'nowrap'}}>


<span className='evntxt'  key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.hostName} - Host</span>

             </h6>
             <div style={{marginTop:"4px"}}>
             
             <span className=" text-muted " style={{fontSize:"11px"}}>
             Creaeted On - {getmenu.coffeeMeet?.createdDate} {getmenu.coffeeMeet?.createdTime}
</span>
<span className="marginleft20px text-muted " style={{fontSize:"11px"}}>
City - {getmenu.coffeeMeet?.city}
</span>
             </div>
             </div>


</div>
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail"key={getmenu.coffeeMeet?.id}>{getmenu.coffeeMeet?.date} At {getmenu.coffeeMeet?.time} </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>{getmenu.coffeeMeet?.city}</span>
            </div>
            <div className="col-2" style={{float:"right"}}>
                            <button className={getmenu.attendStatus == 'Pending' ? "attendbtn" : "hide"} style={{ marginTop: '6px', position: "absolute",width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>Attend</button>
                            {/* <button className={getmenu.attendStatus=='Pending'?"hide ":"attendbtn"} style={{marginTop:'6px',position:"absolute"}} onClick={()=>attendStatusCofeeMeet(getmenu.id)}>Attend</button> */}
                            <div class={getmenu.attendStatus == 'Pending' ? "hide" : "dropdown"}>
                              <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: "160px" }} onClick={() => attendStatusCofeeMeet(getmenu.coffeeMeet?.id)}>
                                You are Attending
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item"  style={{ fontSize: '14px',  }}>Withdraw Attendance</a>
                              </div>
                            </div>
                          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px',textTransform:"capitalize"}}>{getmenu.coffeeMeet?.venue}</span>
                <Link to={`/${getmenu.googleMapsLink}`}><span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span></Link>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>{getmenu.coffeeMeet?.attendeesCount} Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
     {
          getmenu.guests?.map((gname, index) => (
        // <div className="col-2">
        // <img src={gname.profilePicture}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        //     <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>{gname.name}</span></div>
        // </div>
        <div className="col-2">
                                                             
        <span className={gname.status == 'Reject'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnrej' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px' }} /></span>
        <span className={gname.status == 'Attend'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnatt' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>
        <span className={gname.status == 'Pending'?"":"hide"}><img src={gname.profilePicture} id="othercoffeeprofid" className='borderstatusspnpen' style={{ width: "100px", height: "100px", borderRadius: "50%",marginBottom: '5px'  }} /></span>



{/* <span className="borderstatusspn"><img src={gname.profilePicture} id="othercoffeeprofid" className=' ' style={{ width: "100px", height: "100px", borderRadius: "50%" }} /></span> */}
{/* <div className="textcenter"><span className="event-sub-detail1" style={{ fontSize: '10px', marginLeft: '0px' }}>{gname.name}</span></div> */}
<div className="textcenter" style={{ height: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>

<span className="event-sub-detail1" style={{ fontSize: '10px', maxWidth: '100px', textAlign: 'center', whiteSpace: 'normal', lineHeight: '1.2', overflow: 'hidden' }}>{gname.name}</span>
</div>
<div className="textcenter" style={{ textAlign: 'center', minHeight: '20px' }}>
<span className={gname.status === 'Reject' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#E75480", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
<span className={gname.status === 'Attend' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#4CAF50", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
<span className={gname.status === 'Pending' ? "event-sub-detail" : "hide"} style={{ fontSize: '10px', color: "#FFC107", whiteSpace: 'nowrap',marginLeft:"-4px" }}>{gname.status}</span>
</div>
 {/* <span className={gname.status == 'Reject'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#E75480" }}>{gname.status}</span>
 <span className={gname.status == 'Attend'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#4CAF50" }}>{gname.status}</span>
 <span className={gname.status == 'Pending'?"event-sub-detail":"hide"} style={{ fontSize: '10px', marginLeft: '0px',color:"#FFC107" }}>{gname.status}</span> */}

</div>
          ))
}
        {/* <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div> */}
        

     </div>
          <div className="row margintop3"></div>
          </div>
</div>

<div className={ activeDiv && getmenu.job?.activeStatus=='Active'?"":"hide"}>
<div className={getmenu.job ? " margintop " : "hide"}>
  <div className="card" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
      {getmenu.job?.spamCount >=3 ?
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
              ):
              (
              <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap"}} >Active</span>
              )
              }
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='event-sub-detail' style={{color:"#8B0000"}}>Not Uploaded</span>):(  <a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Email Id</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Mobile No.</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  <div className="row mt-3">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {getmenu.job?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.job?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.job?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.job?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.job?.commentCount} Comments</span></div>

            <div className="col-2"></div>
            {/* <div className="col-3 report-spm-div borderorangeforevent" >
                <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
                 </div> */}

          </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
</div>
</div>
</div>
<div className={spamDIv && getmenu.job?.userSpamStatus==true?"":"hide"}>
<div className={getmenu.job ? " margintop " : "hide"}>
  <div className="card" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
      {getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        )
        }
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='event-sub-detail' style={{color:"#8B0000"}}>Not Uploaded</span>):(  < a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Email Id</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Mobile No.</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  <div className="row mt-3">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {getmenu.job?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.job?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.job?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.job?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.job?.commentCount} Comments</span></div>

            <div className="col-2"></div>
            {/* <div className="col-3 report-spm-div borderorangeforevent" >
                <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
                 </div> */}

          </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
</div>
</div>
</div>
<div className={ archiveDiv && getmenu.job?.activeStatus=='Archived'?"":"hide"}>
<div className={getmenu.job ? " margintop " : "hide"}>
  <div className="card" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
      {getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Archived</span>
        )
        }
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='event-sub-detail' style={{color:"#8B0000"}}>Not Uploaded</span>):(  < a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Email Id</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Mobile No.</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  <div className="row mt-3">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {getmenu.job?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.job?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.job?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.job?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.job?.commentCount} Comments</span></div>

            <div className="col-2"></div>
            {/* <div className="col-3 report-spm-div borderorangeforevent" >
                <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
                 </div> */}

          </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
</div>
</div>
</div>
<div className={archiveDiv==false && spamDIv==false && activeDiv==false && getmenu.job ?'example margintop3':'hide'}>
<div className={getmenu.job ? " margintop " : "hide"}>
  <div className="card" style={{paddingTop:"18px",paddingBottom:"16px"}}>
  <div className="row">
    <div className="col-1">
      {/* <img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='eventlogoimg' /> */}
      {getmenu.job?.profilePic == "" || getmenu.job?.profilePic == null ?
                        (<Avatar className="avtarimgSm" style={{ width: "44px", height: "42px",backgroundColor: getRandomColor(),color:"#ffffff" }}>{getmenu.job?.postedByName?.substring(0, 2)}</Avatar>)
                        : (<img src={getmenu.job?.profilePic} id='profpicdisablesave5' className='prof-img-right topprofsmimg eventlogoimg1' />)
                      }
      </div>
    <div className="col-11">
      <div className=""><span className="evntxt ">Open for Opportunities</span>
      <span className=" event-type-text marginleft2" key={getmenu.job?.id}>{getmenu.job?.jobOpeningFor}</span>
      <span className="marginleft2 event-location">{getmenu.job?.currentLocation}</span>
      {getmenu.job?.spamCount >=3 ?
        (
        <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        ):
        (
          <span>
        {/* <span className='viewsub-activ-spn'style={{float:'right',whiteSpace:"nowrap",backgroundColor:"orange",color:"#F2F2F2"}} >Spam</span> */}
        <span  className={getmenu.job?.activeStatus=='Archived'?"viewsub-activ-spn":"hide"} style={{backgroundColor:"orange"}}>Archived</span>
              <span style={{float:'right',whiteSpace:"nowrap"}}  className={getmenu.job?.activeStatus=='Active'?"viewsub-activ-spn":"hide"} >Active</span>
              <span  className={getmenu.job?.userSpamStatus==true?"viewsub-activ-spn":"hide"} style={{float:'right',whiteSpace:"nowrap",backgroundColor:"rgba(211, 15, 69, 1)",color:"#F2F2F2"}} >Spam</span>
        </span>
        )
        }
       </div>
       <div className="">
       <span className="event-date">{getmenu.job?.createdDate} at {getmenu.job?.createdTime}</span>
       <span className="event-publisher ">Published by {getmenu.job?.postedByName}</span>
       </div>
      
    </div>

  </div>
  <div className="row mt-3">
    <div className="col-6">
      <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Community Member</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.postedByName} </span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Job Title</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.jobOpeningFor}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Total Experience</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.experience}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Current Location</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.currentLocation}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Home Town</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.homeLocation}</span></div>
</div>
    </div>
    
    <div className="col-6">
    <div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Resume</span></div>
      <div className="col-7">{
        getmenu.job?.resumePath=="" || getmenu.job?.resumePath==null?
        (<span className='event-sub-detail' style={{color:"#8B0000"}}>Not Uploaded</span>):(  < a href={`//${getmenu.job?.resumePath}`} target='_blank'><AiOutlineFilePdf size='1.9em' color='#8B0000'/></a>
        )
      }
        </div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Available for job in</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.availableIn}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Email Id</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.emailId}</span></div>
</div>
<div className="row mt-2">
      <div className="col-5"><span className="event-sub-detail">Mobile No.</span></div>
      <div className="col-7"><span className="event-sub-detail1 whitenowrap">{getmenu.job?.mobileNumber}</span></div>
</div>

    </div>

  </div>

  <div className="row mt-3">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {getmenu.job?.likeStatus==true?<AiFillLike color="#FF8427" onClick={()=>addLikes(getmenu.job?.id)}   style={{cursor:"pointer"}}/>:<BiLike onClick={()=>addLikes(getmenu.job?.id)}   color="#FF8427" style={{cursor:"pointer"}}/>}<span className="likespn marginleft2" onClick={()=>loadLikesInfo(getmenu.job?.id)}> {getmenu.job?.likeCount} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={()=>commentInfo(getmenu.job?.id)}  style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">{getmenu.job?.commentCount} Comments</span></div>

            <div className="col-2"></div>
            {/* <div className="col-3 report-spm-div borderorangeforevent" >
                <FaEdit color="#FF8427"/><span className="marginleft2 report-spm">Edit Post</span>
                 </div> */}

          </div>
          <Card className={comment && getmenu.job?.id == cmntEventId ? "margintop3":"hide row"}>
          <div className="">
          <div className="" onClick={() => Commentpoupopen(false)} style={{float:"right",marginRight:"10px",cursor:"pointer",fontSize:'13px'}}>X</div>

            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              {/* <Card className="mb-lg-0" style={{height:"auto",border:"none"}}> */}
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        name="txtCmnt"
                        placeholder="Add a Comment"
                        style={{fontSize:"13px",borderRadius:"25px",textTransform:"capitalize"}}
                        onChange={handleChange}
                        value={cmntValue.txtCmnt}
                      />
                    <label className={commentValiderr?"event-sub-detail1":"hide"} style={{color:"red"}}>Please Add Comment</label>

                    </FormGroup>
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}} onClick={()=>saveComent(getmenu.job?.id)}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
          {
 commentData.map((commentmenu,index)=>(
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              {/* <Card style={{height:"auto"}}> */}
                
                <Card.Body>
          
                  <div className="d-sm-flex mt-0 p-3 sub-review-section border br-bl-0 br-br-0">
                    
                    <div className="media-body">
                        <h6 className="mt-0 mb-1 font-12 font-weight-semibold" style={{fontSize:"13px"}}>
                          {commentmenu.viewerName}
                          
                        </h6>
                      <p className="font-11  mb-2 mt-2" style={{fontSize:"12px"}}>
                      {commentmenu.comment}

                      </p>
                      
                        <Badge bg="" className={commentmenu.viewerId==uerAuthId?" bg-danger":"hide"} onClick={()=>deleteComment(commentmenu.id)}>
                          Delete
                        </Badge>
                     
                    </div>
                  </div>
                 
                  
                </Card.Body>
              {/* </Card> */}
            </Card>
          </div>
  ))

}

    </Card>
</div>
</div>
</div>
         
</div>

            </div>
            ))

        }
{
 loadPost  && loadPost.length== 0 &&(
<div className='row margintop'>
  <div className='col-3'></div>
  <div className='col-5 margintop'> <span className='event-sub-detail'>Posts Are not Available</span>
</div>

</div>
 )
}

<br/><br/>





{/* <div className="example" style={{float:"right"}}>
      <Pagination className=" mb-0">
        <Pagination.Item className="page-item" style={{color:"black", fontSize:"16px"}}>
Previous       
 </Pagination.Item>
        <Pagination.Item className="page-item active">
          1
        </Pagination.Item>
        <Pagination.Item className="page-item">
          2
        </Pagination.Item>
        <Pagination.Item className="page-item">
          3
        </Pagination.Item>
        <Pagination.Item className="page-item">
Next        </Pagination.Item>
      </Pagination>
    </div> */}
</div>
      </Col>

                       
                          </Card.Body>
    </Row>
    
    
    <div className={LikeDiv ? "row":"hide row"}>
  <div className="popup-boxdate">
       <div className="boxdate" style={{width:'30%',marginLeft:"620px",marginTop:"100px"}}>
 <div className='bordernonemessg' ref={popupLikeRef} style={{marginLeft:"-16px",marginRight:"-16px"}}>
 <div className=" row" style={{borderBottom:"1px",borderColor:"lightgray"}} >
   <div className='col-1'></div>
     <div className=" col-9" style={{textAlign:"left",fontSize:"16px"}}><span className="event-sub-detail">Reactions</span></div>
     <div className='col-1'>
     <span className="" onClick={()=>Likepoupopen(false)}><MdClose size='1.5em'/></span>
     </div>
     <br/>
     </div>
     <div className='row' style={{marginTop:"20px"}}></div>
 </div>
 
 <div style={{overflowY:"scroll",height:"350px",overflowX:"hidden"}}>
 
 {
  likeData.map((likemenu,index)=>(
 <div className="radius-fourth-card1 bordernonemessg1 margintop3"style={{marginTop:"20px"}}>
           <div className="row margintop1">
             
             <div className="col-9">
               <div className=""><span className="event-sub-detail" style={{fontSize:'14px',color:"black"}}>{likemenu.viewerName}</span>  
      </div>
               <div>
               {/* <span className="event-sub-detail1" style={{lineHeight: '80%'}}>
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
 
                 </span> */}
               </div>
  
             </div>
             
             </div>
             <div className='row' style={{marginTop:"10px"}}></div>
 
             </div>
             ))
 
           }
             
             
 </div>
     
             
       </div>
     </div>
 
   
     
 
     </div>
  </div>
  )

}
  

export default Post
