import React, { useState, useEffect } from "react";
import "./Maintanance.css";
 // You will create this CSS file for styling.
import logo from '../images/Naidus directory.png';

const Maintainance = () => {
  

  
  return (
    <>
    
    <div className="maintenance-container">
      <div className="logo">
        {/* Replace with Naidu Directory logo */}
        <img src={logo}  style={{width:"190px", height:"77px"}}/>
        </div>
      <h1>Naidu Directory: Under Maintenance</h1>
      <p>
        We’re currently enhancing our platform to provide you with a better
        experience.
      </p>
      <p>
        Thank you for your patience! We’ll be back online soon.
      </p>

      
      <p>
        For urgent queries, contact us at:{" "}
        <a href="mailto:admin@naidudirectory.org">admin@naidudirectory.org</a>{" "}
        or call <a href="tel:+919823423424">+91/9823423424</a>
      </p>

      <div className="social-links">
        <a href="https://www.facebook.com/naiducommunitydirectory" target="_blank" rel="noopener noreferrer">
          Facebook
        </a>
        <a href="https://www.linkedin.com/company/naiducommunitydirectory/" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
      </div>

      <footer style={{marginTop:"220px"}}>
        <p>© 2024 Naidu Directory. All rights reserved.</p>
      </footer>
    </div>
    </>
  );
};

export default Maintainance;
