import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Badge,  Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import {configData} from "../../Config"



const Subscribe=({basicInfo,setBasicInfo,handleBack1,handleNext1,joinNowRes,setjoinNowRes,setPaymentreq,Paymentreq})=> {
  
  const [showhide, setShowhide]= useState(true);

  
  const addClick= ()=>{
    debugger;
    setShowhide(true);
    
  }

  //setPaymentreq(joinNowRes)

if(joinNowRes != null && joinNowRes.referralUsed !=null){
  setreferalCodeValue(joinNowRes.referralUsed);

}

  const [discount, setDiscount]= useState(false);
  
  const adddiscount= ()=>{
    debugger
    setDiscount(true);
    setShowhide(false);
    setRemoveDiscount(false);
    getPriceForReferalcode();

  }
  const [removeDiscount, setRemoveDiscount]= useState(false);

  const removeDiscountClick= ()=>{
    debugger;
    setShowhide(true);

    setRemoveDiscount(false);
    setDiscount(false);
    setData(0);
    settotalpayable(pricedata);
    setPaymentreq(preState =>({
      ...preState,
      "amount":pricedata
        }))

  }
//for localstorage
const data = localStorage.getItem('data');
console.log("auth localstoragedata",data)
    
    
  
const [pricedata, setPriceData] = useState();



   useEffect(() => {
     loadUsers();
   },[]);

   
   const loadUsers = async () => {
     const result = await axios.get(configData.ApiUrl+'/user/getPriceForCommunitySubscription');

     setPriceData(result.data.data);
     console.log("price",result.data.data)
     settotalpayable(result.data.data);

    };

    const [referalCodeValue, setreferalCodeValue] = useState();

    const handleReferalCode =(e)=>{
      const {name,value,type,checked}=e.currentTarget;

      setreferalCodeValue(value)

    }

    //for getprice 
    const [countmenu, setData] = useState(0);
    const [totalpayable, settotalpayable] = useState();
    const [expiredtxt, setexpiredtxt] = useState(false);

   // const totalpayable=pricedata;
    const getPriceForReferalcode = async () => {
      const result = await axios.get(`${configData.ApiUrl}/user/getPriceForReferralAndDiscountCode?code=${referalCodeValue}`);

      if(result.data.code == 200 ){
        debugger;
        setData(result.data.data);
        settotalpayable(pricedata-result.data.data);

        setPaymentreq(preState =>({
          ...preState,
          "amount":pricedata-result.data.data,
          "productType":'Community',
          "currency":'INR',
          "code":referalCodeValue,
          "nameId":joinNowRes,



        }))
      
        setexpiredtxt(false)
      }
      else if(result.data.code == 400 ){

        setexpiredtxt(true);

        setData(result.data.data);
        settotalpayable(pricedata-result.data.data);

        setPaymentreq(preState =>({
          ...preState,
          "amount":pricedata-result.data.data,
          "productType":'Community',
          "currency":'INR',
          "code":referalCodeValue,
          "nameId":joinNowRes,



        }))
      toast.warn('Coupon code not Applicable or Expired')
      }
      else{
          toast.warn("Something Went Wrong..!")
      }
      
      
      console.log("getprice",result.data.data)

     };
    
    const [checkboxAgree,setcheckboxAgree]= useState({
      agree:""
    })
    const handlechangeCheckbox =(e)=>{

      const {name,value,type,checked}=e.currentTarget;
      setcheckboxAgree(preState =>({
        ...preState,
        [name]:type ==='checkbox'?checked: value
      }))
    }
     

    return (
<div className="container">



        <div className="row textcenter margintop3">
          <div className="col-2"> </div> 
          <div className="col-8 ">
            <span className="membership-text">
              Naidu Community Matrimony
            </span>
            <div className="margintop3">
              <span className="member-name" style={{fontSize:'18px'}}>Naidu Sagar Suresh</span>
            </div>
          </div>
        </div>   

          <Card className="margintop3  textcenter " style={{borderRadius:"12px",borderColor:"rgba(238, 118, 0, 1)",boxShadow:"0px 5px 5px 0px rgba(242, 242, 242, 1)"}}>
            <div className="margintop row">
           <div className="col-5 textright">
                <span className="subscripdiv-text" style={{fontSize:"13px"}}>Matrimony Gold Plan*</span>
              </div>
              <div className="col-5 textleft" ><span className="subscripdiv-text"  style={{fontSize:"13px"}}>{pricedata} Rs</span></div>
            </div>

            <div className="margintop3 row">
              <div className="col-5 textright">
                <span className="subscripdiv-text"  style={{fontSize:"13px"}}>Discount/Referral Code**</span>
              </div>
              <div className="col-5 textleft">
              {/* {
                    showhide == false && discount!=true && removeDiscount != true &&  (
                      <div style={{whiteSpace:"nowrap",display:"flex"}}>
                         <span className="have-discount">Have a discount or referral code?  </span> 
              <span className="add-now" onClick={addClick}  style={{marginLeft:"5px",cursor:"pointer"}}>  Add Now</span> 
                      </div>
               
              )

            }     */}
            {/* {
                    removeDiscount  &&    (
                      <div style={{whiteSpace:"nowrap",display:"flex"}}>
                         <span className="have-discount">Have a discount or referral code?  </span> 
              <span className="add-now" onClick={addClick}  style={{marginLeft:"5px",cursor:"pointer"}}>  Add Now</span> 
                      </div>
               
              )

            }     */}

               {
                    showhide &&   ( 
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
<input type="text" className=" form-control" id="fst"
                      name="referralUsed"
                      placeholder="Enter Discount/Refrral code"
                      style={{fontSize:'13px'}}
                      onChange={handleReferalCode}
                 />  
                 <button style={{backgroundColor:"#391847",padding:"2px",color:"#fff",fontSize:"13px"}} onClick={adddiscount}>Apply</button> 
                      </div>
                                      )

                }     

{
                    discount && showhide == false && (
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
      <span className="subscripdiv-text"  style={{fontSize:"13px"}}>{countmenu} Rs</span>
      {
        expiredtxt?(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:"",color:"red"}}>Coupon Code Expired And not Applied</span>)
        :(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:""}}>{referalCodeValue} Applied</span>)
      }
                 <span className="removed-refcode-btn" style={{fontSize:"14px",marginLeft:"10px",cursor:"pointer"}} onClick={removeDiscountClick}>Removed</span> 
                      </div>
                                     )

                }    
              </div>
            </div>
            <div className="margintop3 row">
              <div className="col-5 textright">
                <span className="subscripdiv-text" style={{fontSize:"13px"}}>Total Payable*</span>
              </div>
              <div className="col-5 textleft"><span className="subscripdiv-text"  style={{fontSize:"13px"}}> {totalpayable} Rs</span></div>
            </div>
            <div className="row margintop">
            <div class="form-check  col-12">
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value="" name="agree" onChange={handlechangeCheckbox}/>
    <span className="chekboxlbl">By Clicking agree & join , 
    you agree to the </span> <Link to="/MatrimonyTermsConsitions"><span className="chekboxlbl">Naidu Directory Terms and Conditions </span></Link>
  </label>
  </div>
            
            
            </div>

          </Card>

           <div className="margintop1 row ">
            <div className="col-2 "></div>
            <div className="col-3 margintop textright">
            <button
               className="prevbtn "
              
              onClick={handleBack1}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop"style={{marginLeft:"14px"}}>
          <button
            className={checkboxAgree.agree !=""?"nextbtn2":"disabledbtn "}
              variant="contained"
              color="primary"
               onClick={handleNext1}
              type="submit"
            >
              Submit And Proceed to Pay
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          

            

            <div className=" textcenter row ">
            <div className="col-3"></div>
            <div className="margintop3 col-6">
            <span className="alreadyspn1 ">You will be redirect to Payment Gateway once you click on Submit button </span>
            </div>
           
            </div>
            
          </div> 
        </div>
    );
  }
  export default Subscribe;