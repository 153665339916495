import React from "react";
import { Link, NavLink } from "react-router-dom";

export const columns = [
  
  {
    name: "Subscription ",
    selector: "subscription",
    sortable: true,
    
  },
  {
    name: "DOA",
   selector: "Doa",
    sortable: true,
    
},
  
    
  {
    name: "Renewal",
    selector: "renewal",
    sortable: true
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    cell: (d) => [
     
      d.status=="Active"? <span className="" style={{color:'#38CAB3'}}>Success</span> :d.status=="Subscribe Now"?<span className="" style={{color: '#0500E6'}}>Subscribe Now</span>:<span className="" style={{color:'#FF3B30'}}>Suspended</span>  
      
    ]
    
  },
  
  
];

export const data = [
  {
    subscription: "Free Directory",
    Doa: "10 Nov 2022",
    renewal: "Lifetime",
    status: "Active",
      
      
    },
    {
        subscription: "Free Directory",
        Doa: "10 Nov 2022",
        renewal: "10 Mar 2022",
        status: "Active",
          
          
        },
        {
            subscription: "Free Directory",
            Doa: "Not Subscribed",
            renewal: "Not Applicable",
            status: "Subscribe Now",
              
              
            },
            {
              subscription: "Free Directory",
              Doa: "Not Subscribed",
              renewal: "Not Applicable",
              status: "Suspended",
                
                
              }
]



