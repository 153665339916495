
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import { toast } from "react-toastify";
import {configData} from "../../Config"


const BusinessTiming = ({additional,setadditional,handleBack,handleNext,timedata,settimedata}) => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
 
  const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setadditional(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(additional.birthday);
    }
    
      const [error, setError] = useState(false);

     
    
    //for blood group
  const [bloodGroupData, setbloodGroup] = useState([]);

  useEffect(() => {
    loadBloodGroup();
  }, []);

  const loadBloodGroup = async () => {
    debugger
    const result = await axios.get("/user/bloodGroups");
    setbloodGroup(result.data.data);
    console.log("bldgrp",result.data.data)
  };
  
  //for timing

  const [Monvalue, setMonValue] = useState(false);
   const [Tuesvalue, setTuesValue] = React.useState(false);
   const [Wedvalue, setWedValue] = React.useState(false);
   const [Thurvalue, setThurValue] = React.useState(false);
   const [Frivalue, setFriValue] = React.useState(false);
   const [Satvalue, setSatValue] = React.useState(false);
   const [Sunvalue, setSunValue] = React.useState(false);

   //const timeData = [];

   const [timeWeek, settimeWeek] = useState({
    
    monstartTime1: '',
    monstartTime2: '',
    monendTime1: '',
    monendTime2: '',
    
    tuestartTime1: '',
    tuestartTime2: '',
    tueendTime1: '',
    tueendTime2: '',

    wedstartTime1: '',
    wedstartTime2: '',
    wedendTime1: '',
    wedendTime2: '',
    
    thurstartTime1: '',
    thurstartTime2: '',
    thurendTime1: '',
    thurendTime2: '',
    
    fristartTime1: '',
    fristartTime2:'',
    friendTime1: '',
    friendTime2: '',

    satstartTime1: '',
    satstartTime2: '',
    satendTime1: '',
    satendTime2: '',

    
    sunstartTime1: '',
    sunstartTime2: '',
    sunendTime1: '',
    sunendTime2: '',
    
    
    dayOffMon: "",
    dayOfftue: "",
    dayOffwed: "",
    dayOffthur: "",
    dayOfffri: "",
    dayOffsat: "",
    dayOffsun: "",

      
})
const handleChange = (e) => {

  debugger;
  const { name, value, type, checked } = e.currentTarget;
 

  settimeWeek(preState => ({
    ...preState,
    [name]: type === 'checkbox' ? checked : value
  }))
}
const check = (e) =>{
  const { name, value, type, checked } = e.currentTarget.attributes[0]

  console.log(name);
debugger;
if(e.currentTarget.childNodes[1].innerHTML == "No"){
  switch(value){

    case "mondayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffMon":true
      }))
      break;
    case "tuesdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOfftue":true
      }))     
       break;
    case "wednesDaydayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffwed":true
      }))    
        break;
    case "thursdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffthur":true
      }))     
       break;
    case "fridayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOfffri":true
      }))     
       break;
    case "saturdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffsat":true
      }))     
       break;
    case "sundayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffsun":true
      }))     
        break;

  }


}
 else{
  switch(value){

    case "mondayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffMon":false
      }))
      break;
    case "tuesdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOfftue":false
      }))     
       break;
    case "wednesDaydayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffwed":false
      }))    
        break;
    case "thursdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffthur":false
      }))     
       break;
    case "fridayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOfffri":false
      }))     
       break;
    case "saturdayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffsat":false
      }))     
       break;
    case "sundayDayOff":
      settimeWeek(preState => ({
        ...preState,
        "dayOffsun":false
      }))     
        break;

  }


 }

}
useEffect(() => {
  //console.log("new data", time)

}, [])
var buisnessTime=[];
const addtimedata=()=>{
  debugger
  for(var i=0;i<=6;i++){
    const defaultTime ={
      day:"",
      startTime1:"",
      endTime1:"",
      startTime2:"",
      endTime2:"",
      dayOff:"",
      
      }
      
  
      switch(i){
        case 0 :
          defaultTime.day="MONDAY";
          defaultTime.startTime1 = timeWeek.monstartTime1;
          defaultTime.endTime1 = timeWeek.monendTime1;
          defaultTime.startTime2 = timeWeek.monstartTime2;
          defaultTime.endTime2 = timeWeek.monendTime2;
          defaultTime.dayOff = timeWeek.dayOffMon;
          buisnessTime.push(defaultTime);
          break;
        
          case 1:
          defaultTime.day="TUESDAY";
          defaultTime.startTime1 = timeWeek.tuestartTime1;
          defaultTime.endTime1= timeWeek.tueendTime1;
          defaultTime.startTime2 = timeWeek.tuestartTime2;
          defaultTime.endTime2= timeWeek.tueendTime2;
          defaultTime.dayOff = timeWeek.dayOfftue;
          buisnessTime.push(defaultTime);
          break;
          case 2:
          defaultTime.day="WEDNSDAY";
          defaultTime.startTime1 = timeWeek.wedstartTime1;
          defaultTime.endTime1 = timeWeek.wedendTime1;
          defaultTime.startTime2 = timeWeek.wedendTime2;
          defaultTime.endTime2 = timeWeek.wedendTime2;
          defaultTime.dayOff = timeWeek.dayOffwed;
          buisnessTime.push(defaultTime);
          break;
          case 3:
          defaultTime.day="THURSDAY";
          defaultTime.startTime1 = timeWeek.thurstartTime1;
          defaultTime.endTime1 = timeWeek.thurendTime1;
          defaultTime.startTime2 = timeWeek.thurstartTime2;
          defaultTime.endTime2 = timeWeek.thurendTime2;
          defaultTime.dayOff = timeWeek.dayOffthur;
          buisnessTime.push(defaultTime);
          break;
          case 4:
          defaultTime.day="FRIDAY";
          defaultTime.startTime1 = timeWeek.fristartTime1;
          defaultTime.endTime1 = timeWeek.friendTime1;
          defaultTime.startTime2 = timeWeek.fristartTime2;
          defaultTime.endTime2 = timeWeek.friendTime2;
          defaultTime.dayOff = timeWeek.dayOfftfri;
          buisnessTime.push(defaultTime);
          break;
          case 5:
          defaultTime.day="SATURDAY";
          defaultTime.startTime1 = timeWeek.satstartTime1;
          defaultTime.endTime1 = timeWeek.satendTime1;
          defaultTime.startTime2 = timeWeek.satstartTime2;
          defaultTime.endTime2 = timeWeek.satendTime2;
          defaultTime.dayOff = timeWeek.dayOffsat;
          buisnessTime.push(defaultTime);
          break;
          case 6:
          defaultTime.day="SUNDAY";
          defaultTime.startTime1 = timeWeek.sunendTime1;
          defaultTime.endTime1 = timeWeek.sunendTime1;
          defaultTime.startTime2 = timeWeek.sunendTime2;
          defaultTime.endTime2 = timeWeek.sunendTime2;
          defaultTime.dayOff = timeWeek.dayOffsun;
          buisnessTime.push(defaultTime);
          break;
  
      }
  }

  console.log("businessTime = " + buisnessTime );

  settimedata(preState => ({
    ...preState,
    "buisnessTime":buisnessTime
  }))

  timedata.buisnessTime= buisnessTime
  handleNext();

}


return(
    
<div className="container">
<form >
        <div className="row margintop">
          <div className="col-10" id="completeprofradio">
            <div className="row textLeft">
                <div className="col-lg-7 col-sm-12-col-md-12"> 
                     <span className="lbl popinsfont"style={{fontSize:'14px'}} >Business Timing</span>
           </div>

</div>
       

      <div className='workingtime row margintop1'>
          <div className='col-2 '><span className='dayofweek communtdrc-cit-text'>Day of week</span></div>
          <div className='col-2 '><span className='starttime textcenter communtdrc-cit-text'>Start Time</span></div>
          <div className='col-2 '><span className='endtime textcenter communtdrc-cit-text'>End Time</span></div>
          <div className='col-2 '><span className='starttime textcenter communtdrc-cit-text'>Start Time</span></div>
          <div className='col-2 '><span className='endtime textcenter communtdrc-cit-text'>End Time</span></div>
          <div className='col-2 '><span className='isdayoff communtdrc-cit-text'>Is Day Off</span></div>

      </div>
      <div className="workingtime row">
          <div className={timeWeek.dayOffMon ?"disabledayoff col-2 comudrct-digitxt ":'col-2 comudrct-digitxt '} style={{paddingTop:"10px"}}>Monday</div>
          <div  className={timeWeek.dayOffMon ?"disabledayoff col-2":'col-2'} > 
          <div className='dsply-flex'>
           

                  <input type="time" className='form-control ddlwidth1' name='monstartTime1' onChange={handleChange}/>
                  </div>
                  </div>
          <div className={timeWeek.dayOffMon ?"disabledayoff col-2":'col-2'} > 
          
          <div className='dsply-flex'>
          
                    <input type="time" className='form-control ddlwidth1' name='monendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffMon ?"disabledayoff col-2":'col-2'} > 
          
          <div className='dsply-flex'>
          
                    <input type="time" className='form-control ddlwidth1' name='monstartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffMon ?"disabledayoff col-2":'col-2'} > 
          
          <div className='dsply-flex'>
          
                    <input type="time" className='form-control ddlwidth1' name='monendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="mondayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOffMon ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOffMon ? "Yes" : "No"}</span>
         </div>
              </div>

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOfftue ?"disabledayoff col-2 comudrct-digitxt":'col-2 comudrct-digitxt '} style={{paddingTop:"6px"}}>Tuesday</div>
          <div className={timeWeek.dayOfftue ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='tuestartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOfftue ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
          
                <input type="time" className='form-control ddlwidth1' name='tueendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOfftue ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
          
                <input type="time" className='form-control ddlwidth1' name='tuestartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOfftue ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
          
                <input type="time" className='form-control ddlwidth1' name='tueendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="tuesdayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOfftue ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOfftue ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOffwed ?"disabledayoff col-2 comudrct-digitxt ":'col-2 comudrct-digitxt '} style={{paddingTop:"10px"}}>Wednesday</div>
          <div className={timeWeek.dayOffwed ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='wedstartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOffwed ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='wedendTime1'  onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffwed ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='wedstartTime2'  onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffwed ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='wedendTime2'  onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="wednesDaydayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOffwed ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOffwed ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOffthur ?"disabledayoff col-2 comudrct-digitxt":'col-2 comudrct-digitxt '} style={{paddingTop:"10px"}}>Thursday</div>
          <div className={timeWeek.dayOffthur ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='thurstartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOffthur ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='thurendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffthur ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='thurstartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffthur ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='thurendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="thursdayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOffthur ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOffthur ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOfffri ?"disabledayoff col-2 comudrct-digitxt":'col-2 comudrct-digitxt'} style={{paddingTop:"10px"}}>Friday</div>
          <div className={timeWeek.dayOfffri ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
          
                  <input type="time" className='form-control ddlwidth1' name='fristartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOfffri ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='friendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOfffri ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='fristartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOfffri ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                <input type="time" className='form-control ddlwidth1' name='friendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="fridayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOfffri ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOfffri ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOffsat ?"disabledayoff col-2 comudrct-digitxt":'col-2 comudrct-digitxt' } style={{paddingTop:"10px"}}>Saturday</div>
          <div className={timeWeek.dayOffsat ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
          
                    <input type="time" className='form-control ddlwidth1' name='satstartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOffsat ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='satendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffsat ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='satstartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffsat ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='satendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="saturdayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOffsat ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOffsat ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      <div className='workingtime row'>
          <div className={timeWeek.dayOffsun ?"disabledayoff col-2 comudrct-digitxt":'col-2 comudrct-digitxt'} style={{paddingTop:"10px"}}>Sunday</div>
          <div className={timeWeek.dayOffsun ?"disabledayoff col-2":'col-2'}> 
          <div className='dsply-flex'>
         
                      <input type="time" className='form-control ddlwidth1' name='sunstartTime1' onChange={handleChange}/>

                  </div>
                  </div>
          <div className={timeWeek.dayOffsun ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='sunendTime1' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffsun ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='sunstartTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className={timeWeek.dayOffsun ?"disabledayoff col-2":'col-2'}> 
          
          <div className='dsply-flex'>
           
                  <input type="time" className='form-control ddlwidth1' name='sunendTime2' onChange={handleChange}/>

                  </div>
          </div>
          <div className='col-2'>
          <div name="sundayDayOff" onClick={check} type="checkbox"
           className={timeWeek.dayOffsun ? "checkbox11 checkbox11--on ":"checkbox11 false"}
          //  onClick={() => setMonValue(!Monvalue)}
           >
          <div className="checkbox__ball"></div>
          <span className="checkbox__text">{timeWeek.dayOffsun ? "Yes" : "No"}</span>
         </div>
              </div>              

      </div>
      </div>
      

        
          </div>  
          <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              onClick={addtimedata}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
</form>
    </div>
)
}
export default BusinessTiming