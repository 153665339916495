
import { useEffect, useState } from 'react';
import './SignUpMatrimony';
import validator from "validator";
import OtpInput from 'react-otp-input';
import { Badge,  Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import {FiCheck} from 'react-icons/fi';

const MatrimonyPlan=({otp,setOtp,basicInfo,setBasicInfo,handleVerifyOptp,resendOtp,handleBack1,handleNext1,otpNew,setOtpNew})=> {
  
  
  


    return (
      <div className="container-fluid" >
                    
                    
<div className=" row margintop">
  <div className="col-3" style={{marginTop:"0px"}}>
  {/* <Card>
  <Card.Header style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2",paddingTop:"0.45rem",paddingBottom:"0.15rem"}}>
      <h6 className="" style={{textAlign:"center",fontSize:'12px'}}>Silver</h6>
        </Card.Header>
          <Card.Body style={{paddingLeft:'7px',backgroundColor:"rgba(242, 242, 242, 1)"}}>
          <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>50 profile views
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>Messaging to viewed profile
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>View Contact Number
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>6 Month validity
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
          </Card.Body>
          <Card.Footer style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2"}}>
        <h6 style={{fontSize:'13px',textAlign:"center"}}>Rs. 1999</h6>
          </Card.Footer>
  </Card> */}

  </div>
 <div className='col-1 mt-3'><FiCheck size='1.5em'/></div>
  <div className="col-4">
  <Card>
  <Card.Header style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2",paddingTop:"0.45rem",paddingBottom:"0.15rem"}}>
      <h6 className="" style={{textAlign:"center",fontSize:'12px'}}>Community Matrimony</h6>
        </Card.Header>
          <Card.Body style={{paddingLeft:'7px',backgroundColor:"rgba(242, 242, 242, 1)"}}>
          <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>50 profile views
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>Messaging to viewed profile
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>View Contact Number
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>Bookmark Profiles
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>6 Month validity
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
          </Card.Body>
          <Card.Footer style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2"}}>
        <h6 style={{fontSize:'13px',textAlign:"center"}}>Rs. 2999</h6>
          </Card.Footer>
  </Card>

</div>

<div className="col-4 ">
{/* <Card>
  <Card.Header style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2",paddingTop:"0.45rem",paddingBottom:"0.15rem"}}>
      <h6 className="" style={{textAlign:"center",fontSize:'12px'}}>Platinum</h6>
        </Card.Header>
          <Card.Body style={{paddingLeft:'7px',backgroundColor:"rgba(242, 242, 242, 1)"}}>
          <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>150 profile views
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>Messaging to viewed profile
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>View Contact Number
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
           <div className='ckeckboxmatrplan'>
            <label class="chechboxxcustom" style={{fontSize:'12px',color:'#B5924C',paddingLeft:"23px"}}>1 Year validity
           <input type="checkbox"  class=" form-check-input "  value=""/>
           <span class="checkmark1" style={{width:'14px',height:"14px"}}></span>
           </label>
           </div>
          </Card.Body>
          <Card.Footer style={{backgroundColor:"rgba(181, 146, 76, 1)",color:"#F2F2F2"}}>
        <h6 style={{fontSize:'13px',textAlign:"center"}}>Rs. 4999</h6>
          </Card.Footer>
  </Card> */}

</div>
   
    </div>

       
  <div className="margintop1 row ">
            <div className="col-3 marginleftmins4"></div>
            <div className="col-3 margintop textright">
            <button
               className="prevbtn "
              
              onClick={handleBack1}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop ">
          <button
            className="nextbtn1 sourcesansfont"
              variant="contained"
              color="primary"
               //onClick={handleVerifyOptp}
               onClick={handleNext1}
              type="submit"
            >
              Next
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

         

            <div className="  row">
            <div className="col-2 textcenter ml-2"></div>
            <div className="margintop1 col-6">
            <span className="alreadyspn1 ">Other platinum members will be able to view your mobile number </span>
            </div>
           
            </div>
            <div className="margintop1  row"></div>

            
            
          </div> 
      </div>
    );
  }
  export default MatrimonyPlan;