import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {FiFilter} from 'react-icons/fi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'
import BloodReqCheckPopup from "./BloodReqCheckDetailPopup";
import { useHistory } from "react-router-dom";
import axios from "axios";import { toast } from "react-toastify";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import {configData} from "../../Config"


 
const BloodReqCommunityReq =(props)=>{
  
 
//for session
// let history = useHistory();

// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);


    


      const columns = [
  
    
      {
        name: "Required Date",
       selector: "reqDate",
        sortable: true,
        

        
    },
    {
      name: "Requested By",
     selector: "raisedBy",
      sortable: true,
       
      
  },
  {
      name: "Requested For",
     selector: "raisedFor",
      sortable: true,
     className:"texttransformcapitalize"
      
  },
  {
      name: "Blood Group",
     selector: "bloodGroup",
      sortable: true,

      
  },
  {
      name: "Status",
     selector: "status",
      sortable: true,
      className:"texttransformcapitalize",
      cell: (d) => [
     
        d.status=="Open"? <span className="" style={{color:'#38CAB3'}}>Open</span>:<span className="" >Closed</span> 
      ]

      
  },
  {
      name: "Place",
     selector: "reqCity",
      sortable: true,
      className:"texttransformcapitalize"

      
  }
  ,{
      name: "Action",
     selector: "",
      sortable: true,
      className:"texttransformcapitalize bluetextforlink",
      cell: (d) => [  
          <span className="" style={{color:'#0500E6',cursor:"pointer"}} onClick={()=>togglePopupMessage(true,d.id)}>Check Detail</span> 
      ]

  }
    
    ];
   

    // const data = [
    //     {
    //         requireddate: "01 Jan 2023",
    //       requestedby: "Sagar Naidu",
    //       requestedfor: "Mahesh Naidu",
    //       bloodgroup: "B+ve",
    //       status: "Open",
    //       place: "Pune",
    //       action: "Check Details",


            
            
    //       },
    //       {
    //         requireddate: "01 Jan 2023",
    //       requestedby: "Sagar Naidu",
    //       requestedfor: "Mahesh Naidu",
    //       bloodgroup: "B+ve",
    //       status: "Cosed",
    //       place: "Pune",
    //       action: "Check Details",


            
            
    //       },
          
    //   ]
    //for getdetailsby id
    const [bloodData, setbloodData] = useState({
    id:"",
    raisedById: "",
        raisedBy: "",
        raisedFor: "",
        contactNo: "",
         reqCity: "",
        bloodGroup: "",
        reqDate: "",
        matchingMembers: "",
        status:""
  });

      const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
      const [recordId, setrecordId] = useState();

      const togglePopupMessage = (isOpend,id) => {
        debugger;
        if(isOpend)
        {
            //ajax call getMyBloodRequirementsById?id=11 

          setIsOpenMessagePop(true);
          fetchBloodReqData(id);
          setrecordId(id);
          //onDonateBlood(id)
        }
        else{
          setIsOpenMessagePop(false);
          setbloodData("")
    setafterdonatetxt(false);

        }
        
      }
//for close popup when click on outside
const togglePopupMessageRef = useRef(null);
const handleClicktogglePopupMessageOutside = (event) => {
  if (togglePopupMessageRef.current && !togglePopupMessageRef.current.contains(event.target)) {
    setIsOpenMessagePop(false);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClicktogglePopupMessageOutside);
  return () => {
    document.removeEventListener('mousedown', handleClicktogglePopupMessageOutside);
  };
}, []);



 const fetchBloodReqData = async(id) => {
  debugger
    const response  =  await axios.get(`${configData.ApiUrl}/bloodReq/getMyBloodRequirementsById?id=${id}`)
      console.log(response);
      
      setbloodData({ ...response?.data.data });
  
     
      console.log("gg",bloodData);

}

//after donaTE bloodtxt
const [afterdonatetxt, setafterdonatetxt] = useState(false);

//donateblood api for donate btn
 const onDonateBlood= ()=>{
  
    debugger;
   const response =  axios.post(configData.ApiUrl+'/bloodReq/DonateBlood?reqId='+recordId+'&&donorId='+authlocalstoragedata?.userAuthenticationId)
  response.then(() => {
    toast.success("Blood Donate Successfully!");
    setafterdonatetxt(true)
    setIsOpenMessagePop(false);

  })
  response.catch((err) => {
    alert("Getting error in featching data")
  });
  

  }

  //searchdiv  

//for close popup when click on outside
const popupSearchRef = useRef(null);
const handleClickOutsideSearchdiv = (event) => {
  if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
    setSearchDiv(false);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsideSearchdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
  };
}, []);


const [searchDiv ,setSearchDiv] = useState(false);
function searchpoupopen (isSearch){
  if(isSearch){
    setSearchDiv(true);
  }
  else{
    closeSearchPopup();

    
  }
}
   //for reset popup activity when popup is closed
 const closeSearchPopup = () => {
  setDateDiv(false);
  setisfilterdata();
  setdateDataValue({ startdate: "", enddate: "" });
  setSearchDiv(false);
};  

      const [dateDiv ,setDateDiv] = useState(false);


  const [startDate, setStartDate] = useState(new Date());
  const [startDate1, setStartDate1] = useState(new Date());

  const [dateDataValue, setdateDataValue] = useState({
    startdate:"",
    enddate: "",
 })
  
  const handleChangeDate =(e)=>{
    debugger;
    setdateDataValue(preState => ({
      ...preState,
      "startdate": (moment(e).format("DD MMM yyyy"))
    }))
    setStartDate(e);
    
   }
   const handleChangeDate1 =(e)=>{
    setdateDataValue(preState => ({
      ...preState,
      "enddate": (moment(e).format("DD MMM yyyy"))
    }))
    setStartDate1(e)
    
 }
    
 const [isfilterdata, setisfilterdata] = useState();

 const handleChange = (e) => {
    
    if(e.target.value=="custom"){
      //setisfilterdata( e.target.value )
      setDateDiv(true);
    }
    else{
      setDateDiv(false);
    }

    setisfilterdata( e.target.value )

   }

    

   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

    //const [data, setData] = useState([]);

    // const tableData = {
    //   columns,
    //   data,
      
    // };

     useEffect(() => {
      loadConnects();
     },[]);
  
     const loadConnects = async (value) => {
      //debugger
      //  const result = await axios.get(`/bloodReq/getCommunityBloodRequirements?id=${authlocalstoragedata?.userAuthenticationId}`);
      const pageSize = value ? value : showRecord;
      if(value !=null)
      {
      const result = await axios.get(configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?pageNo='+page+'&pageSize='+pageSize+'&id='+uerAuthId)

       props.setbloodCommunityReq(result.data.data);
       console.log("communityBloodreq",result.data.data)
      }
      else{
        const result = await axios.get(configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?pageNo='+page+'&pageSize='+pageSize+'&id='+uerAuthId)

       props.setbloodCommunityReq(result.data.data);
       console.log("communityBloodreq",result.data.data)
      }

     };

     const [showRecord, setshowRecord] = useState(10);

       const  handleChanegeShowRec = (e) => {
         debugger;
         const { name, value, type, checked } = e.currentTarget;
      
         setshowRecord( e.currentTarget.value);
         loadConnects(value);

        }
       //for table records per page
       const [recordCount, setrecordCount] = useState(10);
      
      
       //for pagination
      
       const [pageData, setPageData] = useState([]);
      const [page, setPage] = useState(1);
      const [pageCount, setPageCount] = useState(10);
      
      
      // handle next
      const handleNext = () => {
         if (page === pageCount) return page;
         setPage(page + 1)
      }
      
      // handle previous
      const handlePrevios = () => {
         if (page === 1) return page;
         setPage(page - 1)
      }
      
      useEffect(() => {
       loadConnects()
      }, [page])
      
      useEffect(() => {
         const pagedatacount = Math.ceil(props.bloodCommunityReq.length / showRecord);
         setPageCount(pagedatacount);
      
         if (page) {
             const LIMIT = showRecord;
             const skip = LIMIT * page // 5 *2 = 10
             const dataskip = props.bloodCommunityReq.slice(page === 1 ? 0 : skip - LIMIT, skip);
             setPageData(dataskip)
         }
      }, [props.bloodCommunityReq])

     const [matchingReqdata, setmatchingReqdata] = useState([]);

     useEffect(() => {
      loadMatchingreq();
     },[]);
  
     const loadMatchingreq = async () => {
       const result = await axios.get(`${configData.ApiUrl}/bloodReq/getCountForMatchingRequirements?id=${uerAuthId}`);

       setmatchingReqdata(result.data.data);
       console.log("getmatchingreq",result.data.data)


     };

  
   //for date filter
   const onFilter = async () => {
    var url;
        debugger
        if(dateDataValue.startdate!=''&& dateDataValue.enddate==''&& isfilterdata=='custom'){
           url=configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?dateFilter='
          +isfilterdata+'&startDate='+dateDataValue.startdate+'&id='+authlocalstoragedata?.userAuthenticationId;
    
        }else if(dateDataValue.enddate!=''&&dateDataValue.startdate==''&& isfilterdata=='custom'){
           url=configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?dateFilter='
          +isfilterdata+'&endDate='+dateDataValue.enddate+'&id='+authlocalstoragedata?.userAuthenticationId;
        }
        else if(dateDataValue.startdate==''&& dateDataValue.enddate==''&& isfilterdata!='custom' && isfilterdata!=undefined){
          url=configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?dateFilter='
         +isfilterdata+'&id='+authlocalstoragedata?.userAuthenticationId;
       }
       else if(dateDataValue.startdate==''&& dateDataValue.enddate==''&& isfilterdata!='custom'&& isfilterdata==undefined){
        url=configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?id='+authlocalstoragedata?.userAuthenticationId;
     }
       else{
        url=configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?startDate='+dateDataValue.startdate+'&endDate='+dateDataValue.enddate+'&id='+authlocalstoragedata?.userAuthenticationId;
       }
        
          const result = await axios.get(url);
          props.setbloodCommunityReq(result.data.data);
          console.log("search",result.data.data)
          searchpoupopen(false);
      };
   
      const handleSearchChange = (e) => {
        debugger
        if (e.currentTarget.value === "") {
          loadConnects();
          return;
        }
        const filterBySearch = props.bloodCommunityReq.filter((item) => {
          if (item != null && item.raisedBy != null) {
            if (item?.raisedBy?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.raisedFor != null) {
            if (item?.raisedFor?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.bloodGroup != null) {
            if (item?.bloodGroup?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.status != null) {
            if (item?.status?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.reqCity != null) {
            if (item?.reqCity?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.reqCity != null) {
            if (item?.status?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.action != null) {
            if (item?.action?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          
          
          // if ( item != null && item.profession != null){
          //   if (item?.profession?.toLowerCase()
          //       .includes(e.currentTarget.value)) { return item; }}
        })
        props.setbloodCommunityReq(filterBySearch);
      }

  return (
    
    <div className="table-responsive fileexport pos-relative" >

        <div className="row margintop">
        <div className="custfiltdiv  col-2">
              <div className='row'>
              <div className='col-3 nowraptxt'><span className=''>Custom Filter</span></div>
              <div className='col-6'></div>
               <div className='col-2 filterdiv'>
            <FiFilter size='1.2em' className='filtericon1' onClick={()=>searchpoupopen(true)} style={{cursor:"pointer"}}/> 
              </div>  
              </div>
              
              </div>
            

        </div>
        <div className="row margintop3">
          <lable className="texthideshowautomatic" id="showMe">{matchingReqdata.count1} Open requirement matches your blood group!</lable>
        </div>
        <div>
          <div style={{overflowY:"scroll",height:"288px"}}>
          {/* <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available"          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions> */}
      <div>
<div className='row margintop1' >
            <div className='col-2' style={{float:'left'}}>
        <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
                  className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                  <option  value="10">Show 10</option>
                  <option value="25">Show 25</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>

                  </select>
                  </div>
                  <div className='col-8'></div>
                  <div className='form-group col-2'  style={{float:'right'}}>
                  <input
className="form-control"
placeholder="Search..."
type="search"
onChange={handleSearchChange}
style={{height:"32px",marginTop:"4px",fontSize:"12px"}}
/>
                  </div>

        </div>
<table class="table  table-hove">
  <thead>
    <tr>      <th scope="col" className="tableroww">Required Date</th>

      <th scope="col" className="tableroww">Requested By</th>
      <th scope="col" className="tableroww">Requested For</th>
      <th scope="col" className="tableroww">Blood Group</th>
      <th scope="col" className="tableroww">Status</th>
      <th scope="col" className="tableroww">Place</th>
      <th scope="col" className="tableroww">Action</th>


    </tr>
  </thead>
  <tbody>
   
    {
        pageData.map((data,index)=>( 
            <tr>
            {/* <th scope='row' className="tableroww1">{index + 1}</th> */}
            <td className="tableroww1">{data.reqDate}</td>
            <td className="tableroww1 capitalizetxxxt">{data.raisedBy}</td>
            <td className="tableroww1 capitalizetxxxt">{data.raisedFor}</td>
            <td className="tableroww1 capitalizetxxxt">{data.bloodGroup}</td>
            <td className="tableroww1 capitalizetxxxt">{data.status}</td>
            <td className="tableroww1 capitalizetxxxt">{data.reqCity}</td>
            <td className="tableroww1">
            <span className="" style={{color:'#0500E6',cursor:"pointer"}} onClick={()=>togglePopupMessage(true,data.id)}>Check Detail</span> 
              </td>

            

           

            {/* <td>
                <Link className='btn btn-primary mr-2'to= {`/View/${data.id}`}>View</Link>
                <Link className='btn btn--outline-primary mr-2' to= {`/EditUser/${data.id}`}>Edit</Link>
                <Link className='btn btn-outline-danger' onClick={() =>deleteUser(data.id)}>Delete</Link>
            </td> */}
            </tr>


         ))
    } 
  </tbody>
</table>
<div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div>
    </div>
          </div>
        
      {/* {isOpenMessagePop && <BloodReqCheckPopup
      
      handleClose={()=>togglePopupMessage(false,0)}
   />}     */}


<div className={isOpenMessagePop?"":"hide"}>
<div className="popup-boxcheckdetail">
      <div className="boxcheckdetail" ref={togglePopupMessageRef}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className=" blood-top-text">Requirement Details</span>
                 </div>
            </div>
           
        
        </div>

<div className="row margintop3">
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>Requested For</span>
    </div>
    <div className="col-lg-3 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}>{bloodData.raisedFor}</span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>Requested By</span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}>{bloodData.raisedBy}</span>

    </div>

</div>
<div className="row margintop3">
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}> Required Date</span>
    </div>
    <div className="col-lg-3 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}> {bloodData.reqDate}</span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>Place </span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}>{bloodData.reqCity}</span>

    </div>

</div>
<div className="row margintop3">
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>  Blood Group</span>
    </div>
    <div className="col-lg-3 col-sm-12 col-md-12" style={{whiteSpace:"nowrap"}}>
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}> {bloodData.bloodGroup} <span className="matchesbloogrp-text" style={{whiteSpace:"nowrap",marginLeft:"5px"}}>Matches your blood group</span></span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>Status </span>

    </div>
    <div className="col-lg-3 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px",color:"#38CAB3"}}>{bloodData.status}</span>

    </div>

</div>
<div className="row margintop3">
    <div className="col-lg-3 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"12px"}}>   Contact No</span>
    </div>
    <div className="col-lg-6 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"12px"}}>{bloodData.contactNo} </span>
    {/* <span for="inputState" className="event-sub-detail1" style={{fontSize:"14px",marginLeft:"7px"}}>9823423424 </span> */}


    </div>
    

</div>
<div className={afterdonatetxt?"hide":"row margintop3"}>
    <div className="col-1"></div>
    <div className="col-11">
        <span className="redinesstext">Once you click on 'Donate' button we will notify the user of your readiness</span>
    </div>
</div>
<div className={afterdonatetxt?"row margintop3":"hide"}>
    <div className="col-1"></div>
    <div className="col-11">
        <span className="redinesstext" style={{backgroundColor:"rgba(85, 85, 85, 1)",color:"#F2F2F2",padding:"2px"}}>
        You have shown interest/arrange to donate blood for this requirement       
           </span>
    </div>
</div>


<div className='popupbtn-div margintop3'>

    <div className='row '>
                    <div className='col-3'></div>
                    <div className='col-3 '>
                        <button className='cancelbtn'  onClick={()=>togglePopupMessage(false,0)} style={{width:'130px',height:"42px"}}>Close</button>
                    </div>
                    <div className='col-3'>
                    <button className={afterdonatetxt?"hide":'attendbtn'} style={{width:'130px',height:"42px",marginLeft:"px"}}onClick={()=>onDonateBlood()} >Donate</button>
                  </div>

                  </div>
                  </div>

      </div>
    </div>
    </div>







        </div>
   
      <div className={searchDiv ? "row":"hide row"}>

    <div className="popup-boxdate">
      <div className="boxdate" ref={popupSearchRef}>
        <span className="close-icondate" onClick={()=>searchpoupopen(false)}>x</span>

<div className="maindtdiv">
    <div className="dtspn"><span className="">Date Filter</span></div>
    <br/>
<div class="form-group">
  <select class="fontsize14 form-control" id="date" name='datefilter' onChange={handleChange}>
  <option selected className="fontcolorlight fontsize14">Please Select</option>
  <option value="today" className="fontcolorlight fontsize14">Today</option>
  <option value="yesterday" className="fontcolorlight fontsize14">Yesterday</option>
  <option value="This week" className="fontcolorlight fontsize14">This week</option>
  <option value="This month" className="fontcolorlight fontsize14">This Month</option>
  <option value="This year" className="fontcolorlight fontsize14">This Year</option>
  <option value="custom" className="fontcolorlight fontsize14">Custom</option>
  </select>
</div>

<div className={dateDiv?"":"hide"}>
<div className='row'>
  <div className='col-5'>
  <label for="startdt">Start Date:</label>
  <div className=''>
  <DatePicker
          className="filterdropdate11"
          name='date'
           selected={startDate}
             onChange={date => handleChangeDate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd MMM yyyy"
          />
  </div>

    </div> 
  <div className='col-1'></div>
   <div className='col-4'>
   <label for="enddt">End Date:</label>
  <div className=''>
  <DatePicker
          className="filterdropdate11"
          name='endDate'
            selected={startDate1}
             onChange={date => handleChangeDate1(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate1}
            dateFormat="dd MMM yyyy"
          />
  </div>

   </div>

</div>
</div>

<br/><br/>
<div className="row">

    <div className="col-4 ml-1" >                       
     <button className='cancelbtn'  onClick={()=>searchpoupopen(false)} style={{width:'120px',height:"42px"}}>Cancel</button>
</div>
<div className="col-2"></div>
    <div className="col-3">
    <button className='createeventbtn' style={{width:'120px',height:"42px"}} onClick={onFilter}>Apply</button>

    </div>
    </div>
<br/>

</div>

      </div>
    </div>

    </div>
    </div>

           );
}
 
export default BloodReqCommunityReq;