import React from "react";
import {  Link } from 'react-router-dom'
import './Login.css';
import logo from '../images/Naidus directory.png';
import { useState ,useEffect } from "react";
import {AiFillEye} from 'react-icons/ai'
 import axios from "axios";
 import { useHistory } from "react-router-dom";
 import { useForm } from "react-hook-form";
 import validator from "validator";
 import { toast } from "react-toastify";
 import {configData} from "../Config"
 //import { Countrycode } from '../JoinNow/Countrycode';
import { Countrycode } from './JoinNow/Countrycode';




const ForgotPassword =()=>{
    let history = useHistory();
    

  

   const[email, setEmail]=useState("");
   const[countryCode, setCountrycode]=useState("");
   const[mobileNo, seMobileNo]=useState("");
   localStorage.setItem('email',email);



   const handleEmail =(e)=>{
    const { name, value } = e.target;
     setEmail(e.target.value)
  }
  const handleCountry =(e)=>{
    const { name, value } = e.target;
    setCountrycode(e.target.value)
  }
  const handleMobile =(e)=>{
    const { name, value } = e.target;
    seMobileNo(e.target.value)
  }
  

  const [error, setError] = useState(false);
  const [emailEroor ,setemailEroor] = useState(false);
  const [errforNotExist ,seterrforNotExist] = useState(false);
  const [contactValidEroor ,setContactValidEroor] = useState(false);

 const submitFormData = (e) => {
     debugger

     e.preventDefault();
     setError(false);
     setemailEroor(false);
     setContactValidEroor(false);
     seterrforNotExist(false);

     if (email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setemailEroor(true);
      } else {
        handleSubmit1('email', email);
      }
      
    } 
    else if (mobileNo) {
      
      if (!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(mobileNo) || mobileNo.length !== 10) {
        setContactValidEroor(true);
      } else if (!countryCode) {
        setError(true);
      } else {
        handleSubmit1('mobile', `${countryCode}${mobileNo}`);
      }
    } 
    else {
      setError(true); // Set error if neither email nor mobile is provided
    }
     
//  if (validator.isEmpty(email)) {
//   setError(true);
// }
// else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
// setemailEroor(true);
// }
// else if (validator.isEmpty(mobileNo)) {
//   setError(true);
// }
// else if (validator.isEmpty(countryCode)) {
//   setError(true);
// }
// else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(mobileNo)){
//   setContactValidEroor(true);
  
// }
    
// else {
//       debugger
      
//       handleSubmit1();
// }
    
   };

  const handleSubmit1 = async (type,value) => {
    
    // console.log(email);

    // const response =  await axios.post(`${configData.ApiUrl}/forgotPassword?emailId=${email}` )

    // .then(result =>{
    //   console.log('forgotresult',result.data.data);
    //   debugger;
    //   if(result.data.code ==200){
    //     toast.success("Otp Sent!")

    //     history.push("/ForgotEnterCode");
    //     localStorage.setItem('email',email);

    //   }
    //   else{
    //     seterrforNotExist(true)
    //     //toast.warn("Invalid user");
    //   }
    // })
    // .catch(error =>{
    //   console.log(error)
    // })
    
    try {
      const url = type === 'email' 
        ? `${configData.ApiUrl}/forgotPassword?emailId=${value}` 
        : `${configData.ApiUrl}/forgotPassword?mobileNo=${value}`;
      
      const response = await axios.post(url);
      if (response.data.code === 200) {
        toast.success("Otp Sent!");
        history.push("/ForgotEnterCode");
        // if (email) {
        //   localStorage.setItem('contact', email);
        // } else if (mobileNo) {
        //   localStorage.setItem('contact', `${countryCode}${mobileNo}`);
        // }
         if (type === 'email') {
           localStorage.setItem('email', value);
         } else {
           localStorage.setItem('mobileNo', value);
         }
      } else {
        seterrforNotExist(true);
      }
    } catch (error) {
      console.error(error);
    }

  };



    return(
        <div className="container-fluid  forgotcontainer">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                   <img src={logo} style={{width:"187px", height:"77px"}}s/>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                    {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
            <div className=''>
            <div className="row margintop ">
                <div className="col-lg-4"></div>
                <div className="col-lg-4 col-sm-12 col-md-12 log-form-div ">
                    <div className=" loginform-first-div">
                    <span className="signin-text" style={{marginLeft:"5px"}}>Forgot Password?</span>
                 </div>
                 <div className="row margintop3">
                    <span className="forgotpass-instruct-text">Don't worry, Enter your registered email or phone number, and  we will send you a message to help you reset your password</span>
                 </div>
                 <br/>
                 <div className="row margintop">
                 <div className="col-lg-6 col-sm-12 col-md-6">

                 <label className="forgot-lbl margintop1">Registered Email Id
                </label>
                </div>
                 </div>
                 <div className="row margintop2">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-sm-12 col-md-12">
                        <input type="text" className=" form-control" id="email" 
                        onChange={handleEmail}
                     name="email"
                        placeholder="Enter your Registered Email Id "
                        style={{fontSize:"12px",height:"36px"}}
                     />
                        <div className='row '>
    <div className=' col-sm-12 col-md-12'></div>
    <div className=" col-lg-7 col-sm-12 col-md-12 ">
    <div>    
    {emailEroor  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email. </span>) :
                     (""
              )}
    </div>
    <div>    
    {errforNotExist  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>Email Id Does not Exist. </span>) :
                     (""
              )}
    </div>
                       {error && email == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
                    </div>

                 </div>

                 <div className="row" style={{marginLeft:"13px",marginTop:"25px"}}>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-4 col-sm-14 col-md-4" style={{borderBottom:'1px',marginLeft:"13px",borderBottomStyle:"solid",borderBottomColor:"lightgray",borderBottomWidth:"1px",marginTop:"px"}}></div>
                  <div className="col-lg-1 col-sm-14 col-md-4 " style={{marginTop:"-9px"}}><lable className="forgotpass-instruct-text" style={{position: "absolute"}}>OR</lable></div>

                  <div className="col-lg-4 col-sm-14 col-md-4" style={{borderBottom:'1px',borderBottomStyle:"solid",borderBottomColor:"lightgray",borderBottomWidth:"1px",marginTop:"px"}}></div>

                 </div>
                 <div className="row margintop3">
                 <div className="col-lg-6 col-sm-12 col-md-6">

                 <label className="forgot-lbl">Registered Mobile no.
                </label>
                </div>
                 </div>
                 <div className="row margintop2">
                  <div className="col-lg-1 "></div>
                 <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={referChange}
         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                onChange={handleCountry}>
                <option selected value="" style={{height:"32px"}}>CC</option>
                <option  value="91" style={{height:"32px"}}>+91</option>

                {/* {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                } */}
                </select> 
    </div>

  <div className="col-7 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNumber"
         placeholder="Mobile No"
         onChange={handleMobile}
        style={{fontSize:'12px',textTransform:"capitalize",height:'36px'}}
    />
    
  </div>

                 </div>

                 <div className='row '>
    <div className=' col-sm-12 col-lg-1  col-md-12'></div>
    <div className=" col-lg-9 col-sm-12 col-md-12 ">
    <div>    
    <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Mobile No</span>

    {error && mobileNo == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile no. is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}

                                    {error && mobileNo.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"5px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
    </div>
    <div>    
    {errforNotExist  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>mobile no Does not Exist. </span>) :
                     (""
              )}
    </div>
    <div className="margtop1 nowrapcss">
                                    {error && countryCode == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Country code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                       
                                </div>
                                
                                
  </div>
                 
                 <div className="row margintop3">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-4 col-md-10 col-sm-12 margintop3">
                        <button className="signinbutton"  onClick={submitFormData} >Continue</button>
                    </div>

                 </div>
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>


                    
                    </div>
            </div>
            </div>
           
            
            
            
           


        </div>
    );
}
export default ForgotPassword