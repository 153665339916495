import React, { useState, useEffect } from "react";
import logo from '../../images/Naidus directory.png';
import '../JoinNow/JoinNow.css';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import BusinessDetail from "./Businessdetail";
import BusinessAddress from "./BusinessAddress";
import BusinessTiming from "./BusinessTiming";
import BusinessAboutBusiness from "./BusinessAboutBusiness";
import BusinessAboutPhoto from "./BusinessAboutPhotos";
import {configData} from "../../Config"


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Business details", "Address Details ", "Business Timings","About Business","Business Photos"
  ];
}



const BusinessCompleteProfile = () => {
  
  //for session

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  var newStepValue=activeStep+2;


//   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
// console.log("authdata11",authlocalstoragedata)

// //for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });
  const getStepContent = () => {
    if (newStepValue === 0) {
      return <BusinessDetail additional={additional} setadditional={setadditional} handleBack={handleBack} handleNext={handleNext} />;
    } else if (newStepValue === 1) {
      return <BusinessAddress additional={additional} setadditional={setadditional} handleBack={handleBack} handleNext={handleNext}/>;
    } else if(newStepValue === 2){
      return <BusinessTiming additional={additional} setadditional={setadditional}  handleBack={handleBack} handleNext={handleNext} timedata={timedata} settimedata={settimedata}/>;
    }
    else if (newStepValue === 3){
        return <BusinessAboutBusiness additional={additional} setadditional={setadditional}  handleBack={handleBack} handleNext={handleNext}/>;
      }
      else if (newStepValue === 4){
        return <BusinessAboutPhoto additional={additional} setattachmentphotoOne={setattachmentphotoOne} 
        attachmentphotoTwo={attachmentphotoTwo} setattachmentphotoTwo={setattachmentphotoTwo}
         attachmentphotoThree={attachmentphotoThree} setattachmentphotoThree={setattachmentphotoThree} 
         attachmentphotoFour={attachmentphotoFour} setattachmentphotoFour={setattachmentphotoFour}
         attachmentphotoFive={attachmentphotoFive} setattachmentphotoFive={setattachmentphotoFive}
         attachmentphotoSix={attachmentphotoSix} setattachmentphotoSix={setattachmentphotoSix}
         attachmentProf={attachmentProf} setAttachmentProf={setAttachmentProf}

          setadditional={setadditional} handleBack={handleBack} handleNext={handleNext}/>;
      }
  };
  
  const [loaclId, setloaclId] = useState("");
const [localusername, setLocalUsername] = useState("");

useEffect(() => {  
  setLocalUsername(localStorage.getItem("localusername"))
  setloaclId(localStorage.getItem("loaclId"))
    
  
})
  //const username = localStorage.getItem('name');
  //const userid = localStorage.getItem('id');
 // const userid = localStorage.id;
 const buisnessName = localStorage.getItem('buisnessName');
 const id = localStorage.getItem('id');

   debugger;
    if(authlocalstoragedata !=null && authlocalstoragedata?.buisnessId !=null 
     && authlocalstoragedata?.buisnessName != null && authlocalstoragedata?.buisnessId !=0){
      localStorage.id = authlocalstoragedata?.buisnessId;
      localStorage.buisnessName = authlocalstoragedata?.buisnessName;
  }else{
     localStorage.buisnessName = localStorage.buisnessName;

     localStorage.id = localStorage.id;
   }
 
  const [additional, setadditional] = useState({
   // buisnessId:localStorage.buisnessId,
     buisnessId:id,
    buisnessCategory:null,
    registerationDate:null,
    numberOfEmployees:null,
    dealsIn:null,
    addressLine1:null,
    addressLine2:null,
    landmark:null,
    countryCode:null,
    country:null,
    stateCode:null,
    state:null,
    cityCode:null,
    city:null,
    pinCode:null,
    aboutBuisness:null
  });

  // const [attachment, setAttachment] = useState({
   
  //   profilePhoto:null,
  //   photoOne: null,
  //   photoTwo: null,
  //   photoThree: null,
  //   photoFour: null,
  //   photoFive: null,
  //   photoSix: null,

    
  //   })

    const [attachmentphotoOne, setattachmentphotoOne] = useState(null)
    const [attachmentphotoTwo, setattachmentphotoTwo] = useState(null)
    const [attachmentphotoThree, setattachmentphotoThree] = useState(null)
    const [attachmentphotoFour, setattachmentphotoFour] = useState(null)
    const [attachmentphotoFive, setattachmentphotoFive] = useState(null)
    const [attachmentphotoSix, setattachmentphotoSix] = useState(null)



    const [attachmentProf, setAttachmentProf] = useState(null)
  

    let history = useHistory();
    const [timedata, settimedata] = useState({
      buisnessTime: [],
      //buisnessId:localStorage.id,
      buisnessId:id
    });

   const isStepOptional = (step) => {
     return step === 4 || step === 5;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const handleNext = () => {

    // setAppointment(preState => ({
    //   ...preState,
    //   attachFiles : serviceList
    // }))
    if (newStepValue === 0) {
      const formData = new FormData();
debugger
           formData.append('profile',JSON.stringify(additional));
      
//       // formData.append('profilePhoto', attachment.profilePhoto);
//       // formData.append('photo1', attachment.photo1);
      

 const response = axios.post(configData.ApiUrl+'/buisness/completeBuisnessProfile',formData,{
   headers: {
     'content-type': 'multipart/form-data'
 }
 }

 )
       response.then(() => {
         toast.success("Data Added Successfully!");

       })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
    }

  if (newStepValue === 1) {
    debugger
     const formData = new FormData();


     additional.buisnessCategory = null;
     additional.registerationDate = null;
    additional.numberOfEmployees = null;
     additional.dealsIn = null;
     
      
    debugger
      
     formData.append('profile',JSON.stringify(additional));
      
//       // formData.append('profilePhoto', attachment.profilePhoto);
    

const response = axios.post(configData.ApiUrl+'/buisness/completeBuisnessProfile',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
 }

 )
       response.then(() => {
         toast.success("Data Added Successfully!");

       })
       response.catch((err) => {
         alert("Getting error in featching data")
       });

    }
    if (newStepValue === 2) {
      debugger

      additional.buisnessCategory = null;
      additional.registerationDate = null;
     additional.numberOfEmployees = null;
      additional.dealsIn = null;
      additional.addressLine1=null;
       additional.addressLine2=null;
       additional.landmark=null;
       additional.countryCode=null;
       additional.country=null;
       additional.stateCode=null;
       additional.state=null;
       additional.cityCode=null;
       additional.city=null;
       additional.pinCode=null;
//       debugger
const formData = new FormData();

       formData.append('profile',JSON.stringify(timedata));
        
         console.log(timedata)
      
  
       const response = axios.post(configData.ApiUrl+'/buisness/completeBuisnessProfile',formData,{
        headers: {
          'content-type': 'multipart/form-data'
      }
   }
  
   )
         response.then(() => {
           toast.success("Data Added Successfully!");
  
        })
        response.catch((err) => {
          alert("Getting error in featching data")
         });
//  history.push("/UserLanding")
      }
      if (newStepValue === 3) {
        debugger
         const formData = new FormData();
    
    
    //     additional.gender = null;
    //     additional.birthDate = null;
    //     additional.bloodGroup = null;
    //     additional.maritalStatus = null;
    //     additional.familyCount = null;
    //     additional.profession = null;
    //     additional.natureOfBuisness = null;
        
          
        debugger
          
         formData.append('profile',JSON.stringify(additional));
          
    //       // formData.append('profilePhoto', attachment.profilePhoto);
        
    
    const response = axios.post(configData.ApiUrl+'/buisness/completeBuisnessProfile',formData,{
      headers: {
        'content-type': 'multipart/form-data'
    }
     }
    
     )
           response.then(() => {
             toast.success("Data Added Successfully!");
    
           })
           response.catch((err) => {
             alert("Getting error in featching data")
           });
    
        }
        if (newStepValue === 4) {
          debugger
           
          // setAttachment(preState => ({
          //   ...preState,
          //   "photoOne" : attachment.photoOne
          // }))
          
          // setAttachment(preState => ({
          //   ...preState,
          //   "photoTwo" : attachment.photoTwo
          // }))
          
          // setAttachment(preState => ({
          //   ...preState,
          //   "photoThree" : attachment.photoThree
          // }))

          // setAttachment(preState => ({
          //   ...preState,
          //   "photoFour" : attachment.photoFour
          // }))
          // setAttachment(preState => ({
          //   ...preState,
          //   "photoFive" : attachment.photoFive
          // }))
          // setAttachment(preState => ({
          //   ...preState,
          //   "photoSix" : attachment.photoSix
          // }))
          setAttachmentProf(attachmentProf);

          setattachmentphotoOne(attachmentphotoOne);
            setattachmentphotoTwo(attachmentphotoTwo);
            setattachmentphotoThree(attachmentphotoThree);
            setattachmentphotoFour(attachmentphotoFour);
            setattachmentphotoFive(attachmentphotoFive);
            setattachmentphotoSix(attachmentphotoSix);

          const formData = new FormData();
      
                  
           formData.append('profile',JSON.stringify(additional));
            
       formData.append('profilePhoto', attachmentProf);
       formData.append('photoOne', attachmentphotoOne);
       formData.append('photoTwo', attachmentphotoTwo);
       formData.append('photoThree', attachmentphotoThree);
       formData.append('photoFour', attachmentphotoFour);
       formData.append('photoFive', attachmentphotoFive);
       formData.append('photoSix', attachmentphotoSix);

      
      const response = axios.post(configData.ApiUrl+'/buisness/completeBuisnessProfile',formData,{
        headers: {
          'content-type': 'multipart/form-data'
      }
       }
      
       )
             response.then(() => {
               toast.success("Data Added Successfully!");
      
             })
             response.catch((err) => {
               alert("Getting error in featching data")
             });
        history.push("/UserLanding")

          }

    setActiveStep(activeStep+1);
    newStepValue =newStepValue+1;

    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };

  
  
  const handleBack = () => {
    newStepValue =newStepValue-1;

    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };
  newStepValue = activeStep;
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+0;
// }
//   if(activeStep !=0)
//   {
//     newStepValue = activeStep+1;
// }
// if(activeStep !=0)
//   {
//     newStepValue = activeStep+2;
// }
// debugger

//   if(authlocalstoragedata != null && authlocalstoragedata?.birthDate==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=0;

//   }
//   else if(authlocalstoragedata != null && authlocalstoragedata?.currentCountry==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=1;

//   }
//  else if(authlocalstoragedata != null && authlocalstoragedata?.kycStatus==null && activeStep === 0)
//   {
//     debugger;
//     newStepValue=2;

//   }
//   else {
//     debugger;
//     //history.push("/UserLanding")

//   }
  
  return (
    <div className=" container-fluid">

          <div className="margintop row">
            <div className="col-1">                 </div>
            <div className="textleft col-1">
            {/* <img src={logo}  style={{width:"104px", height:"77px"}}/> */}
            </div>
            {/* <div className="col-1"></div> */}
            <div className="col-9">
<span className='registerspn merriweatherfont'>Complete your profile to connect with the community ...</span>
<div className="margintop3">
<div className="row marglrftrightminusrow">
          <div className={activeStep === 1?"col-11 form-container2":"col-11 form-container1"}>
<Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h5" align="center">
          Now Your Profile is Completed!
             </Typography>
      ) : (
        <>
        
          <div className="">{getStepContent(activeStep)}</div>
          
          
          
        </>
      )}
</div>
</div>
</div>
</div>
          </div>
      
          

    </div>
  );
};

export default BusinessCompleteProfile;