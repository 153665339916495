import React from "react";
import './SuccessPop.css'
 
const SuccessPop = props => {
  return (
    <div className="popup-boxsucess">
      <div className="boxsucess textcenter">

      <div className="topnewsrowlastspellpop backcolor">
            <div className="row">
                <div className="col-2"></div>
                <div className="col-7" >
                <span className="lastpop-top-text ">Success</span>
            
                 </div>
            </div>
           </div>
           <br/>
           <div style={{paddingTop:"20px",paddingBottom:"10px"}}>
           <span className="" >
                  We have received your request to add the required last name. We will contact you at the earliest!             
                 </span>
           </div>
                
                  <div className="margintop" style={{paddingBottom:"17px"}}><button className="margintop1 backcolor sucessokbtn" onClick={props.handleClose}>Ok</button></div>
      </div>
    </div>
  );
};
 
export default SuccessPop;