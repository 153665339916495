import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Avatar,Box,Paper } from "@material-ui/core";
import {AiOutlineLeft,AiOutlineRight} from "react-icons/ai"
import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from 'react-grid-carousel'
import community1 from '../../images/community1.png';
import community2 from '../../images/community2.png';
import community3 from '../../images/community3.png';
import { useHistory } from "react-router-dom";
import {configData} from "../../Config"



const SponserBusiness = () => {

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11",authlocalstoragedata)
  
  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [bloodReqSlider, setbloodReqSlider] = useState([]);
  
      //  useEffect(() => {
      //    loadBloodReqSlider();
      //  }, []);
    
      // const loadBloodReqSlider = async () => {
        
      //   const result = await axios.get(`${configData.ApiUrl}/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
      //   setbloodReqSlider(result.data.data);
      //   console.log("bldreqslider",result.data.data)
      // };
  const [crntSlide, setcrntSlide] = useState(0);

  let history = useHistory();

  //for new session for userlogin
  
  return (
    <div className="sponserdiv">
        <Carousel cols={1} rows={1} gap={10} loop autoplay={2000} hideArrow={true} style={{height:"100%"}} >
        
             <Carousel.Item>
          <div className="row">
          <img width="100%" src={community1}  style={{ cursor: "pointer" ,height:"100px"}} />

        </div>   
       </Carousel.Item>
       <Carousel.Item>
       <div className="row">
       <img width="100%" src={community2}  style={{ cursor: "pointer",height:"100px" }} />

     </div>   
       </Carousel.Item>
       <Carousel.Item>
          <div className="row">
          <img width="100%" src={community3}  style={{ cursor: "pointer" ,height:"100px"}} />

        </div>   
       </Carousel.Item>
   
                    
                   
                  </Carousel>
    </div>
  );
};




export default SponserBusiness;