import React, { useState,useEffect } from "react";

import './NotificationPopup.css';
import {FaRegWindowClose,FaWindowClose} from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import 'react-tippy/dist/tippy.css'
import { Badge,  Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import axios from "axios";
 import { toast } from "react-toastify";
 import {configData} from "../../Config"

 
const NotificationPopup = props => {

  const [hover ,setonHover] = useState(true);
  
  const closenotify =()=>{
    setonHover(false);
    
 }
//notification list
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

 const [listNotific, setlistNotific] = useState([]);

  useEffect(() => {
    loadNotification();
  },[]);

  
  const loadNotification = async (notifiVar) => {
    debugger
    if(notifiVar==null ||notifiVar==""||notifiVar==undefined){
      
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllNotifications?id='+uerAuthId);
     setlistNotific(result.data.data);
      console.log("getnotifylists",result.data.data)
    
    }
    else{
      const result = await axios.get(configData.ApiUrl+'/subscriber/getAllNotifications?id='+uerAuthId+'&filter='+notifiVar);
      setlistNotific(result.data.data);
       console.log("getnotifylists",result.data.data)
    }

   };


   

   const [filter, setfilter] = useState({
    filterstatus:""
  });

  const handleChangeFilter = (e) => {
    debugger
    const { name, value, type, checked } = e.currentTarget;
  
  setfilter(preState => ({
          ...preState,
          [name]: type === 'checkbox' ? checked : value
      }));

        
      loadNotification(e.target.value );
    
      
      

    }

  return (
    <div className="popup-boxnotify">
      <div className="boxnotify">
        <div className="notifytop-div ">
            <div className="row ">
                <div className="col-6 margintop3 colorlightorg">
                <select   id="inputState" name='filterstatus' onChange={handleChangeFilter} 
            className="form-control margintop1 drpdownnotifypopup " >
            <option className="drpoptionnotify"  selected value="All Notifications"> All Notifications</option>
            <option value="Unread">Unread</option>
            <option value="Payments">Payments</option>
           <option value="Community Directory">Community Directory</option>
            <option value="Community Meetups">Community Meetups</option>
            <option value="Coffee Meetups">Coffee Meetups</option>
            <option value="Matrimony">Matrimony</option>
            <option value="Business Directory">Business Directory</option>

          </select>
                </div>
                <div className="col-5 margintop3"></div>
                <div className="col-1 margintop3" data-tip data-for="registerTip11" style={{marginLeft:"-10px"}}>
                 {hover?<FaRegWindowClose size='1.4em' onMouseOver={closenotify} className="margintop3 notyfypop-icon"  color='rgba(238, 118, 0, 1)' onMouseLeave={() => setonHover(true)}  onClick={props.handleClose}/>:<FaWindowClose size='1.4em' className="margintop3 notyfypop-icon" onClick={props.handleClose}  color='rgba(238, 118, 0, 1)' onMouseOver={closenotify} onMouseLeave={() => setonHover(true)}/>}   
                 <ReactTooltip id="registerTip11" data-arrow-color="" place="bottom"   class='tooltipclosenotify' effect="solid">
                 Close Notifications
                 </ReactTooltip>
                  </div>
                  

            </div>

        </div>

        <div className="row ">
          {
            listNotific.map((notificationData,index)=>(
   <Card className="mb-lg-0" style={{height:"auto",borderTop:"none",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"8px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className={notificationData.status=='Unread'?"event-sub-detail":"event-sub-detail1"} style={{color:"gray"}} key={notificationData.id}>{notificationData.notificationText}</span>
          </div>
          <div className="textright margintop3">
            <div className="row">
              {
                notificationData.category=='Payments'?( <div className="col-3"><button className="attendbtn" style={{height:'30px'}}>View Receipt</button></div>):
                (<div className="col-3"></div>)
              }
              <div className="col-3"></div>
              <div className="col-5"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> {notificationData.date} {notificationData.time}</span>
</div>
            </div>
            </div>
          </Card>
            ))
          }
        

          {/* <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card>
          <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card>
          <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card>
          <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card>
          <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card>
          <Card className="mb-lg-0" style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">Community Directory</span></div>
          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. 
              Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. 
              Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
               Nam fermentum, nulla luctus pharetra vulputate</span>
          </div>
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}> 5 Jun 2023 9:18 PM</span></div>
          </Card> */}
                
        </div>
      </div>
    </div>
  );
};
 
export default NotificationPopup;