import './ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"

 
const ReferInviteCommMemberPopup = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);


 const [referData ,setreferData] = useState({
  
  referralCode: uerAuthReferralCode,
  firstName: "",
  lastName: "",
  countryCode: "",
  emailId: "",
  mobileNumber: "",
  
 });

 const referChange = (e) => {
  debugger;
  const { name, value, type, checked } = e.currentTarget;


  setreferData(preState => ({
    ...preState,
    [name]: type === 'checkbox' ? checked : value
  }));
}
const [contactValidEroor ,setContactValidEroor] = useState(false);
  const [emailEroor ,setemailEroor] = useState(false);
const [error, setError] = useState(false);
//for email
   const submitFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
   
     if (validator.isEmpty(referData.firstName)) {
        setError(true);
    }
   else if (validator.isEmpty(referData.lastName)) {
      setError(true);
   }
   else if (validator.isEmpty(referData.countryCode)) {
    setError(true);
  }
   else if (validator.isEmpty(referData.emailId)) {
    setError(true);
   }
   else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(referData.emailId)) {
    setemailEroor(true);
  }
   else if (validator.isEmpty(referData.mobileNumber)) {
    setError(true);
   }
   else if (referData.mobileNumber.length != 10) {
    setError(true);
  
  }
  else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(referData.mobileNumber)){
    setContactValidEroor(true);
    
  }
    
    else {
      debugger
      addReferalinvite();
    }
};

const addReferalinvite =()=>{
  
const response = axios.post(configData.ApiUrl+'/subscriber/inviteMember', referData)
    .then((result) => {
      if(result.data.code==200){
          toast.success("Innvite Member Successfully!");
          props.handleClose();
      }
      else if(result.data.code==400){
        toast.warn("This Member is already exist in this Community.")
      }
      else{
        toast.warn("Something went wrong.")
      }

      })
       response.catch((err) => {
         toast.warn("Something went wrong")
       });
       console.log("response", response?.data)
       console.log("InviteMember",referData)
      
}

//for lastname list
const [lastNameData, setlastNameData] = useState([]);

    useEffect(() => {
      loadLastName();
    }, []);
  
    const loadLastName = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+"/user/listOfLastNames");
      setlastNameData(result.data.data);
      console.log("lastnme",result.data.data)
    };

    //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  return (
    <div className="popup-boxrefmember">
      <div className="boxrefmember" ref={popupSearchRef}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className=" blood-top-text ">Refer a Member</span>
            <span className="refermem-secondtop-text marginleft6"style={{color:"#555555"}}>(Referral ID - {authlocalstoragedata?.referralCode})</span>
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
<div style={{overflowY:"scroll",height:"200px"}}>

        <div className="margintop row">
  <div className="col-4 textright ">
  <span for="inputState" className="lbl " style={{fontSize:"13px"}}>Name of Member</span>
  </div>
  <div className="col-4 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="firstName"
         onChange={referChange}
         placeholder=" First name"
         style={{fontSize:"12px",height:'32px'}}
    />
    <div className="margtop1 nowrapcss">
                                    {error && referData.firstName == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            First Name is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    <div className="col-4 textleft form-group">
    <select   id="inputState" name='lastName' 
            className="form-control " 
            style={{fontSize:"13px",height:'32px',paddingBottom:'6px'}}
            onChange={referChange}
            >
            <option selected value="" style={{fontSize:"13px",height:'30px'}}>Please select</option>
            {
                  
                  lastNameData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
            </select>
            <div className="margtop1 nowrapcss">
                                    {error && referData.lastName == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Last Name is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
    
   
    </div>
    <div className="margintop3 row">
  <div className="col-4 textright">
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Mobile No </span>
  </div>
  <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={referChange}
         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                onChange={referChange}>
                <option selected value="" style={{height:"32px"}}>CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
    </div>

  <div className="col-5 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNumber"
         placeholder="Mobile No"
         onChange={referChange}
        style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    />
    
  </div>
  
</div>
<div className='row'>
  <div className='col-4'></div>
  <div className="col-7 margtop1 nowrapcss">
<span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Mobile No</span>

                                    {error && referData.mobileNumber == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            mobile No is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  referData.mobileNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                 <div className="margtop1 nowrapcss">
                                    {error && referData.countryCode == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Country code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
                               
</div>

    
    <div className="margintop1 row">
  <div className="col-4 textright">
  <span for="inputState" className="lbl " style={{fontSize:"13px"}}>Email Id </span>
  </div>
  <div className="col-8 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="emailId"
         onChange={referChange}
         placeholder="Email Id"
         style={{fontSize:"12px",height:'32px'}}
    />
    <div className="margtop1 nowrapcss">
    <span className={emailEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email </span>

                                    {error && referData.emailId == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  </div>
    
    
    
                  </div>
                  <div className='popupbtn-div margintop3'>

          <div className='row '>
                <div className='col-3'></div>
                <div className='col-3'>
                    <button className='cancelbtn'  onClick={props.handleClose} style={{width:'130px',height:"42px",fontSize:"13px"}}>Cancel</button>
                </div>
                <div className='col-3'>
                <button className='createeventbtn' style={{width:'130px',height:"42px",fontSize:"13px",marginLeft:"15px"}} onClick={submitFormData}>Refer</button>
              </div>

              </div>
              </div>
      </div>
    </div>
  );
};
 
export default ReferInviteCommMemberPopup;