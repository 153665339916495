import React, { useState,useEffect } from "react";

import './NotificationPopup.css';
import {FaRegWindowClose,FaWindowClose} from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import 'react-tippy/dist/tippy.css'
import { Badge,  Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import axios from "axios";
 import { toast } from "react-toastify";
 import { useHistory } from "react-router-dom";
 import {configData} from "../../Config"

 
const JobAllSeePop = props => {

  //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  



  const [hover ,setonHover] = useState(true);
  
  const closenotify =()=>{
    setonHover(false);
    
 }
//notification list
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

 //for job psting fr userlanding pg
 const [jobPostingList, setjobPostingList] = useState([]);

 useEffect(() => {
   loadJobPostingList();
 },[]);

 
 const loadJobPostingList = async () => {
   const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobOpenings?buisnessId=${authlocalstoragedata?.buisnessId}`);

   setjobPostingList(result.data.data);
   console.log("jobPostingList",result.data.data)

  }; 

  const [isOpen, setIsOpen] = useState(false);

  let history = useHistory();

//for new session for userlogin

  return (
    <div className="popup-boxnotify">
      <div className="boxnotify">
        <div className="notifytop-div ">
            <div className="row ">
                <div className="col-6 margintop3 colorlightorg">
                <select   id="inputState" name='' 
            className="form-control margintop1 drpdownnotifypopup " >
            <option className="drpoptionnotify" selected value="All Places"> All Places</option>
            <option value="Pune">Pune</option>
            <option value="Munmbai">Munmbai</option>
            <option value="Channai">Channai</option>
            

          </select>
                </div>
                <div className="col-5 margintop3"></div>
                <div className="col-1 margintop3" data-tip data-for="registerTip11" style={{marginLeft:"-10px"}}>
                 {hover?<FaRegWindowClose size='1.4em' onMouseOver={closenotify} className="margintop3 notyfypop-icon"  color='rgba(238, 118, 0, 1)' onMouseLeave={() => setonHover(true)}  onClick={props.handleClose}/>:<FaWindowClose size='1.4em' className="margintop3 notyfypop-icon" onClick={props.handleClose}  color='rgba(238, 118, 0, 1)' onMouseOver={closenotify} onMouseLeave={() => setonHover(true)}/>}   
                 <ReactTooltip id="registerTip11" data-arrow-color="" place="bottom"   class='tooltipclosenotify' effect="solid">
                 Close Jobs
                 </ReactTooltip>
                  </div>
                  

            </div>
            <div className="row margintop">
        {
            jobPostingList.map((job,index)=>(
        <Card className={isOpen?"lightgraycard":"mb-lg-0 hoverlightgray"} style={{height:"auto",borderTop:"",borderLeft:"none",borderRight:"none",paddingTop:'6px',paddingBottom:"6px"}}>
          <div><span className="event-sub-detail">{job.role}  {job.eduQualification}  {job.city}</span></div>
          <div className="margintop1"><span className="event-sub-detail"> {job.buisnessName} <span style={{fontStyle:"italic",cursor:"pointer"}} onClick={() => setIsOpen(true)}>View Contact Details</span></span></div>

          <div className="">
            <span className="event-sub-detail1" style={{color:"gray"}}>
               {job.jobDescription}
               </span>
          </div>
          {isOpen && job?.id (
       <div className="ViewJobcntactpop">
        <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-12">
            <span className="event-sub-detail">Email:</span>
            </div>
            <div className="col-lg-7 col-sm-12 col-md-12">
            <span className="event-sub-detail1"> {job.buisnessEmail} </span>
            </div>
            <div className="col-lg-1 col-sm-12 col-md-12">
                <FaRegWindowClose onClick={() => setIsOpen(false)}/>
            </div>

        </div>
        <div className="row">
            <div className="col-lg-4 col-sm-12 col-md-12">
            <span className="event-sub-detail">Phone:</span>
            </div>
            <div className="col-lg-8 col-sm-12 col-md-12">
            <span className="event-sub-detail1"> {job.primaryMobileNumber} {job.secondaryMobileNumber} </span>
            </div>
            

        </div>
        
       </div>
      )}
          <div className="textright margintop1"><span className="event-sub-detail1" style={{color:"",paddingBottom:"2px"}}>Posted On {job.createdDate} {job.time}</span></div>
          </Card>
          ))
        }
        </div>
        </div>

      

        
      </div>
    </div>
  );
};
 
export default JobAllSeePop;