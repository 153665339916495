import React from "react";
import {  Link } from 'react-router-dom'
import './Login.css';
import logo from '../images/Naidus directory.png';
import { useState ,useEffect } from "react";
import {AiFillEye} from 'react-icons/ai'
 import axios from "axios";
 import { useHistory } from "react-router-dom";
 import { useForm } from "react-hook-form";
 import validator from "validator";
 import { toast } from "react-toastify";
 import {configData} from "../Config"




const ForgotResetPassword =()=>{
    let history = useHistory();
    
    localStorage.email = localStorage.email;

    const [passwordShown, setPasswordShown] = useState(false);
    const [CirnfirmpasswordShown, setCirnfirmpasswordShown] = useState(false);
    const [eyeshown, setEyeShown] = useState(false);

   const togglePasswordVisiblity = () => {
     setPasswordShown(passwordShown ? false : true);
   };
   const toggleCirnfirmPasswordVisiblity = () => {
    setCirnfirmpasswordShown(CirnfirmpasswordShown ? false : true);
  };
   

   const[password,setPassword]=useState("");
   const[confirmPassword,setconfirmPassword]=useState("");


   
  const handlePassword =(e)=>{
    const { name, value } = e.target;
    setPassword(e.target.value)

  }
  const handleCinfirmPassword =(e)=>{
    const { name, value } = e.target;
    setconfirmPassword(e.target.value)

  }
  const [error, setError] = useState(false);
  const [passwordEroor ,setpasswordEroor] = useState(false);
  const [confirmPassErr,setconfirmPassErr]= useState(false)


  const submitFormData = () => {
      debugger
 
 
      
  if (validator.isEmpty(password)) {
   setError(true);
 }
 else if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(password)){
  setpasswordEroor(true);
  
}
else if (password != confirmPassword) {
  setconfirmPassErr(true);

}
     
 else {
       debugger
       
       handleSubmit1();
 }
     
    };

  const handleSubmit1 = async (e) => {
    
    

    const response =  await axios.post(configData.ApiUrl+'/changePassword?emailId='+localStorage.email+'&password='+password )

    .then(result =>{
      console.log(result.data.data);
      debugger;
      if(result.data.code ==200){
        toast.success("Password changes Successfully")
        history.push("/Login");

      }
      else{
        //seterrforNotExist(true)
        toast.warn("Invalid Otp");
      }
    })
    .catch(error =>{
      console.log(error)
    })
    console.log(password)
    

  };

  

  
  
  

    return(
        <div className="container-fluid  forgotcontainer">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-2 col-sm-12 col-md-3">           
                   <img src={logo} style={{width:"187px", height:"77px"}}/>
                  </div>
                <div className="col-lg-5 col-sm-12 col-md-7">
                    {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
            <div className=''>
            <div className="row margintop ">
                <div className="col-lg-4"></div>
                <div className="col-lg-4 col-sm-12 col-md-12 log-form-div ">
                    <div className=" loginform-first-div">
                    <span className="signin-text" style={{marginLeft:"5px"}}>Reset your password

</span>
                 </div>
                 <div className="row margintop3">
                    <span className="forgotpass-instruct-text">
                       Please set a new password for your account.
                        </span>
                 </div>
                 <div className="row margintop">
                 <div className="col-lg-6 col-sm-12 col-md-8">

                 <label className="forgot-lbl">New Password
                </label>
                </div>
                 </div>
                 <div className="row margintop2">
                    <div className="col-lg-10 col-sm-12 col-md-9">
                        <div className="">
                        <input type={passwordShown ? "email" : "password"} className=" form-control" id="email"
                     name="password" onChange={handlePassword}
                        placeholder="Enter your Password "
                        style={{fontSize:"12px"}}
                     />
         { (password !== "") && <AiFillEye  className='eyeicon' size='1.2em' onClick={togglePasswordVisiblity}/> }

                      
                      <div className='row '>
    <div className=" col-lg-12  col-md-12 col-sm-12 ">
    <span className={passwordEroor?'':"hide"} style={{color:"red",fontSize:"12px",marginLeft:"0px"}}> Password must be 8 to 16 characters which contain at least one numeric digit, one uppercase and one lowercase letter</span>

                                    {error && password == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Password is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
                                
  </div>
                        </div>

                    </div>

                 </div>
                 <div className="row margintop">
                 <div className="col-lg-12 col-sm-12 col-md-12">

                 <label className=""style={{fontSize:"12px"}}>
                 Use 8 or more characters maximum  with a mix of letters, numbers & symbols.

    </label>
                </div>
                 </div>
                 <div className="row margintop">
                 <div className="col-lg-6 col-sm-12 col-md-8">

                 <label className="forgot-lbl">
                 Confirm Password
</label>
                </div>
                 </div>
                 <div className="row margintop2">
                    <div className="col-lg-10 col-sm-12 col-md-9">
                        <input type={CirnfirmpasswordShown ? "email" : "password"}
                        name="confirmPassword"
                        className="form-control"
         onChange={handleCinfirmPassword}
         placeholder="Confirm Password"
                        style={{fontSize:"12px"}}
                     />
        { (confirmPassword !== "") && <AiFillEye  className='eyeicon' size='1.2em' onClick={toggleCirnfirmPasswordVisiblity}/> }

  <div className='row '>
    <div className=' col-sm-12 col-md-12'></div>
    <div className=" col-lg-9 col-sm-12 col-md-12 ">
    <div>    
    {confirmPassErr  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>Password Does not Matched. </span>) :
                     (""
              )}
    </div>
             
                                </div>
                                
                              </div>
                    </div>

                 </div>
                 
                 <div className="row margintop">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-4 col-sm-12 col-md-12 margintop3">
                        <button className="signinbutton" onClick={submitFormData}>Set Password</button>
                    </div>

                 </div>
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>


                    
                    </div>
            </div>
            </div>
           
            
            
            
           


        </div>
    );
}
export default ForgotResetPassword