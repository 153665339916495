import '../ViewProfile/ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"
import '../../Component/UserLandingPages/AccountClosureMatrimony.css'
import logo from '../../images/Naidus directory.png';
import CoupleIMG from '../../images/Couple image.png';
import {  Link } from 'react-router-dom'
import { GiDiamondRing } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imgupload from '../../images/imageupload.svg'
import gif from '../../images/w6mVOk6ft2.gif';
import gifchechmark from '../../images/RHPdUgZL1T.gif'
import { FaBook } from "react-icons/fa";



 
const PremiumPopup = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

    const [joinNowRes,setjoinNowRes]= useState()


    const [Paymentreq, setPaymentreq]= useState({
      nameId:{joinNowRes},
      amount:"",
      productType:'Community',
      currency:'INR',
      code:"",

      });

    //setPaymentreq(joinNowRes);

//payment req
const paymentRequest =()=>{
  debugger

  
    const response = axios.post(configData.ApiUrl+'/user/payment-request', Paymentreq)
    response.then((result) => {
      console.log("Paymentres response",result.data.data);
      debugger
      if (result.data.data != null) {
          toast.success(" Successfully!");

         // localStorage.setItem('id',result.data.data.id);
          //setjoinNowRes(result.data.data)
          debugger;
          localStorage.setItem('name',result.data.data.name);
         // history.push("/CompleteProfile")

  
        }
          toast.success("payments added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

        //history.push("/Login")

}



 

    //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  if(joinNowRes != null && joinNowRes.referralUsed !=null){
    setreferalCodeValue(joinNowRes.referralUsed);
  
  }
  
  const [showhide, setShowhide]= useState(false);
    const [discount, setDiscount]= useState(false);
    
    const adddiscount= ()=>{
      debugger
      setDiscount(true);
      setShowhide(false);
      setRemoveDiscount(false);
      getPriceForReferalcode();
  
    }
    const [removeDiscount, setRemoveDiscount]= useState(false);
  
    const removeDiscountClick= ()=>{
      debugger;
      setShowhide(true);
  
      setRemoveDiscount(false);
      setDiscount(false);
      setData(0);
      settotalpayable(pricedata);
      setPaymentreq(preState =>({
        ...preState,
        "amount":pricedata.price
          }))
  
    }
  //for localstorage
  const data = localStorage.getItem('data');
  console.log("auth localstoragedata",data)
      
      
    
  const [pricedata, setPriceData] = useState();
  
  
  
     useEffect(() => {
       loadUsers();
     },[]);
  
     
     const loadUsers = async () => {
       const result = await axios.get(configData.ApiUrl+'/user/getSubscriptionForDirectory');
  
       setPriceData(result.data.data);
       console.log("price",result.data.data)
       setamouttotal(parseInt(result.data.data.price)-parseInt(result.data.data.discount))
       settotalpayable(result.data.data);
       setPaymentreq(preState =>({
        ...preState,
        "amount":parseInt(result.data.data.price)-parseInt(result.data.data.discount),
        "nameId":joinNowRes,
          }))
  
      };
      
        //let totalAmount= parseInt(pricedata?.price) - parseInt(pricedata?.discount)
      
      
  
  //for opening fields depends on radio cheked
  const [showhideSubTy, setShowhideSubTy]= useState("");
  const [borderbox, setBorderbox]=useState(false);
  const [borderboxfree, setBorderboxfree]=useState(false);
  
   
  const [referalCodeValue, setreferalCodeValue] = useState();
  //for subscription type
  const [substype,setSubstype]= useState({
    subscriptionType:""
  })
  
  const handleshow= e=>{
    const getshow= e.target.value;
    // const {name,value,type,checked}=e.currentTarget;
  
    setShowhideSubTy(getshow);
  }
  
  const divClickPremium =()=>{
     setBorderbox(true);
     setBorderboxfree(false);
    setShowhideSubTy("Premium");
    setreferalCodeValue("Premium");
    setSubstype(preState =>({
      ...preState,
      "subscriptionType" : "Premium"
    }))
    substype.subscriptionType="Premium";
    console.log("5555", substype)
  }
  const divClickFree=()=>{
    // setBorderbox(true)
    setBorderbox(false)
    setBorderboxfree(true);
  
    setShowhideSubTy("Free");
    setreferalCodeValue("Free");
  
    setSubstype(preState =>({
      ...preState,
      "subscriptionType" : "Free"
    }))
    substype.subscriptionType="Free";
    console.log("444", substype)
  
  }
     
  
      const handleReferalCode =(e)=>{
        const {name,value,type,checked}=e.currentTarget;
  
        setreferalCodeValue(value)
  debugger
        setSubstype(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))
  
      }
  
      //for getprice 
      const [countmenu, setData] = useState(0);
      const [totalpayable, settotalpayable] = useState();
      const [expiredtxt, setexpiredtxt] = useState(false);
      const [existtxt, setexisttxt] = useState(false);
  
      const [amouttotal, setamouttotal] = useState();
  
  
     // const totalpayable=pricedata;
      const getPriceForReferalcode = async () => {
        const result = await axios.get(`${configData.ApiUrl}/user/getDiscountPrice?discountCode=${referalCodeValue}`);
  
        if(result.data.code == 200 ){
          debugger;
          setData(result.data.data);
          settotalpayable(pricedata-result.data.data);
          //totalAmount=pricedata.price-result.data.data
          setamouttotal(pricedata.price-result.data.data)
          setPaymentreq(preState =>({
            ...preState,
            "amount":amouttotal,
            "productType":'Community',
            "currency":'INR',
            "code":referalCodeValue,
            "nameId":joinNowRes,
  
  
  
          }))
          toast.success('Coupon code  Applied')
          setexpiredtxt(false)
        }
        else if(result.data.code == 401 ){
  
  
          setData(result.data.data);
          setexpiredtxt(true);
  
          settotalpayable(pricedata-result.data.data);
          setamouttotal(pricedata.price)
  
          setPaymentreq(preState =>({
            ...preState,
            "amount":pricedata.price,
            "productType":'Community',
            "currency":'INR',
            "code":referalCodeValue,
            "nameId":joinNowRes,
  
  
  
          }))
        toast.warn('Coupon code Expired');
  
        }
        else if(result.data.code == 400 ){
  
  
          setData(result.data.data);
          setexpiredtxt(true);
  
          settotalpayable(pricedata-result.data.data);
          setamouttotal(pricedata.price)
  
          setPaymentreq(preState =>({
            ...preState,
            "amount":pricedata.price,
            "productType":'Community',
            "currency":'INR',
            "code":referalCodeValue,
            "nameId":joinNowRes,
  
  
  
          }))
        toast.warn('Coupon code does not exist');
  
        }
        else{
            toast.warn("Something Went Wrong..!")
        }
        
        
        console.log("getprice",result.data.data)
  
       };
      
      const [checkboxAgree,setcheckboxAgree]= useState({
        agree:""
      })
      const handlechangeCheckbox =(e)=>{
  
        const {name,value,type,checked}=e.currentTarget;
        setcheckboxAgree(preState =>({
          ...preState,
          [name]:type ==='checkbox'?checked: value
        }))
      }
       
      
      
      if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
        && authlocalstoragedata?.name1 != null){
        localStorage.id = authlocalstoragedata?.userAuthenticationId;
        localStorage.name = authlocalstoragedata?.name1;
      
      }else{
        localStorage.name = localStorage.name;
      
        localStorage.id = localStorage.id;
      }
 


  return (
    <div>
    <div className="popup-boxrefmember">
      <div className="boxrefmember" ref={popupSearchRef} style={{marginTop:"160px",width:"41%",marginLeft:"445px"}}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-2"></div>
                <div className="col-8  ">
                <span className="blood-top-text" style={{marginLeft:"46px"}}> Naidu Community Global Membership </span>
                
                 </div>
                 <div className="col-1"></div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
        <div>
        <div className="" style={{overflowY:"scroll",height:"370px"}}>
          
        <div className=" row" style={{marginTop:"10px"}}>
            <div className='col-3'></div>
            <div className='col-5 divCenter11' style={{marginLeft:"13px"}}>
            <span className="subscripdiv-text textcenter"style={{textAlign:"center"}}>Premium Membership</span>
            <div className="textleft"><span className="event-sub-detail1"><del>1999</del></span></div>
            <div className="textleft"><span className="event-sub-detail1">999/Lifetime</span></div>
            <div className="textleft"><span className="event-sub-detail1">You Save 50%</span></div>
            </div>

           
           </div>

           <div className="row mt-0">
<div className="col-lg-12 col-md-12 col-sm-12 subscrip-div" style={{height:"auto",paddingBottom:"10px",maxWidth:"88%",marginLeft:"25px"}}>
           
                <div>
                  <div className="margintop3 row">
             <div className="col-lg-5 col-md-12 col-sm-12 textright">
               <span className="subscripdiv-text">Membership Fee</span>
             </div>
             <div className="col-lg-5 col-md-12 col-sm-12 textleft">
             <span className="subscripdiv-text"> {pricedata?.price} Rs</span>
              </div>
              </div>
           <div className="margintop3 row">
              <div className="col-lg-5 col-md-12 col-sm-12 textright" style={{marginTop:"4px"}}>
                <span className="subscripdiv-text">Discount**</span>
              </div>
             
              <div className="col-lg-7 col-md-12 col-sm-12 textleft">
              
               {
                    showhide ?   ( 
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
<input type="text" className=" form-control" id="fst"
                      name="referralUsed"
                      placeholder="Enter Discount/Refrral code"
                      style={{fontSize:'13px'}}
                      onChange={handleReferalCode}
                 />  
                 <button style={{backgroundColor:"#391847",padding:"2px",color:"#fff",fontSize:"13px"}} onClick={adddiscount}>Apply</button> 
                      </div>
                                      ):

                     


                     discount ? (
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
      <span className="subscripdiv-text">{countmenu} Rs</span>
      {
        expiredtxt?(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:"",color:"red"}}>Coupon code not Applied</span>):
        // existtxt?(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:"",color:"red"}}>Coupon Code does not exist and not Applied</span>):
        (<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:""}}>{referalCodeValue} Applied</span>)
      }
                 <span className="removed-refcode-btn" style={{fontSize:"14px",marginLeft:"10px",cursor:"pointer"}} onClick={removeDiscountClick}>Remove</span> 
                      </div>
                                      ):(
                                        <div>
                  <span className="subscripdiv-text"> {pricedata?.discount}Rs</span>
               <span className="removed-refcode-btn" style={{fontSize:"14px",marginLeft:"10px",cursor:"pointer"}} onClick={removeDiscountClick}>Remove</span> 
                                     
                                     </div>
                                     
                                     )
}

                    
              </div>
              
           
            

</div>
              </div>



            <div className="margintop3 row">
              <div className="col-lg-5 col-md-12 col-sm-12 textright">
                <span className="subscripdiv-text">Total Payable*</span>
              </div>
              
              
              {
                showhideSubTy ==='Premium' &&
                <div className="col-lg-5 col-sm-12 col-md-12 textleft"><span className="subscripdiv-text"> {amouttotal} Rs</span></div>
                
              }
              
            </div>
            </div>
</div>

{/* <div className="col-lg-1 col-sm-12 col-md-12"></div> */}


            <div className="row margintop">
            <div class="form-check  col-lg-11 col-sm-12 col-md-12" style={{marginLeft:"40px"}}>
  <label class="form-check-label" style={{marginLeft:'25px'}}>
    <input type="checkbox" class="form-check-input chek" value="" name="agree" onChange={handlechangeCheckbox}/>
    <span className="chekboxlbl">By Clicking agree & join , 
    you agree to the,</span><Link to="/NaiduSanghamCommunityPledge"><span className="chekboxlbl bluetextforlink"style={{marginLeft:'-5px'}}>Naidu Directory Community pledge,<br/></span></Link><Link to="/JoinNowPrivacy"><span className="chekboxlbl bluetextforlink" style={{marginLeft:'-15px'}}>privacy & data policy </span></Link>
    <span className="chekboxlbl">and</span><Link to="/TermsConditionsNaiduSangham"><span className="chekboxlbl bluetextforlink"style={{marginLeft:'-5px'}}> Naidu Directory Terms & Conditions</span></Link>
  </label>
  </div>
            
            

          </div>


         </div>
         <div className='popupbtn-div margintop3'>

<div className='row '>
      <div className='col-4'></div>
      {/* <div className='col-3'>
          <button className='cancelbtn'  onClick={props.handleClose} style={{width:'130px',height:"42px",fontSize:"13px"}}>Cancel</button>
      </div> */}
      <div className='col-3'>
      <button className='cancelbtn' style={{width:'120px',height:"42px",fontSize:"13px",marginLeft:"15px"}} onClick={paymentRequest}>Next</button>
    </div>

    </div>
    </div>
        {/* )} */}
        </div>

        


    

    

        {/* )} */}
                 
      </div>
    </div>


   
    </div>
  );
};
 
export default PremiumPopup;