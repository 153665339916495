import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";






const NaiduSanghamCommunityPledge =()=>{
  
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId); 
  //for session
   let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });

    return(
        <div className="container-fluid  forgotcontainer">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                <Link to='/Landingpage'><img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                   {/* <span className="abccommuniti-text-main">Naidu Directory</span>  */}
                </div>

            </div>
           <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
            <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
            <div className="row">
            <span className="event-sub-detail">
                Naidu Directory Community Pledge As members of the Naidu Sangham platform, 
                we commit ourselves to fostering a thriving and
                 inclusive community that upholds our values and traditions. We pledge to:
                  
                  
                        
            </span>
           </div>
           <div className="row margintop1">
            <span className="event-sub-detail">
            1. Respect and Empathy: 
            </span>
            <span className="event-sub-detail1">
              We will treat each member with respect, empathy, and kindness, recognizing that diversity is our strength. 
                 We will strive to understand each other's perspectives and experiences, 
                 fostering an environment where all voices are heard and valued.
            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
             2. Cultural Heritage: 
             </span>
             <span className="event-sub-detail1">
                 We will celebrate and preserve our rich cultural heritage, traditions, and customs.
                  We shall take pride in our shared history and actively work towards passing it on to future generations. 
            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
             3. Knowledge Sharing:
             </span>
             <span className="event-sub-detail1">
              We will encourage the exchange of knowledge, ideas, and experiences within the community.
                   We will support each other in personal and professional growth, fostering an environment of learning and mentorship. 
            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           4. Integrity and Honesty: 
             </span>
             <span className="event-sub-detail1">
             We will maintain the highest standards of integrity and honesty in our interactions.
                    Misinformation and harmful behavior shall not be tolerated, and we will strive to promote a safe and trustworthy community. 
            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           5. Collaboration and Unity: 
             </span>
             <span className="event-sub-detail1">
              We will collaborate and cooperate with fellow members, 
                    nurturing a sense of unity and collective progress. Together, we will address challenges and seize opportunities for the betterment of our community. 
            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           6. Community Service:
             </span>
             <span className="event-sub-detail1">
              We will actively participate in community service initiatives, contributing our time and resources to uplift the less fortunate and make a positive impact on society.

            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           7. Open-Mindedness:

             </span>
             <span className="event-sub-detail1">
              We will remain open-minded and receptive to new ideas, embracing innovation and change that aligns with our shared values.

            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           8. Conflict Resolution:

             </span>
             <span className="event-sub-detail1">
              In times of disagreement or conflict, we will engage in respectful and constructive dialogue to find resolutions that strengthen our bonds.

            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           9.Privacy and Security:

             </span>
             <span className="event-sub-detail1">
              We will respect each other's privacy and ensure the security of personal information shared within the platform.

            </span>
           </div>
           <div className="row">
           <span className="event-sub-detail">
           10. Indiscrimination Clause:

             </span>
             <span className="event-sub-detail1">
             We pledge to treat every member of the Naidu Sangham platform without discrimination based on caste, language, gender or economic status. We affirm that caste-based , gender-based, economic status-based or linguistic prejudice has no place in our community, and we shall actively work towards creating an inclusive space for all. By pledging to abide by these principles, we commit ourselves to building a vibrant and supportive Naidu Sangham community. Together, we shall embrace our identity, make meaningful connections, 
             and create a legacy that reflects the best of our heritage.
            </span>
           </div>

            </Card>
            
            <div className="row textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12">  <Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>

            
            </div>

           </div>
          
            
            
           


        </div>
    );
}
export default NaiduSanghamCommunityPledge