import React from "react";
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const MySubscription =()=>{
      const columns = [
  
        {
            name: "Date",
           selector: "date",
            sortable: true,
            
         },
        {
          name: "Plan",
         selector: "plan",
          sortable: true,
          width:"100px"
      },
      
      {
          name: "Validity",
         selector: "validity",
          sortable: true,
         className:"texttransformcapitalize"
          
      },
      {
        name: "Renewal",
        selector: "renewal",
        sortable: true,
        cell: (d) => [
         d.renewal=="Extend Renewal"? <span className="" style={{color:'Blue'}}>Extend Renewal</span> 
          :<span className="">Expired</span>
          
        ]
        
      },
      
      
    ];
    
     const data = [
        {
            date:"1 May 2023",
            plan: "Business",
            validity: "01 Nov 2023",
             renewal: "Extend Renewal",
             
    
          },
          {
            date:"1 May 2023",
            plan: "Business",
            profileviews: "50",
            validity: "01 Nov 2023",
            remainingviews: "Expired",
             
    
            },
    
                ]
              
          const tableData = {
                    columns,
                    data,
                    
                  };
    
    return(
<div>
<DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available" //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions>
</div>
    )
}
export default MySubscription




