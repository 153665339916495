import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";




const MatrimonyTermsConsitions  =()=>{
   
   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

let history = useHistory();

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
//for new session for userlogin
const redirecToLandingdata =  () => {
 
   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
     history.push("/");

   }
 }
    
    useEffect(() => {
     redirecToLandingdata();
  });
    return(
        <div className="container-fluid  ">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                <Link to='/LandingPage'> <img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                 {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
           <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
            <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
             <div className="row">
            <span className="event-sub-detail">
            Community Matrimony Terms & Conditions

                        
            </span>
           </div>
           {/* <div className="row">
            <span className="event-sub-detail1">
            Welcome to the Naidu Sangham Community Platform ("the Platform"). 
            These Terms and Conditions govern your access to and use of the Platform. 
            By accessing or using the Platform, you agree to be bound by these Terms and Conditions. 
            If you do not agree to these terms, please refrain from using the Platform.
                        
            </span>
           </div>  */}
           <div className="row margintop3">
            <span className="event-sub-detail">
            Registration and Profile Creation:
              </span>
            <span className="event-sub-detail margintop1">
            1.1. Eligibility: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            1.1. Eligibility: To use the matrimony vertical, you must be a registered member of Naidu Sangham and meet the eligibility criteria for matrimony services as specified in the platform's guidelines.

             
            </span>
            <span className="event-sub-detail margintop1">
            1.2. Accuracy of Information:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            You agree to provide accurate and truthful information while creating your profile. Any misrepresentation or falsification of details may result in the termination of your account. 
             
            </span>
            
        
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Partner Search and Communication:
  
              </span>
            <span className="event-sub-detail margintop1">
            2.1. Partner Search: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            The matrimony vertical allows you to search for potential partners based on specified criteria. 
            You are responsible for making independent decisions while connecting with other members.
            </span>
            <span className="event-sub-detail margintop1">
            2.2. Communication: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Members may communicate with each other through the platform's messaging system. However, 
            you are advised to exercise caution and discretion while sharing personal information with others. 
            </span>
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            User Conduct and Content:
              </span>
            <span className="event-sub-detail margintop1">
            3.1. Responsible Behavior: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Users of the matrimony vertical are expected to behave responsibly and respectfully towards others. 
            Any form of harassment, offensive language, or inappropriate behavior is strictly prohibited.
            </span>
            <span className="event-sub-detail margintop1">
            3.2.  Profile Content: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            You are solely responsible for the content you share on your profile, including text, photos, and other media. Naidu Sangham reserves 
            the right to moderate and remove content that violates community guidelines or is deemed inappropriate.
            </span>
        </div>
          
        <div className="row margintop3">
            <span className="event-sub-detail">
            Privacy and Data Protection:

              </span>
            <span className="event-sub-detail margintop1">
            4.1.  Personal Information: 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            As part of the matrimony service, you may share personal information with other users.
             It is essential to exercise caution and discretion when sharing such information and be mindful of your privacy.
            </span>
            <span className="event-sub-detail margintop1">
            4.2. Data Usage 
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham will handle your personal data in accordance with the Privacy and Data Policy outlined for the community platform.            </span>
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Verification and Security:

              </span>
            <span className="event-sub-detail margintop1">
            5.1. Profile Verification  
             
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may offer a profile verification process to ensure the authenticity of member profiles. However,
             this does not guarantee the accuracy or reliability of information shared by members.
            </span>
            <span className="event-sub-detail margintop1">
            5.2. Security Precautions:
             
         </span>
            <span className="event-sub-detail1 margintop1">
            While Naidu Sangham takes reasonable security measures to protect member data, we cannot guarantee the absolute security of information transmitted over the internet.            </span>
        </div>
        
        <div className="row margintop3">
            <span className="event-sub-detail">
            Matchmaking Assistance:
              </span>
            <span className="event-sub-detail margintop1">
            6.1. Platform Assistance:     
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may provide matchmaking assistance to members seeking suitable matches. However,
             this service is based on the information provided by members and does not guarantee successful matches.            </span>
            
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Fees and Refunds:              </span>
            <span className="event-sub-detail margintop1">
            7.1. Membership Fees:      
         </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may charge membership fees or offer premium services for the matrimony vertical. 
            Details regarding fees and services will be outlined separately.     
                   </span>
                   <span className="event-sub-detail margintop1">
                   7.2. Refunds:     
         </span>
            <span className="event-sub-detail1 margintop1">
            Membership fees are non-refundable unless otherwise specified by Naidu Sangham.      
                   </span>
            
        </div> 
           
        <div className="row margintop3">
            <span className="event-sub-detail">
            Disclaimer:
              </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham acts as a platform to facilitate connections between community members for matrimony purposes. We do not endorse or guarantee the success of any match or the accuracy of information provided by members.
        
               </span>
            
            
        </div>
           
        <div className="row margintop3">
            <span className="event-sub-detail">
            Termination:
              </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham reserves the right to suspend or terminate access to the matrimony vertical for users who violate these Terms and Conditions or engage in inappropriate behavior.
        
               </span>
            
            
        </div>
        <div className="row margintop3">
            <span className="event-sub-detail">
            Governing Law and Jurisdiction:
              </span>
            <span className="event-sub-detail1 margintop1">
            These Terms and Conditions specific to the matrimony vertical shall be governed by and construed in accordance with the laws of Pune jurisdiction. Any disputes arising from or related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Pune.
      
               </span>
            
            
        </div>

          
          

            </Card>
            <div className="row margintop"></div>
            <div className="row mt-0 textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12"><Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>
           
            
            </div>

           </div>
          
            
            
           


        </div>
    );
}
export default MatrimonyTermsConsitions