
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import editviewpic from '../../images/u842.png';
import Avatar from '@mui/material/Avatar';
import { configData } from "../../Config"



const MatrimonyBasicDetail = ({ basicDetails, setbasicDetails, handleBack, handleNext, attachmentProf, setAttachmentProf }) => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')

  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);


  const [startDate, setStartDate] = useState(new Date());

  const handleChangeDate = (e) => {
    setStartDate(e);
    // setStartDate(preState => ({
    //     ...preState,
    //     "birthday": (moment(e).format("dd-MMM-yyyy")),

    //   }))

    setbasicDetails(preState => ({
      ...preState,
      "birthDate": (moment(e).format("DD MMM yyyy")),

    }))

    console.log(basicDetails.birthday);
  }

  const [error, setError] = useState(false);
  const [contactValidEroor, setContactValidEroor] = useState(false);
  const [sameNoError, setSameNoError] = useState(false);



  const submitFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (basicDetails.gender == null) {
      setError(true);
    }

    else if (basicDetails.bloodGroup == "" || basicDetails.bloodGroup == null) {
      setError(true);
    }
    else if (basicDetails.motherTongue == "" || basicDetails.motherTongue == null) {
      setError(true);
    }

    else if (basicDetails.maritalStatus == "" || basicDetails.maritalStatus == null) {
      setError(true);

    }
    // else if (basicDetails.secondaryMobileNumber==""||basicDetails.secondaryMobileNumber==null) {
    //   setError(true);

    // }

    //  else if (basicDetails.secondaryMobileNumber != authlocalstoragedata.mobileNumber && !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(basicDetails.secondaryMobileNumber)){

    //     setContactValidEroor(true);

    //   }
    else if ((basicDetails.secondaryMobileNumber != null && basicDetails.secondaryMobileNumber != "")
      && (basicDetails.secondaryMobileNumber?.length != 10) ){
        setError(true);
        setSameNoError(false);
      }
    
    else if (basicDetails.secondaryMobileNumber == authlocalstoragedata.mobileNumber) {
        setSameNoError(true);

      }
    
    else if (attachmentProf == "" || attachmentProf == null) {
      setError(true);

    }


    else {
      debugger
      handleNext()
    }
  };

  //for img preview
  const [imgData, setImgData] = useState(null);
  const fileref = useRef(null);

  const handlephotoOneChange = (e, index) => {
    const { name, value } = e.target;
    debugger;
    // setAttachmentProf(preState => ({
    //    ...preState,
    //    "profilePhoto" : e.target.files[0]
    //  }))

    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
      setAttachmentProf(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }


  };

  const handleChange = (e) => {
    debugger;
    const { name, value, type, checked } = e.currentTarget;


    setbasicDetails(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
    }));
  }

  //for blood group
  const [bloodGroupData, setbloodGroup] = useState([]);

  useEffect(() => {
    loadBloodGroup();
  }, []);

  const loadBloodGroup = async () => {
    debugger
    const result = await axios.get(configData.ApiUrl + "/user/bloodGroups");
    setbloodGroup(result.data.data);
    console.log("bldgrp", result.data.data)
  };

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11", authlocalstoragedata)


  return (

    <div className="container">
      <form >
        <div className="row margintop">
          <div className="col-9" id="completeprofradio">
            <div className="row textLeft">
              <div className="col-lg-7 col-sm-12-col-md-12">
                <span className="lbl popinsfont" style={{ fontSize: '14px' }} >Basic & Contact Details</span>
              </div>

            </div>
            <div className="row">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }} >Name</span>
              </div>
              <div className="col-6 textleft">
                <span className="complet-name" style={{ fontSize: '13px' }}>{authlocalstoragedata?.name2}</span>
              </div>
            </div>
            <div className="row margintop">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }}>Gender</span>
              </div>

              <div className="col-2 margintop1 textleft">
                <label class="radiodivcust radiobtn-text1" >Male
                  <input type="radio" id="defaultRadio5" className='radiobtn' name="gender" checked={basicDetails.gender == "Male" ? true : false} onChange={handleChange} value="Male" />

                  <span class="checkmark"></span>
                </label>
              </div>

              <div className="col-3 margintop1 textleft">
                <label class="radiodivcust radiobtn-text1" >Female
                  <input type="radio" id="defaultRadio6" className='radiobtn' name="gender" checked={basicDetails.gender == "Female" ? true : false} onChange={handleChange} value="Female" />

                  <span class="checkmark"></span>
                </label>
              </div>
              <div className="col-1"></div>


            </div>
            <div className='row '>
              <div className='col-4'></div>
              <div className=" col-4 ">
                {error && basicDetails.gender == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Gender is required.
                  </span>
                ) : (
                  ""
                )}
              </div>


            </div>

            <div className="row margintop3">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }} >Date of Birth</span>
              </div>
              <div className="col-7 textleft">
                <span className="comudrct-digitxt" style={{ fontSize: '13px' }}>{authlocalstoragedata?.birthDate}</span>
              </div>
            </div>

            <div className="row margintop">
              <div className="col-4 textright" style={{ marginTop: "5px" }}>
                <span className="lbl popinsfont" style={{ fontSize: '13px' }}>Blood Group</span>
              </div>
              <div className="col-6 textleft">
                <select id="inputState"
                  className=" form-control "
                  name='bloodGroup'
                  value={basicDetails.bloodGroup}

                  onChange={handleChange} >
                  <option selected value="">Select</option>

                  {

                    bloodGroupData.map((result) => (
                      <option value={result}>{result}</option>
                    ))

                  }
                </select>
              </div>
            </div>
            <div className='row '>
              <div className='col-4'></div>
              <div className=" col-4 ">
                {error && basicDetails.bloodGroup == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Blood Group is required.
                  </span>
                ) : (
                  ""
                )}
              </div>


            </div>
            <div className="row margintop">
              <div className="col-4 textright" style={{ marginTop: "5px" }}>
                <span className="lbl popinsfont" style={{ fontSize: '13px' }}>Known Languages</span>
              </div>
              <div className="col-6 textleft">
              <textarea class="form-control" name='knownLanguages'  id="exampleFormControlTextarea1" rows="3" placeholder="Known Languages" onChange={handleChange} style={{fontSize:"12px"}}/>

              </div>
            </div>
            <div className='row '>
              <div className='col-4'></div>
              <div className=" col-4 ">
                {error && basicDetails.knownLanguages == null ? (
                  <span style={{ color: "red", fontSize: "13px" }}>
                   Known Languages is required.
                  </span>
                ) : (
                  ""
                )}
              </div>


            </div>
            <div className="row margintop">
              <div className="col-4 textright" style={{ marginTop: "5px" }}>
                <span className="lbl popinsfont" style={{ fontSize: '13px' }}>Mother Tongue</span>
              </div>
              <div className="col-6 textleft">
                <select id="inputState" name='motherTongue'
                  value={basicDetails.motherTongue}

                  onChange={handleChange}
                  className=" form-control" style={{ height: "34px", fontSize: "11px" }}>
                  <option selected value="">Please Select</option>
                  <option  value="Hindi">Hindi</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Telugu">Telugu</option>
                  <option value="Tamil">Tamil</option>
                  <option value="gujrati">gujrati</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Kanada">Kanada</option>


                </select>
              </div>
            </div>
            <div className='row '>
              <div className='col-4'></div>
              <div className=" col-4 ">
                {error && basicDetails.motherTongue == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Mother Tongue is required.
                  </span>
                ) : (
                  ""
                )}
              </div>


            </div>
            <div className="row margintop">
              <div className="col-4 textright" style={{ marginTop: "5px" }}>
                <span className="lbl popinsfont" style={{ fontSize: '13px' }}>Marital Status</span>
              </div>
              <div className="col-6 textleft">
                <select id="inputState"
                  className=" form-control "
                  name='maritalStatus'
                  value={basicDetails.maritalStatus}

                  onChange={handleChange} >
                  <option selected value="">Select</option>
                  <option value="Never Married">Never Married</option>
                  <option value="Widow">Widow</option>
                  <option value="Widower">Widower</option>
                  <option value="Divorced">Divorced</option>

                </select>
              </div>
            </div>
            <div className='row '>
              <div className='col-4'></div>
              <div className=" col-4 ">
                {error && basicDetails.maritalStatus == null ? (
                  <span style={{ color: "red", fontSize: "13px" }}>
                    Marital Status is required.
                  </span>
                ) : (
                  ""
                )}
              </div>


            </div>

            <div className="row margintop">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }} >Primary Mobile No.</span>
              </div>
              <div className="col-6 textleft">
                <span className="comudrct-digitxt" style={{ fontSize: '13px' }}>{authlocalstoragedata?.mobileNumber}</span>
              </div>
            </div>
            <div className="row margintop">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }} >Secondary Mobile No.</span>
                <div style={{ marginTop: "-3px" }}><span className="lbl popinsfont" style={{ fontSize: '12px' }} >(Optional)</span></div>
              </div>
              <div className="col-6 textleft">
                <input type="number" class=" form-control" placeholder='Mobile No' name='secondaryMobileNumber'
                  value={basicDetails.secondaryMobileNumber}
                  onChange={handleChange} style={{ fontSize: "13px" }} />
              </div>
            </div>
            <div className='row '>
              <div className='col-4'></div>

              <div className=" col-8 " style={{ marginLeft: "0px" }}>
                <span className={contactValidEroor ? '' : "hide"} style={{ color: "red", fontSize: "12px", marginLeft: '0px' }}>Please Enter Valid Mobile No.</span>
                <span className={sameNoError ? '' : "hide"} style={{ color: "red", fontSize: "12px", marginLeft: '0px' }}>Primary Mobile no. is same as Secondary,Please enter different no. </span>


                <div>
                  {error && basicDetails.secondaryMobileNumber != null && basicDetails.secondaryMobileNumber != "" && basicDetails.secondaryMobileNumber?.length != 10 ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Mobile No is 10 digits only.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>


            </div>

            <div className="row margintop">
              <div className="col-4 textright">
                <span className="lbl popinsfont" style={{ fontSize: '13px' }} >Email</span>
              </div>
              <div className="col-6 textleft">
                <span className="comudrct-digitxt" style={{ fontSize: '13px' }}>{authlocalstoragedata?.emailId}</span>
              </div>
            </div>


          </div>
          <div className="col-3 margintop">
            <div className="row margintop"></div>
            <div className="row margintop"></div>


            <label className='' style={{ paddingLeft: "10px", paddingTop: "3px", cursor: "pointer" }} for="selectFile">

              {
                imgData != null ? (
                  <img id='image' className="playerProfilePic_home_tile"
                    src={imgData}

                  />
                ) : (
                  <Avatar className="avtarimg1" style={{ backgroundColor: "#fff", width: "181px", height: "160px" }}></Avatar>


                )
              }



              <img src={editviewpic} className="imgforeditviewpic" style={{ width: "194px", marginLeft: "-7px" }} />
              {
                imgData == null ? (
                  <div className="editpic-viewprof" style={{ marginLeft: "-5px" }}>
                    Add Profile Pic
                  </div>) :
                  (<div className="editpic-viewprof" style={{ marginLeft: "-5px" }}>
                    Edit Profile Pic
                  </div>)
              }

            </label>
            <input id="selectFile" type="file" className="fileupload hide"
              name="profilePhoto" ref={fileref} onChange={handlephotoOneChange}
            />
            <div className='row '>
              <div className=" col-12 ">
                {error && attachmentProf == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Profile photo is required.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
              <button
                className="displynoneprev"

                onClick={handleBack}
              >
                Previous
              </button>
            </div>
            <div className="col-2 margintop3">
              <button
                className="nextbtn "
                variant="contained"
                color="primary"
                type='submit'
                //onClick={handleNext}
                onClick={submitFormData}
              >
                Next
              </button>


            </div>
            <div className="margintop2  row"></div>


          </div>
        </div>
      </form>
    </div>
  )
}
export default MatrimonyBasicDetail