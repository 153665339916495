import './AddNewsPopupTest.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import AddBlogPopup from './AddBlogPopup';
import AddKycPopup from './AddKycPopup';
import { Breadcrumb, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import SuccessPopCast from '../JoinNow/SuccessPopCast';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

 
const AddNewsPopupTest = props => {

  //for session
  let history = useHistory();

  const [session ,setSession] = useState(false);

  const gesessiondata = async () => {
     debugger;
     axios.get('/checkSession')
      
     .then(result =>{
       if(result.data.code == 501){
         setSession(true)  ;
          }
      else{
           history.push("/Login");

      }
       debugger;
       console.log(result.data.code);
     }).catch(error =>{
       console.log(error);
     })
   };

         console.log("checksession on campaign",session);

  useEffect(() => {
      gesessiondata();
    }, [session]);
  

    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate= (e) => {
      debugger
     setStartDate(e)
    }

    const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  
  

  const [selected, setSelected] = useState('event');
  const handleChange = event => {
    setSelected(event.target.value);
  };
  //sucess pop
  const [isOpenSuccess, setisOpenSuccess] = useState(false);
 
  function togglePopupSuccess (isLastname){
    if(isLastname){
      debugger
      setisOpenSuccess(true);
    }
    else{
        setisOpenSuccess(false);
    }
  }
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  
  return (
    <div className="popup-boxnews">
      <div className="boxnews">
                  <div className="topnewsrow">
                  <div className="row " id="sharediv">
                    <div className="col-2"><span>Share an</span></div>
                    <div className="col-2  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'event'}
                   value='event'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Event</label>
                    </div>
                    <div className="col-2  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'blog'}
                   value='blog'
                     name="example4" onClick={togglePopupBlog}/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Blog</label>
                     {isOpenBlog && <AddBlogPopup
      
      handleClose={togglePopupBlog}
     />}
                    </div>
                    <div className="col-2  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                   value='recipie'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Recipie</label>
                    </div>
                    <div className="col-2 whitenowrap  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' name="example4"
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                   value='vlog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>Vlog</label>
                    </div>
                    <div className="col-1 whitenowrap  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                   value='kyc'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text'>KYC</label>
                     {isOpenkyc && <AddKycPopup
      
                  handleClose={togglePopupKyc}
                />}
                    </div>
                    

                  </div>
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                    
                    <div className="row margintop3">
                    <div className="col-4 textright whitenowrap"><span className="lbl sourcesansfont">Host Name</span></div>
                    <div className="col-8 textleft "><span className="complet-name">Sagar Naidu</span></div>
                </div>
                <div className="row margintop3">
                    <div className="col-4 textright"><span className="lbl whitenowrap sourcesansfont">Event Type</span></div>
                    <div className="col-8 textleft">
                    <select   id="inputState" name='lastnme' 
            className="form-control inputwidth " style={{fontSize:"12px"}}>
            <option selected value=""> Select Event Type</option>
            <option value="House Warming Ceremony">House Warming Ceremony</option>
            <option value="Birthday">Birthday</option>
            <option value="Marrige Anniversary">Marrige Anniversary</option>
            <option value="Death Anniversary"> Death Anniversary</option>
            <option value="Others">Others</option>
       </select>
                    </div>
                </div>
                <div className="row margintop3">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">Date</span></div>
                    <div className="col-8 textleft">
                    <DatePicker
                    style={{fontSize:"12px"}}
          className="adreservdate inputwidth"
          name='date'
            selected={startDate}
             onChange={date => handleChangedate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd  MMM yyyy"
          />                       
           </div>
                </div>
                <div className="row margintop3">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">Time</span></div>
                    <div className="col-8 textleft">
                        <input type="time" class=" inputwidth form-control" style={{fontSize:"12px"}}/>
                        </div>
                </div>
                <div className="row margintop3">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">Address</span></div>
                    <div className="col-8 textleft form-group">
                    <textarea class="form-control inputwidth" id="exampleFormControlTextarea1" rows="2" placeholder="Enter Address" style={{fontSize:"12px"}}/>
                        </div>
                </div>
                <div className="row margintop1">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">City</span></div>
                    <div className="col-8 textleft form-group">
                    <input type="text" class="inputwidth form-control" placeholder='Enter City' style={{fontSize:"12px"}}/>
                 </div>
                </div>
                <div className="row margintop1">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">State</span></div>
                    <div className="col-8 textleft form-group">
                    <input type="text" class="inputwidth form-control" placeholder='Enter State' style={{fontSize:"12px"}}/>
                 </div>
                </div>
                
                <div className="row margintop1">
                    <div className="col-4 textright"><span className="lbl sourcesansfont">Country</span></div>
                    <div className="col-8 textleft form-group">
                    <input type="text" class="inputwidth form-control" placeholder='Enter Country' style={{fontSize:"12px"}} />
                 </div>
                </div>
                    </div>
                    
                    
                    
                    
                    <div className='col-6'>
                    <div className='row margintop'></div>
                    <div className='row margintop1'></div>
                    <div className='row margintop1'></div>


                        <div className='row margintop'>
                            <div className='col-2'></div>
                            <div className='col-10 margintop3'>
                            <label for="selectFile" name="fileupload" className="uplod" style={{ backgroudColor: "white" }}   >
          
                           </label>
                     <input id="selectFile" type="file"  className="fileupload hide" 
                       name="fileupload"
                        />
                       <div><span className='photoupload-lbl'style={{fontSize:"12px",fontStyle:"normal"}}>Add Photos to your Post(optional)</span></div> 
                              </div>
                            </div>
                            
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>
                            <div className='row margintop'></div>





                   
                    </div>

                  </div>
                  <div className='row margintop3'>
                    <div className='col-6'></div>
                    <div className='col-3'>
                        <button className='cancelbtn'  onClick={props.handleClose} style={{width:"120px",height:"40px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                    <button className='createeventbtn'style={{width:"130px",height:"40px"}} onClick={togglePopup}>Create Event</button>
                  </div>

                  </div>
                  {isOpen && <SuccessPopCast
      
      handleClose={togglePopup}
    />}
                  {/* <div className={isOpenSuccess?"":"hide"}>
       <Modal>
        <Modal.Header>
          <Button variant="" className="btn btn-close"onClick={() => togglePopupSuccess(false)} >
            x
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="tx-center ">
            <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-success lh-1 mg-t-20 d-inline-block"></i>{" "}
            <h4 className="tx-success tx-semibold mg-b-20">Congratulations!</h4>{" "}
            <p className="mg-b-20 mg-x-20">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration.
            </p>
            <Button
              variant=""
              aria-label="Close"
              className="btn ripple btn-success pd-x-25"
              type="button"
            >
              Continue
            </Button>{" "}
          </div>
        </Modal.Body>
      </Modal> 
      </div> */}
                  
      </div>
      
      
    </div>
  );
};
 
export default AddNewsPopupTest;