import './ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"
import '../../Component/UserLandingPages/AccountClosureMatrimony.css'
import logo from '../../images/Naidus directory.png';
import CoupleIMG from '../../images/Couple image.png';
import {  Link } from 'react-router-dom'
import { GiDiamondRing } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imgupload from '../../images/imageupload.svg'
import gif from '../../images/w6mVOk6ft2.gif';
import gifchechmark from '../../images/RHPdUgZL1T.gif'
import { FaBook } from "react-icons/fa";



 
const DirectoryClosure = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);


 

    //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  //for close popup when click on outside
  const popupFoundRef = useRef(null);
  const handleClickOutsideFounddiv = (event) => {
    if (popupFoundRef.current && !popupFoundRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFounddiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFounddiv);
    };
  }, []);
  const [firstDiv, setfirstDiv] = useState(true);
  const [sucessUploadStoryPopup, setsucessUploadStoryPopup] = useState(false);
  const [succesDeletPopup, setsuccesDeletPopup] = useState(false);
  const [skipPopup, setskipPopup] = useState(false);
  const [fulfiledoutsidesuccesspop, setfulfiledoutsidesuccesspop] = useState(false);

  const handlefulfiloutsidesuccpop = () => {
    setskipPopup(false); 
    setdetailsSuccessStory(false); 
    setShowSuccessStory(false)// Show success story section
    setFoundMatchDiv(false);
    setfirstDiv(false);
    setsucessUploadStoryPopup(false);
    setsuccesDeletPopup(false);
    setfulfiledoutsidesuccesspop(true)
    
};
  

  
const handleNextClick = () => {
  setsuccesDeletPopup(true);  // Show the success message div
  
  setfirstDiv(true);
  
};

const [isOthersSelected, setIsOthersSelected] = useState(false); // For showing the textbox and next button
const [showSuccessMessage, setShowSuccessMessage] = useState(false); // For showing success message div

const handleotherRadioChange = (e) => {
  if (e.target.value === "Others") {
    setIsOthersSelected(true); // Show textbox and next button for "Others"
    setFoundMatchDiv(false);   // Hide any other match divs if needed
  } else {
    setIsOthersSelected(false); // Hide textbox and next button
  }
};

const [startDate, setStartDate] = useState(new Date());

const handleChangedate= (e) => {
  debugger
 setStartDate(e)
//  setEventData(preState => ({
//   ...preState,
//   "eventDate": (moment(e).format("DD MMM yyyy")),

// }))
}
//for upload image

const [imgData, setImgData] = useState(null);
      const fileref = useRef(null);
      

      const [attachment, setAttachment] = useState(null)
const banerImageChange = event=>{

  // if(event.currentTarget.files != null)
  //    {
  //      setAttachment(event.target.files[0]);
  //    }
  if (event.currentTarget.files[0]) {
    console.log("picture: ", event.target.files);
    setAttachment(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgData(reader.result);
    });
    reader.readAsDataURL(event.currentTarget.files[0]);
  }
}

const [checkboxAgree,setcheckboxAgree]= useState({
  agree:""
})
const handlechangeCheckbox =(e)=>{

  const {name,value,type,checked}=e.currentTarget;
  setcheckboxAgree(preState =>({
    ...preState,
    [name]:type ==='checkbox'?checked: value
  }))
}
 

  return (
    <div>
    <div className="popup-boxrefmember">
      <div className="boxrefmember" ref={popupSearchRef} style={{marginTop:"170px"}}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="blood-top-text"> Community Directory </span>
                
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
        <div>
        <div className={firstDiv?"":"hide"} style={{overflowY:"scroll",height:"370px"}}>
          
        <div className="row margintop3">
              <div className="col-lg-12   ">
                <span className='event-sub-detail1' style={{fontSize:"px",color:""}}>
                By closing your Community Directory Account, Your Matrimony Account will also be permanently closed.   </span>
              </div>
              </div>
              <div className='row '>
                <div className=''></div>
                <div className='col-12'>
                <div className='row margintop matrimonyborfercloser '>
          
           <div className='col-7'>
           <FaBook/> <span className='event-sub-detail1' style={{fontSize:"px",color:""}}>
            Community Directory             
                </span>
           </div>
           <div className='col-4'></div>

           <div className='col-1'>
            <IoIosCheckmarkCircle color='#CF6B20' size="1.2em"/>
           </div>
              </div>
                </div>

                
              </div>
              
              <div className="row margintop">
              <div className="col-lg-12   ">
                <span className='event-sub-detail' style={{}}>
                Kindly provide your reason and feedback to help us improve our services. </span>
                <div>
                  </div>

              </div>
              </div>
          <div className="row   margintop3">

<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"   style={{fontSize:'13px'}}>I have found all the connections I needed
<input type="radio" id="defaultRadio1" className=' radiobtn' name="deleteCondition"   value="Fulfilled within community"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >I no longer use the platform.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >I prefer another community platform.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >Privacy concerns.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >Too many notifications/emails.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >Difficulty in navigating or using the platform.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >I didn’t find the platform useful for my needs.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >Too few members in my area/community.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >I’m concerned about data security.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} >I’m unhappy with the community interactions.
<input type="radio" id="defaultRadio2" className=' radiobtn' name="deleteCondition"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>



<div className="col-12 margintop1 textleft">

<label class="radiodivcust radiobtn-text1" style={{fontSize:'13px'}} onChange={handleotherRadioChange}>Others
<input type="radio" id="defaultRadio3" className=' radiobtn'  name="deleteCondition" value="Others"/>  

                <span class="checkmark"></span>
           </label>
           {isOthersSelected && (<div style={{paddingBottom:"15px",marginTop:"10px"}}>
  <div className="others-section margintop1">
    <input type="text" className="form-control" placeholder="Please tell us your reason for closing account." style={{fontSize: '13px', marginTop: '10px'}} />
  </div>
  <div className='row'>
    <div className='col-3'></div>
    <div className='col-6'>  <button className="cancelbtn" onClick={handleNextClick} style={{height:"38px",fontSize:"13px", marginTop: '18px'}}>Next</button>
    </div>
    </div>

  </div>
)}
</div>

    </div>

         
         </div>
         <div className='popupbtn-div margintop3'>

<div className='row '>
      <div className='col-4'></div>
      {/* <div className='col-3'>
          <button className='cancelbtn'  onClick={props.handleClose} style={{width:'130px',height:"42px",fontSize:"13px"}}>Cancel</button>
      </div> */}
      <div className='col-3'>
      <button className='cancelbtn' style={{width:'120px',height:"42px",fontSize:"13px",marginLeft:"15px"}} onClick={handleNextClick}>Next</button>
    </div>

    </div>
    </div>
        {/* )} */}
        </div>

        


    

    

        {/* )} */}
                 
      </div>
    </div>

    
    <div className={succesDeletPopup ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"27%",marginLeft:"616px",paddingBottom:"20px",height:"45vh"}}>
        
<div style={{}}>
  <div className='row margintop3'>
    <div className='col-3'></div>
  <div className='col-6'>
  <img src={gifchechmark} />

  </div>
  <div className='col-1'></div>

  </div>
<div className="row " >
  
              <div className="col-lg-12 textcenter " style={{marginTop:"-10px",marginBottom:"10px"}}>
                <span className='event-sub-detail' style={{fontSize:"14px",color:""}}>
                Your account has been closed.  </span>
                <div className=''>
                <span className='event-sub-detail1 margintop1' style={{fontSize:"px",color:""}}>
                Your account has been successfully closed. If you ever wish to return, feel free to signup again. We appreciate your time with us. </span>
                </div>
                
              </div>
              </div>
              <div className='row margintop3' style={{paddingBottom:"px",paddingTop:"px"}}>
                <div className='col-4'></div>
                <div className='col-3 '>
                    <button className='cancelbtn' onClick={() => setsuccesDeletPopup(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Ok</button>
                </div>
                

              </div>
             
    
   
</div>

          
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
   
    </div>
  );
};
 
export default DirectoryClosure;