import './SignUpMatrimony';
import React, { useState, useEffect } from "react";
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
import { event } from 'jquery';
import OtpInput from 'react-otp-input';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {configData} from "../../Config"


const BasicDetail=({ handleChange1, handleNext, matrimonySign, setmatrimonySign,  handleBack1,confirmPass,setConfirmPass,confirmPassErr,setOtpNew,otpNew })=> {

  


 let history = useHistory();


 const redirectoLogin = () => {
   history.push("/Login") 
 };
  

const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)
    

const [selected, setSelected] = useState([]);
    const [autofetch, setAutofetch] = useState([]);

    useEffect(() => {
      PopulatUser();
    }, []);
  
    const PopulatUser = async () => {
      debugger
      const result = await axios.get(`${configData.ApiUrl}/matrimony/getMatrimonyUsersList?id=${authlocalstoragedata?.userAuthenticationId}`);
      setAutofetch(result.data.data);
      console.log("autofetchuserMatrimony",result.data.data)
    };



    
    const [mobileNumberInfo, setmobileNumberInfo] = useState();
    const [emailInfo, setemailInfo] = useState();
    const [countrycodeInfo, setcountrycodeInfo] = useState();
    const [mobileNumberOtp, setmobileNumberOtp] = useState();
    const [nameInfo, setnameInfo] = useState();
    //make obj candidate same 
    const [candidate, setcandidate] = useState(
    {
      id:"",
      name:"",
      countryCode:"",
      emailId:"",
      gender:"",
      mobileNumber:"",
      birthDate1:"",
      birthDate2:""
  })
    
    const onChandeDDL =  (e) => {
      debugger
      setSelected(e)
    //console.log( e.mobileNumber);
    if(e.length>0){
    // setmobileNumberInfo(e[0].mobileNumberFormate)
    // setemailInfo(e[0].emailId)
    // setcountrycodeInfo(e[0].countryCode)
    // setmobileNumberOtp(e[0].mobileNumber)
    // setnameInfo(e[0].name)
    
    // setcandidate(preState =>({
    //   ...preState,
    //   "id":e[0].id,

    //   "mobileNumber":e[0].mobileNumber,
    //   "name":e[0].name,
    //   "countryCode":e[0].countryCode,
    //   "gender":e[0].gender,
    //   "emailId":e[0].emailId,
    //   "birthDate1":e[0].birthDate1,
    //   "birthDate2":e[0].birthDate2
    // }))

    candidate.id =e[0].id;
    candidate.mobileNumber = e[0].mobileNumber;
    candidate.name=e[0].name;
      candidate.countryCode=e[0].countryCode;
      candidate.gender=e[0].gender;
      candidate.emailId=e[0].emailId;
      candidate.birthDate1= e[0].birthDate1;
      candidate.birthDate2 = e[0].birthDate2;
    }
    else{
    //   setmobileNumberInfo('')
    //   setemailInfo('')
    //   setcountrycodeInfo('')
    // setmobileNumberOtp('')
    // setnameInfo(' ')
    
    }
    setmatrimonySign(preState =>({
      ...preState,
      "candidate":candidate
    }))
debugger;
    console.log("mobile ="+candidate);
   // matrimonySign.candidate =candidate;
      
    }

    const sentOtp = async()=>{
      setCounter(30)
      setenableResend(false);

      if(matrimonySign.creatingFor=='Self'){
        const result = await axios.get(configData.ApiUrl+'/matrimony/sendOTP?mobileNo='+
        authlocalstoragedata?.mobileNumber+ '&emailId='+authlocalstoragedata?.emailId+'&countryCode='+authlocalstoragedata?.countryCode);
      console.log("sendotp",result.data.data)
        if(result.data.code==200){
          toast.success("Otp Sent Successfully!");
          //setenableResend(true);

          //newStepValue=1;
        }
       
        else{
          //newStepValue=0;
      
        
        
      }    
       }
      else{
        const result = await axios.get(configData.ApiUrl+'/matrimony/sendOTP?mobileNo='+
        candidate.mobileNumber + '&emailId='+candidate.emailId+'&countryCode='+candidate.countryCode);
      console.log("sendotp",result.data.data)
        if(result.data.code==200){
          toast.success("Otp Sent Successfully!");
    
          //newStepValue=1;
        }
       
        else{
          //newStepValue=0;
      
        
        
      }    
      }
    }

    //resendotp
const resendOtp =async()=>{
  debugger;
  if(matrimonySign.creatingFor=='Self'){


  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  authlocalstoragedata?.mobileNumber+ '&emailId='+authlocalstoragedata?.emailId+'&countryCode='+authlocalstoragedata?.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }
}
else{
  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  candidate.mobileNumber+ '&emailId='+candidate.emailId+'&countryCode='+candidate.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }
}

}  
     const [demoma,setdemoma]= useState();
    const handleChange=(e)=>{
     const {name,value,type,checked}=e.currentTarget;
    
    //  setLastname(preState =>({
    //    ...preState,
    //    [name]:type ==='checkbox'?checked: value
    //  }))
     debugger;
     matrimonySign.relation=value;
    setmatrimonySign(preState =>({
       ...preState,
       [name]:type ==='checkbox'?checked: value
     }))
    
    setdemoma(value);
    

console.log("Selected Data= "+matrimonySign)
console.log("Selected Data= "+demoma)

  
   }

    const [error, setError] = useState(false);
    const submitFormData = (e) => {
     debugger

    e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
     
      if (validator.isBoolean(matrimonySign.creatingFor)) {
      setError(true);

     }
     else if (matrimonySign.relation =='' && matrimonySign.creatingFor=='Other') {
      setError(true);
 }
     
    
    
    else {
      debugger
      
   handleNext();
}
    
   };




const [enablejoin, setenableResend] = useState(false);


const counterandbtn =()=>{
  setCounter(30);
  
  setenableResend(false);
 resendOtp() ;
}

    const [counter, setCounter]= useState(0)
;
  useEffect(() =>{
     if(counter === 0){
       setenableResend(true);

  
     }
    
    const timer =
    counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
    return () => clearInterval(timer);
  },[counter]);


return (
        <div className="container">
          {/* <form > */}
         
          <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-3 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Name<span className="asteric"></span></span>
  </div>
 
  <div className="col-3  textleft">

<span className='event-sub-detail1'>{authlocalstoragedata?.name2}</span>
</div>


   
    </div>
</div>
<div className="col-3 ">
</div>
            </div>
          
      <div className="row margintop">
<div className="col-11">
<div className=" row">
  <div className="col-3 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Signing Up for<span className="asteric"></span></span>
  </div>
 
  <div className="col-2  textleft" style={{marginTop:"0px"}}>

<label class="radiodivcust radiobtn-text1">Self
<input type="radio" id="defaultRadio2" className=' radiobtn1' name="creatingFor" radioGroup="A"  value='Self' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>
<div className="col-2  textleft" style={{whiteSpace:"nowrap",marginTop:"0px"}}>

    <label class="radiodivcust radiobtn-text1">Others
    <input type="radio" id="defaultRadio3" className='radiobtn1' name="creatingFor" radioGroup="A"  value='Other' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>
<div className={matrimonySign.creatingFor == "Other"?"col-3 textright":"hide"} style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Candidate needs to be listed in Community Member<span className="asteric"></span></span>
  </div>
   
    </div>
</div>
<div className="col-3 margintop1">
</div>
            </div>
             <div className='row margintpmins1'>
    <div className='col-3'></div>
    <div className=" col-4 textcenter">
                                    {error && matrimonySign.creatingFor == "" && matrimonySign.creatingFor==null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            This is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
         
          <div className={matrimonySign.creatingFor == "Other"?"row margintop":"hide"}>
<div className="col-11">
<div className=" row">
  <div className="col-3 textright" style={{marginTop:"3px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Relation<span className="asteric"></span></span>
  </div>
 
    <div className="col-9 textleft form-group">
    <select   id="inputState" name='relation' onChange={handleChange}
            className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}} >
              <option selected value=""> Select</option>
             <option  value="Parent"> Parent</option>
            <option  value="Brother"> Brother</option>
            <option  value="Sister"> Sister</option>
            <option  value="realetive"> realetive</option>
            <option  value="Friend"> Friend</option>
            <option  value="Other"> Other</option>
            </select>
    </div>
   
    </div>
</div>

            </div>
             <div className={matrimonySign.creatingFor == "Other"?'row margintpmins1':"hide"}>
    <div className='col-3'></div>
    <div className=" col-4 textcenter">
                                    {error && matrimonySign.relation == "" && matrimonySign.relation ==null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Relation is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
          <div className={matrimonySign.creatingFor == "Other"?"row":"hide"}>
<div className="col-11">
<div className="margintop row">
  <div className="col-3 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl " style={{fontSize:'12px',textTransform:"capitalize",whiteSpace:"pre-wrap"}}>Name of the  bride/groom<span className="asteric"></span></span>
  </div>
  {/* <div className="col-3 textleft form-group">
  <input type="text" className="textbox form-control" id="fst"
         name="firstName"
         placeholder=" First name"
         onChange={handleChange}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
  </div>
  <div className="col-3 textleft form-group">
  <input type="text" className="textbox form-control" id="fst"
         name="middleName"
         onChange={handleChange}

         placeholder="Middle Name"
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    </div>
    <div className="col-3 textleft form-group">
    <select   id="inputState" name='lastName'   
           onChange={handleChange}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
            <option selected value="">Please select</option>
            {
                  
                  lastNameData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
            </select>
    </div> */}
    <div className="col-9 textleft form-group">

<Typeahead
      id="basic-example"
      name="candidate"
      //labelKey="name"
      labelKey={option => `${option.name} ${option.mobileNumber}`}

      filterBy={['name']}
      onChange={onChandeDDL}
      options={autofetch}
      placeholder="Type Name  "
      selected={selected}
      style={{fontSize:"12px"}}
    />
    </div>
    </div>
    
</div>

</div>
{/* <div className='row margintpmins1'>
    <div className='col-3'></div>
    <div className=" col-4 textcenter">
                                    {error && matrimonySign.candidate == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Candidate is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>  */}

<div className='row margintop3'>
  <div className='col-5'></div>
  <div className='col-2'>
  <button className='messgsend-btn' onClick={sentOtp}>Send Otp</button>

  </div>

  <div className='col-3'></div>

  </div>

<div className="row margintop3">
<div className="col-11">
  <div className='row'>
    <div className='col-3'></div>
  <div className='col-8'>
    <span className={matrimonySign.creatingFor == "Other"?'lbl':"hide"}>Enter the OTP sent to your mobile number {candidate.mobileNumber}</span>
    <span className={matrimonySign.creatingFor == "Self"?'lbl':"hide"}>Enter the OTP sent to your mobile number {authlocalstoragedata?.mobileNumber}</span>

    </div>

  </div>
</div>
 </div>
 <div className="row margintop3">
<div className="col-11">
  <div className='row'>
  <div className='col-4'></div>
  <div className='col-7' style={{marginLeft:"16px"}}>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>
  </div>
 

</div>
 </div>

 <div className="row margintop3">
<div className="col-11">
 <div className=' row margintop1 '>
              <div className='col-4'></div>
              <div className=' col-8'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtn()} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            </div>
            </div>
            
           <div className="margintop1 row ">
            <div className="col-4 "></div>
            <div className="col-3 margintop textright">
            <button
               className="displynoneprev"
              
              onClick={handleBack1}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop1 ">
          <button
            className="nextbtn "
              variant="contained"
              color="primary"
               onClick={submitFormData}
              //type="submit"
            >
              Next
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className="margintop1 textcenter row">
            <div className="col-5"></div>
            <div className="margintop col-4">
            <span className="alreadyspn marginleftmins1">Already a registered member ?</span>
            <span className="signin-stepper marginleft5" onClick={redirectoLogin} style={{cursor:"pointer"}}>Sign In</span>
            </div>
           
            </div>

            
            
          </div>  
          {/* </form> */}
           

            
            

     
</div>
    );
  }
  export default BasicDetail;