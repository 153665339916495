
import React from 'react';
import './LandingTest.css';
import {FaWhatsapp,FaArrowRight} from 'react-icons/fa'
 import logo from '../images/u128.svg';
 import community from '../images/community.svg';
 import googleplayimg from '../images/getitgooglply.svg';
 import news1 from '../images/news1.svg';
 import news2 from '../images/news2.png';
 import news3 from '../images/news3.png';


 import firstcard from '../images/firstcard.png';
 import secondcard from '../images/secondcard.png';
 import wedding from '../images/wedding.png';
 import sisthcard from '../images/sisthcard.png';
 import fourthcard from '../images/fourtgcard.png';
 import coffee from '../images/cofee.png';
import Footer from './Footer';
import FooterTest from './FooterTest';




const LandingTest =()=>{
  
    return (
<div class='container-fluid'>
<div class='row toprow'>
<div class='  textcenter col-3 col-sm-3 col-md-3'>
    <span className=' straighline'>|</span><span class='contactspn marginleft5 opensansfont'>Contact Us: 91/9923474723</span>
    <FaWhatsapp class='marginleft5 margintpmins' color='#B5924C'size="1.5em"/>  
    <span className='straighline marginleft5'>|</span>
</div>

<div class='col-2 col-sm-2 col-md-2  textleft mr-3'>
    <span class=' contactspn marginleftmins1 opensansfont'>Email: contact@gmail.com</span>
    <span className='straighline marginleft5'>|</span>
    </div>

</div>
<div className='row logodiv'>
    <div className='textright col-1 col-sm-1 col-md-1'>
    <img src={logo} />
    </div>
    <div className='col-3 col-sm-3 col-md-3'>
        <span className=' community-name'>Naidu Directory</span>
    </div>
    <div className='col-5 col-sm-5 col-md-5 '></div>
    <div className='col-2 '>
       <a href='/JoinNow'><button className='joinowbtn primary_button'>JOIN NOW</button></a> 
    </div>
    <div className='col-1 col-sm-1 col-md-1 textleft'>
        <a href='/Login'><span className='signin sourcesansfont'>SIGN IN</span></a>
    </div>


</div>

<div className='row'>
    <div className='backgimg'>
        <div className='row'>
            <dv class='col-5 col-sm-5 col-md-5'></dv>
            <dv class='col-1 col-sm-1 col-md-1 textright'>
                <a href='/'><span className='sourcesansfont kowmor'>Know More</span></a>
            </dv>
            <div class='col-2 col-sm-2 col-md-2 textleft'>
            <button className='join-the-community primary_button'>Join The Community</button>
            </div>

        </div>
    </div>
</div>

<div class='row margintop'>
    <span class='section-name '>Revolutionary Directory</span>
<div className='col-1  margintop'></div>
    <div class='col-3 mr-3 ml-3 margintop5 maxwidthcard'>

        <div class='row cardrow'>
        <span className='card-title  marginleft5' style={{fontSize:".85876rem"}}>Know Your Community</span>

            <div class='cardimg-div  col-2'>
                <div className='img-border'>
                <img src={firstcard} class='card-img'/>
               </div>
        </div>
        <div class='col-1 '></div>
            <div class='col-10  textleft' style={{paddingBottom:'91px'}}>
                <div className=''>
                    <span className=' cart-text' style={{fontSize:".81568rem"}}>
                    "Celebrate Excellence with 'Know Your Community' - Discover and honor the achievements of remarkable personalities, from history to today, within the Naidu community. Our feature showcases their inspiring journeys, contributions, and lasting legacies. 
                    Unite with our rich heritage and the impactful individuals who have shaped it."
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>
    <div class='col-3  mr-3 ml-5 margintop5 maxwidthcard'>
        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5' style={{fontSize:".85876rem"}}>Community Members in your radius  </span>

            <div class='cardimg-div-blue col-2'>
                <div className='img-border-blue'>
                <img src={secondcard} class='card-img'/>
               </div>
        </div>
        <div class='col-1 '></div>
            <div class='col-10  textleft' style={{paddingBottom:'61px'}}>
                <div className=''>
                    <span className='cart-text 'style={{fontSize:".81568rem"}}>
                    The "Community Members in Your Radius" feature in Naidu Directory is a handy tool to help you discover how many fellow community members are in your vicinity. This feature enhances your sense of belonging and connection, providing you with valuable insights into the local presence of our community. 
                    It's all about bringing your community closer to home.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>    

    <div class='col-3  ml-5 maxwidthcard margintop5'>
        <div class='row cardrow'>
        <span className='card-title  marginleft5' style={{fontSize:".85876rem"}}> Meet Ups over coffee  </span>

            <div class='cardimg-div col-2'>
                <div className='img-border'>
                <img src={coffee}  class='whitecolor card-img'/>
               </div>
        </div>
        <div class='col-1 '></div>
            <div class='col-10 textleft' style={{paddingBottom:'61px'}}>
                <div className=''>
                    <span className=' cart-text'style={{fontSize:".81568rem"}}>
                    Meet Ups Over Coffee: Beyond Virtual Connections. Take your interactions to the next level by inviting fellow community members for a coffee meet-up. It's a chance to connect in person, 
                    get to know one another, and strengthen community bonds.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>   
      <div className='col-1'></div>

</div>

<div class='row  margintop'>
<div className='col-1 '></div>
    <div class='col-3  mr-3 ml-3  maxwidthcard'>

        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5' style={{fontSize:".85876rem"}}>Community job postings </span>

            <div class='cardimg-div-blue  col-2'>
                <div className='img-border-blue'>
                <img src={fourthcard} class='card-img'/>
               </div>
        </div>
        <div class='col-1 col-sm-1 col-md-1'></div>
            <div class='col-10  textleft' style={{paddingBottom:'61px'}}>
                <div className=''>
                    <span className='cart-text' style={{fontSize:".81568rem"}}>
                    Community Job Postings: Connecting Businesses and Job Seekers. Business owners can post job openings, ensuring that the opportunities first reach our community members in need. 
                    It's a platform that prioritizes the Naidu community's professional growth.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>
    <div class='col-3 mr-3 ml-5  maxwidthcard'>
        <div class='row cardrow'>
        <span className='card-title  marginleft5' style={{fontSize:".85876rem"}}>Community Business Directory  </span>

            <div class='cardimg-div col-2'>
                <div className='img-border'>
                <img src={sisthcard} class='card-img'/>
               </div>
        </div>
        <div class='col-1'></div>
            <div class='col-10 textleft' style={{paddingBottom:'61px'}}>
                <div className=''>
                    <span className=' cart-text' style={{fontSize:".81568rem"}}>
                    Community Business Directory: Showcasing Naidu Entrepreneurship. This feature empowers community entrepreneurs and business owners to highlight their businesses on our platform. 
                    It's a spotlight for Naidu enterprises within the community.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>    

    <div class='col-3 ml-5 maxwidthcard'>
        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5'  style={{fontSize:".85876rem"}}>Community Matrimony</span>

            <div class='cardimg-div-blue col-2'>
                <div className='img-border-blue'>
                <img src={wedding} class='card-img'/>
               </div>
        </div>
        <div class='col-1 '></div>
            <div class='col-10  textleft paddingbottom' style={{paddingBottom:'61px'}}>
                <div className=''>
                    <span className=' cart-text' style={{fontSize:".81568rem"}}>
                    Community Matrimony: Exclusively for the Naidu Community. 
                    Find your life partner within our close-knit community, where tradition meets modern matchmaking.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>   
      <div className='col-1 '></div>

</div>
<div class="row margintop5"></div>
<div class='margintop borderbottom'></div>

<div class='row margintop'>
    <span class='section-name '>So far yet so close... </span>
    <div class='margintop   col-6'>
    <img src={community}  class='community-img'/>

    </div>
    <div class='margintop  col-sm-6 col-md-6  col-6'>
    <span class='xyz-community '>Naidu Directory</span>
    <div class=' margintop5'>
        <span class=' community-txt'style={{fontSize:".81568rem"}}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae ante porttitor, finibus purus eget, varius sem. Integer facilisis posuere aliquet. 
        Ut arcu arcu, tincidunt nec lacus cursus, gravida porttitor erat. Ut sit amet turpis ultrices, aliquam velit et, laoreet erat. Praesent venenatis finibus leo,
        et scelerisque erat consequat id. Curabitur bibendum, ipsum vel consectetur viverra, mi nibh venenatis mauris, at auctor nisl neque at sapien.
        Nullam tellus augue, ultrices quis efficitur porta, scelerisque tincidunt nunc. Nullam mattis sed lacus vitae venenatis. Proin ac nisl neque.
        Morbi blandit felis ac erat commodo, placerat aliquet magna ultricies. Curabitur sit amet lorem ex. Praesent metus dui, consequat vel imperdiet commodo,
        bibendum id ligula. Cras nec auctor sem. Mauris non elit placerat, rhoncus magna sit amet, aliquam metus. Vivamus pharetra mauris eu sapien mollis,
        vitae faucibus neque placerat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nam vitae ante porttitor, finibus purus eget,
        </span>
    </div>
    <div class='margintop'>
   </div>
    <div class='btn-div textcenter'>
        <button class='know-more  primary_button'>Know More</button>
    </div>
  </div>
  </div>

    <div class='row margintop'>
    <span class='section-name '>Socializing Simplified</span>
    <div class='margintop  googleplay-div'>
        <div class='row margintop5'>
            <div class='col-2 col-sm-2 col-md-2'></div>
            <div class='margintop textcenter col-sm-8 col-md-8 col-8'>
                <span class=" downloadapptxt ">Download the ABC Global Community App</span>
             </div>

        </div>
        <div className='margintop5 row'>
            <div class="col-4 col-sm-4 col-md-4"></div>
        <div className='margintop col-4 ml-1 textcenter getiton-googleplay'>
                <img src={googleplayimg}  class='community-img'/>
                </div>
        </div>
        <div class='row margintop5'></div>
        <div class='margintop row'>
            <div class="col-5 col-sm-5 col-md-5"></div>
            <div class='margintop ml-1 col-sm-3 col-md-3 col-3'>
                <button class="downloadnow primary_button">Download Now</button>
            </div>
        
        </div>
        
    </div>

    </div>
    <div class='row margintop'>
    <span class='section-name '>Community News</span>
    <div class='margintop ml-5 newscard col-sm-4 col-md-4 col-4'>
    <img src={news1}  class='news1-img'/>
    <div className='margintop5'>
        <span className='news-title '>Business</span>
    </div>
    <div className='margintop1'><span class=" news-sub-title"  style={{fontSize:".81569rem"}}>Grand Diwali Sale</span></div>
    <div className='margintop1'><span class=" news-text" style={{fontSize:".81568rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae ante porttitor, 
    finibus purus eget, varius sem. Integer facilisis posuere aliquet. Ut arcu arcu, tincidunt nec lacus cursus, </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
    </div>
    <div class="margintop5"></div>
</div>
<div class='margintop  newscard ml-3 mr-2 col-4 col-sm-4 col-md-4'>
    <img src={news2}  class='news2-img'/>
    <div className='margintop5'>
        <span className='news-title '>Education</span>
    </div>
    <div className='margintop1'><span class=" news-sub-title"  style={{fontSize:".81569rem"}}>Admission Open For AIIMS/NEET/IIT-JEE Batch  </span></div>
    <div className='margintop1'><span class=" news-text" style={{fontSize:".81568rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae ante porttitor, 
    finibus purus eget, varius sem. Integer facilisis posuere aliquet. Ut arcu arcu, tincidunt nec lacus cursus, </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
<div class="widhdiv"></div>
<span class="sponsered">*Sponsered</span>
    </div>
    <div class="margintop5"></div>
</div>
<div class='margintop  ml-3 newscard col-4 col-sm-4 col-md-4'>
    <img src={news3}  class='news3-img'/>
    <div className='margintop5'>
        <span className='news-title '>Cultural</span>
    </div>
    <div className='margintop1'><span class=" news-sub-title" style={{fontSize:".81569rem"}}>Social Event At XYZ Institute</span></div>
    <div className='margintop1'><span class=" news-text" style={{fontSize:".81568rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae ante porttitor, 
    finibus purus eget, varius sem. Integer facilisis posuere aliquet. Ut arcu arcu, tincidunt nec lacus cursus, </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
    </div>
    <div class="margintop5"></div>
</div>

    </div>

    <div class='row margintop'>
<div class='newsletterimg'>

</div>
    </div>


<div class='row margintop'>
</div>
<div class='row margintop'>
</div>

<FooterTest class="positionfix"/>
</div>
);
    
}
 
export default LandingTest;