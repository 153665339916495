import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import {configData} from "../../Config"


 
const ReferralInvitaionSent=(props)=>{
  
 

    //for session
  //   let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  



      const columns = [
  
    
      {
        name: "Name",
       selector: "name",
        sortable: true,
        

        
    },
    
  {
      name: "Mobile Number",
     selector: "mobileNumber",
      sortable: true,
      className:"texttransformcapitalize"

      
  },
  
  ,{
      name: "Email",
     selector: "emailId",
      sortable: true,
      className:"texttransformcapitalize"


  }
  ,{
    name: "Status",
   selector: "joinStatus",
    sortable: true,
    className:"texttransformcapitalize"


}
    
    ];
   

    // const data = [
    //     {
    //       name: "Sagar Naidu",
    //       mobileno: "65434543",
    //       email: "sagarnaidu26@gmail.com",
    //       status: "Joined",
          

    //     },
    //     {
    //         name: "Sagar Naidu",
    //         mobileno: "65434543",
    //         email: "sagarnaidu26@gmail.com",
    //         status: "Invitation Pending",
            
  
    //       },
          
    //   ]
    


   
    


    

      const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
      console.log("authdata11",authlocalstoragedata)
      
      const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
      const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

      //const [data, setData] = useState([]);
  
      // const tableData = {
      //   columns,
      //   data,
        
      // };
  
       useEffect(() => {
        loadConnects();
       },[]);
    
       const loadConnects = async (value) => {
        const pageSize = value ? value : showRecord;
        if(value !=null)
        {
        //  const result = await axios.get(`/subscriber/getAllInvitationSent?referralCode=${uerAuthReferralCode}`);
         const result = await axios.get(configData.ApiUrl+'/subscriber/getAllInvitationSent?pageNo='+page+'&pageSize='+pageSize+'&referralCode='+uerAuthReferralCode)

         props.setsentReferal(result.data.data);
         console.log("getListOfAllInvitationSent",result.data.data)
        }
        else{
          const result = await axios.get(configData.ApiUrl+'/subscriber/getAllInvitationSent?pageNo='+page+'&pageSize='+pageSize+'&referralCode='+uerAuthReferralCode)

         props.setsentReferal(result.data.data);
         console.log("getListOfAllInvitationSent",result.data.data)
        }
  
       };
  
       const [showRecord, setshowRecord] = useState(10);

       const  handleChanegeShowRec = (e) => {
         debugger;
         const { name, value, type, checked } = e.currentTarget;
      
         setshowRecord( e.currentTarget.value);
         loadConnects(value);

        }
       //for table records per page
       const [recordCount, setrecordCount] = useState(10);
      
      
       //for pagination
      
       const [pageData, setPageData] = useState([]);
      const [page, setPage] = useState(1);
      const [pageCount, setPageCount] = useState(10);
      
      
      // handle next
      const handleNext = () => {
         if (page === pageCount) return page;
         setPage(page + 1)
      }
      
      // handle previous
      const handlePrevios = () => {
         if (page === 1) return page;
         setPage(page - 1)
      }
      
      useEffect(() => {
       loadConnects()
      }, [page])
      
      useEffect(() => {
         const pagedatacount = Math.ceil(props.sentReferal.length / showRecord);
         setPageCount(pagedatacount);
      
         if (page) {
             const LIMIT = showRecord;
             const skip = LIMIT * page // 5 *2 = 10
             const dataskip = props.sentReferal.slice(page === 1 ? 0 : skip - LIMIT, skip);
             setPageData(dataskip)
         }
      }, [props.sentReferal])
      
  
      const handleSearchChange = (e) => {
        debugger
        if (e.currentTarget.value === "") {
          loadConnects();
          return;
        }
        const filterBySearch = props.sentReferal.filter((item) => {
          if (item != null && item.name != null) {
            if (item?.name?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          // if (item != null && item.mobileNumber != null) {
          //   if (item?.mobileNumber?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          // }
          if (item != null && item.emailId != null) {
            if (item?.emailId?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          if (item != null && item.joinStatus != null) {
            if (item?.joinStatus?.toLowerCase().includes(e.currentTarget.value)) { return item; }
          }
          
          // if ( item != null && item.profession != null){
          //   if (item?.profession?.toLowerCase()
          //       .includes(e.currentTarget.value)) { return item; }}
        })
        props.setsentReferal(filterBySearch);
      }
  
   

  return (
    <div className="table-responsive fileexport pos-relative">
    {/* <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available"          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions> */}
       <div>
<div className='row ' >
            <div className='col-2' style={{float:'left'}}>
        <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
                  className="selectdrpdn form-control" style={{height:"32px",marginTop:"4px"}}>
                  <option  value="10">Show 10</option>
                  <option value="25">Show 25</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>

                  </select>
                  </div>
                  <div className='col-8'></div>
                  <div className='form-group col-2'  style={{float:'right'}}>
                  <input
className="form-control"
placeholder="Search..."
type="search"
onChange={handleSearchChange}
style={{height:"32px",marginTop:"4px",fontSize:"12px"}}
/>
                  </div>

        </div>
<table class="table  table-hove">
  <thead>
    <tr> 
    <th scope="col" className="tableroww">Sr.No.</th>    
       <th scope="col" className="tableroww">Name</th>

      <th scope="col" className="tableroww">Mobile Number</th>
      <th scope="col" className="tableroww">Email</th>
      <th scope="col" className="tableroww">Status</th>
      


    </tr>
  </thead>
  <tbody>
   
    {
        pageData.map((data,index)=>( 
            <tr>
            <th scope='' className="tableroww1">{index + 1}</th>
            <td className="tableroww1 capitalizetxxxt">{data.name}</td>
            <td className="tableroww1 capitalizetxxxt">{data.mobileNumber}</td>
            <td className="tableroww1 capitalizetxxxt">{data.emailId}</td>
            <td className="tableroww1 capitalizetxxxt">{data.joinStatus}</td>
            
            

           

            {/* <td>
                <Link className='btn btn-primary mr-2'to= {`/View/${data.id}`}>View</Link>
                <Link className='btn btn--outline-primary mr-2' to= {`/EditUser/${data.id}`}>Edit</Link>
                <Link className='btn btn-outline-danger' onClick={() =>deleteUser(data.id)}>Delete</Link>
            </td> */}
            </tr>


         ))
    } 
  </tbody>
</table>
<div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div>
    </div>
    </div>

           );
}
 
export default ReferralInvitaionSent;