import React from "react";
import "./FooterTest.css";
import logo from '../images/footerimg.svg';
import { Link } from "react-router-dom";

const FooterTest =()=> {
 

    return (
        <div className="footer-main row">
      <div className="row footer-div">
        <div className="margintop paddingleft5 col-3">
            <span class ="footer-heading ">Naidu Directory</span>
            <div className="margintop3">
                <span class='footer-text '>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae ante porttitor, finibus purus eget, varius sem. Integer facilisis posuere aliquet. 
        Ut arcu arcu, tincidunt nec lacus cursus,
                </span>
                </div>
        </div>
        <div className="col-3 margintop">
        <span class="footer-heading ">Community Services</span>
          <div class='margintop3'><span class='margintop1 footer-text '>Community Listing</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Community Matrimonial Services</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Community Business Listing</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Community Women Empowerment</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Community Social Services</span></div>

        </div>
        <div className="col-2 margintop">
        <span class="footer-heading sourcesansfont">We Are Social</span>
        <div class='margintop3'><span class='margintop1 footer-text '>Facebook</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Instagram</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Twitter</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Linkedin</span></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Telegram</span></div>
        </div>
        <div className="col-2 margintop">
        <span class="footer-heading sourcesansfont">Policies</span>
        <div class='margintop3'><Link to="/JoinNowPrivacy"><span class='margintop1 footer-text '>Privacy Policy</span></Link></div>
          <div class='margintop3'><Link to="/JoinNowPrivacy"><span class='margintop1 footer-text '>Data Policy</span></Link></div>
          <div class='margintop3'><Link to="/NaiduSanghamCommunityPledge"><span class='margintop1 footer-text '>Community Pledge</span></Link></div>
          <div class='margintop3'><span class='margintop1 footer-text '>Job Listing Policy</span></div>
          <div class='margintop3'><Link to="/NaiduSanghamTermsConditions"><span class='margintop1 footer-text '>Terms And Conditions</span></Link></div>
        </div>
        <div className="col-2 margintop">
        <span class="footer-heading ">Contact Us</span>
        <div class='margintop3'><span class='margintop1 footer-text '>hello@abccommunity.com</span></div>
        <div class='margintop'><span class='margintop1 footer-text '>+91/7083769760</span></div>
        <span class='margintop1 footer-text '>+91/7083769760</span>
        </div>
</div>
<div class='margintop bordertop textcenter'>
<span> <img src={logo} /></span>
<span class='allrightspn  marginleft5'>All rights reserved @ Naidu Directory Services</span> 
</div>

      </div>
    );
  }
  
  export default FooterTest;
  