import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {Tab,Tabs,Row,Col,Nav,NavItem,Card} from 'react-bootstrap'
import ContactAll from "./ContactAll";
import ContactInvitationsent from "./ContactInvitationsent";
import ContactInvitationRecive from "./ContactInvitationRecive";
import ContactBlock from "./ContactBlock";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {configData} from "../../Config"

 
const Contact=()=>{
  
 
    
    //for session
  //   let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11",authlocalstoragedata)
  
  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [countmenu, setData] = useState([]);
  

     useEffect(() => {
       loadUsers();
     },[countmenu]);
  
     useEffect(()=>{
      loadConnects();}
      ,[]);
     
     const loadUsers = async () => {
       const result = await axios.get(`${configData.ApiUrl}/connection/getCountForConnections?id=${uerAuthId}`);

       setData(result.data.data);
       console.log("countforconnection",result.data.data)

      };

      const [dataAll, setDataAll] = useState([]);

      const loadConnects = async () => {
        debugger;
        const result = await axios.get(configData.ApiUrl+'/connection/getListOfAllConnects?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)
        setDataAll(result.data.data);
       // console.log("getListOfAllConnects",result.data.data)
 
 };
    
  
 //for contact recive

 const [reciveData,setreciveData]=useState([])
 
 useEffect(() => {
  loadReciveConnects();
 },[]);

 const loadReciveConnects = async () => {
   
  const result = await axios.get(configData.ApiUrl+'/connection/getListRecievedContacts?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)

  setreciveData(result.data.data);
   console.log("getListreciveContacts",result.data.data)


 };

 //for sent contact
 const [sentData,setsentData]=useState([])

 useEffect(() => {
  loadSentConnects();
 },[]);

 const loadSentConnects = async () => {
   const result = await axios.get(configData.ApiUrl+'/connection/getListSentContacts?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)

   setsentData(result.data.data);
   console.log("getListSentContacts",result.data.data)


 }; 
   
 //for block contact
 const [blockData,setblockData]=useState([])

 useEffect(() => {
  loadBlokConnects();
 },[]);

 const loadBlokConnects = async () => {
  //  const result = await axios.get(`/connection/getListBlockedContacts?id=${uerAuthId}`);
   const result = await axios.get(configData.ApiUrl+'/connection/getListBlockedContacts?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)

   setblockData(result.data.data);
   console.log("blockcontac",result.data.data)


 };

  return (
    <div >
<Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                      <div className=" bg-gray-100 nav-bg horizontal-tabmenu backcolor-none ">

                      
                        <Nav className="nav nav-tabs backcolor-none ">
                        
                          <Nav.Item>
                            <Nav.Link eventKey="link-29" className="contactnvlink">
                              (All- {countmenu.count1})
                            </Nav.Link>
                          </Nav.Item>
                         
                          <Nav.Item>
                            <Nav.Link eventKey="link-30" className="contactnvlink">
                             (Invitation Sent - {countmenu.count2})
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="link-31" className="contactnvlink">
                            (Invitations Received - {countmenu.count3})
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="link-32" className="contactnvlink">
                            (Blocked - {countmenu.count4})
                            </Nav.Link>
                          </Nav.Item>
                          
                        </Nav>
 

                      </div>

                      <Tab.Content className=" ">
                        <Tab.Pane eventKey="link-29">
                        <div className="">
                          <ContactAll dataAll={dataAll} setDataAll={setDataAll}/>
                        </div>                  
                       </Tab.Pane>
                        <Tab.Pane eventKey="link-30">
                          <div>
                            <ContactInvitationsent sentData={sentData} setsentData={setsentData}/>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-31">
                          <div>
                            <ContactInvitationRecive dataAll={dataAll} setDataAll={setDataAll} reciveData={reciveData} setreciveData={setreciveData}/>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-32">
                          <div>
                            <ContactBlock blockData={blockData} setblockData={setblockData}/>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
    </div>

           );
}
 
export default Contact;