
import React, { useState, useEffect} from "react";
import './LandingTest.css';
import {  Link } from 'react-router-dom'

import {FaWhatsapp,FaArrowRight} from 'react-icons/fa'
 import logo from '../images/Naidus directory.png';
 import community from '../images/community.svg';
 import googleplayimg from '../images/googleplay.jpeg';
 import news1 from '../images/news1.svg';
 import news2 from '../images/news2.png';
 import news3 from '../images/news3.png';
 import Diwali from '../images/Diwali.png';
 import Dussehera from '../images/Dussehra.png';
 import andalur from '../images/andalur kavu .png';

 import firstcard from '../images/firstcard.png';
 import secondcard from '../images/secondcard.png';
 import wedding from '../images/wedding.png';
 import sisthcard from '../images/sisthcard.png';
 import fourthcard from '../images/fourtgcard.png';
 import coffee from '../images/cofee.png';
import Footer from './Footer';
import { useHistory } from "react-router-dom";




const LandingPage =()=>{

    // const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    //  console.log("authdata11landingjs",authlocalstoragedata)
    
    //  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    let history = useHistory();

    const redirect = () => { 
        history.push('/JoinNow/null' );

     }
     const redirectLogin = () => { 
        history.push('/Login' );

     }
  
    //  const redirecToLandingdata =  () => {
 
    //     if(authlocalstoragedata?.kycStatus !=null &&  authlocalstoragedata?.kycStatus !=''){
    //       history.push("/UserLanding");
    
    //     }
    //   }
         
    //      useEffect(() => {
    //       redirecToLandingdata();
    //    });
    return (
<div class='container-fluid'>
{/* <div class='row toprow'>
<div class='  textright col-lg-3 col-sm-12 col-md-12'>
    <span className=' straighline'>|</span><span class='contactspn marginleft5 opensansfont'>Contact Us: 91/9923474723</span>
    <FaWhatsapp class='marginleft5 margintpmins' color='#B5924C'size="1.5em"/>  
    <span className='straighline marginleft5'>|</span>
</div>

<div class='col-lg-2 col-sm-12 col-md-12  textleft mr-1'>
    <span class=' contactspn  opensansfont'>Email: contact@gmail.com</span>
    <span className='straighline marginleft5'>|</span>
    </div>

</div> */}
<div className="row toprow">
    <div className="col-lg-12 col-md-12 col-sm-12">
    <span className='straighline'>|</span>
    <span class='contactspn marginleft5 marginleft7pxforresponsive'>Contact Us: +91/9823423424</span>
    <FaWhatsapp class='marginleft5 margintpmins' color='#B5924C'size="1.2em"/>
    <span className='straighline marginleft5'>|</span>
    <span class='contactspn marginleft5 marginleft7pxforresponsive responsivdispblock' >Email: info@naidudirectory.org <span className='straighline '>|</span></span>
    {/* <span className='straighline marginleft5'></span> */}


    </div>

</div>
<div className='row logodiv'>
    <div className=' col-lg-2 col-sm-6 col-md-12'>
    <img src={logo}  style={{width:"190px", height:"77px"}}/>
    </div>
    <div className='col-lg-2 col-sm-6 col-md-12'>
        {/* <span className='merriweatherfont community-name'>Naidu Directory</span> */}
    </div>
    <div className='col-lg-5 '></div>
    <div className='col-lg-2 col-sm-3 col-md-6 marginresltop'>
 {/* <button className='joinowbtn primary_button joinnowresbtn' onClick={redirect}>JOIN NOW</button>  */}
{/* <Link to="/sitemap_index.xml"><button className='joinowbtn primary_button joinnowresbtn' >JOIN NOW</button></Link> */}

    </div>
    <div className='col-lg-1 col-sm-3 col-md-6 textleft '>
        {/* <span className='signin signinres' onClick={redirectLogin} style={{cursor:"pointer"}}>SIGN IN</span> */}
    </div>


</div>

<div className='landingrow row'>
    <div className='backgimg' >
        <div className='row'>
            <div class='col-lg-5 '></div>
            <div class='col-lg-1 col-sm-12 col-md-12 textright'>
                <a href='/'><span className=' kowmor'>Know More</span></a>
            </div>
            <div class='col-lg-2 col-sm-12 col-md-13 textleft'>
            <button className='join-the-community primary_button' onClick={redirect}>Join The Community</button>
            </div>

        </div>
    </div>
</div>

<div class='row margintop'>
    <span class='section-name merriweatherfont'>Revolutionary Directory</span>
{/* <div className='col-lg-1 col-sm-12 col-md-12 margintop'></div> */}
    <div class='col-lg-3 col-sm-12 col-md-12 margintop ml-5 marginleft7pxforresponsive mr-5 margintop5 maxwidthcard'>

        <div class='row cardrow'>
        <span className='card-title  marginleft5'>Know Your Community</span>

            <div class='cardimg-div  col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border'>
                <img src={firstcard} class='card-img'/>
               </div>
        </div>
        <div class='col-lg-1 col-sm-1 col-md-1'></div>
            <div class='col-lg-10 col-sm-10 col-md-10 textleft' style={{paddingBottom:'1px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className=' cart-text'>
                    "Celebrate Excellence with 'Know Your Community' - Discover and honor the achievements of remarkable personalities, from history to today, within the Naidu community. Our feature showcases their inspiring journeys, contributions, and lasting legacies. 
                    Unite with our rich heritage and the impactful individuals who have shaped it."
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>
    <div class='col-lg-3 col-sm-12  col-md-12 ml-5 mr-5 marginleft7pxforresponsive margintop5 maxwidthcard'>
        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5'>Community Members in your radius  </span>

            <div class='cardimg-div-blue col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border-blue'>
                <img src={secondcard} class='card-img'/>
               </div>
        </div>
        <div class='col-lg-1 col-sm-1 col-md-1 '></div>
            <div class='col-lg-10 col-sm-10 col-md-10 textleft' style={{paddingBottom:'1px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className='cart-text '>
                    The "Community Members in Your Radius" feature in Naidu Directory is a handy tool to help you discover how many fellow community members are in your vicinity. This feature enhances your sense of belonging and connection, providing you with valuable insights into the local presence of our community.
                     It's all about bringing your community closer to home.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>    

    <div class='col-lg-3 col-md-12  col-sm-12  ml-5 marginleft7pxforresponsive maxwidthcard margintop5'>
        <div class='row cardrow'>
        <span className='card-title  marginleft5'> Meet Ups over coffee  </span>

            <div class='cardimg-div col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border'>
                <img src={coffee}  class='whitecolor card-img'/>
               </div>
        </div>
        <div class='col-lg-1 col-sm-1 col-md-1' ></div>
            <div class='col-lg-10  col-md-10 col-sm-10 textleft paddingbotomRes1' style={{paddingBottom:'73px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className=' cart-text'>
                    Meet Ups Over Coffee: Beyond Virtual Connections. Take your interactions to the next level by inviting fellow community members for a coffee meet-up. It's a chance to connect in person,
                     get to know one another, and strengthen community bonds.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>   
      <div className='col-lg-1'></div>

</div>

<div class='row  margintop'>
{/* <div className='col-lg-1 '></div> */}
    <div class='col-lg-3 col-sm-12 col-md-12 ml-5 marginleft7pxforresponsive mr-5  maxwidthcard'>

        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5'>Community job postings </span>

            <div class='cardimg-div-blue  col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border-blue'>
                <img src={fourthcard} class='card-img'/>
               </div>
        </div>
        <div class='col-lg-1'></div>
            <div class='col-lg-10 col-md-10 col-sm-10 textleft' style={{paddingBottom:'61px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className='cart-text '>
                    Community Job Postings: Connecting Businesses and Job Seekers. Business owners can post job openings, ensuring that the opportunities first reach our community members in need.
                     It's a platform that prioritizes the Naidu community's professional growth.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>
    <div class='col-lg-3 col-md-12 col-sm-12 ml-5 mr-5 marginleft7pxforresponsive   maxwidthcard'>
        <div class='row cardrow'>
        <span className='card-title  marginleft5'>Community Business Directory  </span>

            <div class='cardimg-div col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border'>
                <img src={sisthcard} class='card-img'/>
               </div>
        </div>
        <div class='col-lg-1 col-md-1 col-sm-1'></div>
            <div class='col-lg-10 col-sm-10 col-md-10 paddingbotomRes2 textleft' style={{paddingBottom:'61px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className=' cart-text'>
                    Community Business Directory: Showcasing Naidu Entrepreneurship. This feature empowers community entrepreneurs and business owners to highlight their businesses on our platform. 
                    It's a spotlight for Naidu enterprises within the community.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>    

    <div class='col-lg-3 col-sm-12 col-md-12 ml-5 marginleft7pxforresponsive  maxwidthcard'>
        <div class='row cardrow-blue'>
        <span className='card-title  marginleft5'>Community Matrimony</span>

            <div class='cardimg-div-blue col-lg-2 col-sm-2 col-md-2'>
                <div className='img-border-blue'>
                <img src={wedding} class='card-img'/>
               </div>
        </div>
        <div class='col-lg-1 '></div>
            <div class='col-lg-10 col-md-10 col-sm-10 paddingbotomRes textleft' style={{paddingBottom:'134px'}}>
                <div className='marginleft20pxforresponsive'>
                    <span className=' cart-text'>
                    Community Matrimony: Exclusively for the Naidu Community. Find your life partner within our close-knit community,
                     where tradition meets modern matchmaking.
                    </span>
                    </div>
                    <div className='margintop'></div>
            </div>
        </div>
    </div>   
      <div className='col-lg-1 '></div>

</div>
<div class="row margintop5"></div>
<div class='margintop borderbottom'></div>

<div class='row margintop'>
    <span class='section-name '>Empowering Unity, Celebrating Heritage: Naidu Directory </span>
    <div class='margintop   col-lg-6 col-sm-12 col-md-12'>
    <img src={community}  class='community-img widtheightforresponsiv' style={{width:"-webkit-fill-available",height:"571px"}}/>

    </div>
    <div class='margintop  col-sm-6 col-md-6  col-lg-6'>
    <span class='xyz-community'> </span>
    <div class=' margintop5'>
        <div>
        <span className="event-sub-detail" style={{fontSize:"16px"}}>
        Welcome to Naidu Directory          
         </span>
         </div> 
<span className="event-sub-detail1 margintop1" style={{fontSize:"16px"}}>
At Naidu Directory, we take pride in nurturing and strengthening the bonds of our vibrant Naidu community. 
Our platform is designed to serve as a dynamic hub for community members, where you can connect, grow,
 and celebrate our shared heritage.
</span>
<div className="margintop1"><span className="event-sub-detail" style={{fontSize:"16px"}}>Our Mission</span></div>
<span className="event-sub-detail1 margintop1" style={{fontSize:"16px"}}>Our mission is to foster unity, promote opportunities, 
and celebrate the rich cultural heritage of the Naidu community. We strive to create a supportive environment 
where members can network, showcase their talents, and find valuable resources to thrive personally and professionally.</span>


<div className="margintop1"><span className="event-sub-detail" style={{fontSize:"16px"}}>What We Offer</span></div>

<span className="event-sub-detail1 margintop1" style={{fontSize:"16px"}}>From a comprehensive community directory and exclusive matrimony services to job postings and business listings,
 Naidu Directory provides a wide range of features tailored to the needs and aspirations of our diverse community members. Our goal is to empower every individual within our community to succeed and prosper.</span>

 <div className="margintop1"><span className="event-sub-detail" style={{fontSize:"16px"}}>Join Us</span></div>

 <span className="event-sub-detail1 margintop1" style={{fontSize:"16px"}}>Become a part of the Naidu Directory community and embark on a journey of connection, 
 growth, and celebration. Together, let's build a stronger,
  more connected community for a brighter future.
        </span>
    </div>
    <div class='margintop'>
   </div>
    <div class='btn-div textcenter'>
        <button class='know-more  primary_button'>Know More</button>
    </div>
  </div>
  </div>

    <div class='row margintop'>
    <span class='section-name '>Socializing Simplified</span>
    <div class='margintop  googleplay-div'>
        <div class='row margintop5'>
            <div class='col-lg-2 col-sm-2 col-md-2'></div>
            <div class='margintop  margtop10 textcenter col-sm-8 col-md-8 col-lg-8'>
                <span class=" downloadapptxt ">Download the Naidu Directory Global Community App</span>
             </div>

        </div>
        <div className='margintop1 MARGINTOPRES row'>
            <div class="col-lg-4 col-sm-2 col-md-2"></div>
        <div className='margintop col-lg-4 col-md-6 col-md-6 ml-1 textcenter getiton-googleplay'>
                {/* <img src={googleplayimg}  class='communitygoogleplay-img'/> */}
                </div>
        </div>
        <div class='row margintop MARGINTOPRES'></div>
        <div class='margintop MARGINTOPRES row'>
            <div class="col-lg-5 col-sm-5 col-md-5"></div>
            <div class='margintop MARGINTOPRES ml-1 col-sm-3 col-md-3 col-lg-3'>
                <button class="downloadnow primary_button downladres">Download Now</button>
            </div>
        
        </div>
        
    </div>

    </div>
    <br/>
    <div class='row margintop'>

    <span class='section-name '>Community News & Events</span>
    <div class='margintop   col-lg-1'></div>

    <div class='margintop  newscard col-sm-12 col-md-12 col-lg-3'>
    <img src={Dussehera}  class='dussraimg' height={300} width={370} style={{marginLeft:"-14px"}}/>
    {/* <div className='margintop5'>
        <span className='news-title '>Business</span>
    </div> */}
    <div className='margintop1'><span class=" news-sub-title">Celebrate the triumph of good over evil with Naidu Directory this Dussehra!</span></div>
    <div className='margintop1'><span class=" news-text">Join us for a joyous virtual gathering filled with festive spirit, cultural performances, and shared traditions.
     Let's unite and revel in the spirit of positivity and harmony. Happy Dussehra from Naidu Directory! </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
    </div>
    <div class="margintop5"></div>
</div>
<div class='margintop  newscard ml-3 mr-2 col-lg-3 col-sm-12 col-md-12'>
    <img src={Diwali}  class='dussraimg' height={300} width={371} style={{marginLeft:"-14px"}}/>
    {/* <div className='margintop5'>
        <span className='news-title '>Education</span>
    </div> */}
    <div className='margintop1'><span class=" news-sub-title">Light up your Diwali with Naidu Directory! </span></div>
    <div className='margintop1'><span class=" news-text">Join us for a virtual celebration filled with joy, togetherness, and cultural festivities. Let's illuminate our spirits and cherish the traditions that bind us together.
     Wishing you a sparkling and joyous Diwali from Naidu Directory! </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
<div class=""style={{width:"140px"}}></div>
{/* <span class="sponsered" style={{float:"right"}}>*Sponsered</span> */}
    </div>
    <div class="margintop5"></div>
</div>
<div class='margintop ml-2  newscard col-lg-3 col-sm-12 col-md-12'>
    <img src={andalur}  class='dussraimg' height={300} width={371} style={{marginLeft:"-14px"}}/>
    {/* <div className='margintop5'>
        <span className='news-title'>Cultural</span>
    </div> */}
    <div className='margintop1'><span class=" news-sub-title">Embrace the divine fervor at Andalur Kavu Temple Festival</span></div>
    <div className='margintop1'><span class=" news-text">Join us for a sacred celebration filled with rituals, cultural performances, and spiritual unity. Let's honour tradition and bask in the blessings of this auspicious occasion. 
    Wishing you a blessed and joyous Andalur Kavu Temple Festival from Naidu Directory! </span></div>
    <div className='margintop1 news-readmor'>
        <a href='/'><span className='news-readmor '>Read More</span><FaArrowRight size="1.2em" className='arrowright' color='#FF8427'/> 
</a>
    </div>
    <div class="margintop5"></div>
</div>

    </div>

    <div class='row margintop margtop10'>
<div class= 'col-lg-12 col-md-12 col-sm-12 newsletterimg'>
<div className="row margintop margtop10"></div>
<div className="row margintop margtop10"></div>
<div className="row margintop margtop10"></div>
<div className="row margintop margtop10"></div>

<div className="row margintop margtop10"></div>
<div className="row margintop margtop10"></div>

<div className="row margintop margtop10">
    <div className="col-lg-5"></div>
    <div class= 'col-lg-4 col-md-12 col-sm-12 margintop margtop10'>
    <button className="subscribenewsleterbtn margintop">Subscribe Now</button>

    </div>
</div>

</div>
    </div>


<div class='row margintop'>
</div>
<div class='row margintop'>
</div>

<Footer class="positionfix"/>
</div>
);
    
}
 
export default LandingPage;