import './ReferCommMemberPopup.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import React, { useState, useEffect,useRef} from "react";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Countrycode } from '../JoinNow/Countrycode';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"
import '../../Component/UserLandingPages/AccountClosureMatrimony.css'
import logo from '../../images/Naidus directory.png';
import CoupleIMG from '../../images/Couple image.png';
import {  Link } from 'react-router-dom'
import { GiDiamondRing } from "react-icons/gi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import imgupload from '../../images/imageupload.svg'
import gif from '../../images/w6mVOk6ft2.gif';
import gifchechmark from '../../images/RHPdUgZL1T.gif'



 
const MatrimonyClosurePpup = props => {

  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
    const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);

    const [storyData,setstoryData]= useState({
      coupleNames:null,
      story:null,
      engagementDate:null
      })
    
    
    const [matrimonyClosure, setmatrimonyClosure]= useState({
      
      matrimonyId:authlocalstoragedata?.matrimonyId,
      closingReason:"",
      source:"",
      successStory:{storyData},
     
    });
    const [error, setError] = useState(false);

    const submitFormData = (e) => {
      debugger
      e.preventDefault();
  
      // checking if value of first name and last name is empty show error else take to step 2
      if (matrimonyClosure.closingReason==null) {
          setError(true);
      }
      
      else if (storyData.coupleNames==""||storyData.coupleNames==null) {
        setError(true);
      }
      else if (storyData.story==""||storyData.story==null) {
        setError(true);
      }
      
      else if (attachmentMatrimony == "" || attachmentMatrimony == null) {
        setError(true);
  
      }
  
    
     
      
  else {
        debugger
        closeMatrimonyAcc()
      }
  };
 
  const closeMatrimonyAcc =()=>{
      const formData = new FormData();
    debugger
    matrimonyClosure.successStory= storyData;
   // setAttachmentMatrimony(attachmentMatrimony);
     formData.append('closure',JSON.stringify(matrimonyClosure));
     
       formData.append('photoOne', attachmentMatrimony);
     // formData.append('photo1', attachment.photo1);
     

const response = axios.post(configData.ApiUrl+'/matrimony/closeMatrimonyProfile',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
}

)
     response.then(() => {
        //toast.success("Data  Successfully!");
        if(matrimonyClosure.source=="Fulfiled outside Community App"){
          setskipPopup(false); 
          setdetailsSuccessStory(false); 
          setShowSuccessStory(false)// Show success story section
          setFoundMatchDiv(false);
          setfirstDiv(false);
          setsucessUploadStoryPopup(false);
          setsuccesDeletPopup(false);
          setfulfiledoutsidesuccesspop(true)
        }
        else{
          handleSuccessUploaddetailStory();
        }
      })
      response.catch((err) => {
       alert("Getting error in featching data");

      });
   
  }

  //for skip story
  const [errorSkipMat, seterrorSkipMat] = useState(false);

    const submitFormDataSkip = (e) => {
      debugger
      e.preventDefault();
  
      // checking if value of first name and last name is empty show error else take to step 2
      if (matrimonyClosure.closingReason==null) {
          setError(true);
      }
      
      else {
        debugger
        closeMatrimonyAccSkip()
      }
  };
 
  const closeMatrimonyAccSkip =()=>{
      const formData = new FormData();
    debugger
    matrimonyClosure.successStory= storyData;
   // setAttachmentMatrimony(attachmentMatrimony);
     formData.append('closure',JSON.stringify(matrimonyClosure));
     
       //formData.append('photoOne', attachmentMatrimony);
     // formData.append('photo1', attachment.photo1);
     

const response = axios.post(configData.ApiUrl+'/matrimony/closeMatrimonyProfile',formData,{
  headers: {
    'content-type': 'multipart/form-data'
}
}

)
     response.then(() => {
       // toast.success("Data  Successfully!");
       handleSkipStory();
      })
      response.catch((err) => {
       alert("Getting error in featching data");

      });
   
  }
  //for no longer looking for
  

const closeMatrimonyAccNolongerLooking =()=>{
  debugger;
    const formData = new FormData();
 
    if (matrimonyClosure.closingReason==null) {
      setError(true);
  }
  else{
   formData.append('closure',JSON.stringify(matrimonyClosure));
   

const response = axios.post(configData.ApiUrl+'/matrimony/closeMatrimonyProfile',formData,{
headers: {
  'content-type': 'multipart/form-data'
}
}

)
   response.then(() => {
     // toast.success("Data  Successfully!");
     setsuccesDeletPopup(true);
    })
    response.catch((err) => {
     alert("Getting error in featching data");

    });
  }
 
}

    //for close popup when click on outside
  const popupSearchRef = useRef(null);
  const handleClickOutsideSearchdiv = (event) => {
    if (popupSearchRef.current && !popupSearchRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSearchdiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSearchdiv);
    };
  }, []);

  //for close popup when click on outside
  const popupFoundRef = useRef(null);
  const handleClickOutsideFounddiv = (event) => {
    if (popupFoundRef.current && !popupFoundRef.current.contains(event.target)) {
      props.handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFounddiv);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFounddiv);
    };
  }, []);
  const [firstDiv, setfirstDiv] = useState(true);
  const [sucessUploadStoryPopup, setsucessUploadStoryPopup] = useState(false);
  const [succesDeletPopup, setsuccesDeletPopup] = useState(false);
  const [skipPopup, setskipPopup] = useState(false);
  const [fulfiledoutsidesuccesspop, setfulfiledoutsidesuccesspop] = useState(false);

  const handlefulfiloutsidesuccpop = (e) => {
    debugger
    setmatrimonyClosure(preState => ({
      ...preState,
      [e.target.name]:  e.target.value
  }));
  matrimonyClosure.source=e.target.value
  closeMatrimonyAcc();
};
  

  const [showSuccessStory, setShowSuccessStory] = useState(false); // State to toggle views

    const handleCreateStory = (e) => {
debugger;
      const { name, value, type, checked } = e.target.value;
 setmatrimonyClosure(preState => ({
      ...preState,
      [e.target.name]: type === 'checkbox' ? checked : e.target.value
  }));

        setShowSuccessStory(true); 
        setdetailsSuccessStory(false);
        setfirstDiv(false) // Show success story section
        setsucessUploadStoryPopup(false)
    };

    const handleSkipStory = () => {
      setskipPopup(true); 
      setdetailsSuccessStory(false); 
      setShowSuccessStory(false)// Show success story section
      setFoundMatchDiv(false);
      setfirstDiv(false);
      setsucessUploadStoryPopup(false);
      setsuccesDeletPopup(false)
      
  };
    const [detailsSuccessStory, setdetailsSuccessStory] = useState(false); // State to toggle views

    const handleCreatedetailStory = () => {
      setdetailsSuccessStory(true); 
      setShowSuccessStory(false)// Show success story section
      setFoundMatchDiv(false);
      setfirstDiv(false);
      setsucessUploadStoryPopup(false);
      setsuccesDeletPopup(false)

    };
    const handleSuccessUploaddetailStory = () => {
      // setdetailsSuccessStory(false); 
      setShowSuccessStory(false)// Show success story section
      setFoundMatchDiv(false);
      setfirstDiv(false);
      setsucessUploadStoryPopup(true);
      setdetailsSuccessStory(false)
      setsuccesDeletPopup(false)

    };
    // const handleSuccessDeletePopup = () => {
    //   setdetailsSuccessStory(false); 
    //   setShowSuccessStory(false)// Show success story section
    //   setFoundMatchDiv(false);
    //   setfirstDiv(false);
    //   setsucessUploadStoryPopup(false);
    //   setsuccesDeletPopup(true)

    // };
    //for found match
    const [foundMatchDiv, setFoundMatchDiv] = useState(false); // Control visibility of found match div
// Handle radio button changes
const handleRadioChange = (e) => {
debugger;
  const { name, value, type, checked } = e.target.value;
 setmatrimonyClosure(preState => ({
      ...preState,
      [e.target.name]: type === 'checkbox' ? checked : e.target.value
  }));

  if (e.target.value === "Found a match") {
      setFoundMatchDiv(true); // Show the found match div
  } else {
      setFoundMatchDiv(false); // Hide the found match div
  }
  
};

const handleRadionoLongerLookChange = (e) => {
  const { name, value, type, checked } = e.target.value;
  setmatrimonyClosure(preState => ({
       ...preState,
       [e.target.name]: type === 'checkbox' ? checked : e.target.value
   }));

   matrimonyClosure.closingReason = e.target.value;
  if (e.target.value === "No longer looking for a partner") {
    //setsuccesDeletPopup(true); // Show the found match div
    closeMatrimonyAccNolongerLooking();
    setShowSuccessStory(false)// Show success story section
      setFoundMatchDiv(false);
      setfirstDiv(false);
      setsucessUploadStoryPopup(false);
      setdetailsSuccessStory(false)
  } else {
    setsuccesDeletPopup(false); // Hide the found match div
  }
};


const handleNextClick = () => {

debugger
 // setAttachmentMatrimony(attachmentMatrimony);
 const formData = new FormData();

   formData.append('closure',JSON.stringify(matrimonyClosure));
   
   const response = axios.post(configData.ApiUrl+'/matrimony/closeMatrimonyProfile',formData,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    }
    )
       response.then(() => {
        setsuccesDeletPopup(true);  // Show the success message div
        setShowSuccessStory(false)// Show success story section
        setFoundMatchDiv(false);
        setfirstDiv(false);
        setsucessUploadStoryPopup(false);
        setdetailsSuccessStory(false)
        })
        response.catch((err) => {
         alert("Getting error in featching data");
    
        });


  
};

const [isOthersSelected, setIsOthersSelected] = useState(false); // For showing the textbox and next button
const [showSuccessMessage, setShowSuccessMessage] = useState(false); // For showing success message div

const handleotherRadioChange = (e) => {
  if (e.target.value === "Others") {
    setIsOthersSelected(true); // Show textbox and next button for "Others"
    setFoundMatchDiv(false);   // Hide any other match divs if needed
  } else {
    setIsOthersSelected(false); // Hide textbox and next button
  }
};

const [startDate, setStartDate] = useState(new Date());

const handleChangedate= (e) => {
  debugger
 setStartDate(e)
//  setEventData(preState => ({
//   ...preState,
//   "eventDate": (moment(e).format("DD MMM yyyy")),

// }))
}
//for upload image

const [imgDataclseure, setImgDataclseure] = useState(null);
      const fileref = useRef(null);
      

      const [attachmentMatrimony, setAttachmentMatrimony] = useState(null)
const banerImageChange = event=>{

  // if(event.currentTarget.files != null)
  //    {
  //      setAttachment(event.target.files[0]);
  //    }
  debugger
  if (event.currentTarget.files[0]) {
    console.log("picture: ", event.target.files);
    setAttachmentMatrimony(event.currentTarget.files[0]);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImgDataclseure(reader.result);
    });
    reader.readAsDataURL(event.currentTarget.files[0]);
  }
}

const [checkboxAgree,setcheckboxAgree]= useState({
  agree:""
})
const handlechangeCheckbox =(e)=>{

  const {name,value,type,checked}=e.currentTarget;
  setcheckboxAgree(preState =>({
    ...preState,
    [name]:type ==='checkbox'?checked: value
  }))
}
const handleChange = (e) => {
  debugger;
  const { name, value, type, checked } = e.currentTarget;
 setmatrimonyClosure(preState => ({
      ...preState,
      [name]: type === 'checkbox' ? checked : value
  }));
  
  
}



const handleChangeSuccessStory=(e)=>{
  debugger
  const { name, value, type, checked } = e.currentTarget;

setstoryData(preState => ({
    ...preState,
    [name]: type === 'checkbox' ? checked : value
}));

}

  return (
    <div>
    <div className={sucessUploadStoryPopup ||fulfiledoutsidesuccesspop||succesDeletPopup||skipPopup?"hide":"popup-boxrefmember"}>
      <div className="boxrefmember" ref={popupSearchRef} style={{marginTop:"170px"}}>
        <div className="topnewsrow ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="blood-top-text"> Community Matrimony </span>
                
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer"}}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
        <div className={firstDiv?"":"hide"} style={{overflowY:"scroll",height:"auto"}}>
          
        <div className="row margintop3">
              <div className="col-lg-12   ">
                <span className='event-sub-detail1' style={{fontSize:"px",color:""}}>
                By closing your account, you will lose access to all your connections and messages within the community directory.
                Please select a reason for closing your account              </span>
              </div>
              </div>
              <div className='row '>
                <div className=''></div>
                <div className='col-12'>
                <div className='row margintop matrimonyborfercloser '>
          
           <div className='col-7'>
           <GiDiamondRing/> <span className='event-sub-detail1' style={{fontSize:"px",color:""}}>
            Community Matrimony             
                </span>
           </div>
           <div className='col-4'></div>

           <div className='col-1'>
            <IoIosCheckmarkCircle color='#CF6B20' size="1.2em"/>
           </div>
              </div>
                </div>

                
              </div>
              
              <div className="row margintop">
              <div className="col-lg-12   ">
                <span className='event-sub-detail' style={{fontSize:"14px",color:""}}>
                Reason for Closure  </span>
                <div>
                <span className='event-sub-detail1' style={{fontSize:"px",color:"",fontStyle:"italic"}}>Please let us know why you're closing your account. </span>
                  </div>

              </div>
              </div>
          <div className="row   margintop3">

<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"  onChange={handleRadioChange} style={{fontSize:'13px'}}>Found a match
<input type="radio" id="defaultRadio1" className=' radiobtn'   name="closingReason"   value="Found a match"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} onChange={handleRadionoLongerLookChange} >No longer looking for a partner
<input type="radio" id="defaultRadio2" className=' radiobtn'   name="closingReason"    value="No longer looking for a partner"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 textleft">

<label class="radiodivcust radiobtn-text1" style={{fontSize:'13px'}} onChange={handleotherRadioChange}>Others
<input type="radio" id="defaultRadio3" className=' radiobtn'  value="Others"  name="closingReason"  />  

                <span class="checkmark"></span>
           </label>
           {isOthersSelected && (<div style={{paddingBottom:"15px",marginTop:"10px"}}>
  <div className="others-section margintop1">
    <input type="text" className="form-control" name="closingReason" onChange={handleChange} placeholder="Please tell us your reason for closing account." style={{fontSize: '13px', marginTop: '10px'}} />
  </div>
  <div className='row'>
    <div className='col-3'></div>
    <div className='col-6'>  <button className="cancelbtn" onClick={handleNextClick} style={{height:"38px",fontSize:"13px", marginTop: '18px'}}>Next</button>
    </div>
    </div>

  </div>
)}
</div>

         
         </div>
         <div className={foundMatchDiv ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"27%",marginLeft:"616px",paddingBottom:"20px"}}>
        
<div style={{overflowY:"",height:"80px"}}>
<div className="row margintop">
              <div className="col-lg-12   ">
                <span className='event-sub-detail' style={{fontSize:"14px",color:""}}>
                Select an option to complete the requirement  </span>
                
              </div>
              </div>
              <div className="row   margintop3">
              <div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1 " onChange={handleCreateStory}  style={{fontSize:'13px'}}>Fulfiled within Community App
<input type="radio" id="defaultRadio1" className=' radiobtn' name="source"    value="Fulfilled within community"/>  

                <span class="checkmark"></span>
           </label>
</div>
<div className="col-12 margintop1 ">

<label class="radiodivcust radiobtn-text1"style={{fontSize:'13px',fontWeight:"500"}} onChange={handlefulfiloutsidesuccpop}>Fulfiled outside Community App
<input type="radio" id="defaultRadio2" className=' radiobtn' name="source"    value="Fulfilled outsite community"/>  

                <span class="checkmark"></span>
           </label>
</div>
</div>
    
   
</div>

          <div className='row margintop' style={{paddingBottom:"5px",paddingTop:"5px"}}>
                <div className='col-4'></div>
                <div className='col-3 margintop1'>
                    <button className='cancelbtn' onClick={() => setFoundMatchDiv(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Close</button>
                </div>
                

              </div>
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
        {/* )} */}
        </div>

        <div className={showSuccessStory?"":"hide"}>
        <div style={{overflowY:"scroll",height:"370px"}}>

<div className="row ">
                  <div className="col-lg-1"></div>
                  {/* <div className="col-lg-1 col-sm-12 col-md-4">           
                  <Link to='/LandingPage'><img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                    </div> */}
                  <div className="col-lg-6 col-md-7 col-sm-12">
                  {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                  </div>
  
              </div>
              <div className="row margintop3">
              <div className="col-lg-12  txtaligncenter ">
                <span style={{fontSize:"15px"}}>
              Your success story can look just like this! share your journey and inspire other.
              </span>
              </div>
              </div>
             <div className="row margintop3">
              <div className="col-lg-12  txtaligncenter bgimg">
                <span style={{fontSize:"15px"}}>
              </span>
              <div className='txtaligncenter margintop ' style={{marginTop:"40px"}}>
              <img src={CoupleIMG} width="141"/>
              <br></br>
              <span className='namespan'>
              Rajesh & Priya
              </span>
              <br></br>
              <span className='spantxt' style={{fontWeight:"bold"}}>
              Engagement Date - July 15, 2024
              </span>
              <br></br>
              <br></br>
              <div className='row'>
                <div className='col-2'></div>
                <div className='col-8' style={{marginLeft:"3px"}}>
                <span className='spantxt' >
             We found each other through the community app, and our connection deepened quickly. Soon after, we realized we were meant to be,
             leading to our engagement and the start of a beautiful journey together!
              </span>
                </div>

              
              </div>
             
              </div>
              </div>
  
             </div>
    
    
    
                  </div>
                  <div className='popupbtn-div margintop3'>

<div className='row '>
      <div className='col-1'></div>
      <div className='col-5'>
          <button className='cancelbtn'   style={{width:'200px',height:"42px",fontSize:"13px"}} onClick={submitFormDataSkip} >Skip</button>
      </div>
      <div className='col-6'>
      <button className='createeventbtn'  style={{width:'210px',height:"42px",fontSize:"13px",marginLeft:"5px"}} onClick={handleCreatedetailStory}>Create Your Success Story</button>
    </div>

    </div>
    </div>
  </div> 

<div className={detailsSuccessStory ?"":"hide"}>
<div style={{overflowY:"scroll",height:"370px"}}>

<div className="row margintop1">
                  
                  <div className="col-lg-12 col-md-7 textcenter col-sm-12">
                  <span className='event-sub-detail1' style={{fontSize:"px",color:"",fontStyle:"italic"}}>Share your success story. </span>

                  </div>
  
              </div>
              <div className="row margintop1">
              <div className="col-lg-12  txtaligncenter ">
                <span style={{fontSize:"14px", fontStyle:"italic"}}>
             We'd love to hear about your journey and feature your story to inspire others in our Community.
              </span>
              </div>
              </div>
              <div className="margintop3 textleft row">
  <div className="col-10 textleft ">
  <span for="inputState" className="event-sub-detail1 " style={{fontSize:"13px"}}>Couple Names</span>
  </div>
  </div>
    <div className="margintop1 row">
  
  <div className="col-10 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="coupleNames"
         onChange={handleChangeSuccessStory}
         placeholder="e.g. Sagar & Priya"
         style={{fontSize:"12px",height:'35px'}}
    />
     <div className="margtop1 nowrapcss">
                                    {error && storyData.coupleNames == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Couple Names is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div> 
  </div>
  
 </div>
 <div className=" textleft row">
  <div className="col-10 textleft ">
  <span for="inputState" className="event-sub-detail1 " style={{fontSize:"13px"}}>Your Story</span>
  </div>
  </div>
    <div className="margintop1 row">
  
  <div className="col-10 textleft form-group">
  <textarea class="form-control " name='story' id="exampleFormControlTextarea1" rows="3" placeholder="How was your experience on Community Matrimony "
      onChange={handleChangeSuccessStory} style={{fontSize:"12px"}}  />
     <div className="margtop1 nowrapcss">
                                    {error && storyData.story == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Story content is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div> 
  </div>
  
 </div>
 <div className=" textleft row">
  <div className="col-10 textleft ">
  <span for="inputState" className="event-sub-detail1 " style={{fontSize:"13px"}}>Engagementor Wedding Date (Optional) </span>
  </div>
  </div>
    <div className="margintop1 row">
  
  <div className="col-10 textleft form-group">
  <DatePicker
          className="adreservdate11 inputwidth1"
          name='engagementDate'
            selected={startDate}
             onChange={date => handleChangedate(date)}

            selectsStart // tells this DatePicker that it is part of a range*

            startDate={startDate}
            dateFormat="dd  MMM yyyy"
            value={storyData.engagementDate}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
          />         
  </div>
  
 </div> 
 <div className=" textleft row">
  <div className="col-10 textleft ">
  <span for="inputState" className="event-sub-detail1 " style={{fontSize:"13px"}}>Photos (Upload) </span>
  </div>
  </div>
    <div className="margintop1 row">
  
  <div className="col-10 textleft form-group">
  <label for="selectFilePhoto" name="photoOne" className="" style={{ backgroudColor: "white" }}   >
                            {
                  imgDataclseure !=null ?(
                    <img id='image' className="playerProfilePic_home_tileblog"
                    src={imgDataclseure} 
               
                />
                  ):
                       ( <img src={imgupload}  className='imagePreview' style={{height:"160px"}} onChange={banerImageChange}/>)
                  }
                           </label>
                     <input id="selectFilePhoto" type="file" ref={fileref} onChange={banerImageChange} className="fileupload hide" 
                       name="photoOne"
                        />
                        <div className={imgDataclseure ==null?'margintpmins1 kycimg-divmat':"hide"}><span className='photoupload-lbl textleft'>Upload Image</span></div>
  </div>
  
 </div>   
 <div className='row '>
              <div className=" col-12 ">
                {error && attachmentMatrimony == null ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Couple photo is required.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>    
    
 <div className="row margintop1">
              <div className="col-lg-12  textleft ">
                <span className='event-sub-detail1' style={{fontSize:"px", fontStyle:""}}>
            Permission to Share.
              </span>
              </div>
              </div>
              <div className="row ">
            <div class="form-check  col-lg-11 col-sm-12 col-md-12" style={{marginLeft:"18px"}}>
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value="" name="agree" onChange={handlechangeCheckbox}/>
    <span className="chekboxlbl">I agree to have my story featured on Community Matrimony App and Website</span>
  </label>
  </div>
            
            
            </div>
    
    
                  </div>
                  <div className='popupbtn-div margintop3'>

<div className='row '>
      <div className='col-1'></div>
      <div className='col-5'>
          <button className='cancelbtn'  onClick={props.handleClose} style={{width:'200px',height:"42px",fontSize:"13px"}}>Cancel</button>
      </div>
      <div className='col-6'>
      <button  className={checkboxAgree.agree !=""?"createeventbtn":"disabledbtn "} style={{width:'210px',height:"42px",fontSize:"13px",marginLeft:"5px"}} onClick={ submitFormData} >Submit my Success Story</button>
    </div>

    </div>
    </div>
</div>


    

    

        {/* )} */}
                 
      </div>
    </div>

    <div className={sucessUploadStoryPopup ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"26%",marginLeft:"616px",paddingBottom:"20px",height:"45vh"}}>
        
<div style={{}}>
  <div className='row margintop3'>
    <div className='col-3'></div>
  <div className='col-6'>
  <img src={gif} width="141"/>

  </div>
  <div className='col-1'></div>

  </div>
<div className="row margintop3">
  
              <div className="col-lg-12  margintop1 ">
                <span className='event-sub-detail1' style={{fontSize:"14px",color:""}}>
                Thank you for sharing your Beautiful Story!  </span>
                <div className=''>
                <span className='event-sub-detail margintop1' style={{fontSize:"14px",color:""}}>
                Your account has been successfully deleted.  </span>
                </div>
                
              </div>
              </div>
              <div className='row margintop3' style={{paddingBottom:"0px",paddingTop:"px"}}>
                <div className='col-4'></div>
                <div className='col-3 margintop1'>
                    <button className='cancelbtn' onClick={() => setsucessUploadStoryPopup(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Ok</button>
                </div>
                

              </div>
             
    
   
</div>

          
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
    <div className={fulfiledoutsidesuccesspop ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"27%",marginLeft:"616px",paddingBottom:"20px",height:"44vh"}}>
        
<div style={{}}>
  <div className='row margintop'>
    <div className='col-3'></div>
  <div className='col-6'>
  <img src={gifchechmark} />

  </div>
  <div className='col-1'></div>

  </div>
<div className="row ">
  
              <div className="col-lg-12  textcenter " style={{marginTop:"-7px"}}>
                
                <div className=''>
                <span className='event-sub-detail margintop1' style={{fontSize:"14px",color:""}}>
                Your Account Has Been  Closed.  </span>
                </div>
                <span className='event-sub-detail1' style={{fontSize:"x",color:""}}>
                We appreciate your feedback!, we're sorry to see you go, but we're glad you found your match.  </span>
                
              </div>
              </div>
              <div className='row margintop3' style={{paddingBottom:"5px",paddingTop:"px"}}>
                <div className='col-4'></div>
                <div className='col-3 margintop3'>
                    <button className='cancelbtn' onClick={() => setfulfiledoutsidesuccesspop(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Ok</button>
                </div>
                

              </div>
             
    
   
</div>

          
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
    <div className={succesDeletPopup ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"27%",marginLeft:"616px",paddingBottom:"20px",height:"45vh"}}>
        
<div style={{}}>
  <div className='row margintop3'>
    <div className='col-3'></div>
  <div className='col-6'>
  <img src={gifchechmark} />

  </div>
  <div className='col-1'></div>

  </div>
<div className="row " >
  
              <div className="col-lg-12 textcenter " style={{marginTop:"-10px",marginBottom:"10px"}}>
                <span className='event-sub-detail' style={{fontSize:"14px",color:""}}>
                Your account has been closed.  </span>
                <div className=''>
                <span className='event-sub-detail1 margintop1' style={{fontSize:"px",color:""}}>
                Your account has been successfully closed. If you ever wish to return, feel free to signup again. We appreciate your time with us. </span>
                </div>
                
              </div>
              </div>
              <div className='row margintop3' style={{paddingBottom:"px",paddingTop:"px"}}>
                <div className='col-4'></div>
                <div className='col-3 '>
                    <button className='cancelbtn' onClick={() => setsuccesDeletPopup(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Ok</button>
                </div>
                

              </div>
             
    
   
</div>

          
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
    <div className={skipPopup ? "row" : "hide row"}>
      <div className="popup-boxrefmember">
      <div className="boxrefmember"  style={{width:"27%",marginLeft:"616px",paddingBottom:"20px",height:"45vh"}}>
        
<div style={{}}>
  <div className='row margintop3'>
    <div className='col-3'></div>
  <div className='col-6'>
  <img src={gif} width="110"/>

  </div>
  <div className='col-1'></div>

  </div>
<div className="row margintop3">
  
              <div className="col-lg-12 textcenter margintop1 ">
                <span className='event-sub-detail' style={{fontSize:"14px",color:""}}>
                Your account has been closed.  </span>
                <div className=''>
                <span className='event-sub-detail1 margintop1' style={{fontSize:"px",color:""}}>
                Your account has been successfully closed. Thank you for being a part of our community. We wish you the best in your journey ahead! </span>
                </div>
                
              </div>
              </div>
              <div className='row margintop3' style={{paddingBottom:"5px",paddingTop:"5px"}}>
                <div className='col-4'></div>
                <div className='col-3 margintop1'>
                    <button className='cancelbtn' onClick={() => setskipPopup(false)} style={{width:'100px',height:"30px",fontSize:"13px"}}>Ok</button>
                </div>
                

              </div>
             
    
   
</div>

          
              <div className='row margintop1'></div>
              
      </div>
    </div>
    </div>
    </div>
  );
};
 
export default MatrimonyClosurePpup;