
import React from "react";
import './JoinNow.css';
import payment from '../../images/paymentfail.svg';
import success from '../../images/fail.svg';


 
const PaymentFail = () => {
  return (
    <div className="container">
        <div className="margintop  row"></div>
    <div className="margintop  row ">
        <div className="col-2"></div>
          <div className="col-9 form-containerwhite">
            <div className="margintop textcenter">
            <img src={payment} class='margintop'/>
            <img src={success} class='succimg1 '/>
            </div>
            <div className="margintop row"></div>

            <div className="textcenter margintop">
              <span className="paidfail-text"> Your Payment to Naidu Directory  Org.failed</span>
            </div>
            <div className="margintop3 row"></div>
            <div className="textcenter margintop">
              <span className="recivemail-text">Try making the payment wih different payment option. In case of any queries you can drop an  </span>
              <div ><span className="recivemail-text"> email to support@XYZcommunity.com</span></div>
            </div>
            <div className="margintop row"></div>
            <div className="margintop row"></div>


            <div className="margintop textcenter">
              <button className="marginleftmins3 compl-propilebtn">Retry Payment</button>
            </div>
          </div>
          </div>
          </div>
  );
};
 
export default PaymentFail;

