import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";






const JobListingPrivacy =()=>{
  
  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId); 
  //for session
   let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });

    return(
        <div className="container-fluid  ">
            
        <div className="row margintop "></div>
                  <div className="row margintop">
                      <div className="col-lg-1"></div>
                      <div className="col-lg-1 col-sm-12 col-md-4">           
                      <Link to='/LandingPage'><img src={logo} style={{width:"187px", height:"77px"}}/></Link> 
                        </div>
                      <div className="col-lg-6 col-md-7 col-sm-12">
                         {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                      </div>
      
                  </div>
                 <div className="row margintop3">
                  <div className="col-lg-2"></div>
                  <div className="col-lg-8">
                  <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
                  <div className="row">
                  <span className="event-sub-detail">
                  Naidu Directory Job Listing Policy

                  {/* <div className="margintop1">Effective Date: 22 Apr 2023</div> */}
      
                              
                  </span>
                 </div>
                 <div className="row margintop3">
                  <span className="event-sub-detail1">
                  At Naidu Directory, our Job Listing Policy is designed to foster a sense of community and mutual benefit.
                   This policy outlines the guidelines for job listings, which can be created by Naidu Directory community members who are registered for Business Listings.

                              
                  </span>
                 </div>
                 <div className="row margintop3">
                  <span className="event-sub-detail">
                  Eligibility           </span>
                  <span className="event-sub-detail1 margintop1">
                  1.Business Listing Registration: Job listings can be created only by Naidu Directory community members who are registered for Business Listings.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  2.Authenticity: All job listings must be submitted by an individual representing a legitimate business or organization. The job poster must have the authority to post job openings on behalf of their business.
      
                  </span>
                 </div>
                 <div className="row margintop3">
                  <span className="event-sub-detail">
                  Job Listing Guidelines:           </span>
                  <span className="event-sub-detail1 margintop1">
                  1. Community-Centric Approach: Naidu Directory's job listing feature is primarily designed for the benefit of the community members who are seeking job opportunities. Job listings should be created with the intent of helping our community members in their job search.
      
                   
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  2. Accurate Information: Job listings must contain accurate and up-to-date information about the job, including the job title, description, requirements, and application process.
                   
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  3. Relevance: Job listings should be relevant to the Naidu Directory community and align with the interests and skills of our members.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  4. Non-Discrimination: Job listings must not discriminate on the basis of race, color, religion, gender, gender identity or expression, sexual orientation, national origin, disability, or age.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  5. Complete Information: Job postings should include details such as the job title, company name, location, job description, qualifications, application instructions, and contact information.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  6. No Fee: Job listings should not require candidates to pay any application or processing fees. Any exceptions to this rule must be clearly explained in the job posting.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  7. Respectful Language: All job postings must use respectful and professional language. Any content that is offensive, derogatory, or violates our community guidelines will not be accepted.
      
                  </span>
                 </div>
                 
                 
                 <div className="row margintop3">
                  <span className="event-sub-detail">
                  Win-Win Policy:

             </span>
                  <span className="event-sub-detail1 margintop1">
                  1. Mutually Beneficial: Naidu Directory's job listing policy is structured to create a win-win scenario for both business owners and job aspirants within our community. Businesses gain access to a pool of talented and culturally aligned professionals, while job aspirants have the advantage of exploring job openings from businesses that appreciate their cultural background. This synergy fosters stronger community bonds and economic growth, benefiting everyone involved.
      
                  </span>
                  
                 </div>
      
      
                 <div className="row margintop3">
                  <span className="event-sub-detail">
                  Responsibility          </span>
                  <span className="event-sub-detail1 margintop1">
                  1. Accuracy: It is the responsibility of the job poster to ensure the accuracy of the information provided in the job listing. Naidu Directory is not responsible for inaccuracies or discrepancies.

                  </span>
                  <span className="event-sub-detail1 margintop1">
                  2. Follow-Up: Job posters are encouraged to respond promptly to candidate inquiries and applications and maintain professional communication throughout the hiring process.
      
                  </span>
                 </div>
      
                 <div className="row margintop3">
                  <span className="event-sub-detail">
                  Removal of Job Listings:
   </span>
                  
                  <span className="event-sub-detail1 margintop1">
                  1. Review Process: Naidu Directory reserves the right to review all job listings to ensure they adhere to our guidelines. Job listings that violate our policies will be removed.
      
                  </span>
                  <span className="event-sub-detail1 margintop1">
                  2. Discretion: Naidu Directory has the discretion to remove any job listing without prior notice if it is found to be in violation of our guidelines or community standards.
      
                  </span>
                 </div>
                 
                 
                
                 
      
                 <div className="row margintop1">
                  <span className="event-sub-detail">
                  Naidu Directory is committed to fostering a positive and productive environment for our community members. By adhering to these Job Listing Policies, we can maintain the integrity of our platform and provide valuable opportunities for professionals within the Naidu community.
                          </span>
                  
                  
                 </div>
                
      
                  </Card>
                  <div className="row margintop"></div>
                  <div className="row textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12">  <Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>

                  
                  </div>
      
                 </div>
                
                  
                  
                 
      
      
              </div>
    );
}
export default JobListingPrivacy