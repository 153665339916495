import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"

const MatrimonyFeatureAndHealth = ({healthDetails,sethealthDetails,handleBack,handleNext}) => {

  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
  
  const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        sethealthDetails(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(healthDetails.birthday);
    }
    
      const [error, setError] = useState(false);
      const submitFormData2 = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (healthDetails.heightFeet==""||healthDetails.heightFeet==null) {
          setError(true);
        }
        
        else if (healthDetails.weight==""||healthDetails.weight==null) {
            setError(true);
    
        }
        else if (healthDetails.skinTone==""||healthDetails.skinTone==null) {
          setError(true);
  
        }
        else if (healthDetails.bodyType==""||healthDetails.bodyType==null) {
          setError(true);
  
        }
        
        else if (healthDetails.healthInformation==""||healthDetails.healthInformation==null) {
          setError(true);
  
        }
        else if (healthDetails.disability==""||healthDetails.disability==null) {
          setError(true);
  
        }
        else if (healthDetails.glasses==""||healthDetails.glasses==null) {
          setError(true);
  
        }
        
       
        
    else {
          debugger
          handleNext()
        }
    };

      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        sethealthDetails(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
        
    }

return(
    
<div className="container">
    <form >
            <div className="row margintop">
              <div className="col-9" id="completeprofradio">
                <div className="row textLeft">
                    <div className="col-lg-7 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Features & Health</span>
               </div>
    
    </div>
             <div className="row margintop3">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >height</span>
    </div>
    <div className="col-3 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightFeet'
                    value={healthDetails.heightFeet}
                    onChange={handleChange} >
                    <option selected value="">In Ft</option>
                    <option  value="1">1ft</option>
                    <option  value="2">2ft</option>
                    <option  value="3">3ft</option>
                    <option  value="4">4ft</option>
                    <option  value="5">5ft</option>
                    <option  value="6">6ft</option>
                    <option  value="7">7ft</option>
                    <option  value="8">8ft</option>
                    <option  value="9">9ft</option>
                    <option  value="10">10ft</option>


                    
                    </select> 
                    <div className='row '>
    <div className="col-12 ">
                                    {error && healthDetails.heightFeet == null ? (
                                        <span style={{ color: "red",fontSize:"12px",whiteSpace:"nowrap" }}>
                                            Height In Ft is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
                    
                    <div className="col-3 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='heightInches'
                    value={healthDetails.heightInches}
                    onChange={handleChange} >
                    <option selected value="">In Inch</option>
                    <option  value="1">1inch</option>
                    <option  value="2">2inch</option>
                    <option  value="3">3inch</option>
                    <option  value="4">4inch</option>
                    <option  value="5">5inch</option>
                    <option  value="6">6inch</option>
                    <option  value="7">7inch</option>
                    <option  value="8">8inch</option>
                    <option  value="9">9inch</option>
                    <option  value="10">10inch</option>


                    </select>    
                    </div>
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Weight</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='weight'
                    value={healthDetails.weight}
                    onChange={handleChange} >
                    <option selected value="">Select weight in Kg</option>
                    <option  value="20">20</option>
                    <option  value="21">21</option>
                    <option  value="22">22</option>
                    <option  value="23">23</option>
                    <option  value="24">24</option>
                    <option  value="25">25</option>
                    <option  value="26">26</option>
                    <option  value="27">27</option>
                    <option  value="28">28</option>
                    <option  value="29">29</option>
                    <option  value="30">30</option>
                    <option  value="31">31</option>
                    <option  value="32">32</option>
                    <option  value="33">33</option>
                    <option  value="34">34</option>
                    <option  value="35">35</option>
                    <option  value="36">36</option>
                    <option  value="37">37</option>
                    <option  value="38">38</option>
                    <option  value="39">39</option>
                    <option  value="40">40</option>
                    <option  value="41">41</option>
                    <option  value="42">42</option>
                    <option  value="43">43</option>
                    <option  value="44">44</option>
                    <option  value="45">45</option>
                    <option  value="46">46</option>
                    <option  value="47">47</option>
                    <option  value="48">48</option>
                    <option  value="49">49</option>
                    <option  value="50">50</option>
                    <option  value="51">51</option>
                    <option  value="51">51</option>
                    <option  value="53">53</option>
                    <option  value="54">54</option>
                    <option  value="55">55</option>
                    <option  value="56">56</option>
                    <option  value="57">57</option>
                    <option  value="58">58</option>
                    <option  value="59">59</option>
                    <option  value="60">60</option>
                    <option  value="61">61</option>
                    <option  value="62">62</option>
                    <option  value="63">63</option>
                    <option  value="64">64</option>
                    <option  value="65">65</option>
                    <option  value="66">66</option>
                    <option  value="67">67</option>
                    <option  value="68">68</option>
                    <option  value="69">69</option>
                    <option  value="70">70</option>
                    <option  value="71">71</option>
                    <option  value="72">72</option>
                    <option  value="73">73</option>
                    <option  value="74">74</option>
                    <option  value="75">75</option>
                    <option  value="76">76</option>
                    <option  value="77">77</option>
                    <option  value="78">78</option>
                    <option  value="79">79</option>
                    <option  value="80">80</option>
                    <option  value="81">81</option>
                    <option  value="82">82</option>
                    <option  value="83">83</option>
                    <option  value="84">84</option>
                    <option  value="85">85</option>
                    <option  value="86">86</option>
                    <option  value="87">87</option>
                    <option  value="88">88</option>
                    <option  value="89">89</option>
                    <option  value="90">90</option>
                    <option  value="91">91</option>
                    <option  value="92">92</option>
                    <option  value="93">93</option>
                    <option  value="94">94</option>
                    <option  value="95">95</option>
                    <option  value="96">96</option>
                    <option  value="97">97</option>
                    <option  value="98">98</option>
                    <option  value="99">99</option>
                    <option  value="100">100</option>
                    <option  value="Above 100">Above 100</option>

                     </select> 
                     <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.weight == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Weight is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
                    
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Skin Tone</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='skinTone'
                    value={healthDetails.skinTone}

                    onChange={handleChange} >
                    <option selected value="">Select Skin Tone</option>
                    <option  value="Light">Light</option>
                    <option  value="Fair">Fair</option>
                    <option  value="Medium">Medium</option>
                    <option  value="Dark">Dark</option>

                    
                    </select>  
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.skinTone == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Skin Tone is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>  
                    </div>
                    
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Body Type</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='bodyType'
                    value={healthDetails.bodyType}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Average">Average</option>
                    <option  value="Slim">Slim</option>
                    <option  value="Athletic">Athletic</option>
                    <option  value="Fat">Fat</option>
     </select> 
                    
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.bodyType == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Body Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
                    </div>
             </div>
            
             
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Health Information</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='healthInformation'
                    value={healthDetails.healthInformation}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Modern">Modern</option>
                    <option  value="Moderate">Moderate</option>
                    <option  value="Traditional">Traditional</option>
                    
                    </select>
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.healthInformation == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Health Information Type is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>    
                    </div>
             </div>
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Disability</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='disability'
                    value={healthDetails.disability}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Yes">Yes</option>
                    <option  value="No">No </option>
                    
                    </select>
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.disability == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Disability is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>      
                    </div>
             </div>
             
             <div className="row margintop">
    <div className="col-4 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Glasses</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='glasses'
                    value={healthDetails.glasses}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Yes">Yes</option>
                    <option  value="No">No</option>
                    
                    </select>
                    <div className='row '>
              <div className=" col-12 ">
                                    {error && healthDetails.glasses == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Glasses is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>    
                    </div>
             </div>
      
            
             
      
            
              </div>  
              <div className="margintop3 row">
            <div className="col-2"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              type="submit"
              onClick={submitFormData2}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
          </div>
    </form>
        </div>
        
        )
}
export default MatrimonyFeatureAndHealth