import React, { useState } from "react";

import './UserLandingTest.css';
import clogo from '../../images/clogo.png';
import profile from '../../images/sagarsir.svg';
import {MdOutlineArrowDropDown,MdLocationOn,MdNotifications,MdWaterDrop} from 'react-icons/md';
import { Link } from "react-router-dom";
import {CgProfile} from 'react-icons/cg'
import {Box} from '@material-ui/core';
import {Slider} from '@material-ui/core';
import AddNewsPopup from "./AddNewsPopup";
import eventlogo from '../../images/eventlogo.svg';
import eventimg1 from '../../images/event1.png';
import eventimg2 from '../../images/event2.png';
import eventimg3 from '../../images/event3.png';
import eventimg4 from '../../images/eventimg4jpg.jpg';
import {BiLike,} from 'react-icons/bi';
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit} from 'react-icons/fa';

import {AiOutlineDislike,AiFillLike} from 'react-icons/ai'
import {HiOutlineLogout} from 'react-icons/hi'
import{BsBookmark} from 'react-icons/bs'
import {RiCupFill,RiSendPlaneFill} from 'react-icons/ri';
import imgupload from '../../images/imageupload.svg'
import blogimg from '../../images/blog.png'
import kycimg from '../../images/kyc.png'
import AddBlogPopup from "./AddBlogPopup";
import AddKycPopup from "./AddKycPopup";
import flag from '../../images/india.png';
import subcast from '../../images/subcast.svg';
import refferal from '../../images/refferalsvg.svg';
import cups from '../../images/cups.svg';
import from from '../../images/from.svg';
import radius from '../../images/radius.svg';
import conprof from '../../images/coneect.svg';

import contactinfo from '../../images/contactinfo.png';
import connect from '../../images/connect.png';
import ContactInfoPopup from "./ContactInfoPopup";
import NotificationPopup from "./NotificationPopup";
import {MdAddBox} from 'react-icons/md';
import SliderPage from "./SliderPage";
import BloodRequirePopup from "./BloodRequirePopup";
import MessagePopup from "./MessagePopup";

import { Badge,  Button, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { Gallery } from "react-grid-gallery";
import AddNewsPopupTest from "./AddNewsPopupTest";










//for like button
const {  useReducer } = React;
const initialState = {
  likes: 21,
  dislikes: 5
}



function valuetext(value) {
  return `${value}`;
}
 
const UserLandingTest = () => {
    
    
    const [profdivhide, setprofdivhide] = useState(false);


    //for like functionality
    const appReducer = (state, action) => {
      switch(action.type) {
        case 'HANDLE_LIKE':
          return {
            ...state,
            likes: state.likes + action.payload
          }
        case 'HANDLE_DISLIKE':
          return {
            ...state,
            dislikes: state.dislikes + action.payload
          }
        default:
          return state
      }
    }
    
      
      const handleClickLike = () => {
        if (status==='like') {
          setStatus(null)
          dispatch({
            type: 'HANDLE_LIKE',
            payload: -1,
          })
        } else {
          setStatus('like')
          if (status==='dislike') {
            dispatch({
              type: 'HANDLE_DISLIKE',
              payload: -1,
            })
          }
          dispatch({
            type: 'HANDLE_LIKE',
            payload: 1,
          })
        }
      }
      
      const handleClickDislike = () => {
        if (status==='dislike') {
          setStatus(null)
          dispatch({
            type: 'HANDLE_DISLIKE',
            payload: -1,
          })
        } else {
          setStatus('dislike')
          if (status==='like') {
            dispatch({
              type: 'HANDLE_LIKE',
              payload: -1,
            })
          }
          dispatch({
            type: 'HANDLE_DISLIKE',
            payload: 1,
          })
        }
      }
      const [state, dispatch] = useReducer(appReducer, initialState)
    const { likes, dislikes } = state
    const [status, setStatus] = useState(null)


    const [isOpenMessagePop, setIsOpenMessagePop] = useState(false);
  const togglePopupMessage = () => {
    setIsOpenMessagePop(!isOpenMessagePop);
  }

    const [isOpenNotification, setIsOpenNotification] = useState(false);
    const togglePopupNotify = () => {
      setIsOpenNotification(!isOpenNotification);
    }

    const [isOpenContactPopup, setisOpenContactPopup] = useState(false);
    const togglePopupContact = () => {
      setisOpenContactPopup(!isOpenContactPopup);
    }

const [selected, setSelected] = useState('');

const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const [isOpenBlog, setIsOpenBlog] = useState(false);
  const togglePopupBlog = () => {
    setIsOpenBlog(!isOpenBlog);
  }
  const [isOpenkyc, setIsOpenkyc] = useState(false);
  const togglePopupKyc = () => {
    setIsOpenkyc(!isOpenkyc);
  }
  const handleChange = event => {
    setSelected(event.target.value);
  };

const [sliderval, setSliderVal] = useState()

const getValue =(e,val)=>{
  setSliderVal(val);
}

const mark=[
  {
    value:25,
    label:"25 Km",
  },
  {
    value:50,
    label:"50 Km"
  },
  {
    value:75,
    label:"75 Km"
  },
  {
    value:100,
    label:"100 Km"
  }
  

]



const [news ,setNews] = useState(true);
const [communityDirect ,setcommunityDirect] = useState(false);
const [matrimony ,setMatrimony] = useState(false);
const [communityMeet ,setcommunityMeet] = useState(false);
const [businessDirect ,setbusinessDirect] = useState(false);

 const showNews =()=>{
   setNews(true);
   setcommunityDirect(false);
   setMatrimony(false);
   setbusinessDirect(false);
   setcommunityMeet(false);



   }

  const showCommunityDirect =()=>{
    setNews(false);
  setcommunityDirect(true);
  setMatrimony(false);
  setbusinessDirect(false);
  setcommunityMeet(false);

    }
    const showMatrimony =()=>{
      setNews(false);
  setcommunityDirect(false);
  setMatrimony(true);
  setbusinessDirect(false);
  setcommunityMeet(false);

      }
      const showBusinessDirect =()=>{
        setNews(false);
  setcommunityDirect(false);
  setMatrimony(false);
  setbusinessDirect(true);
  setcommunityMeet(false);
        }
        const showCommunityMeet =()=>{
          setNews(false);
  setcommunityDirect(false);
  setMatrimony(false);
  setbusinessDirect(false);
  setcommunityMeet(true);
          }


          const [isOpenBloodPopup, setisOpenBloodPopup] = useState(false);
  const togglePopupBlood = () => {
    setisOpenBloodPopup(!isOpenBloodPopup);
  }


  const [comment ,setComment] = useState(false);
  const commentClick = () => {
    setComment(true);
  }

  return (
    <div className="container-fluid containerfluidpadding0">
        <div className="container-fluid  userlandtop-div">
        <div className="row">
                  <div className="col-1">
                  <img src={clogo} className='clogo' />

                  </div>
                  <div className="col-2 textleft">
                    <span className="com-name">Naidu Directory</span>
                    
                  </div>
                  <div className="col-5"></div>
                  <div className="col-2 displyinline  ">
                  <MdNotifications  size='1.8em' className="notifiicon" onClick={togglePopupNotify}/>
                  { isOpenNotification && <NotificationPopup
                  handleClose={togglePopupNotify}
                  />
                  }
                    <p className="notifi-text">12</p>
                    <RiCupFill  className="cupicon-top" size='1.7em'/> 
                    <p className="notifi-text1">2</p>
                
                     </div>
                  <div className="col-2 textcenter">
                    <div className="row proftop-div textcenter">
                        <div className="col-1 margintop1">
                        <img src={profile}  className='prof-img-right'/>

                        </div>
                        <div className="col-7 margintop1 textright">
                            <span className="proftop-name">Sagar Naidu</span>
                        </div>
                        <div className="col-1 margintop1 textright">
<div class="dropdown drpdiv">
<button type="button" class="drpbtn-menu dropdown-toggle" data-toggle="dropdown">
  </button>
  <div class="dropdown-menu drpdn-meni-item">
    <a class="dropdown-item drp-item" href="/ViewProfile">View Profile</a>
    <a class="dropdown-item drp-item" href="#">View Contacts</a>
    <a class="dropdown-item drp-item" href="#">View Subscriptions</a>
    <a class="dropdown-item drp-item" href="#">View Connect requests</a>
    <a class="dropdown-item drp-item" href="#">Preferred Language Settings</a>
    <a class="dropdown-item drp-item" href="#">Referrals</a>
    <a class="dropdown-item drp-item" href="#">Change Password</a>
    <a class="dropdown-item drp-item" href="#">My Account</a>
    <a class="dropdown-item drp-item" href="#">Sign Out <HiOutlineLogout size='1.4em' className="marginleft2"/></a>


  </div>
            </div>                      
          </div>
                        

                    </div>
                  </div>


      </div>
        </div>

        <div className="container-fluid   borderbotomcontiner">
            <div className="row ">
                <div className="col-2 displyinline margintpmins1">
                    
                <div className="margintop3 ">
                  <div className="">
                  <span className=" menu-name">Sagar Naidu</span>
                  <div className="margintpmins2"><span className="imgspn-menu"><FaUserClock color="#FF8427" size="0.9em" className="prof-veryfiimg"/></span><span className="menu-veryfyprof">Verified profile</span></div>

                  </div>

                {/* <div className="margintpmins2 "><span className=" menuname-subtext">CID # 0000001</span></div>  */}
                </div>
                
                </div>
                <div className="col-2 textright">
                    <span className={news?"textdecorunder menu-name":"menu-name"} onClick={showNews} name="news">News & Updates</span>
                </div>
                <div className="col-2 textright">
                    <span className={communityDirect?"textdecorunder menu-name":"menu-name"} onClick={showCommunityDirect} name="cdirect">Community Directory</span>
                    </div>
                <div className="col-2 textcenter">
                    <span className={matrimony?"textdecorunder menu-name":"menu-name"} onClick={showMatrimony} name="matrimony">Matrimony</span>
                    </div>
                <div className="col-2 textcenter">
                   <span className={communityMeet?"textdecorunder menu-name":"menu-name"} onClick={showBusinessDirect} name="bdirect">Business Directory</span>
                   </div>
                <div className="col-2 textcenter">
                    <span className={businessDirect?"textdecorunder menu-name":"menu-name"} onClick={showCommunityMeet} name="communitym">Community Meetups</span>
                    </div>

            </div>
        </div>
        <div className="row margintop1">
          <div className="col-3 leftdivuser" id="radiusdivchek">
            {/* <div className="profdiv">
            <div className="row">
              <div className="col-1">
                <CgProfile color="#214B0D" size='1.0em'/>
              </div>
              <div className="col-4  textcenter">
                <span className="marginleftmins4 profpercent ">Profile 50% - </span>
              </div>
              <div className="col-6 textleft marginleftmins1">
                <span className="colpletprof-spn">Complete your profile now</span>
              </div>
           </div>
           <div className="row">
              <div className="col-1">
                <MdEmail color="#214B0D" size='1.0em'/>
              </div>
              <div className="col-6 ">
                <span className="profpercent ">Email - Verification Pending -</span>
              </div>
              <div className="col-4  marginleftmins">
                <span className="colpletprof-spn">Verify now</span>
              </div>
           </div>
            </div> */}
            
       <div className="row margintop1">
        <div className="borderbottom-radius">
        <span className="radius-heding"> Members In My Radius</span>
        
        <div className="margintop1 "></div>
        <div className="radius-div">
        <div className="marginleft15 ">
          <div className="firstcircle">
        <div className="secondcircle">
           <div className="radisval">{sliderval} </div> 
           </div>
        </div>
        </div>
        
      
        
        <div className="marginleft2">
        <Box className="" sx={{ width: 300 }}>
      <Slider
        aria-label=""
        defaultValue={25}
        getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        step={25}
        marks={mark}
        min={25}
        max={100}
        onChange={getValue}
      />
    </Box>
        </div>
        <div className="marginleft1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="showconnectmemrs">Show Connected Members Only</span>
    </label>
        </div>
        </div>
        </div>
       </div>

       <div className="row margintop1">
        <div className="borderbottom-radius">
        <span className="radius-heding"> My Community Networks (225)</span>
        
        <div className="margintop1 "></div>
        <div className="radius-div">
        <div className="row ">
          <div className="col-5">
            <span className="radius-secobd-text marginleftmins4">Members Joined</span>
          </div>
          <div className="col-1">
            <span className="radius-secobd-text marginleftmins6">30</span>
          </div>
          <div className="col-5">
           <span className="radius-second-linktext whitenowrap marginleftmins6">Invite New Member</span> 
            </div>
        </div>
        
        <div className="row margintop1 ">
          <div className="col-5">
            <span className="radius-secobd-text marginleftmins4">Invitations Sent</span>
          </div>
          <div className="col-1">
            <span className="radius-secobd-text marginleftmins6">30</span>
          </div>
          <div className="col-5">
           <span className="radius-second-linktext whitenowrap marginleftmins6">Sent Invitations</span> 
            </div>
        </div>
        
       
        </div>
        </div>
       </div>

       <div className="row margintop1">
        <div className="borderbottom-radius">
        <span className="radius-heding"> My Community Platforms</span>
        
        <div className="margintop1"></div>
        <div className="radius-div">
        <div className="marginleft1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Listing & Directory </span>
    </label>
        </div>
        <div className="marginleft1 margintop1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Community Meetups</span>
    </label>
        </div>
        <div className="marginleft1 margintop1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" checked value=""/>
    <span className="radius-secobd-text">Business Directory</span>
    </label>
        </div>
        <div className="marginleft1 margintop1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Community Matrimony</span>  <span className="radius-second-linktext">Sign Up</span>
    </label>
        </div>
        <div className="marginleft1 margintop1">
        <label class="form-check-label ">
    <input type="checkbox" class=" form-check-input chek" value=""/>
    <span className="radius-secobd-text">Business Listing</span> <FaInfoCircle color='#214B0D' size='0.9em'/> <span className="radius-second-linktext">Sign Up</span>
    </label>
        </div>
        
       
        </div>
        </div>
       </div>
       <div className="row margintop1">
        <div className="borderbottom-radius">
        <span className="radius-heding"> My Coffee Meet Ups</span>   <RiCupFill color="#B5924C" size='1.3em'/>
      <div className="margintop1 "></div>
        <div className="radius-div">
        <span className="radius-fourth-subspn">You have 2 Upcoming Cofee Meet Ups</span>
        
        <div className="row margintop2">
          <div className="col-6 margintop1 serchbyorg">
            Search by Organizer
          </div>
          <div className="col-6 margintop1 dtheight">
          <select  id="inputState" name='' 
                  className="height form-control" >
                  <option selected value="">Date</option>
                  <option value="pending response">Pending Response</option>
                  <option value="accepted invitations">Accepted Invitations</option>
                  </select>
          </div>
          
        </div>
        <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Date/Place</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">12 Oct 2022</span>  <span className="marginleft5 radius-fourth-card1-text">6:00 PM </span><span className="marginleft5 radius-fourth-card1-text">Pune</span>
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Organizer</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Shankar Naidu</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Guests:- 3</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Sagar Naidu, Vishal Naidu +1</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Venue</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Mariott , SB Road</span>  
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
            <span className="radius-fourth-subspn ">You have accepted this invitation</span>  <span className="radius-fourth-linktext ">View Invitation</span>

            </div>



        </div>
        <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Date/Place</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">12 Oct 2022</span>  <span className="marginleft5 radius-fourth-card1-text">6:00 PM </span><span className="marginleft5 radius-fourth-card1-text">Pune</span>
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Organizer</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Shankar Naidu</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Guests:- 3</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Sagar Naidu, Vishal Naidu +1</span>  
            </div>
            </div>
            <div className="row margintop1">
            <div className="col-4">
              <span className="radius-fourth-card1-lefttext">Venue</span>
            </div>
            <div className="col-8">
              <span className="radius-fourth-card1-text">Mariott , SB Road</span>  
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
            <span className="radius-fourth-subspn ">You have not respode to this invitation</span>  <span className="radius-fourth-linktext ">View Invitation</span>

            </div>

    </div>
        <div className="textright"><span className="radius-fourth-linktext">Check all invites</span></div>
       
        </div>
        </div>
       </div>
       <div className="row margintop1">
        <div className="borderbottom-radius">
        <span className="radius-heding"> My Connection Requests</span>   <FaRegHandshake color="#B5924C" size='1.3em'/>
      <div className="margintop1 "></div>
        <div className="radius-div">
        <span className="radius-fourth-subspn">You have 2 new connection requests</span>
        
        
        <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4 margintop1">
            <img src={conprof}  className='margintop1'/>
            <div className=""><span className="radius-fourth-linktext">View Profile</span></div>
            </div>
            <div className="col-8">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span> </div>
              <div className=""><span className="radius-fourth-card1-text">Balaji Naidu</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Information Technology</span></div> 
              <div className=""><span className="radius-fourth-card1-text">Latur, Maharashtra</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Pune, Maharashtra</span> </div> 
 
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
             <span className="radius-fourth-linktext ">Responded to this invitation</span>

            </div>



        </div>
        <div className="radius-fourth-card1 margintop3">
          <div className="row margintop1">
            <div className="col-4 margintop1">
            <img src={conprof}  className='margintop1'/>
            <div className=""><span className="radius-fourth-linktext">View Profile</span></div>
            </div>
            <div className="col-8">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span> </div>
              <div className=""><span className="radius-fourth-card1-text">Balaji Naidu</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Information Technology</span></div> 
              <div className=""><span className="radius-fourth-card1-text">Latur, Maharashtra</span> </div> 
              <div className=""><span className="radius-fourth-card1-text">Pune, Maharashtra</span> </div> 
 
            </div>
            </div>
            <div className="marginleft1 whitenowrap">
             <span className="radius-fourth-linktext ">Responde to this invitation</span>

            </div>



        </div>
        
        <div className="textright"><span className="radius-fourth-linktext">Check all Requests</span></div>
       
        </div>
        </div>
       </div>


       <div className="row margintop"></div>
       <div className="row margintop"></div>
       <div className="row margintop"></div>

       
       </div>
          
          
          <div className={news?"col-6  newsfeeddiv":"hide"}>
            <div className="row margintop4 eventdivopen-popup">
              <div className="col-1 ">
              <img src={profile}  className='sagarsirimg1'/>
          </div>
              <div className="col-11 margintop1 form-group ">
                <input type='text' className="userland-pop-textbox form-control"
                 placeholder="Share an update (Your post will be approved shortly. All the content needs to be community oriented)"
                 onClick={togglePopup}/>
              </div>
              {isOpen && <AddNewsPopup
      
      handleClose={togglePopup}
    />}

    <div className="row " id="sharediv1">
                    <div className="col-2  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' value='event' onClick={togglePopup} 
                      checked={selected === 'event'}
                     onChange={handleChange}
                     name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Event</label>
                     {isOpen && <AddNewsPopupTest
      
               handleClose={togglePopup}
              />}
                    </div>
                    <div className="col-2   textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' name="example4" onClick={togglePopupBlog} 
                    onChange={handleChange}
                    checked={selected === 'blog'}
                   value='blog'
                    />  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Blog</label>
                     {isOpenBlog && <AddBlogPopup
      
               handleClose={togglePopupBlog}
              />}
                    </div>
                    <div className="col-2  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews' 
                    onChange={handleChange}
                    checked={selected === 'recipie'}
                    value='recipie'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Recipie</label>
                    </div>
                    <div className="col-2 whitenowrap  textleft">
                    <input type="radio" id="radio1" className='radiobtnnews'
                    onChange={handleChange}
                    checked={selected === 'vlog'}
                    value='vlog'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>Vlog</label>
                    </div>
                    <div className="col-3 whitenowrap marginleftmins5 textleft">
                    <input type="radio" id="radio1" className='radiobtnnews'
                    onClick={togglePopupKyc}
                    onChange={handleChange}
                    checked={selected === 'kyc'}
                    value='kyc'
                    name="example4"/>  
                     <label for="defaultRadio1" className='marginleft5 radiobtn-text1'>KYC(Know your community)</label>
                     {isOpenkyc && <AddKycPopup
      
                     handleClose={togglePopupKyc}
                  />}
                    </div>
                    

                  </div>

            </div>
          <div className="cardiv-event">
          <div className="row margintop">
              <div className="col-1">
              <img src={eventlogo}  className='eventlogoimg'/>
              </div>
              <div className="col-1 textright"><span className="evntxt ">Event</span></div>
              <div className="col-5"><span className="marginleftmins5 event-type-text">House Warming Ceremony</span><span className="marginleft2 event-location">Pune</span></div>
            <div className="col-6 event-date-div"><span className="event-date">August 3 at 8:00 PM</span><span className="event-publisher ">Published by ABC Admin</span></div>
            </div>
          {/* <div className="margintop3 row">
           <section id="image-grid">
            <div>
            <img src={eventimg1}  className='img1'/>
         </div>
            <div>
            <img src={eventimg2}  className='img2'/>
          </div>
            <div>
          </div>
          <div>
            <img src={eventimg3}  className='img3'/>  

          </div>
          <div>
          </div>


          </section> 


          </div> */}
          {/* <div className="row margintop3">
            <div className="col-6">
            <img src={eventimg1}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>
            <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>

          </div> */}

          {/* <div className="row margintop3">
            <div className="col-6">
            <img src={eventimg1}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>
            <div className="col-6">
            <img src={eventimg2}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>

          </div>
          <div className="row margintop1">
            <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"672px",height:"100%"}}/>  

            </div>
            

          </div> */}
          <div className="row margintop3">
            <div className="col-6">
            <img src={eventimg1}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>
            <div className="col-6">
            <img src={eventimg2}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>

          </div>
          <div className="row margintop1">
            <div className="col-6">
            <img src={eventimg3}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>
            <div className="col-6">
            <img src={eventimg2}  className=''style={{  width:"333px",height:"100%"}}/>  

            </div>
            

          </div>

          <div className="row margintop1">
            <div className="col-6 marginleftmins2">
              <div className="row">
              <div className="col-6 textright"><span className="event-sub-detail">Event Type</span></div>
              <div className="col-6"><span className="event-sub-detail1 whitenowrap">House Warming Ceremony </span></div>

              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Event Date</span></div>
                <div className="col-6 "><span className="event-sub-detail1"> 12 Sep 2022</span></div>
              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Event Time</span></div>
                <div className="col-6"><span className="event-sub-detail1">5:00 PM</span></div>
              </div>

            </div>
            
            
            <div className="col-6">
            <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Host Name </span></div>
                <div className="col-6 "><span className="event-sub-detail1">First Name, Last Name</span></div>
              </div>
              <div className="row">
                <div className="col-6 textright"><span className="event-sub-detail">Location</span></div>
                <div className="col-6 "><span className="event-sub-detail1">Address Line1 
               </span>
               <div><span className="event-sub-detail1"> Address Line2 </span></div>
               <div><span className="event-sub-detail1"> City </span></div>

                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              {/* <BiLike className={status==='like'? 'activelike' : ''} onClick={handleClickLike}/><span className="likespn marginleft2"> {likes}</span> */}
              {status==='like'?<AiFillLike color="#FF8427" onClick={handleClickLike}/>:<BiLike onClick={handleClickLike} color="#FF8427"/>}<span className="likespn marginleft2"> {likes} Likes</span>
              </div>
            <div className="col-2"></div>
            <div className="col-3" onClick={commentClick} style={{cursor:"pointer"}}><FaRegComments color="#FF8427"/><span className="likespn marginleft2">0 Comments</span></div>

            <div className="col-2"></div>
            <div className="col-3 report-spm-div"><AiOutlineDislike color="#D30F45	"/><span className="marginleft2 report-spm">Report Spam</span> </div>

          </div>
          <div className={comment ? "margintop3":"hide row"}>
<div className="">
            <Card className="overflow-hidden" style={{height:"auto",border:"none"}}>
              <Card className="mb-lg-0" style={{height:"auto",border:"none"}}>
                
                <Card.Body>
                  <div className="mt-2">
                    <FormGroup className="form-group " >
                      <Form.Control
                        type="text"
                        className="form-control borderadius25px "
                        id="name1"
                        placeholder="Add a Comment"
                        style={{fontSize:"14px",borderRadius:"25px"}}
                      />
                    </FormGroup>
                    
                    <button  className="attendbtn" style={{width:"90px",height:"28px"}}>
                      Send Reply
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Card>
          </div>
<div className="">
            <Card className="" style={{height:"auto",border:"none"}}>
              <Card style={{height:"auto",border:"none"}}>
                
                <Card.Body>
                  <div className="d-sm-flex mt-0 p-3 sub-review-section borderforcomment br-bl-0 br-br-0" style={{borderColor:"rgba(242, 242, 242, 1)"}}>
                    <div className="d-flex me-3">
                      <Link to={`${process.env.PUBLIC_URL}/pages/profile`}>
                        <img
                          className="media-object brround avatar-md"
                          alt="64x64"
                          src={eventlogo}
                        />
                      </Link>
                    </div>
                    <div className="media-body">
                      <Link to="">
                        <h6 className="mt-0 mb-1 font-weight-semibold event-sub-detail">
                          Joanne Scott
                          <span
                            className="tx-14 ms-0 me-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-original-title="verified"
                          >
                            <i className="fe fe-check-circle text-success tx-12 ms-1"></i>
                          </span>
                          
                        </h6>
                      </Link>
                      <p className="font-13  mb-2 mt-2 event-sub-detail1">
                        Lorem ipsum dolor sit amet, quis Neque porro quisquam
                        est, nostrud exercitation ullamco laboris commodo
                        consequat.
                      </p>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" badge-primary">
                          Like
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success ">
                          Reply
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success">
                          Report
                        </Badge>
                      </Link>
                      
                    </div>
                  </div>
                  <div className="d-sm-flex p-3 mt-4 sub-review-section borderforcomment subsection-color br-tl-0 br-tr-0" style={{borderColor:"rgba(242, 242, 242, 1)"}}>
                    <div className="d-flex me-3">
                      <Link to="">
                        <img
                          className="media-object brround avatar-md"
                          alt="64x64"
                          src={eventlogo}
                        />
                      </Link>
                    </div>
                    <div className="media-body">
                      <Link to="">
                        <h6 className="mt-0 mb-1 font-weight-semibold event-sub-detail">
                          Rose Slater
                          <span
                            className="tx-14 ms-0  me-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-original-title="verified"
                          >
                            <i className="fe fe-check-circle text-success tx-12 "></i>
                          </span>
                        </h6>
                      </Link>
                      <p className="font-13  mb-2 mt-2 event-sub-detail1">
                        Lorem ipsum dolor sit amet nostrud exercitation ullamco
                        laboris commodo consequat.
                      </p>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" badge-primary">
                          Like
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success ">
                          Reply
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success">
                          Report
                        </Badge>
                      </Link>
                      
                    </div>
                  </div>
                  <div className="d-sm-flex p-3 mt-4 borderforcomment sub-review-section pb-0">
                    <div className="d-flex me-3">
                      <Link to="">
                        <img
                          className="media-object brround avatar-md"
                          alt="64x64"
                          src={eventlogo}
                        />
                      </Link>
                    </div>
                    <div className="media-body">
                      <Link to="">
                        <h6 className="mt-0 mb-1 font-weight-semibold event-sub-detail">
                          Edward
                          <span
                            className="tx-14 ms-0 me-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-original-title="verified"
                          >
                            <i className="fe fe-check-circle text-success tx-12  ms-1"></i>
                          </span>
                          
                        </h6>
                      </Link>
                      <p className="font-13  mb-2 mt-2 event-sub-detail1">
                        Lorem ipsum dolor sit amet, quis Neque porro quisquam
                        est, nostrud exercitation ullamco laboris commodo
                        consequat.
                      </p>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" badge-primary">
                          Like
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success ">
                          Reply
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success">
                          Report
                        </Badge>
                      </Link>
                      
                    </div>
                  </div>
                  <div className="d-sm-flex p-3 mt-4 sub-review-section borderforcomment subsection-color br-tl-0 br-tr-0" style={{borderColor:"rgba(242, 242, 242, 1)"}}>
                    <div className="d-flex me-3">
                      <Link to="">
                        <img
                          className="media-object brround avatar-md"
                          alt="64x64"
                          src={eventlogo}
                        />
                      </Link>
                    </div>
                    <div className="media-body">
                      <Link to="">
                        <h6 className="mt-0 mb-1 font-weight-semibold event-sub-detail">
                          Camila cabello
                          <span
                            className="tx-14 ms-0 me-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-original-title="verified"
                          >
                            <i className="fe fe-check-circle text-success tx-12  ms-1"></i>
                          </span>
                        </h6>
                      </Link>
                      <p className="font-13  mb-2 mt-2 event-sub-detail1">
                        Lorem ipsum dolor sit amet nostrud exercitation ullamco
                        laboris commodo consequat.
                      </p>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" badge-primary">
                          Like
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success ">
                          Reply
                        </Badge>
                      </Link>
                      <Link to="#" className="me-2 mt-1">
                        <Badge bg="" className=" bg-success">
                          Report
                        </Badge>
                      </Link>
                      
                    </div>
                  </div>
                  <div className="row margintop1" style={{float:"left"}}>
            <span className="showmorefor-coffee">Load More Comments</span>
          </div>
                </Card.Body>
              </Card>
            </Card>
          </div>

    </div>
          </div>

          <div className="cardiv-event">
          <div className="row margintop">
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-1 textright"><span className="evntxt ">Blog</span></div>
              <div className="col-9"><span className="marginleftmins5 event-type-text whitenowrap">The traditional way of celebrating Diwali - The Festival of Light</span></div>
            <div className="col-6 event-date-div"><span className="event-date">August 3 at 4:00 PM</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - Sagar Naidu</span></div>
            </div>

            <div className="row margintop3">
            <img src={blogimg}  className='blogimg'/>
            <div className="img-source-spn">Image Source - Pixabay</div>
            <div className="blog-textdiv margintop1">
              <span className="blog-text">Experience the Diversity of Diwali celebrations this season.</span>
            </div>
            <div className="blog-textdiv margintop3">
              <span className="blog-text">Lone the Diyas outside the door.Sweep the dust from every corner. Line up the boxex of sweets to carry to friends and family. 
                Prepare your newest or finest clothes for the occasion. That's right! Diwali is just around the corner.This is a time for friends and family.
                A time for celebration and introspections. A display of good over evil. Everyone in india,regardless of religion or caste,reveles in the spectacular festival of lights
                .Homes are lit up, and open, inviting the goddess of wealth, luck and prosperity - Lakshmi - into their homes and lives.

              </span>
              <div className="textright"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {status==='like'?<AiFillLike color="#FF8427" onClick={handleClickLike}/>:<BiLike onClick={handleClickLike} color="#FF8427"/>}<span className="likespn marginleft2"> {likes} Likes</span>
            </div>

            </div>
 
            </div>
            </div>
            <div className="cardiv-event">
          <div className="row margintop">
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-1 textright"><span className="evntxt ">KYC</span></div>
              <div className="col-9"><span className="marginleftmins5 event-type-text whitenowrap">Gopalswamy DoraiSwamy Naidu</span><span className="marginleft2 event-location whitenowrap">(23 March 1893- 4 January 19974)</span></div>
            <div className="col-6 event-date-div"><span className="event-date">August 3 at 4:00 PM</span><span className="event-publisher ">Published by ABC Admin</span><span className="marginleft1 blog-auth-text">Auther - Sagar Naidu</span></div>
            </div>

            <div className="row margintop3">
            <img src={kycimg}  className='blogimg'/>
            <div className="img-source-spn">Image Source - Pixabay</div>
            <div className="margintop1 textcenter"><span className="kyc-subheading">Edison of India</span></div>
            <div className="margintop1">
              <span className="blog-text">G.D.Naidu(Gopalswamy DoraiSwamy Naidu)(23 March 1893- 4 January 19974) was an Indian Inventor and engineer who is referred to as 
                the "Edison of India" and  "the wealth creator of Coimbatore".[1][2] He is credited with the manufacture of the first electric motor in India. His contributions
                were primarily industrial but also spanned the fields of electrical, mechanical, agricultural(hybrid cultivation) and
                automobile engineering.[3][4] Naidu developed an independently internal combustion four stroke engine.
                he had only primary education but excelled as aversatile genius. He is also known as "Miracle Man"
              </span>
              </div>
              <div className="textright"><span className="read-more-blog">Read More</span></div>
              <div className="">              
              {status==='like'?<AiFillLike color="#FF8427" onClick={handleClickLike}/>:<BiLike onClick={handleClickLike} color="#FF8427"/>}<span className="likespn marginleft2"> {likes} Likes</span>
            </div>
            <div className="">
              <span className="kyc-ref">References:</span>
              <div className="kyc-ref">1. <Link><span className="link-refrence">G D Naidu “Edison of India” - Dailylist</span></Link></div>
              <div className="kyc-ref">2. <Link><span className="link-refrence">A non-conformist genius Architects of Coimbatore</span></Link>
             </div>
              </div>
            </div>
            
            </div>

            <div className="cardiv-event1">
          <div className="row margintop" >
              <div className="col-1">
              <img src={imgupload}  className='eventlogoimg1'/>
              </div>
              <div className="col-5 "><span className="evntxt-birthday ">Birthday Greetings!</span></div>
            <div className="col-7 event-date-div"><span className="event-date-birthday">August 3 at 4:00 PM</span></div>
            </div>
            
            <div className="textcenter">
              <span className="birdtday-date-text">23 December 2022</span>
            </div>
            <div className="textcenter">
              <span className="birdtday-date-text">Heartiest Birthday wish from Naidu Directory</span>
            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>
            <div className="row margintop" >
            <div className="col-1 "></div>

              <div className="col-2  ">             
               <img src={imgupload}  className='birthday-date-img marginleftmins6'/>
               <span className="birthday-date-nme1">First Name,Last Name</span>
          </div>
          <div className="col-1 "></div>

              <div className="col-2 mr-3 ">
              <img src={imgupload}  className='birthday-date-img2 '/>
              <span className="birthday-date-nme2">First Name,Last Name</span>

              </div>

              <div className="col-2 ml-3 ">
              <img src={imgupload}  className='birthday-date-img3 '/>
              <span className="birthday-date-nme3">First Name,Last Name</span>

              </div>
              <div className="col-1 "></div>

              <div className="col-2 mr-3 marginleftmins">
              <img src={imgupload}  className='birthday-date-img'/>
              <span className="birthday-date-nme">First Name,Last Name</span>

              </div>
              <div className="col-1  "></div>


            </div>



            </div>
            <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">
            <button className="attendbtn" style={{marginTop:'6px',position:"absolute"}}>Attend</button>
          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>05 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div>

          <div className="cardiv-coffee margintop border1px">
          <div className="row margintop3">
            <div className="col-9">
                <span className="event-sub-detail">01 Feb 2023 </span><span className="event-sub-detail" style={{marginLeft:'12px'}}>Pune</span>
            </div>
            <div className="col-2">

            <div class="dropdown">
  <button class="btn  dropdown-toggle attendbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{width:"160px"}}>
  You are attending
  </button>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
    <a class="dropdown-item" href="#" style={{fontSize:'14px',fontFamily:"Source Sans Pro"}}>Withdraw Attendance</a>
  </div>
</div>

          </div>
          </div>
          
          <div className="row margintop2">
            <div className="">
                <span className="event-sub-detail">Venue:- </span>
                <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>Good Luck Cafe, FC Road, Pune 01</span>
                <span className="bluetextforlink" style={{margin:"7px",fontSize:'10px'}}>Check on Google Maps</span>
            </div>
          </div>
          <div className="row margintop3">
      <div className="">
      <span className="event-sub-detail1" style={{margin:"5px",fontSize:'13px'}}>05 Members are attending this Coffee Meet Up</span>
    </div>
     </div>
     <div className="row margintop">
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
            <div><span className="event-sub-detail1" style={{fontSize:'10px',marginLeft:'27px'}}>First Name</span></div>
        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
               <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        <div className="col-2">
        <img src={imgupload}  className=' ' style={{width:"100px",height:"100px",borderRadius:"50%"}}/>
        <div><span className="event-sub-detail1" style={{fontSize:'10px' ,marginLeft:'27px'}}>First Name</span></div>

        </div>
        

     </div>
          <div className="row margintop3"></div>
          </div>
            
          <div className="row margintop"></div>
          
          </div>

          <div className={communityDirect?"col-6  communitydiv":"hide"}>
            <div className="margintop3 row">
              <div className="col-3 searchdiv">Search Member</div>
              <div className="col-1"><FaFilter size='1.2em'/></div>
              <div className="col-4"></div>
              <div className="col-4 ">
              <select   id="inputState" name='' 
            className="form-control drpdowncmdirect " >
            <option selected value=""> All</option>
            <option value="House Warming Ceremony">Country</option>
            <option value="Birthday">State</option>
            <option value="Marrige Anniversary">City</option>
            <option value="Death Anniversary">Profession</option>
            <option value="Others">Blood Group</option>
            <option value="Others">Bookmark Profile</option>

          </select>
              </div>
      
            </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text contct-infohover" onClick={togglePopupContact} >Contact Info </span>  <span className="comudrct-digitxt"></span>
                </div>
                {isOpenContactPopup && <ContactInfoPopup
      
      handleClose={togglePopupContact}
    />}
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">
            <img src={connect}  className='marginleft6 communitydrect-img1'/>

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Community Connect</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-3 margintop1"><span className="community-direct-nametxt">Swapnaili Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins1' />
        </div>
        <div className="col-4 marginleftmins4 margintop1"><span className="marginleftmins1 community-country">India</span><span className="communi-field1 marginleft1">Women Enterpreneur</span></div>
        <div className="col-1"></div>
        <div className="col-3 whitenowrap marginleft2 messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-5"></div>
            
            <div className="col-2  textcenter">
              <button className="com-conect-btn">Connect</button>
                       
           </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>
        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>

        <div className="communit-cardfirst margintop3">
          <div className="row  cardfirst-toprow">
        <div className="col-2 margintop1"><span className="community-direct-nametxt">Sagar Naidu</span></div>
        <div className="col-1  textleft">
          <img src={flag} className='margintop2 marginleftmins' />
        </div>
        <div className="col-4 margintop1"><span className="marginleftmins6 community-country">India</span><span className="communi-field marginleft1">Information Technology</span></div>
        <div className="col-2"></div>
        <div className="col-3 whitenowrap messg-div"> <RiSendPlaneFill size='1.3em' className='marginleftmins5'/>   Message Sagar
      </div>
          </div>

          <div className="row margintop3">
            <div className="col-3">
            <img src={profile}  className='communitydir-prof'/>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={imgupload}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">CID #</span>  <span className="comudrct-digitxt">0000001</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdWaterDrop size='1.3em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Blood Group -</span>  <span className="comudrct-digitxt">B+ve</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={subcast}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Sub Cast -</span>  <span className="comudrct-digitxt">Balija Naidu</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={refferal}  className='communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Referrals #</span>  <span className="comudrct-digitxt">34</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <RiCupFill color="#555555" size='1.3em'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Cups of Coffee #</span>  <span className="comudrct-digitxt">112</span>
                </div>
           </div>
            </div>
            
            <div className="col-4 margintop1">
              <div className="row margintop2">
                <div className="col-1">             
                 <img src={from}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">From -</span>  <span className="comudrct-digitxt">Latur</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">   
                <MdLocationOn size='1.4em' color='#555555'/>          
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Lives in -</span>  <span className="comudrct-digitxt">Pune, Maharashtra</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                 <img src={radius}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Distance radius -</span>  <span className="comudrct-digitxt">15 Kms approx</span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                    <BsBookmark color="#555555" size='1.3em'/>         
                      </div>
                <div className="col-10 marginleft4 whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Bookmark Profile </span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
           <div className="row margintop2">
                <div className="col-1">             
                <img src={contactinfo}  className=' communitydrect-img'/>
              </div>
                <div className="col-10 marginleft4  whitenowrap textleft">
                <span className="marginleft4 communtdrc-cit-text">Contact Info</span>  <span className="comudrct-digitxt"></span>
                </div>
           </div>
            </div>
          </div>
          <div className="row margintop1">
            <div className="col-1"></div>
            <div className="col-2">
            <span className=" community-viewprof">View Profile</span>

            </div>
            <div className="col-3"></div>
            <div className="col-1">

            </div>
            <div className="col-3 textcenter">
              <span className="communityconnect-txt">Connect Request Sent</span>
            </div>
            <div className="col-2 cuminvite-div">  
             <RiCupFill color="#FEFDFA" size='1.3em'/>
          <span className="cominvite-text">Invite</span>
           </div>
       </div>
       <div className="row margintop1"></div>
        </div>
        <div className="row margintop1"></div>
        
        
        </div>
          
          
          <div className="col-3 bloodreq-head-div right-div">
          <div className="row  margintop1">
        <div className="borderbottom-radius ">
        <span className="radius-heding">Urgent Blood Requirement</span>   <MdAddBox size='1.5em' onClick={togglePopupBlood}/>
        {isOpenBloodPopup && <BloodRequirePopup
      
      handleClose={togglePopupBlood}
    />}
        <div className="radius-div11 " style={{marginTop:4,paddingTop:0}}>
          <span className="rightdiv-kycnote-text">KYC Note</span>
          <SliderPage/>
        </div>
        </div>

        <div className="borderbottom-radius ">
        <span className="radius-heding">Community Business</span> <span className="sponser-text">Sponsored</span>
        <div className="radius-div11 " style={{marginTop:4,paddingTop:0}}>
          <div className="backimg-businessdirect">
            
          </div>
        </div>
        </div>


        <div className="borderbottom-radius ">
        <span className="radius-heding">Job Openings</span> 
        <div className="radius-div11 " style={{marginTop:4,paddingTop:0}}>
          <div className="row margintop3"></div>
          <div className="row margintop3" >
            <div className="col-5">
            <lable className='radius-fourth-card1-lefttext'>Sales Executive</lable>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
            <lable className='radius-fourth-card1-lefttext'>Mumbai</lable>

            </div>
          </div>
          <div className="row margintop2">
            <span className="radius-fourth-card1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            </span>
          </div>

          <div className="row margintop3" >
            <div className="col-5">
            <lable className='radius-fourth-card1-lefttext'>Shop Assistant</lable>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
            <lable className='radius-fourth-card1-lefttext'>Pune</lable>

            </div>
          </div>
          <div className="row margintop2">
            <span className="radius-fourth-card1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            </span>
          </div>

          <div className="row margintop3" >
            <div className="col-5">
            <lable className='radius-fourth-card1-lefttext'>Graphics Designer</lable>
            </div>
            <div className="col-1"></div>
            <div className="col-4">
            <lable className='radius-fourth-card1-lefttext'>Nasik</lable>

            </div>
          </div>
          <div className="row margintop2">
            <span className="radius-fourth-card1-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            </span>
          </div>

<div className="row">
  <span style={{textAlign:'right'}} className='seall-text'>See All</span>
</div>
        
       
       
        </div>
        </div>


        <div className="borderbottom-radius ">
        <span className="radius-heding">My Community Messenger</span> <FaEdit style={{marginLeft:'70px'}}/>
        <br/>
        <div className="radius-div11  " style={{marginTop:4,paddingTop:6}}>
          {/* <div className="row margintop3">
            <input type='search' className="margintop1" placeholder="Search Messages" style={{borderColor:'lightgray',borderTopColor:"lightgray",borderLeftColor:"lightgray",fontSize:'14px'}}/>
          </div> */}
          <div class="form-group has-search margintop3">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="text" class="form-control" placeholder="Search Messages"  style={{fontSize:'14px'}}/>
  </div>


          <div className="radius-fourth-card1 margintop3" onClick={togglePopupMessage}>
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'60px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div>
            {isOpenMessagePop && <MessagePopup
      
      handleClose={togglePopupMessage}
    />}
            


            <div className="radius-fourth-card1 ">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'60px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div>
        
            <div className="radius-fourth-card1 ">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'60px'}}>10:30</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div>

            <div className="radius-fourth-card1 ">
          <div className="row margintop1">
            <div className="col-3 margintop1">
            <img src={conprof}  className='margintop1'/>
            </div>
            <div className="col-9">
              <div className=""><span className="radius-fourth-card1-lefttext ">Sagar Naidu</span>  <span className="radius-fourth-card1-text" style={{marginLeft:'40px',whiteSpace:'nowrap'}}>32 Oct,2022</span>
     </div>
              <div>
              <span className=" message-text" style={{lineHeight: '80%'}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum

                </span>
              </div>
 
            </div>
            
            </div>
            </div>
        
        
        
        </div>
        </div>


        </div>
        



          </div>

        </div>

      


      
    </div>
  );
};
 
export default UserLandingTest;