import React, { useState,useEffect } from "react";
import logo from '../../images/Naidus directory.png';
import './SignUpBusiness.css';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom"

import BusinessDetail from "./BusinessDetail";
import RegisterAndCommunity from "./RegisterAndCommunity";
import BusinessContactDetail from "./BusinessContactDetail";
import {configData} from "../../Config"


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Business Details", "Business Contact Details", "Register & Community Pledge"
  ];
}



const SignUpBusiness = (props) => {

  //for session
  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
//   const [activeStep1, setActiveStep1] = useState(0);

//   var newStepValue=0;
//   if(activeStep1 < 0)
//   {
//     newStepValue = activeStep1+2;
// }
// else if(activeStep1>0){
//   newStepValue=activeStep1;
// }

 
//   else{
//   const [activeStep, setActiveStep] = useState(0);
// }
  const steps = getSteps();
//debugger
   const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
 console.log("authdata11",authlocalstoragedata)


// //for new session for userlogin
// const redirecToLandingdata =  () => {
 
//   if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//     history.push("/");

//   }
// }
   
//    useEffect(() => {
//     redirecToLandingdata();
//  });
  const getStepContent = (value) => {
    //debugger;
    // if(newStepValue==2){
    //   //setActiveStep(activeStep==2)
    //   return <SubscribeCommunity handleNext={handleNext} basicInfo={basicInfo} setBasicInfo={setBasicInfo} handleBack={handleBack}/>;
    // }
     if (activeStep === 0) {
      return <BusinessDetail handleNext={handleNext} businessSign={businessSign} setbusinessSign={setbusinessSign} handleBack1={handleBack}  resendOtp={resendOtp}  confirmPass={confirmPass} setConfirmPass={setConfirmPass} confirmPassErr={confirmPassErr} setconfirmPassErr={setconfirmPassErr} setOtpNew={setOtpNew} otpNew={otpNew}  />;
    } else if (activeStep === 1) {
      return <BusinessContactDetail handleNext={handleNext} businessSign={businessSign} setbusinessSign={setbusinessSign}   resendOtp={resendOtp} addBasicInfo={addBasicInfo} handleBack1={handleBack}  />;
    } else {
      debugger;
      return <RegisterAndCommunity handleNext1={handleNext1} businessSign={businessSign} setbusinessSign={setbusinessSign} joinNowRes={joinNowRes} setjoinNowRes={setjoinNowRes} handleBack1={handleBack1} Paymentreq={Paymentreq} setPaymentreq={setPaymentreq} />;
    }
  };

  
//for otp verify basicinfo


const [otpNew, setOtpNew] = useState('');


 const [confirmPass,setConfirmPass]= useState({
  confirmPassword:"",
  

 })



 const handleVerifyOptp =async()=>{
  debugger;
  if(authlocalstoragedata?.countryCode=="+91"||authlocalstoragedata?.countryCode=="91"||authlocalstoragedata?.countryCode==" 91"){
  const result = await axios.get(configData.ApiUrl+'/buisness/validateMobileOtp?mobileNo='+
  authlocalstoragedata?.mobileNumber+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
   // addBasicInfo();
   // newStepValue=1;
   setActiveStep(activeStep + 1);
   //newStepValue =newStepValue+1;
    //newStepValue =newStepValue+1;

  }
  else{
    alert("fail")
    //newStepValue=0;
    setActiveStep(0);

  }
}
else{

  const result = await axios.get(configData.ApiUrl+'/buisness/validateEmailOtp?emailId='+
  businessSign.emailId+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
   // addBasicInfo();
    //newStepValue =newStepValue+1;
    setActiveStep(activeStep + 1);
         // newStepValue =newStepValue+1;
    //newStepValue=1;

    
  }
  else{
    alert("fail")
    setActiveStep(0);


  }
}

}



const { id } = useParams();

 //add basic info
 const [businessSign,setbusinessSign]= useState({
  userId:authlocalstoragedata?.userAuthenticationId,
  userName:authlocalstoragedata?.name2,
	 buisnessName:"",
	 buisnessType:"",
	 designation:"",
	 womenEntrepreneur:"",
	 countryCode:"",
	 mobileNumber:authlocalstoragedata?.mobileNumber,
	 primaryBuisnessCountryCode:"",
	 primaryBuisnessMobileNumber:"",
	 secondaryBuisnessCountryCode:"",
	 secondaryBuisnessMobileNumber:"",
	 emailId:authlocalstoragedata?.emailId,
	 buisnessEmailId:"",
	 registeredGst:"",
	 gstNumber:"",
	 haveWebsite:"",
	 websiteUrl:"",

 })
    const [confirmPassErr,setconfirmPassErr]= useState(false)

    let history = useHistory();

    const [joinNowRes,setjoinNowRes]= useState()


    const [Paymentreq, setPaymentreq]= useState({
      nameId:{joinNowRes},
      amount:"",
      productType:'Community',
      currency:'INR',
      code:"",

      });

    //setPaymentreq(joinNowRes);

//payment req
const paymentRequest =()=>{
  debugger

  
    const response = axios.post(configData.ApiUrl+'/user/payment-request', Paymentreq)
    response.then((result) => {
      console.log("Paymentres response",result.data.data);
      debugger
      if (result.data.data != null) {
          toast.success(" Successfully!");

         // localStorage.setItem('id',result.data.data.id);
          //setjoinNowRes(result.data.data)
          debugger;
          //localStorage.setItem('name',result.data.data.name);
         // history.push("/CompleteProfile")

  
        }
          //toast.success("Last Name  has been added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

      
}

const [updatedData, setupdatedData] = useState(false);

 const loadUpdatedData = async () => {
   debugger;
   const result = await axios.get(`${configData.ApiUrl}/getUpdatedData?userId=${authlocalstoragedata.userAuthenticationId}`);
   setupdatedData(result.data.data);

   
console.log("updatedadata",updatedData)
 }; 

const addBasicInfo =()=>{
  debugger

  
    const response = axios.post(configData.ApiUrl+'/buisness/addBuisnessUser', businessSign)
    response.then((result) => {
     // toast.success("Basic Detail Added Successfully!");

      console.log("business response",result.data.data);
      debugger
       if (result.data.data != null) {
           toast.success("Basic Details Added Successfully!");
           //localStorage.setItem('signupbussData',JSON.stringify(result.data.data));

           localStorage.setItem('id',result.data.data.id);
           debugger;
         localStorage.setItem('buisnessName',result.data.data.buisnessName);
         var storageUpdate = JSON.parse(localStorage.getItem("authdata"));
           if(storageUpdate !=null)
           {
            storageUpdate.buisnessId = result.data.data.id;
           }
           
           localStorage.setItem("authdata",JSON.stringify(storageUpdate));
         loadUpdatedData()


     history.push("/BusinessCompleteProfile")

  
        }
        setActiveStep(activeStep + 1);
          //newStepValue =newStepValue+1;
          //toast.success("Last Name  has been added Successfully!");
   })
       response.catch((err) => {
         alert("Getting error in featching data")
       });
       console.log("response", response?.data)

      
}

//resendotp
const resendOtp = async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  businessSign.mobileNumber+ '&emailId='+businessSign.emailId+'&countryCode='+businessSign.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

   const isStepOptional = (step) => {
     return step === 4 || step === 5;
   };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };
  const handleNext = async() => {
    if (activeStep === 0) {

      debugger
      handleVerifyOptp()
    }
      
  if (activeStep === 1) {
    debugger
    addBasicInfo();
  }

  if (activeStep === 2) {
    debugger
    paymentRequest();
  }
  if(activeStep !=0){
    setActiveStep(activeStep + 1);
    //newStepValue =newStepValue+1;
}
    // setSkippedSteps(skippedSteps.filter((skipItem) => skipItem !== activeStep));
  };
  const handleNext1 = async()=>{
    debugger
    setActiveStep(activeStep + 1);

  }
  const handleBack1 = async()=>{
    debugger
    setActiveStep(activeStep - 1);

  }


  const handleBack = () => {
    //
    debugger;
    //newStepValue =newStepValue-1;
    //getStepContent(newStepValue);
    setActiveStep(activeStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepSkipped(activeStep)) {
  //     setSkippedSteps([...skippedSteps, activeStep]);
  //   }
  //   setActiveStep(activeStep + 1);
  // };

  // const handleBackData = () => {
  //   setActiveStep(activeStep +2);
  // };
//   if(authlocalstoragedata != null && authlocalstoragedata?.directory==false && activeStep === 0)
//   {
//     debugger;
//     newStepValue=2;

//   }
  

  return (
    <div className="popup-boxSign">
      <div className="boxSign">
      <span className="close-iconSign" onClick={props.handleClose}>x</span>

    <div className=" "style={{}}>

          <div className="margintop1  row">
            
            {/* <div className="col-1"></div> */}
            <div className="col-12">
<div className="demo1">    
<Stepper alternativeLabel activeStep={activeStep} style={{width:"50px !important"}}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
             {/* {joinpoupopen(true)}         */}
             </Typography>
      ) : (
        <>
        <div className="row marglrftrightminusrow">
          <div className={activeStep==0?"col-11":"col-12"}>
          <div className="">{getStepContent(activeStep)}</div> 
         
         {/* { authlocalstoragedata?.directory==false? (
          <div className="">{getStepContent(newStepValue)}</div>

         ):(
          <div className="">{getStepContent(newStepValue)}</div> 

         )


          

          
} */}

          
          {/* <div className="margintop1 row ">
            <div className="col-3 marginleftmins4"></div>
            <div className="col-3 margintop textright">
            <button
               className={activeStep === 0?"displynoneprev":"prevbtn sourcesansfont"}
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop ">
          <button
            className={activeStep === 0?"nextbtn sourcesansfont":activeStep === 2?"nextbtn2 sourcesansfont ":"nextbtn1 sourcesansfont"}
              variant="contained"
              color="primary"
               onClick={handleNext}
              type="submit"
            >
              {activeStep === steps.length - 1 ? "Submit And Proceed to Pay" : "Next"}
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className={activeStep === 1?"displynoneprev":activeStep === 2?"displynoneprev":"margintop textcenter row"}>
            <div className="col-4"></div>
            <div className="margintop col-4">
            <span className="alreadyspn marginleftmins1">Already a registered member ?</span>
            <span className="signin-stepper marginleft5" onClick={redirectoLogin} style={{cursor:"pointer"}}>Sign In</span>
            </div>
           
            </div>

            <div className={activeStep === 2?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row"}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">Mobile verified badge is shown on your profile once you verify your mobile </span>
            </div>
           
            </div>

            <div className={activeStep === 1?"displynoneprev":activeStep === 0?"displynoneprev":" textcenter row "}>
            <div className="col-3"></div>
            <div className="margintop col-6">
            <span className="alreadyspn1 ">You will be redirect to Payment Gateway once you click on Submit button </span>
            </div>
           
            </div>
            
          </div> */}
         
          </div>
          <br/>
        </div>
        </>
      )}
</div>

</div>
          </div>
      
          

    </div>
    </div>
    </div>
  );
  
};

export default SignUpBusiness;