import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {FiFilter} from 'react-icons/fi';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { duration } from 'moment'
import BloodReqCheckPopup from "./BloodReqCheckDetailPopup";
import BloodReqCheckDetailMyReqPop from "./BloodReqCheckDetailMyReqPop";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';
import {configData} from "../../Config"
import {AiOutlineClose} from 'react-icons/ai'
import './ReferCommMemberPopup.css'
import { MdOutlineFileDownload } from "react-icons/md";
import logo from '../../images/Naidus directory.png';
import { MdCall } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";


import { toast } from "react-toastify";

 
const ReceiptPopup =(props)=>{
  
 

    //for session

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  



    
      //for close popup when click on outside
      const popupRef = useRef(null);
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setmarkAsDiv(false);
          resetStates();
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      

      
      

  return (
    <div className="popup-boxrefmember" >
    <div className="boxrefmember"style={{width:"46%",marginTop:"100px",maxHeight:"83vh"}} >
      <div className="topnewsrow " style={{backgroundColor:"#1B3D0B",color:"#ffffff"}}>
      <div className="row">
        <div className="col-4"></div>
                <div className="col-7">                 
                    <img src={logo} style={{width:"185px", height:"70px"}}/>
                </div>
                <div className="col-1"style={{float:"right"}}>
                <span className="lastpop-top-text" onClick={props.handleClose} style={{cursor:"pointer",color:"#ffffff",marginLeft:"px"}}><AiOutlineClose size="1.3em"/></span>

                  </div>
                </div>
      <div className="row">
                <div className="col-3"></div>
                <div className="col-7 textcenter" style={{marginLeft:"-5px"}}>
                <span className=" blood-top-text" style={{color:"#ffffff",fontSize:"20px",fontWeight:"500"}}>Customer Receipt</span>
                {/* <div> <span className=" blood-top-text"style={{color:"#ffffff",fontSize:"16px",fontWeight:"500",marginTop:"7px"}}>Thank you for your payment!</span>
                </div> */}
                 {/* <MdFileCopy color='#fff' className="marginleft2"style={{color:"#555555"}}/>
                 <FaShareAltSquare color='#fff' className="marginleft5"style={{color:"#555555"}}/> */}
                 </div>
                 
                 <div className='col-1' style={{float:"",}}>
                 
                 <MdOutlineFileDownload className="downloadicon"size="1.6em" color="#ffffff" backgroundColor="#FF8427"style={{marginTop:"0px"}}/>
                 
                 </div>
            </div>
        </div>
        <div style={{}}>

        <div className="margintop row">
  <div className="col-12">
  <span for="inputState" className="lbl " style={{fontSize:"15px",fontWeight:"500",color:"#555555"}}>Your Subscription to <span style={{fontWeight:"bold"}}>Community Matrimony</span>  has been Successfully processed. Below are the details of your transaction</span>
  </div>
   </div>
   
   <div className="margintop row">
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Order Id</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>ORD-127654651</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Transaction Id</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>#127654651</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Date & Time</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>5 November 2024, 8:15 am</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>Amount Paid</span>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>419:00 Rs</span>

  </div>
  
  </div>
   

    
    
    
    
    
                  </div>
                  <div className='popupbtn-div ' style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",paddingBottom:"2px"}}>
<div className="row textright">
<div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"600",color:"#555555"}}> Subscription Details</span></div>
  </div>
</div>
                  <div className="row">
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Service</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>Matrimony</span>

  </div>
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Plan</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"#555555"}}>Gold Plan</span>

  </div>
  
  </div>

    </div>
    <div className='popupbtn-div 'style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",borderTop:"none",marginTop:"4px",paddingBottom:"2px"}}>

                  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Sub Total</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"black"}}>499:00 rs</span>

  </div>
  
  </div>
  <div className="row"style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}> Discount</span></div>
  </div>
  <div className="col-6  textright form-group">
  <span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"black"}}> -99 rs</span>

  </div>
  
  </div>

    </div>
    <div className='popupbtn-div 'style={{marginLeft:"0px",marginRight:'0px',borderLeft:"none",borderRight:"none",borderTop:"none",marginTop:"4px",paddingBottom:"2px"}}>

<div className="row"style={{marginTop:"-7px"}}>
<div className="col-6 textleft ">
<div className=""><span for="inputState" className="lbl " style={{fontSize:"14px",fontWeight:"500",color:"gray"}}>  Total</span></div>
</div>
<div className="col-6  textright form-group">
<span for="inputState" className="lbl " style={{fontSize:"18px",fontWeight:"600",color:"gray"}}>419:00 rs</span>

</div>

</div>
<div className="row" style={{marginTop:"7px"}}>
  <div className="col-8 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> If You need assistance with your payment, please contact</span></div>
  </div>
  
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> <MdCall/> 9100000000</span></div>
  </div>
  
  
  </div>
  <div className="row" style={{marginTop:"-7px"}}>
  <div className="col-6 textleft ">
  <div className=""><span for="inputState" className="lbl " style={{fontSize:"10px",fontWeight:"500",color:"gray"}}> <MdOutlineEmail/> support@gmail.com</span></div>
  </div>
  
  
  </div>

</div>

        </div>

        
    </div>

           );
}
 
export default ReceiptPopup;