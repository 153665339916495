import React from "react";
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import {configData} from "../../Config"


const MySubscriptionMatrimony =()=>{
  
  

  const columns = [
  
    
    {
      name: "Date",
     selector: "date",
      sortable: true,
      
   },
  {
    name: "Plan",
   selector: "plan",
    sortable: true,
    width:"100px"
},
{
  name: "Profile Views",
 selector: "profileviews",
  sortable: true,
  className:"texttransformcapitalize"

  
},
{
    name: "Validity",
   selector: "validity",
    sortable: true,
   className:"texttransformcapitalize"
    
},
{
    name: "Remaining Views",
   selector: "remainingviews",
    sortable: true,

    
},

  
  ];

  const data = [
  {
    date:"1 May 2023",
    plan: "Gold",
    profileviews: "100",
     validity: "01 Nov 2023",
    remainingviews: "01",
     

  },
  {
    date:"1 May 2023",
    plan: "Silver",
    profileviews: "50",
    validity: "01 Nov 2023",
    remainingviews: "0",
     

    },
    
 ] 
              
          const tableData = {
                    columns,
                    data,
                    
                  };
    
    return(
<div>
<DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available" //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions>
</div>
    )
}
export default MySubscriptionMatrimony




