import './SignUpBusiness';
import React, { useState, useEffect } from "react";
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import csc from "country-state-city";
import Select from "react-select";
import { event } from 'jquery';
import OtpInput from 'react-otp-input';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Countrycode } from '../JoinNow/Countrycode';
import {configData} from "../../Config"



const BusinessContactDetail=({ setbusinessSign, businessSign, handleNext, handleBack,setOtpNew,otpNew })=> {

  //for countrycode

  //for country dropdown
//   const countries = csc.getAllCountries();
//   //console.log(csc.getAllCountries())

//   const updatedCountries = countries.map((country) => ({
//       label: country.name,
//       value: country.id,
//       ...country
//   }));


//   const [isOpen, setIsOpen] = useState(false);
//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   }
//   const [isOpencast, setIsOpencast] = useState(false);
//   const togglePopupcast = () => {
//     setIsOpencast(!isOpencast);
//   }
//   const [contactValidEroor ,setContactValidEroor] = useState(false);
//   const [passwordEroor ,setpasswordEroor] = useState(false);
//   const [emailEroor ,setemailEroor] = useState(false);
//   const [FirstNameEroor ,setFirstNameEroor] = useState(false);
//   const [MiddleNameEroor ,setMiddleNameEroor] = useState(false);

  
//   const [phonevalue, setPhoneValue] = useState()


  //for  add basic info
//   const submitBasicFormData = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
//     if (validator.isEmpty(basicInfo.firstName)) {
//         setError(true);
//     }
//     else if (!/^[a-zA-Z]*$/g.test(basicInfo.firstName)) {
//       setFirstNameEroor(true);
//     }
//     else if (validator.isEmpty(basicInfo.emailId)) {
//         setError(true);
//     }
//     else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(basicInfo.emailId)) {
//       setemailEroor(true);
//     }
//     else if (validator.isEmpty(basicInfo.middleName)) {
//       setError(true);
//     }
//     else if (!/^[a-zA-Z]*$/g.test(basicInfo.middleName)) {
//       setMiddleNameEroor(true);
//     }
//     else if (validator.isEmpty(basicInfo.lastName)) {
//         setError(true);

//     }
//     else if (validator.isEmpty(basicInfo.nationality)) {
//       setError(true);

//   }
//     else if (validator.isEmpty(basicInfo.religion)) {
//       setError(true);

//   }
//   else if (validator.isEmpty(basicInfo.subCaste)) {
//     setError(true);

// }
// else if (validator.isEmpty(basicInfo.countryCode)) {
//   setError(true);

// }
// else if (validator.isEmpty(basicInfo.mobileNumber)) {
//   setError(true);

// }
// else if (basicInfo.mobileNumber.length != 10) {
//   setError(true);

// }
// else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(basicInfo.mobileNumber)){
//   setContactValidEroor(true);
  
// }
// else if (validator.isEmpty(basicInfo.password)) {
//   setError(true);

// }
// else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/.test(basicInfo.password)){
//   setpasswordEroor(true);
  
// }

//  else if (basicInfo.password != confirmPass.confirmPassword) {
//    setconfirmPassErr(true);

//  }
    
//     else {
//       debugger
//       handleNext();
//     }
// };

// console.log('countrycode',basicInfo.countryCode)
  

 let history = useHistory();


 const redirectoLogin = () => {
   history.push("/Login") 
 };
  
 //for sub cast pop
//   const [subCastPop ,setsubCastPop] = useState(false);
// function subCastpoupopen (isSubCast){
//   if(isSubCast){
//     debugger
//     setsubCastPop(true);
//   }
//   else{
//     setsubCastPop(false);
//   }
// }

// const [isOpendivCast, setIsOpendivCast] = useState(true);
//   const [isOtpDivCast, setIsotpDivCast] = useState(false);

//   const onNextCast = (isbackCast) => {
//    debugger
//     if(isbackCast){

//       setIsOpendivCast(true);
//       setIsotpDivCast(false);
//     }
//     else{
//       setIsOpendivCast(false);
//       setIsotpDivCast(true);
//       sendOtpCast();
//     }
//     return
//   }

//   const [subCast,setsubCast]= useState({
//     id:"",
//     spelling:"",
//     countryCode:"",
//     mobileNumber:"",
//     emailId:""

//    })

//    const submitFormDataCast = (e) => {
//     debugger
//     e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
//     if (validator.isEmpty(subCast.spelling)) {
//         setError(true);
//     }
//      else if (!/^[a-zA-Z]*$/g.test(subCast.spelling)) {
//       setMiddleNameEroor(true);
//     }
//     else if (validator.isEmpty(subCast.emailId)) {
//         setError(true);
//     }
//     else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(subCast.emailId)) {
//       setemailEroor(true);
//     }
//     else if (validator.isEmpty(subCast.mobileNumber)) {
//       setError(true);

//     }
//     else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(subCast.mobileNumber)){
//       setContactValidEroor(true);
      
//     }
//     else if (subCast.mobileNumber.length != 10) {
//       setError(true);
    
//     }
//     else if (validator.isEmpty(subCast.countryCode)) {
//         setError(true);

//     }
    
    
//     else {
//       debugger
//       if(isOtpDivCast){
//       sendOtpCast();}
//     }
// };

// const sendOtpCast = async()=>{
//   if(isOpendivCast){
  
//   const result = await axios.get('/user/sendUserOTP?mobileNo='+
//   subCast.mobileNumber+ '&emailId='+subCast.emailId+'&countryCode='+subCast.countryCode);
//   console.log("sendotpcast",result.data.data)
//     if(result.data.code==200){
//       toast.success("Otp sent!")

//       setIsotpDivCast(true);
//       setIsOpendivCast(false);
  
//     }
//     else if(result.data.code==400){
//       toast.warn("Mobile no Already Exist!");
//       setIsotpDivCast(false);
//       setIsOpendivCast(true);

//      }
//      else if(result.data.code==409){
//       toast.warn("Email Already Exist!");
//       setIsotpDivCast(false);
//       setIsOpendivCast(true);

//      }
//     else{
//       toast.warn("Otp sent fail!")

//       setIsotpDivCast(false);
//       setIsOpendivCast(true);
  
  
//     }
//   }
  
//  }

//  const handleVerifyOptpCast =async()=>{
//   debugger;
//   if(subCast.countryCode=="+91"){

//   // const result = await axios.get('/user/validateMobileOtp?mobileNo='+
//   // subCast.mobileNumber+ '&otp='+otp.otpNum1+otp.otpNum2+otp.otpNum3+otp.otpNum4);
//   const result = await axios.get('/user/validateMobileOtp?mobileNo='+
//    subCast.mobileNumber+ '&otp='+otpNew);
  
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp Verified!");
//     addNewCast()

//   }
//   else{
//     toast.warn("Otp Does not match!");

//   }
// }
// else{

//   const result = await axios.get('/user/validateEmailOtp?emailId='+
//   subCast.emailId+ '&otp='+otpNew);
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp Verified!");
//     //togglePopup()
//     addNewCast()
//   }
//   else{
//     toast.warn("Otp Does not match!");

//   }
// }

// }

// const addNewCast =(e)=>{
//   const response = axios.post('/user/addNewSubCaste', subCast)
//     response.then(() => {
//        toast.success("sub Cast  has been added Successfully!");
//         togglePopup()
//         subCastpoupopen(false)
//     })
//     response.catch((err) => {
//       alert("Getting error in featching data")
//     });
//     console.log("response", response?.data)
// }

//   //for last name pop
//   const [lastnamePop ,setlastnamePop] = useState(false);
// function lastNamepoupopen (isLastname){
//   if(isLastname){
//     debugger
//     setlastnamePop(true);
//   }
//   else{
//     setlastnamePop(false);
//   }
// }



// const [isOpendiv, setIsOpendiv] = useState(true);
//   const [isOtpDiv, setIsotpDiv] = useState(false);

//   const onNext = (isback) => {
//    debugger
//     if(isback){

//       setIsOpendiv(true);
//       setIsotpDiv(false);
//     }
//     else{
//       setIsOpendiv(false);
//       setIsotpDiv(true);
//       sendOtp();
//     }
//     return
//   }

//   const [enablejoin, setenableResend] = useState(false);


//   const counterandbtn =()=>{
//     setCounter(30);
//     setenableResend(false);
//    resendOtp()     
//   }
  
//       const [counter, setCounter]= useState(46)
;
//     useEffect(() =>{
//       if(counter === 0){
//         setenableResend(true);
    
//       }
      
//       const timer =
//       counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
//       return () => clearInterval(timer);
//     },[counter]);

const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)
    


//   const mobileNumberFormate = lastname.mobileNumber.substr(0, 2) + 'xxxxxx' 
//    + lastname.mobileNumber.substr(8, 9);
   
//    //for sucast mobile
//    const mobileNumberFormate1 = subCast.mobileNumber.substr(0, 2) + 'xxxxxx' 
//    + subCast.mobileNumber.substr(8, 9);


//    //for lastname emeil
//    let email = lastname.emailId
//    let chunks = email.split("@");
//    let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`

//    //for subcast
//    let emailsubcast = subCast.emailId
//    let chunkssubcast = emailsubcast.split("@");
//    let Emailsubcast = `${chunkssubcast[0].slice(0, 3)}xxxxxx${chunkssubcast[0].substr(chunkssubcast[0].length - 3)}@${chunkssubcast[1]}`
   
//    const onchangecountry=(e)=>{
//     debugger
//     const {name,value,type,checked}=e.currentTarget;
//     debugger;
//    }

    

   

    const handleChange=(e)=>{
     const {name,value,type,checked}=e.currentTarget;
    
    //  setLastname(preState =>({
    //    ...preState,
    //    [name]:type ==='checkbox'?checked: value
    //  }))
     
    
    setbusinessSign(preState =>({
       ...preState,
       [name]:type ==='checkbox'?checked: value
     }))
    
//     setConfirmPass(preState =>({
//       ...preState,
//       [name]:type ==='checkbox'?checked: value
//     }))
  
   }

    const [error, setError] = useState(false);
    const [contactValidEroor ,setContactValidEroor] = useState(false);
    const [contactSecondValidEroor ,setContactSecondValidEroor] = useState(false);
    const [sameNoError, setSameNoError] = useState(false);
    const [sameCountrycodeError, setsameCountrycodeError] = useState(false);


    
    const [emailEroor ,setemailEroor] = useState(false);

    const submitFormData = (e) => {
     debugger

    e.preventDefault();

//     // checking if value of first name and last name is empty show error else take to step 2
     
      if (validator.isBoolean(businessSign.registeredGst)) {
      setError(true);

     }
    else if (validator.isBoolean(businessSign.haveWebsite)) {
        setError(true);
  
       }
     else if (businessSign?.primaryBuisnessCountryCode==""||businessSign.primaryBuisnessCountryCode==null) {
                setError(true);
           }
    else if (businessSign.primaryBuisnessMobileNumber==""||businessSign.primaryBuisnessMobileNumber==null) {
            setError(true);
       }
       else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(businessSign.primaryBuisnessMobileNumber)){
        setContactValidEroor(true);
        
      }
    // else if (businessSign.secondaryBuisnessCountryCode==""||businessSign.secondaryBuisnessCountryCode==null) {
    //     setError(true);
    // }
    // else if (businessSign.secondaryBuisnessMobileNumber==""||businessSign.secondaryBuisnessMobileNumber==null) {
    //     setError(true);
    // }
    // else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(businessSign.secondaryBuisnessMobileNumber)){
    //     setContactSecondValidEroor(true);
        
    //   }

      else if ((businessSign.secondaryBuisnessMobileNumber != null && businessSign.secondaryBuisnessMobileNumber != "")
      && (businessSign.secondaryBuisnessMobileNumber?.length != 10) ){
        setError(true);
        setSameNoError(false);
      }
      else if (businessSign.secondaryBuisnessMobileNumber == businessSign.primaryBuisnessMobileNumber) {
        setSameNoError(true);

      }
      
    
    else if (businessSign.buisnessEmailId==""||businessSign.buisnessEmailId==null) {
        setError(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(businessSign.buisnessEmailId)) {
        setemailEroor(true);
      }
    
else if (validator.isEmpty(businessSign.websiteUrl)&& businessSign.haveWebsite=='Yes') {
        setError(true);
   }
else if (validator.isEmpty(businessSign.gstNumber)&& businessSign.registeredGst=='Yes') {
    setError(true);
}
// else if (businessSign.secondaryBuisnessMobileNumber.length != 10) {
//     setError(true);
  
//   }
else if (businessSign?.primaryBuisnessMobileNumber.length != 10) {
    setError(true);
  
  }
     
    
  else {
    debugger
    
 handleNext();
}
    
   };


// const sendOtp = async()=>{
// if(isOpendiv){

// const result = await axios.get('/user/sendUserOTP?mobileNo='+
//   lastname.mobileNumber+ '&emailId='+lastname.emailId+'&countryCode='+lastname.countryCode);
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp sent!")
//     setIsotpDiv(true);
//     setIsOpendiv(false);

//   }
//   else if(result.data.code==400){
//     toast.warn("Mobile no Already Exist!");
//     setIsotpDiv(false);
//     setIsOpendiv(true);
//    }
//    else if(result.data.code==409){
//     toast.warn("Email Already Exist!");
//     setIsotpDiv(false);
//     setIsOpendiv(true);
//    }
//   else{
//     toast.warn("Otp Does not sent!")

//     setIsotpDiv(false);
//     setIsOpendiv(true);


//   }
// }
// // if(lastname.countryCode=="+91"){
// //   const result = await axios.get("/user/validateMobileOtp");
// // }
// // else{
// //   const result = await axios.get("/user/validateEmailOtp");
// // }


// }

// const [otp,setOtp]= useState({
//   otpNum1:"",
//   otpNum2:"",
//   otpNum3:"",
//   otpNum4:""

//  })

//  const [otpNew, setOtpNew] = useState('');



//  const submitOtpFormData = (e) => {
//    debugger
//    e.preventDefault();

//    // checking if value of first name and last name is empty show error else take to step 2
//    if (otp.otpNum1==""||otp.otpNum1==null) {
//        setError(true);
//    }
//    else if (otp.otpNum2==""||otp.otpNum2==null) {
//        setError(true);
//    }
   
//    else if (otp.otpNum3==""||otp.otpNum3==null) {
//      setError(true);

//    }
//    else if (otp.otpNum4==""||otp.otpNum4==null) {
//      setError(true);

//    }
   
// else {
//      debugger
//      handleVerifyOptp();
//    }
// };

// const submitOtpCastFormData = (e) => {
//   debugger
//   e.preventDefault();

//   // checking if value of first name and last name is empty show error else take to step 2
//   if (otp.otpNum1==""||otp.otpNum1==null) {
//       setError(true);
//   }
//   else if (otp.otpNum2==""||otp.otpNum2==null) {
//       setError(true);
//   }
  
//   else if (otp.otpNum3==""||otp.otpNum3==null) {
//     setError(true);

//   }
//   else if (otp.otpNum4==""||otp.otpNum4==null) {
//     setError(true);

//   }
  
// else {
//     debugger
//     handleVerifyOptpCast();
//   }
// };


//  const handlechangeOtp =(e)=>{
//   const {name,value,type,checked}=e.currentTarget;
//   setOtp(preState =>({
//     ...preState,
//     [name]:type ==='checkbox'?checked: value
//   }))
// }

// const handleVerifyOptp =async()=>{
//   debugger;
//   if(lastname.countryCode=="+91"){

//   // const result = await axios.get('/user/validateMobileOtp?mobileNo='+
//   // lastname.mobileNumber+ '&otp='+otp.otpNum1+otp.otpNum2+otp.otpNum3+otp.otpNum4);
//   const result = await axios.get('/user/validateMobileOtp?mobileNo='+
//    lastname.mobileNumber+ '&otp='+otpNew);
  
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp Verified!");
//     addNewName()

//   }
//   else{
//     toast.warn("Otp Does not match!");

//   }
// }
// else{

//   const result = await axios.get('/user/validateEmailOtp?emailId='+
//   lastname.emailId+ '&otp='+otpNew);
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp Verified!");
//     //togglePopup()
//     addNewName()
//   }
//   else{
//     toast.warn("Otp Does not match!");

//   }
// }

// }

// const addNewName =(e)=>{
//   const response = axios.post('/user/addNewLastName', lastname)
//     response.then(() => {
//        toast.success("Last Name  has been added Successfully!");
//         togglePopup()
//         lastNamepoupopen(false)
//     })
//     response.catch((err) => {
//       alert("Getting error in featching data")
//     });
//     console.log("response", response?.data)
// }

// //resend otp
// const resendOtp =async()=>{
//   debugger;

//   const result = await axios.get('/user/resendUserOTP?mobileNo='+
//   lastname.mobileNumber+ '&emailId='+lastname.emailId +'&countryCode='+lastname.countryCode);
// console.log("sendotp",result.data.data)
//   if(result.data.code==200){
//     toast.success("Otp Sent!");

//   }
//   else{
//     toast.warn("Otp Sent fail")

//   }


// }
    
//  //for lastname list
//  const [lastNameData, setlastNameData] = useState([]);

//      useEffect(() => {
//        loadLastName();
//      }, []);
  
//      const loadLastName = async () => {
//       debugger
//       const result = await axios.get("/user/listOfLastNames");
//        setlastNameData(result.data.data);
//        console.log("lastnme",result.data.data)
//      };
// //for subcast
// //for lastname list
// const [subCastData, setsubCastData] = useState([]);

//     useEffect(() => {
//       loadsubCast();
//     }, []);
  
//     const loadsubCast = async () => {
//       debugger
//       const result = await axios.get("/user/listOfSubCastes");
//       setsubCastData(result.data.data);
//       console.log("subCast",result.data.data)
//     };

//const [otpNew, setOtpNew] = useState('');

const [enablejoin, setenableResend] = useState(false);


  const counterandbtn =()=>{
    setCounter(30);
    setenableResend(false);
  }
  
      const [counter, setCounter]= useState(46)
;
    useEffect(() =>{
      if(counter === 0){
        setenableResend(true);
    
      }
      
      const timer =
      counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
      return () => clearInterval(timer);
    },[counter]);


return (
        <div className="container">
          {/* <form > */}
         
          <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Primary Business Contact Number

<span className="asteric"></span></span>
  </div>
  <div className="col-2 ">
  <select   id="inputState"  
                className=" form-control "
                name='primaryBuisnessCountryCode'
                onChange={handleChange}
                value={businessSign?.primaryBuisnessCountryCode}
                >
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
    <div className="  textleft">
                                    {error && businessSign.primaryBuisnessCountryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" ,whiteSpace:"pre-wrap"}}>
                                            Country Code is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                            </div>
</div>

  <div className="col-5  textleft">

  <input type="number" className=" form-control" id="fst"
                      name="primaryBuisnessMobileNumber"
                      value={businessSign.primaryBuisnessMobileNumber}
                      placeholder="Enter Business Contact No."
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                 /> 
                 <div className=" textleft">
    <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px" }}>Please Enter Valid Mobile No</span>

                                    {error && businessSign?.primaryBuisnessMobileNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile No. is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  businessSign.primaryBuisnessMobileNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    
                 </div>
                  </div>


   
    </div>
</div>

            </div>
            
                            

            <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Secondary Business Contact Number
  <div style={{ marginTop: "-3px" }}><span className="lbl popinsfont" style={{ fontSize: '12px' }} >(Optional)</span></div>



<span className="asteric"></span></span>
  </div>
  <div className="col-2 ">
  <select   id="inputState"  
                className=" form-control "
                name='secondaryBuisnessCountryCode'
                value={businessSign.secondaryBuisnessCountryCode}
                onChange={handleChange}>
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
</div>
  <div className="col-5  textleft">

  <input type="number" className=" form-control" id="fst"
                      name="secondaryBuisnessMobileNumber"
                      value={businessSign.secondaryBuisnessMobileNumber}
                      placeholder="Enter Business Contact No."
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                 />  


    </div>
</div>

            </div>
          </div>
          <div className='row '>
    <div className='col-7'></div>
    <div className=" col-4 textleft">
    <span className={contactSecondValidEroor?'':"hide"} style={{color:"red",fontSize:"12px" }}>Please Enter Valid Mobile No</span>
    <span className={sameNoError ? '' : "hide"} style={{ color: "red", fontSize: "12px", marginLeft: '0px' }}>Primary Mobile no. is same as Secondary,Please enter different no. </span>

                                    
                {error && businessSign.secondaryBuisnessMobileNumber != null  && businessSign.secondaryBuisnessMobileNumber != "" && businessSign.secondaryBuisnessMobileNumber?.length != 10 ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      Mobile No is 10 digits only.
                    </span>
                  ) : (
                    ""
                  )}
                                </div>
                            </div>
                            <div className='row '>
    <div className='col-7'></div>
    <div className=" col-4 textleft">
                                    {/* {error && businessSign.secondaryBuisnessCountryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Country Cde is required.
                                        </span>
                                    ) : (
                                        ""
                                    )} */}
                                </div>
                            </div>
          <div className="row margintop3">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Business Email ID<span className="asteric"></span></span>
  </div>
 
  <div className="col-7  textleft">

  <input type="text" className=" form-control" id="fst"
                      name="buisnessEmailId"
                      value={businessSign.buisnessEmailId}
                      placeholder="Enter Business Email Id"
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                 /> 

   
    </div>
</div>

            </div>
          </div>
          <div className='row '>
    <div className='col-5'></div>
    <div className=" col-4 textleft">
                                    {error && businessSign.buisnessEmailId == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> 
         
      <div className="row margintop">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Are you registered under GST<span className="asteric"></span></span>
  </div>
 
  <div className="col-2  textleft" style={{marginTop:"4px"}}>

<label class="radiodivcust radiobtn-text1">Yes
<input type="radio" id="defaultRadio2" className=' radiobtn1' name="registeredGst" radioGroup="A"  value='Yes' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>
<div className="col-2  textleft" style={{whiteSpace:"nowrap",marginTop:"4px"}}>

    <label class="radiodivcust radiobtn-text1">No
    <input type="radio" id="defaultRadio3" className='radiobtn1' name="registeredGst" radioGroup="A"  value='No' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>

   
    </div>
</div>
<div className="col-3 margintop1">
</div>
            </div>
             <div className='row '>
    <div className='col-5'></div>
    <div className=" col-4 textleft">
                                    {error && businessSign.registeredGst == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            GST is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
         
  <div className={businessSign.registeredGst == "Yes"?"row margintop3":"hide"}>
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Enter GST Number

<span className="asteric"></span></span>
  </div>
 
  <div className="col-7  textleft">

  <input type="text" className=" form-control" id="fst"
                      name="gstNumber"
                      value={businessSign.gstNumber}
                      placeholder="Enter GST No."
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                 /> 

   
    </div>
</div>

            </div>
          </div>
          <div className={businessSign.registeredGst == "Yes"?'row ':"hide"}>
    <div className='col-5'></div>
    <div className=" col-4 textleft">
                                    {error && businessSign.gstNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            GST Number is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> 

    <div className="row margintop">
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Do you have business website?

<span className="asteric"></span></span>
  </div>
 
  <div className="col-2  textleft" style={{marginTop:"4px"}}>

<label class="radiodivcust radiobtn-text1">Yes
<input type="radio" id="defaultRadio2" className=' radiobtn1' name="haveWebsite" radioGroup="A"  checked={businessSign.haveWebsite=="Yes"?true:false}  value='Yes' onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>
<div className="col-2  textleft" style={{whiteSpace:"nowrap",marginTop:"4px"}}>

    <label class="radiodivcust radiobtn-text1">No
    <input type="radio" id="defaultRadio3" className='radiobtn1' name="haveWebsite" radioGroup="A"  value='No' checked={businessSign.haveWebsite=="No"?true:false}  onChange={handleChange}/>  

                      <span class="checkmark"></span>
           </label>
</div>

   
    </div>
</div>

            </div>
             <div className='row '>
    <div className='col-5'></div>
    <div className=" col-4 textleft">
                                    {error && businessSign.haveWebsite == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Website is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div> 
         
  <div className={businessSign.haveWebsite == "Yes"?"row margintop3":"hide"}>
<div className="col-11">
<div className=" row">
  <div className="col-5 textright" style={{marginTop:"0px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Enter website



<span className="asteric"></span></span>
  </div>
 
  <div className="col-7  textleft">

  <input type="text" className=" form-control" id="fst"
                      name="websiteUrl"
                      value={businessSign.websiteUrl}
                      placeholder="Enter Website URL"
                      style={{fontSize:'13px'}}
                      onChange={handleChange}
                 /> 

   
    </div>
</div>

            </div>
          </div>
          <div className={businessSign.haveWebsite == "Yes"?'row':"hide"}>
    <div className='col-5'></div>
    <div className=" col-4 textleft">
                                    {error && businessSign.websiteUrl == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Website URL is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>                                 



                            <div className="margintop1 row ">
            <div className="col-3 "></div>
            <div className="col-3 margintop textright">
            <button
               className="prevbtn "
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop"style={{marginLeft:"14px"}}>
          <button
            className="nextbtn1"
              variant="contained"
              color="primary"
               onClick={submitFormData}
              //type="submit"
            >
              Next
            </button>
            
            
          </div>

        
            
          </div> 
          <div className="margintop row "></div>

          {/* </form> */}
           

            
            

     
</div>
    );
  }
  export default BusinessContactDetail;