import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {AiOutlineFilePdf} from 'react-icons/ai'
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown ,ButtonGroup, InputGroup, Row ,Carousel,Pagination} from 'react-bootstrap';
import imgupload from '../../images/imageupload.svg'
import ReactTooltip from "react-tooltip";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SuccessPop from '../JoinNow/SuccessPop';
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import OtpInput from 'react-otp-input';
import { Countrycode } from "../JoinNow/Countrycode";
import {configData} from "../../Config"
import DirectoryClosure from "./DirectoryClosure";


 
const AccountSettingOld=()=>{
  
  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  


  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  
  const [isOpendiv, setIsOpendiv] = useState(true);
  const [isOtpDiv, setIsotpDiv] = useState(false);

  const onNext = (isback) => {
   debugger
    if(isback){

      setIsOpendiv(true);
      setIsotpDiv(false);
    }
    else{
      setIsOpendiv(false);
      setIsotpDiv(true);
    }
    return
  }
  //for mobile
  const [isOpendiv1, setIsOpendiv1] = useState(true);
  const [isOtpDiv1, setIsotpDiv1] = useState(false);

  const onNext1 = (isback1) => {
   debugger
    if(isback1){

      setIsOpendiv1(true);
      setIsotpDiv1(false);
    }
    else{
      setIsOpendiv1(false);
      setIsotpDiv1(true);
    }
    return
  }
  //for email password
  const [changeEmailPop ,setchangeEmailPop] = useState(false);
  function emailpoupopen (isEmail){
    if(isEmail){
      debugger
      setchangeEmailPop(true);
    }
    else{
      setchangeEmailPop(false);
      setemailchange("");
      resetFormData();

    }
  }
  
  //for close popup when click on outside
 const popupEmailRef = useRef(null);
 const handleClickOutsideEmaildiv = (event) => {
   if (popupEmailRef.current && !popupEmailRef.current.contains(event.target)) {
    resetFormData();
    
    setchangeEmailPop(false);
   }
 };

 const resetFormData = () => {
  setemailchange({
    id: "",
    spelling: "",
    countryCode: "",
    mobileNumber: "",
    emailId: ""
  });
  setIsOpendiv(true);
  setIsotpDiv(false);

  setOtpNew("");
  setCounter(60);
}

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideEmaildiv);
   return () => {
     document.removeEventListener('mousedown', handleClickOutsideEmaildiv);
   };
 }, []);
  
 const [emailchange,setemailchange]= useState({
    id:"",
    spelling:"",
    countryCode:"",
    mobileNumber:"",
    emailId:""

   })

   const handleChange=(e)=>{
    const {name,value,type,checked}=e.currentTarget;
    
    setemailchange(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    setConfirmPass(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    setPassword(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    setprimMobchange(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    setsecondMobchange(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
  }

  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);


//for email
   const submitFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
   
     if (validator.isEmpty(emailchange.emailId)) {
        setError(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailchange.emailId)) {
      setError(true);
    }
    
    
    
    else {
      debugger
      sendOtp();
    }
};


const sendOtp = async()=>{
if(isOpendiv){

const result = await axios.get(`${configData.ApiUrl}/user/sendEmailOTPAccountSettings?emailId=${emailchange.emailId}`);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp sent!")
    setIsotpDiv(true);
    setIsOpendiv(false);

  }
  else{
    toast.warn("Email Already Registered or Same as Previous !")

    setIsotpDiv(false);
    setIsOpendiv(true);


  }
}


}





const [otp,setOtp]= useState({
  otpNum1:"",
  otpNum2:"",
  otpNum3:"",
  otpNum4:""

 })
 const [otpNew, setOtpNew] = useState('');


 const handlechangeOtp =(e)=>{
  const {name,value,type,checked}=e.currentTarget;
  setOtp(preState =>({
    ...preState,
    [name]:type ==='checkbox'?checked: value
  }))
}

const handleVerifyOptp =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/validateEmailOtpAccountSettings?emailId='+
  emailchange.emailId+ '&otp='+otpNew+'&id='+uerAuthId);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    toast.success("Email  has been Changed Successfully!!");
    setchangeEmailPop(false);


  }
  else{
    toast.warn("Otp Does not match!");

  }


}

// const addNewEmail =(e)=>{
//   const response = axios.post('/user/addNewLastName', emailchange)
//     response.then(() => {
//        toast.success("Email  has been Changed Successfully!");
//         //togglePopup()
//         emailpoupopen(false)
//     })
//     response.catch((err) => {
//       alert("Getting error in featching data")
//     });
//     console.log("response", response?.data)
// }

const [enablejoin, setenableResend] = useState(false);

const counterandbtnPrimMob =()=>{
  setCounter(30);
  setenableResend(false);
  resendOtpPrimMob();
}
const counterandbtnSecondMob =()=>{
  setCounter(30);
  setenableResend(false);
  resendOtpSecondMob();
}

const counterandbtn =()=>{
  setCounter(30);
  setenableResend(false);
  resendOtpEmail() ;
}

    const [counter, setCounter]= useState(50)
;
  useEffect(() =>{
    if(counter === 0){
      setenableResend(true);

  
    }
    
    const timer =
    counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
    return () => clearInterval(timer);
  },[counter]);


//resend otp email chnage
const resendOtpEmail =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  emailchange.mobileNumber+ '&emailId='+emailchange.emailId+'&countryCode='+emailchange.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}



//for primary mob

const [changePrimaryMobPop ,setchangePrimaryMobPop] = useState(false);
function primMobpoupopen (isPrimMob){
  debugger
  if(isPrimMob){
    
    setchangePrimaryMobPop(true);
  }
  else{
    resetFormDataPrimMob();
    setchangePrimaryMobPop(false);
  }
}

//for close popup when click on outside
const popupPrimeMobRef = useRef(null);
const handleClickOutsidePrimeMobdiv = (event) => {
  if (popupPrimeMobRef.current && !popupPrimeMobRef.current.contains(event.target)) {
    setchangePrimaryMobPop(false);
    resetFormDataPrimMob();
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutsidePrimeMobdiv);
  return () => {
    document.removeEventListener('mousedown', handleClickOutsidePrimeMobdiv);
  };
}, []);

const [primMobchange,setprimMobchange]= useState({
  id:"",
  mobileNo:"",
  countryCode:"",

 })
 
 const resetFormDataPrimMob = () => {
  setprimMobchange({
    id:"",
  mobileNo:"",
  countryCode:"",
  });
  setIsOpendiv1(true);
  setIsotpDiv1(false);
  setOtpNew("");
  setCounter(60);
}
 const submitPrimMobFormData = (e) => {
  debugger
  e.preventDefault();

  // checking if value of first name and last name is empty show error else take to step 2
 
   if (validator.isEmpty(primMobchange.mobileNo)) {
      setError1(true);
  }
  else if (primMobchange.mobileNo.length != 10) {
    setError1(true);

  }
  else if (validator.isEmpty(primMobchange.countryCode)) {
    setError1(true);
  }
 
  
  else {
    debugger
    sendOtpPrimMob();
  }
};


const sendOtpPrimMob = async()=>{
if(isOpendiv1){

const result = await axios.get(configData.ApiUrl+'/user/sendMobileOTPAccountSettings?mobileNo='+primMobchange.mobileNo+'&countryCode='+primMobchange.countryCode+'&id='+uerAuthId);
console.log("sendotp",result.data.data)
if(result.data.code==200){
  toast.success("Otp sent!")
  setIsotpDiv1(true);
  setIsOpendiv1(false);

}
else if(result.data.code==400){
  toast.warn("Mobile No Already Registered or Same as Previous!")
  setIsotpDiv1(false);
  setIsOpendiv1(true);
}
else{
  toast.warn("Something went wrong!")

  setIsotpDiv1(false);
  setIsOpendiv1(true);


}
}


}

//resend otp email chnage
const resendOtpPrimMob =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  primMobchange.mobileNo+ '&emailId='+accdetails.emailId+'&countryCode='+primMobchange.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

const handleVerifyOptpPrimMobile =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/validatePrimaryOtpAccountSettings?mobileNumber='+
  primMobchange.mobileNo+ '&otp='+otpNew+'&countryCode='+primMobchange.countryCode+'&id='+uerAuthId);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    toast.success("Mobile No  has been Changed Successfully!!");
    setchangePrimaryMobPop(false)
  }
 
  else{
    toast.warn("Otp Does not match!");

  }


}

//for secondary mobile no
const [changeSecondMobPop ,setchangeSecondMobPop] = useState(false);
function secondMobpoupopen (isSecondMob){
  debugger
  if(isSecondMob){
    
    setchangeSecondMobPop(true);
  }
  else{
    setchangeSecondMobPop(false);
    resetFormDataSecMob();
  }
}
 //for close popup when click on outside
 const popupSecMobRef = useRef(null);
 const handleClickOutsideSecMobdiv = (event) => {
   if (popupSecMobRef.current && !popupSecMobRef.current.contains(event.target)) {
    setchangeSecondMobPop(false);
    resetFormDataSecMob();
   }
 };

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideSecMobdiv);
   return () => {
     document.removeEventListener('mousedown', handleClickOutsideSecMobdiv);
   };
 }, []);

const [secondMobchange,setsecondMobchange]= useState({
  id:"",
  mobileNo:"",
  countryCode:"",

 })

 const resetFormDataSecMob = () => {
  setsecondMobchange({
    id:"",
  mobileNo:"",
  countryCode:"",
  });
  setIsOpendiv1(true);
  setIsotpDiv1(false);
  setOtpNew("");
  setCounter(60);
}
 const submitSecondMobFormData = (e) => {
  debugger
  e.preventDefault();

  // checking if value of first name and last name is empty show error else take to step 2
 
   if (validator.isEmpty(secondMobchange.mobileNo)) {
      setError2(true);
  }
  else if (secondMobchange.mobileNo.length != 10) {
    setError2(true);

  }
  else if (validator.isEmpty(secondMobchange.countryCode)) {
    setError2(true);
  }
 
  
  else {
    debugger
    sendOtpSecondMob();
  }
};


const sendOtpSecondMob = async()=>{
if(isOpendiv1){

const result = await axios.get(configData.ApiUrl+'/user/sendMobileOTPAccountSettings?mobileNo='+secondMobchange.mobileNo+'&countryCode='+secondMobchange.countryCode+'&id='+uerAuthId);
console.log("sendotp",result.data.data)
if(result.data.code==200){
  toast.success("Otp sent!")
  setIsotpDiv1(true);
  setIsOpendiv1(false);

}
else if(result.data.code==400){
  toast.warn("Mobile No Already Registered or Same as Previous!")
  setIsotpDiv1(false);
  setIsOpendiv1(true);
}
else{
  toast.warn("Something went wrong!")

  setIsotpDiv1(false);
  setIsOpendiv1(true);


}
}


}

//resend otp secndmob chnage
const resendOtpSecondMob =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  secondMobchange.mobileNo+ '&emailId='+accdetails.emailId+'&countryCode='+secondMobchange.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

const handleVerifyOptpSecondMobile =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/validateSecondaryOtpAccountSettings?mobileNumber='+
  secondMobchange.mobileNo+ '&otp='+otpNew+'&countryCode='+secondMobchange.countryCode+'&id='+uerAuthId);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    toast.success("Mobile No  has been Changed Successfully!!");
    secondMobpoupopen(false)
  }
 
  else{
    toast.warn("Otp Does not match!");

  }


}


//change password api
const [passwordData ,setPassword]= useState({
  
  previousPassword:"",
  newPassword:"",

 })

 const [confirmPass , setConfirmPass]= useState({
  confirmPassword:"",
  

 })
 const [confirmPassErr,setconfirmPassErr]= useState(false)

 const submitPasswordFormData = (e) => {
  debugger
  e.preventDefault();

  // checking if value of first name and last name is empty show error else take to step 2
  if (validator.isEmpty(passwordData.previousPassword)) {
      setError3(true);
  }
  else if (validator.isEmpty(passwordData.newPassword)) {
      setError3(true);
  }
  
 
else if (passwordData.newPassword != confirmPass.confirmPassword) {
 setconfirmPassErr(true);

}
  
  else {
    debugger
    UpdatePassword()
  }
};

const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);

const UpdatePassword = async()=>{

  debugger
  const response =  await axios.post(configData.ApiUrl+'/subscriber/changePassword?hostId='+uerAuthId+'&previousPassword='+passwordData.previousPassword+'&newPassword='+passwordData.newPassword)
  
  if(response.status == 200 && response.data.code==200 ){
    toast.success("Updated Password Successfully!")
    setConfirmPass(preState =>({
      ...preState,
      "confirmPassword":""
    }))
    
    setPassword(preState =>({
      ...preState,
          "previousPassword" :"" ,
           "newPassword":""
  }))
  }
  else{
      alert("Wrong Password..!")
  }
 }

 const [accdetails, setaccdetails] = useState([]);

   useEffect(() => {
    AccountUser();
    }, [accdetails]);
  
    const AccountUser = async () => {
      //debugger
      const result = await axios.get(`${configData.ApiUrl}/user/accountDetails?id=${authlocalstoragedata?.userAuthenticationId}`);
      setaccdetails(result.data.data);
      console.log("accdetails",result.data.data)
    };

    //for email and mobile

const mobileNumberFormate = emailchange.mobileNumber?.substr(0, 2) + 'xxxxxx' 
+ emailchange.mobileNumber?.substr(8, 9);

//for primary mobile
const mobileNumberFormate1 = primMobchange.mobileNo?.substr(0, 2) + 'xxxxxx' 
+ primMobchange.mobileNo?.substr(8, 9);

//for secondary mobile
const mobileNumberFormate2 = secondMobchange.mobileNo?.substr(0, 2) + 'xxxxxx' 
+ secondMobchange.mobileNo?.substr(8, 9);

//for  emeil
let email =  emailchange.emailId
let chunks = email.split("@");
let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`


const [directorycloseaccPop, setdirectorycloseaccPop] = useState(false);
const togglePopupMessageDirectory = () => {
  setdirectorycloseaccPop(!directorycloseaccPop);
}


  return (
    <div>

<Row className="row-sm " style={{overflowY:"scroll",height:"440px"}}>
      <Card.Body>
      <Col className='col-lg-1'></Col>
      <Col className='col-lg-11 col-sm-12 col-md-12'>
      <div className="example" style={{backgroundColor:"#fff"}}>
      
      <div className="row margintop1">
        <div className="col-5"><h6 className="" style={{fontSize:"14px"}}>Account Settings</h6></div>
        <div className="col-4"></div>
       
    
</div>

      <div className="cardiv-event border1px" >
      <div className="row margintop1">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}> User ID</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"13px"}}> {accdetails.emailId}</span>
<span className="bluetextforlink marginleft2" onClick={() => emailpoupopen(true)}>Change Login Email ID</span>
    </div>
    

      </div>
      <div className="row margintop">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}> Primary Mobile Number</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12">
    <span for="inputState" className="event-sub-detail1" style={{fontSize:"13px"}}> {accdetails.mobileNumber}</span>
<span className="bluetextforlink marginleft2" onClick={()=>primMobpoupopen(true)}>Change Mobile</span>
    </div>
    

      </div>
      <div className="row margintop">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}> Secondary Mobile Number</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12">
      {
        accdetails.secondaryMobileNumber==''|| accdetails.secondaryMobileNumber== null?
        (    <span for="inputState" className="event-sub-detail1" style={{fontSize:"13px"}}> No Number Added</span>
        ):
        (    <span for="inputState" className="event-sub-detail1" style={{fontSize:"13px"}}> {accdetails.secondaryMobileNumber}</span>
        )
      }

{
  accdetails.secondaryMobileNumber==''|| accdetails.secondaryMobileNumber== null?
  (
    <span className="bluetextforlink marginleft2" onClick={()=> secondMobpoupopen(true)}>Add Secondary Mobile</span>

  ):
  (
<span className="bluetextforlink marginleft2" onClick={()=> secondMobpoupopen(true)}>Change Secondary Mobile</span>

  )
}
    </div>
    

      </div>
            </div>
          
<div className="row margintop">
    <h6 className="" style={{fontSize:"14px"}} >Password Reset</h6>
</div>
            <form onSubmit={submitPasswordFormData} >
            <div className="cardiv-event border1px" >
            <div className="row margintop">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}>Current Password</span>
    </div>
    <div className="col-lg-6 col-sm-12 col-md-12 form-group">
    <input type="password" className=" form-control" id="fst"
         name="previousPassword"
         placeholder="Enter Current Password"
                  style={{fontSize:"12px"}}
                  onChange={handleChange}
                  value={passwordData.previousPassword }

         />
         <div className="margtop1 nowrapcss">
                                    {error3 && passwordData.previousPassword == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Old Password is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
    

      </div>
      <div className="row margintop3">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}>Enter New Password</span>
    </div>
    <div className="col-lg-6 col-sm-12 col-md-12 form-group">
    <input type="password" className=" form-control" id="fst"
         name="newPassword"
         placeholder="Enter New Password"
         style={{fontSize:"12px"}}
         onChange={handleChange}
         value={passwordData.newPassword }

         />
         <div className="margtop1 nowrapcss">
                                    {error3 && passwordData.newPassword == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            New Password is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
    

      </div>
      <div className="row margintop3">
    <div className="col-lg-5 col-sm-12 col-md-12 textright">
    <span for="inputState" className="event-sub-detail" style={{fontSize:"13px"}}>Confirm Password</span>
    </div>
    <div className="col-lg-6 col-sm-12 col-md-12 form-group">
    <input type="password" className=" form-control" id="fst"
         name="confirmPassword"
         placeholder="Confirm Password"
         style={{fontSize:"12px"}}
         onChange={handleChange}
         value={confirmPass.confirmPassword }
         />
    </div>
    

      </div>
      <div className='row margintpmins1'>
    <div className='col-5'></div>
    <div className=" col-6 textcenter">
                                    
                <span className={confirmPassErr && passwordData.newPassword != confirmPass.confirmPassword ?'':"hide"} style={{ color: "red",fontSize:"12px" }}>
                      Passwords does not match.
                   </span>
                                    
                                </div>
                                
                                
                                
  </div>
      <div className='row margintop3'>
                    <div className='col-3'></div>
                    <div className='col-3'>
                        <button className='cancelbtn' style={{width:'149px',height:"45px",fontSize:"13px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                    <button className='createeventbtn' style={{width:'149px',height:"45px",fontSize:"13px"}} type="submit">Update Password</button>
                  </div>
                    <div className='col-1'></div>

                  </div>


                </div>
          </form>
                
 </div>
 </Col>
 </Card.Body>
 </Row>

 <div className={changeEmailPop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop" ref={popupEmailRef}>
      <div className={isOpendiv?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text">Change Email Id!</span>
            
                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-11">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

       
    <div className="margintop3 row">
  <div className="col-3 textright ">
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Email Id </span>
  {/* <FaInfoCircle size='0.8em' className="margintpmins1" data-tip data-for="registerTip"/>:
            <ReactTooltip id="registerTip" place="right"  class='tooltip1' effect="solid" width="300px">
                    We will Notify on This email id once required name is added
                 </ReactTooltip> */}
  </div>
  <div className="col-7 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="emailId"
         placeholder="Your Email Id"
         onChange={handleChange}
          value={emailchange.emailId}
         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    />
    <div className="margtop1 nowrapcss">
                                    {error && emailchange.emailId == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    
   
    </div>
    

    
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  onClick={() => emailpoupopen(false)} style={{width:'130px',height:"42px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                     <button className='createeventbtn' onClick={submitFormData} style={{width:'130px',height:"42px"}}>Next</button> 
                  </div>
                    <div className='col-1'></div>

                  </div>
        </div>
        
        <div className={isOtpDiv?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text "> Change Email Id !</span>
            
                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            </div>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox1 textcenter form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox3 textcenter form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
              </div>
            
            </div> */}
            <div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>


</div>
            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtn()} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'130px',height:"42px"}} onClick={() => onNext(true)}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px",justifySelf:"right"}} onClick={handleVerifyOptp}>Submit</button>
                  </div>
                  {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />}
                    <div className='col-1'></div>

                  </div>
        </div>
</div>
          
        
        </div>
</div>

<div className={changePrimaryMobPop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop" ref={popupPrimeMobRef}>
      <div className={isOpendiv1?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text">Change Primary Mobile!</span>
            
                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-11">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

       
        <div className="margintop3 row">
  <div className="col-3 textright">
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Mobile No </span>
  </div>
  <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                value={primMobchange.countryCode}
                onChange={handleChange}>
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
    </div>

  <div className="col-4 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNo"
         placeholder="Mobile No"
         onChange={handleChange}
         value={primMobchange.mobileNo}
         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    />
    
  </div>
  
</div>
<div className='row'>
    <div className='col-3'></div>
    <div className='col-7'>
    <div className=" nowrapcss ">
                                    {error1 && primMobchange.mobileNo == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                        </div>
                        <div className=" nowrapcss ">
                                    {error1 && primMobchange.mobileNo.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=" nowrapcss">
                                    {error1 && primMobchange.countryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            countryCode is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
  </div>

    
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  onClick={() => primMobpoupopen(false)} style={{width:'130px',height:"42px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                     <button className='createeventbtn' onClick={submitPrimMobFormData} style={{width:'130px',height:"42px"}}>Next</button> 
                  </div>
                    <div className='col-1'></div>

                  </div>
        </div>
        
        <div className={isOtpDiv1?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text ">Change Primary Mobile No !</span>
            
                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate1}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            </div>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox1 textcenter form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox3 textcenter form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
              </div>
            
            </div> */}
            <div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>


</div>
            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtnPrimMob()} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'130px',height:"42px"}} onClick={() => onNext1(true)}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px",justifySelf:"right"}} onClick={handleVerifyOptpPrimMobile}>Submit</button>
                  </div>
                  {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />}
                    <div className='col-1'></div>

                  </div>
        </div>
</div>
          
        
        </div>
</div>
<div className={changeSecondMobPop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop" ref={popupSecMobRef}>
      <div className={isOpendiv1?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text">Change Secondary Mobile!</span>
            
                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-11">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

       
        <div className="margintop3 row">
  <div className="col-3 textright">
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Mobile No </span>
  </div>
  <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                value={secondMobchange.countryCode}
                onChange={handleChange}>
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
    </div>

  <div className="col-4 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNo"
         value={secondMobchange.mobileNo}
         placeholder="Mobile No"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    />
    
  </div>
  
</div>
<div className='row'>
    <div className='col-3'></div>
    <div className='col-7'>
    <div className=" nowrapcss ">
                                    {error2 && secondMobchange.mobileNo == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                        </div>
                        <div className=" nowrapcss ">
                                    {error2 && secondMobchange.mobileNo.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=" nowrapcss">
                                    {error2 && secondMobchange.countryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            countryCode is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
  </div>

    
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  onClick={() => secondMobpoupopen(false)} style={{width:'130px',height:"42px"}}>Cancel</button>
                    </div>
                    <div className='col-3'>
                     <button className='createeventbtn' onClick={submitSecondMobFormData} style={{width:'130px',height:"42px"}}>Next</button> 
                  </div>
                    <div className='col-1'></div>

                  </div>
        </div>
        
        <div className={isOtpDiv1?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-8">
                <span className="lastpop-top-text">Change Secondary Mobile No !</span>
            
                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate2}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            </div>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox1 textcenter form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox3 textcenter form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
              </div>
            
            </div> */}
            <div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
  </div>


</div>
            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtnSecondMob()} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'130px',height:"42px"}} onClick={() => onNext1(true)}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'130px',height:"42px",justifySelf:"right"}} onClick={handleVerifyOptpSecondMobile}>Submit</button>
                  </div>
                  {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />}
                    <div className='col-1'></div>

                  </div>
        </div>
</div>
          
        
        </div>
</div>
<div className="col-3" style={{color:"red",fontSize:"px",cursor:'pointer',marginLeft:"20px"}}><span className="event-sub-detail" onClick={togglePopupMessageDirectory}>Account Closure</span></div>
        {directorycloseaccPop && <DirectoryClosure
      
      handleClose={togglePopupMessageDirectory}
    />}  
     </div>

           );
}
 
export default AccountSettingOld;