import React, { useState, useEffect ,useRef} from "react";
import { Link, NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import {configData} from "../../Config"


const MyJobPosting =()=>{
  
  //for session
  // let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  



  const columns = [
    {
        name: "Job Id",
       selector: "id",
        sortable: true,
        width:"100px",
        cell: (d) => (
            <Link to={`/ViewJobPosting/${d.id}`}  className="dlink">
                {d.id}
           </Link> 
           ),      
          
        },
    
    {
        name: "Date",
       selector: "createdDate",
        sortable: true,
        
     },
    {
      name: "Position",
     selector: "role",
      sortable: true,
      width:"100px"
  },
  
  {
      name: "Location",
     selector: "city",
      sortable: true,
     className:"texttransformcapitalize"
      
  },
  {
    name: "Status",
    selector: "status",
    sortable: true,
    cell: (d) => [
     d.status=="Open"? <span className="" style={{color:'#70B603'}}>Open</span> 
      :<span className="" style={{color:'#FA114F'}}>Closed</span>
      
    ]
    
  },
  
  
];
    
const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
const [uerAuthReferralCode, setuerAuthReferalCode] = useState(authlocalstoragedata?.referralCode);
              
const [data, setDataJob] = useState([]);
  
const tableData = {
  columns,
  data,
  
};

 useEffect(() => {
  loadJobList();
 },[]);

 const loadJobList = async () => {
   debugger
  const result = await axios.get(`${configData.ApiUrl}/buisness/getListJobPostings?buisnessId=${authlocalstoragedata?.buisnessId}`);
  setDataJob(result.data.data);
   console.log("getalljobpost",result.data.data)


 };
    
    return(
<div>
<DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent= "Data is Not Available" //or your component
          striped
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          print={false}
          export={true}
        
        />
      </DataTableExtensions>
      <span className="comudrct-digitxt" style={{fontStyle:"italic"}}>Click on JOB ID to view or edit post</span>
</div>
    )
}
export default MyJobPosting




