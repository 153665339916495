import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {configData} from "../../Config"
import { useHistory } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { BsBoxArrowUpRight } from "react-icons/bs";







const SubscribeCommunity=({basicInfo,setBasicInfo,handleBack,handleNext,joinNowRes,setjoinNowRes,setPaymentreq,Paymentreq,paymentRequest, amouttotal, setamouttotal })=> {
  

  //for new payment apis

  const [subscriptionForDi, setsubscriptionForDi] = useState();



  useEffect(() => {
    loadSubscriptionForDi();
  },[]);

  
  const loadSubscriptionForDi = async () => {
     
    const result = await axios.get(configData.ApiUrl+'/user/getSubscriptionForDirectory');

    setsubscriptionForDi(result.data.data);
    console.log("effectiveprice",result.data.data)
    settotalpayable(result.data.data);

   };
  //old
  const [showhide, setShowhide]= useState(false);
  let history = useHistory();

  
  const addClick= ()=>{
     ;
    setShowhide(true);
    
  }

  //setPaymentreq(joinNowRes)

if(joinNowRes != null && joinNowRes.referralUsed !=null){
  setreferalCodeValue(joinNowRes.referralUsed);

}


  const [discount, setDiscount]= useState(false);
  
  const adddiscount= ()=>{
     
    setDiscount(true);
    setShowhide(false);
    setRemoveDiscount(false);
    getPriceForReferalcode();

  }
  const [removeDiscount, setRemoveDiscount]= useState(false);

  const removeDiscountClick= ()=>{
     ;
    setShowhide(true);

    setRemoveDiscount(false);
    setDiscount(false);
    setData(0);
    settotalpayable(pricedata);
    setPaymentreq(preState =>({
      ...preState,
      "amount":pricedata.price
        }))

  }
//for localstorage
const data = localStorage.getItem('data');
console.log("auth localstoragedata",data)
    
    
  
const [pricedata, setPriceData] = useState();
const [defaultpricedata, setdefaultpricedata] = useState();



   useEffect(() => {
     loadUsers();
   },[]);

   
   const loadUsers = async () => {
     const result = await axios.get(configData.ApiUrl+'/user/getSubscriptionForDirectory');

     setPriceData(result.data.data);
     console.log("price",result.data.data)
     setamouttotal(parseInt(result.data.data.price)-parseInt(result.data.data.discount))
     settotalpayable(result.data.data);
     setPaymentreq(preState =>({
      ...preState,
      "amount":parseInt(result.data.data.price)-parseInt(result.data.data.discount),
      "nameId":joinNowRes,
        }))
        setdefaultpricedata(parseInt(result.data.data.price)-parseInt(result.data.data.discount))

    };
    
      //let totalAmount= parseInt(pricedata?.price) - parseInt(pricedata?.discount)
    
    

//for opening fields depends on radio cheked
const [showhideSubTy, setShowhideSubTy]= useState("");
const [borderbox, setBorderbox]=useState(false);
const [borderboxfree, setBorderboxfree]=useState(false);

 
const [referalCodeValue, setreferalCodeValue] = useState();
//for subscription type
const [substype,setSubstype]= useState({
  subscriptionType:""
})

const handleshow= e=>{
  const getshow= e.target.value;
  // const {name,value,type,checked}=e.currentTarget;

  setShowhideSubTy(getshow);
}

const divClickPremium =()=>{
   setBorderbox(true);
   setBorderboxfree(false);
  setShowhideSubTy("Premium");
  setreferalCodeValue("Premium");
  
  setPaymentreq(preState =>({
    ...preState,
    "amount":defaultpricedata,
      }))
  setSubstype(preState =>({
    ...preState,
    "subscriptionType" : "Premium"
  }))
  substype.subscriptionType="Premium";
  console.log("5555", substype)
}
const divClickFree=()=>{
  // setBorderbox(true)
  setBorderbox(false)
  setBorderboxfree(true);

  setShowhideSubTy("Free");
  setreferalCodeValue("Free");

  setSubstype(preState =>({
    ...preState,
    "subscriptionType" : "Free"
  }))
  setPaymentreq(preState =>({
    ...preState,
    "amount":"0",
    "nameId":joinNowRes,
    "code":null
      }))
  substype.subscriptionType="Free";
  console.log("444", substype)

}
   

    const handleReferalCode =(e)=>{
      const {name,value,type,checked}=e.currentTarget;

      setreferalCodeValue(value)
 
      setSubstype(preState =>({
        ...preState,
        [name]:type ==='checkbox'?checked: value
      }))

    }

    //for getprice 
    const [countmenu, setData] = useState(0);
    const [totalpayable, settotalpayable] = useState();
    const [expiredtxt, setexpiredtxt] = useState(false);
    const [existtxt, setexisttxt] = useState(false);

    // const [amouttotal, setamouttotal] = useState();


   // const totalpayable=pricedata;
    const getPriceForReferalcode = async () => {
      const result = await axios.get(`${configData.ApiUrl}/user/getDiscountPrice?discountCode=${referalCodeValue}`);

      if(result.data.code == 200 ){
        
        setData(result.data.data);
        settotalpayable(pricedata-result.data.data);
        //totalAmount=pricedata.price-result.data.data
        setamouttotal(pricedata.price-result.data.data)
        setPaymentreq(preState =>({
          ...preState,
          "amount":amouttotal,
          "productType":'Community',
          "currency":'INR',
          "code":referalCodeValue,
          "nameId":joinNowRes,



        }))
        Paymentreq.amount=pricedata.price-result.data.data;
        toast.success('Coupon code  Applied')
        setexpiredtxt(false)
        setexisttxt(false)

      }
      else if(result.data.code == 401 ){


        setData(result.data.data);
        setexpiredtxt(true);
        setexisttxt(false)
        settotalpayable(pricedata-result.data.data);
        setamouttotal(pricedata.price)

        setPaymentreq(preState =>({
          ...preState,
          "amount":pricedata.price,
          "productType":'Community',
          "currency":'INR',
          "code":referalCodeValue,
          "nameId":joinNowRes,

         }))
      toast.warn('Coupon code Expired');

      }
      else if(result.data.code == 400 ){


        setData(result.data.data);
        setexpiredtxt(false);
        setexisttxt(true);

        settotalpayable(pricedata-result.data.data);
        setamouttotal(pricedata.price)

        setPaymentreq(preState =>({
          ...preState,
          "amount":pricedata.price,
          "productType":'Community',
          "currency":'INR',
          "code":referalCodeValue,
          "nameId":joinNowRes,



        }))
      toast.warn('Coupon code does not exist');

      }
      else{
          toast.warn("Something Went Wrong..!")
      }
      
      
      console.log("getprice",result.data.data)

     };
    
    const [checkboxAgree,setcheckboxAgree]= useState({
      agree:""
    })
    const handlechangeCheckbox =(e)=>{

      const {name,value,type,checked}=e.currentTarget;
      setcheckboxAgree(preState =>({
        ...preState,
        [name]:type ==='checkbox'?checked: value
      }))
    }
     
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    if(authlocalstoragedata !=null && authlocalstoragedata?.userAuthenticationId !=null 
      && authlocalstoragedata?.name1 != null){
      localStorage.id = authlocalstoragedata?.userAuthenticationId;
      localStorage.name = authlocalstoragedata?.name1;
    
    }else{
      localStorage.name = localStorage.name;
    
      localStorage.id = localStorage.id;
    }
    const redirecttoprof =()=>{
      history.push('/CompleteProfile')
    }
     
    return (
<div className="container">



        <div className="row textcenter margintop3">
          <div className="col-lg-2 col-md-12 col-sm-12"> </div> 
          <div className="col-lg-8 col-md-12 col-sm-12 ">
            <span className="membership-text">
              Naidu Community Global Membership
            </span>
            <div className="margintop3">
              <span className="member-name" style={{textTransform:"capitalize"}}>{localStorage.name}</span>
            </div>
          </div>
        </div> 
        <div className="margintop row">
            <div className="col-lg-2 col-md-12 col-sm-12 textright" style={{height:"auto"}}></div>
            <div className="col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div" style={{height:"auto"}}>
            {/* <span className="subscripdiv-text">Standard Membership</span>
            <div className=""><span>Free</span></div> */}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div" style={{height:"auto"}}>
            {/* <span className="subscripdiv-text">Premium Membership</span>
            <div className=""><span className="event-sub-detail1">1999</span></div>
            <div className=""><span className="event-sub-detail1">999/Lifetime</span></div>
            <div className=""><span className="event-sub-detail1">You Save 50%</span></div>
            <div className=""><span className="event-sub-detail1"> 10 hrs left at this price</span></div> */}
            </div>
            

            </div>
        
        <div className="margintop row">
            <div className="col-lg-2 col-md-12 col-sm-12 textright" style={{height:"auto"}} >
                {/* <span className="subscripdiv-text"> Subscription Type*</span> */}
              </div> 
              
           <div className = {borderboxfree?"divActive col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div": "col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div"} style={{height:"auto"}} onClick={ divClickFree }>
            <div className="divCenter"style={{paddingBottom:"49px",paddingTop:"10px"}}>
            <span className="subscripdiv-text textcenter"style={{textAlign:"center"}}>Standard Membership</span>
            <div className="textleft"><span className="event-sub-detail" style={{fontSize:"15px"}}>Free</span></div>
            </div>
          
           {/* <label class="radiodivcust radiobtn-text1" style={{marginLeft:"16px"}} >Free
<input type="radio" id="defaultRadio6" className='radiobtn'
 name="subscriptionType" onChange={handleReferalCode} onClick={ handleshow }
 value="Free"  />  

                <span class="checkmark"></span>
           </label> */}
           <div className="">

    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Member in your radius</span> 
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Connect with new member</span> 
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/> <span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Blood requirement </span> 
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Access community Directory </span>  
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Access Post </span>   
    </ul>

    <ul>
    <IoCloseOutline size="1.3em" color="#D9001B"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Messenger </span>  

    </ul>
    <ul>
    <IoCloseOutline size="1.3em" color="#D9001B"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Coffee Meet ups </span>  

    </ul>
    
    

           </div>
</div>  
{/* <div className="col-lg-1 col-md-12 col-sm-12" style={{height:"auto", width:"auto"}}></div> */}
<div  className = {borderbox?"divActive col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div": "col-lg-4 col-md-12 col-sm-12 margintop1 subscrip-div"} style={{height:"auto",marginLeft:"22px"}} onClick={ divClickPremium }>
    <div>  <p className="event-sub-detail1" style={{color:"#ffffff",backgroundColor:"#B5924C",marginTop:"-10px",float:"right",paddingLeft:"4px",paddingRight:"4px",borderRadius:"4px"}}>Most Preferred</p></div>
  
{/* <label class="radiodivcust radiobtn-text1" >Premium Directory
<input type="radio" id="defaultRadio5" className='radiobtn' name="subscriptionType" onChange={handleReferalCode}
onClick={ handleshow }
value="Premium"/>  

                <span class="checkmark"></span>
           </label> */}
           <div className="divCenter" style={{marginTop:"10px"}}>
           <span className="subscripdiv-text textcenter"style={{textAlign:"center"}}>Premium Membership</span>
            <div className="textleft"><span className="event-sub-detail1"><del>1999</del></span></div>
            <div className="textleft"><span className="event-sub-detail1">999/Lifetime</span></div>
            <div className="textleft"><span className="event-sub-detail1">You Save 50%</span></div>
           </div>

           <div className="">
           <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Member in your radius </span>  
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Connect with new member </span>  
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Blood requirement  </span>  
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}>Access community Directory  </span>   
    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}> Access Post  </span>  
    </ul>

    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}> Messenger </span>   

    </ul>
    <ul>
    <IoMdCheckmark size="1.1em" color="#214B0D"/><span className="event-sub-detail1" style={{fontSize:"14px",marginLeft:'3px'}}> Coffee Meet Ups </span>   

    </ul>
    
   
           </div>
           </div>

      
        </div>
        <div className="row mt-3">
        <div className="col-lg-2 col-md-12 col-sm-12 textright"></div>
<div className="col-lg-9 col-md-12 col-sm-12 subscrip-div" style={{height:"auto",paddingBottom:"10px",maxWidth:"69%"}}>
            {
                showhideSubTy ==='Premium' &&
                <div>
                  <div className="margintop3 row">
             <div className="col-lg-5 col-md-12 col-sm-12 textright">
               <span className="subscripdiv-text">Membership Fee</span>
             </div>
             <div className="col-lg-5 col-md-12 col-sm-12 textleft">
             <span className="subscripdiv-text"> {pricedata?.price} Rs</span>
              </div>
              </div>
           <div className="margintop3 row">
              <div className="col-lg-5 col-md-12 col-sm-12 textright" style={{marginTop:"4px"}}>
                <span className="subscripdiv-text">Discount**</span>
              </div>
             
              <div className="col-lg-7 col-md-12 col-sm-12 textleft">
              
               {
                    showhide ?   ( 
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
<input type="text" className=" form-control" id="fst"
                      name="referralUsed"
                      placeholder="Enter Discount/Refrral code"
                      style={{fontSize:'13px'}}
                      onChange={handleReferalCode}
                 />  
                 <button style={{backgroundColor:"#391847",padding:"2px",color:"#fff",fontSize:"13px"}} onClick={adddiscount}>Apply</button> 
                      </div>
                                      ):

                     


                     discount ? (
                      <div className="" style={{whiteSpace:"nowrap",display:"flex"}}>
                        
      <span className="subscripdiv-text">{countmenu} Rs</span>
      {
        expiredtxt?(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:"",color:"red"}}>Coupon code not Applied</span>):
         existtxt?(<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:"",color:"red"}}>Coupon Code does not exist and not Applied</span>):
        (<span className="refrel-disc-code" style={{marginLeft:"10px",cursor:""}}>{referalCodeValue} Applied</span>)
      }
                 <span className="removed-refcode-btn" style={{fontSize:"14px",marginLeft:"10px",cursor:"pointer"}} onClick={removeDiscountClick}>Remove</span> 
                      </div>
                                      ):(
                                        <div>
                  <span className="subscripdiv-text"> {pricedata?.discount}Rs</span>
               <span className="removed-refcode-btn" style={{fontSize:"14px",marginLeft:"10px",cursor:"pointer"}} onClick={removeDiscountClick}>Remove</span> 
                                     
                                     </div>
                                     
                                     )
}

                    
              </div>
              
           
            

</div>
              </div>


}
            <div className="margintop3 row">
              <div className="col-lg-5 col-md-12 col-sm-12 textright">
                <span className="subscripdiv-text">Total Payable*</span>
              </div>
              
              {
                showhideSubTy ==='Free' &&      
              <div className="col-lg-5 col-sm-12 col-md-12 textleft"><span className="subscripdiv-text"> 0 Rs</span></div>
           
              }
              {
                showhideSubTy ==='Premium' &&
                <div className="col-lg-5 col-sm-12 col-md-12 textleft"><span className="subscripdiv-text"> {amouttotal} Rs</span></div>
                
              }
              
            </div>
            </div>
</div>

<div className="row">
{/* <div className="col-lg-1 col-sm-12 col-md-12"></div> */}
<div className="col-lg-11 ml-3 col-sm-12 col-md-12">
<div className="margintop3  textcenter ">

            <div className="row margintop">
              <div className="col-lg-1"></div>
            <div class="form-check  col-lg-11 col-sm-12 col-md-12">
  <label class="form-check-label">
    <input type="checkbox" class="form-check-input chek" value="" name="agree" onChange={handlechangeCheckbox}/>
    <span className="chekboxlbl">By Clicking agree & join , 
    you agree to the,</span><Link to="/NaiduSanghamCommunityPledge"><span className="chekboxlbl bluetextforlink"style={{marginLeft:'-5px'}}>Naidu Directory Community pledge ,</span></Link><Link to="/JoinNowPrivacy"><span className="chekboxlbl bluetextforlink" style={{marginLeft:'-15px'}}>privacy & data policy </span></Link>
    <div><span className="chekboxlbl">and</span><Link to="/TermsConditionsNaiduSangham"><span className="chekboxlbl bluetextforlink"style={{marginLeft:'-5px'}}> Naidu Directory Terms & Conditions</span></Link></div>
  </label>
  </div>
            
            
            </div>

          </div>
</div>

<div className="col-lg-1 col-sm-12 col-md-12"></div>

</div>
         

           <div className="margintop1 row ">
            <div className="col-lg-3 col-sm-12 col-md-12 marginleftmins4"></div>
            <div className="col-lg-3 col-sm-12 col-md-12 margintop textright">
            <button
               className="prevbtn "
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-sm-12 col-md-12 margintop ">
          <button
            className={(checkboxAgree.agree !=""&& (borderboxfree||borderbox))?"nextbtn2":"disabledbtn "}
              variant="contained"
              color="primary"
              //  onClick={handleNext}
              // type="submit"
              onClick={paymentRequest}
            >
              Submit And Proceed to Pay
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          

            

            <div className=" textcenter row ">
            <div className="col-lg-3"></div>
            <div className="margintop col-lg-7 col-sm-12 col-md-12">
            {
                showhideSubTy ==='Free' &&      
                <span className="alreadyspn1 ">We strongly recommend you to upgrade to Premium today and elevate your Naidu Directory experience.

                Your support helps us continue to provide valuable services and benefits to the Naidu Community.</span>
           
              }
              {
                showhideSubTy ==='Premium' &&
                <div>
                <span className="alreadyspn1 ">You will be redirect to Payment Gateway once you click on Submit button </span> <BsBoxArrowUpRight style={{marginLeft:"5px"}}/>
                </div>
              }
            </div>
           
            </div>
            
          </div> 
        </div>
    );
  }
  export default SubscribeCommunity;