
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {configData} from "../../Config"

const BusinessDetail = ({additional,setadditional,handleBack,handleNext}) => {

//for session
let history = useHistory();

// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);



    const [startDate, setStartDate] = useState(new Date());

    const handleChangedate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        setadditional(preState => ({
            ...preState,
            "registerationDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(additional.birthday);
    }
    
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));

    const buisnessName = localStorage.getItem('buisnessName');
    const id = localStorage.getItem('id');
    //const userid = localStorage.id;
    
    debugger;
   if(authlocalstoragedata !=null && authlocalstoragedata?.buisnessId !=null 
       && authlocalstoragedata?.buisnessName != null && authlocalstoragedata?.buisnessId !=0){
       localStorage.id = authlocalstoragedata?.buisnessId;
       localStorage.buisnessName = authlocalstoragedata?.buisnessName;
  
     }else{
       localStorage.buisnessName = localStorage.buisnessName;
  
       localStorage.id = localStorage.id;
     }

    //localStorage.buisnessName = localStorage.buisnessName;
  
      //localStorage.id = localStorage.id;
    const [error, setError] = useState(false);
    const [contactValidEroor ,setContactValidEroor] = useState(false);

    
    const submitFormData = (e) => {
      debugger
      e.preventDefault();
  
      // checking if value of first name and last name is empty show error else take to step 2
      
      
       if (additional.buisnessCategory==""||additional.buisnessCategory==null) {
        setError(true);
      }
      else if (additional.registerationDate==""||additional.registerationDate==null) {
        setError(true);
      }
      
      else if (additional.numberOfEmployees==""||additional.numberOfEmployees==null) {
          setError(true);
  
      }
      else if (additional.dealsIn==""||additional.dealsIn==null) {
        setError(true);

      }
      
      else if (additional.dealsIn.length >200) {
        setError(true);
      
      }
      
     
      
  else {
        debugger
        handleNext()
      }
  };
      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        setadditional(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
    //for blood group
  const [bloodGroupData, setbloodGroup] = useState([]);

  useEffect(() => {
    loadBloodGroup();
  }, []);

  const loadBloodGroup = async () => {
    debugger
    const result = await axios.get("/user/bloodGroups");
    setbloodGroup(result.data.data);
    console.log("bldgrp",result.data.data)
  };
  
return(
    
<div className="container">
<form >
        <div className="row margintop">
          <div className="col-10" id="completeprofradio">
            <div className="row textLeft">
                <div className="col-lg-7 col-sm-12-col-md-12"> 
                     <span className="lbl popinsfont"style={{fontSize:'14px'}} >Business Details</span>
           </div>

</div>
         <div className="row">
<div className="col-4 textright">
  <span className="lbl popinsfont"style={{fontSize:'13px'}} >Name</span>
</div>
<div className="col-7 textleft">
  <span className="complet-name"style={{fontSize:'13px'}}>{buisnessName}</span>
</div>
         </div>
        

       
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>Business Category</span>
</div>
<div className="col-7 textleft">
<select   id="inputState"  
                className=" form-control "
                name='buisnessCategory'
                onChange={handleChange} >
                <option selected value="">Select Category</option>
                <option  value="Information Technology">Information Technology</option>
                <option  value="Food">Food</option>
                <option  value="HealthCare">HealthCare</option>
                <option  value="Retail">Retail</option>

                {/* {
                  
                  bloodGroupData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                } */}
                </select>
</div>
         </div>
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.buisnessCategory == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Business Category is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
  <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>Date of Registration</span>
</div>
<div className="col-7 textleft">
<DatePicker
                    className="adreservdate11 heightfordtbusness inputwidth"
                    name='registerationDate'
                    selected={startDate}
                    onChange={date => handleChangedate(date)}
                    selectsStart // tells this DatePicker that it is part of a range*
                    startDate={startDate}
                    dateFormat="dd  MMM yyyy"
                   // maxDate={moment().toDate()}

                />                
</div>
         </div>
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.registerationDate == null ? (
                                        <span style={{ color: "red",fontSize:"13px" }}>
                                            Registeration Date  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>

  <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"7px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>Number of Employees</span>
</div>
<div className="col-7 textleft">
<select   id="inputState"  
                className=" form-control "
                name='numberOfEmployees'
                onChange={handleChange} >
                <option selected value="">Select Employee</option>
                <option  value="1">1</option>
                <option  value="1-5">1-5</option>
                <option  value="5-10">5-10</option>
                <option  value="10-20">10-20</option>
                <option  value="20-30">20-30</option>
                <option  value="30-40">30-40</option>
                <option  value="40-50">40-50</option>
                <option  value="40-50">40-50</option>
                <option  value="More Than 50">More Than 50</option>
          {/* {
                  
                  bloodGroupData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                } */}
                </select>
</div>
         </div>  
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-5 ">
                                    {error && additional.numberOfEmployees == null ? (
                                        <span style={{ color: "red",fontSize:"13px" }}>
                                            Number Of Employees  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>   
         <div className="row margintop">
<div className="col-4 textright" style={{marginTop:"15px"}}>
  <span className="lbl popinsfont" style={{fontSize:'13px'}}>Deals In</span>
</div>
<div className="col-7 textleft">
<textarea class="form-control" name='dealsIn' onChange={handleChange}  id="exampleFormControlTextarea1" rows="5" placeholder="" style={{fontSize:"12px"}}/>

</div>
         </div> 
         <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && additional.dealsIn == null ? (
                                        <span style={{ color: "red",fontSize:"13px" }}>
                                            Deals In  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  additional.dealsIn?.length >200 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                          Max 200 Charecters allowed.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div> 
        
        
          </div>  
          <div className="margintop row">
            <div className="col-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="displynoneprev"
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-2 margintop3">
          <button
            className="nextbtn "
              variant="contained"
              color="primary"
              //type='submit'
              onClick={submitFormData}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

            
          </div>   
      </div>
</form>
    </div>
)
}
export default BusinessDetail