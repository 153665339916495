import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {AiOutlineFilePdf} from 'react-icons/ai'
import {Tab,Tabs,Row,Col,Nav,NavItem,Card} from 'react-bootstrap'
import imgupload from '../../images/imageupload.svg'
import BloodReqCommunityReq from "./BloodReqCommunityReq";
import BloodRequirePopup from "../UserLandingPages/BloodRequirePopup";
import BloodReqMyReq from "./BloodReqMyReq";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {configData} from "../../Config"

 
const BloodRequirement =()=>{
  
  const [isOpenBloodPopup, setisOpenBloodPopup] = useState(false);
  const togglePopupBlood = () => {
    setisOpenBloodPopup(!isOpenBloodPopup);
  }

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)
    
    const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [bloodReqdata, setbloodReqdata] = useState([]);

  useEffect(() => {
   loadMatchingreq();
  },[]);

  const loadMatchingreq = async () => {
    const result = await axios.get(`${configData.ApiUrl}/bloodReq/getCountBloodRequirement?id=${uerAuthId}`);

    setbloodReqdata(result.data.data);
    console.log("getbloodreqcount",result.data.data)

};


//for community bloodreq

const [bloodCommunityReq, setbloodCommunityReq] = useState([]);

useEffect(() => {
  loadCommuBlood();
 },[]);


 const loadCommuBlood = async () => {
  //debugger
  //  const result = await axios.get(`/bloodReq/getCommunityBloodRequirements?id=${authlocalstoragedata?.userAuthenticationId}`);
   const result = await axios.get(configData.ApiUrl+'/bloodReq/getCommunityBloodRequirements?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)
   setbloodCommunityReq(result.data.data);
   console.log("communityBloodreq",result.data.data)

};

//for my blood req
const [bloodMyReq, setbloodMyReq] = useState([]);

useEffect(() => {
  loadConnects();
 },[]);

 const loadConnects = async () => {
  //debugger
  //  const result = await axios.get(`/bloodReq/getMyBloodRequirements?id=${authlocalstoragedata?.userAuthenticationId}`);
   const result = await axios.get(configData.ApiUrl+'/bloodReq/getMyBloodRequirements?pageNo='+1+'&pageSize='+10+'&id='+uerAuthId)

   setbloodMyReq(result.data.data);
   console.log("mybloodreq",result.data.data)


 };

  return (
    <div >


      <div className="example" style={{backgroundColor:"#fff"}}>
 

 
         <div className="row">
            <div>
            <span className='event-sub-detail'>Blood Requirements</span>
        <span className="bluetextforlink" style={{marginLeft:"20px"}} onClick={togglePopupBlood}>Add New Requirement</span> 
            </div>
            {isOpenBloodPopup && <BloodRequirePopup
      
      handleClose={togglePopupBlood}
    />}
           </div>
           <div className="row margintop3"></div>

           <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                      <div className=" bg-gray-100 nav-bg horizontal-tabmenu backcolor-none ">

                        <Nav className="nav nav-tabs backcolor-none ">
                        
                          <Nav.Item>
                            <Nav.Link eventKey="link-29" className="contactnvlink">
                              Community Requirements({bloodReqdata.count1})
                            </Nav.Link>
                          </Nav.Item>
                         
                          <Nav.Item>
                            <Nav.Link eventKey="link-30" className="contactnvlink">
                             My Requirements({bloodReqdata.count2})
                            </Nav.Link>
                          </Nav.Item>
                          
                          
                          
                        </Nav>

                      </div>



                      <Tab.Content className=" ">
                        <Tab.Pane eventKey="link-29">
                        <div className="">
                          <BloodReqCommunityReq bloodCommunityReq={bloodCommunityReq} setbloodCommunityReq={setbloodCommunityReq}/>
                        </div>                  
                       </Tab.Pane>
                        <Tab.Pane eventKey="link-30">
                          <div>
                            <BloodReqMyReq setbloodMyReq={setbloodMyReq} bloodMyReq={bloodMyReq}/>                          
                         </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="link-31">
                          <div>
                          hii          
                                </div>
                        </Tab.Pane>
                        
                      </Tab.Content>
            </Tab.Container>



 </div>

</div>
           );
}
 
export default BloodRequirement;