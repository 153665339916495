import './AddNewsPopup.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect,useRef } from "react";
import AddNewsPopup from './AddNewsPopup';
import AddBlogPopup from './AddBlogPopup';
import {FaInfoCircle} from 'react-icons/fa';
import {MdAddBox} from 'react-icons/md';
import ReactTooltip from "react-tooltip";
import validator from "validator";
import imgupload from '../../images/imageupload.svg'

import axios from "axios";
import { toast } from "react-toastify";
import moment, { duration } from 'moment'
import {BsPlusLg} from 'react-icons/bs'
import {MdOutlineDeleteOutline} from 'react-icons/md'
import AddJobPop from './AddJobPop';
import {configData} from "../../Config"
import { YearData } from './YearData';

import {  Link } from 'react-router-dom'
import './AccountClosureMatrimony.css'
import logo from '../../images/Naidus directory.png';
import CoupleIMG from '../../images/Couple image.png';




 
const AccountClosureMatrimony = props => {

  
  return (
    // <div className='row'>
    //     <div className='col-lg-12 col-sm-12 col-md-12'>
    // <label>
    //     Your success story can look just like this! share your journey and inspire other.
    // </label>
    //     </div>

    // </div>

    <div className="container-fluid  ">
            
    <div className="row margintop "></div>
              <div className="row margintop">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-1 col-sm-12 col-md-4">           
                  <Link to='/LandingPage'><img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                    </div>
                  <div className="col-lg-6 col-md-7 col-sm-12">
                  {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                  </div>
  
              </div>
             <div className="row margintop3">
              <div className="col-lg-2"></div>
              <div className="col-lg-6 border2px txtaligncenter bgimg">
                <span>
              Your success story can look just like this! share your journey and inspire other.
              </span>
              <div className='txtaligncenter margintop3 '>
              <img src={CoupleIMG} />
              <br></br>
              <span className='namespan'>
              Rajesh & Priya
              </span>
              <br></br>
              <span className='spantxt'>
              Engagement Date - July 15, 2024
              </span>
              <br></br>
              <br></br>
              <span className='spantxt'>
             We found each other through the community app, and our connection deepened quickly. Soon after, we realized we were meant to be,
             leading to our engagement and the start of a beautiful journey together!
              </span>
              </div>
              </div>
  
             </div>
            
              
              
             
  
  
          </div>
  );
};
 
export default AccountClosureMatrimony;