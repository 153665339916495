import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";
import { Avatar,Box,Paper } from "@material-ui/core";
import {AiOutlineLeft,AiOutlineRight} from "react-icons/ai"
import React, { useState, useEffect } from "react";
import axios from "axios";
import {configData} from "../../Config"


const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span style={{ color: "#404040", fontSize: "10px" }} >
        prev
        </span>
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <span style={{ color: "#404040", fontSize: "10px" }} >
        next
        </span>
    </div>
  );
};
const SliderPage = () => {

  const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
  console.log("authdata11",authlocalstoragedata)
  
  const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
  
  const [bloodReqSlider, setbloodReqSlider] = useState([]);
  
       useEffect(() => {
         loadBloodReqSlider();
       }, []);
    
      const loadBloodReqSlider = async () => {
        
        const result = await axios.get(`${configData.ApiUrl}/bloodReq/getCommunityBloodRequirementsForMain?id=${uerAuthId}`);
        setbloodReqSlider(result.data.data);
        console.log("bldreqslider",result.data.data)
      };
  const [crntSlide, setcrntSlide] = useState(0);

  
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "81%" }} className="slider-div" >
        <Slider  dots>
{
        bloodReqSlider.map((getmenu,index)=>(
    <div
      style={{
        color: "gray",
      }} className={index===crntSlide?"":"slide"}
    >
      
      <div>
      <div className="row">
        <div className="col-2">
         <span className="slider-location-name"> {getmenu.reqCity}</span>
        </div>
        <div className="col-5"></div>
        <div className="col-5 textleft"><span className="slider-name whitenowrap"> {getmenu.raisedBy}</span>
      </div>
      </div>
      <div className="row">
        <div className="col-3">
        </div>
        <div className="col-5" style={{marginLeft:"10px"}}>
          <span className="bpositiv">{getmenu.bloodGroup}</span>
        </div>
        <div className="col-4 textright ">
      </div>
      </div>
      <div className="row margintop3" style={{whiteSpace:"nowrap"}}>
        <div className="col-6 " >
          <span className="slider-name " style={{textAlign:"right"}}>Date Required:</span>
        </div>
        <div className="col-5 textleft">
          <span className="slider-name marginleftmins4"> {getmenu.reqDate}</span>
        </div>
        <div className="col-1 ">
      </div>
      </div>
      <div className="row">
        <div className="col-4 ">
          <span className="slider-name "style={{textAlign:"right"}}>Contact:</span>
        </div>
        <div className="col-4 textleft">
          <span className="slider-name ">{getmenu.contactNo}</span>
        </div>
        <div className="col-1">
      </div>
      </div>
      <div className="row margintop3"></div>
      <div className="row margintop3"></div>
</div>
     
      
      
    </div>
    ))
    }
    {
     bloodReqSlider  && bloodReqSlider.length== 0 &&(
      <span className="slider-name textcenter">No Blood Requirements Currently</span>
     )
}
          {/* <Card  > */}
          {/* < img src={image1}/> */}
            {/* </Card> */}
            {/* <Card1  > */}
          {/* < img src={image1}/> */}
            {/* </Card1>           */}
            {/* <Card2  > */}
          {/* < img src={image1}/> */}
            {/* </Card2> */}
          {/* <Card img="https://www.tutorialrepublic.com/examples/images/clients/2.jpg" />
          <Card img="https://www.tutorialrepublic.com/examples/images/clients/3.jpg" /> */}
        </Slider>
      </div>
    </div>
  );
};


// const Card1 = ({ img }) => {
//   return (
//     <div
//       style={{
//         color: "gray",
//       }}
//     >
//       <div className="row">
//         <div className="col-2">
//          <span className="slider-location-name"> Pune</span>
//         </div>
//         <div className="col-5"></div>
//         <div className="col-5 textleft"><span className="slider-name whitenowrap">Sagar Naidu</span>
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-3">
//         </div>
//         <div className="col-5">
//           <span className="bpositiv">B+Ve</span>
//         </div>
//         <div className="col-4 textright ">
//       </div>
//       </div>
//       <div className="row margintop1">
//         <div className="col-6 ">
//           <span className="slider-name ">Date Required:</span>
//         </div>
//         <div className="col-5 textleft">
//           <span className="slider-name marginleftmins4"> 05 Nov 2022</span>
//         </div>
//         <div className="col-1 ">
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-4 ">
//           <span className="slider-name ">Contact:</span>
//         </div>
//         <div className="col-4 textleft">
//           <span className="slider-name ">9923474723</span>
//         </div>
//         <div className="col-1">
//       </div>
//       </div>
//       <div className="row margintop3"></div>
//       <div className="row margintop3"></div>

      
      
//     </div>
//   );
// };
// const Card2 = ({ img }) => {
//   return (
//     <div
//       style={{
//         color: "gray",
//       }}
//     >
//       <div className="row">
//         <div className="col-2">
//          <span className="slider-location-name"> Pune</span>
//         </div>
//         <div className="col-5"></div>
//         <div className="col-5 textleft"><span className="slider-name whitenowrap">Sagar Naidu</span>
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-3">
//         </div>
//         <div className="col-5">
//           <span className="bpositiv">B+Ve</span>
//         </div>
//         <div className="col-4 textright ">
//       </div>
//       </div>
//       <div className="row margintop1">
//         <div className="col-6 ">
//           <span className="slider-name ">Date Required:</span>
//         </div>
//         <div className="col-5 textleft">
//           <span className="slider-name marginleftmins4 "> 05 Nov 2022</span>
//         </div>
//         <div className="col-1 ">
//       </div>
//       </div>
//       <div className="row">
//         <div className="col-4 ">
//           <span className="slider-name ">Contact:</span>
//         </div>
//         <div className="col-4 textleft">
//           <span className="slider-name ">9923474723</span>
//         </div>
//         <div className="col-1">
//       </div>
//       </div>
//       <div className="row margintop3"></div>    
//         <div className="row margintop1"></div>
//         <div className="row margintop3"></div>

      
      
      
//     </div>
//   );
// };

export default SliderPage;