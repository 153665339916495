
import React from "react";
import './JoinNow.css';
import payment from '../../images/paymentsuc.svg';
import success from '../../images/sucses.svg';


 
const PaymentSuccess = () => {

  
  return (
    <div className="container">
        <div className="margintop  row"></div>
    <div className="margintop  row ">
        <div className="col-2"></div>
          <div className="col-9 form-containerwhite">
            <div className="margintop textcenter">
            <img src={payment} class='margintop'/>
            <img src={success} class='succimg'/>
            </div>
            <div className="textcenter margintop">
              <span className="rslable">Rs. 399</span>
            </div>
            <div className="textcenter margintop">
              <span className="paidsucc-text">Paid successfully to Naidu Directory  Org</span>
            </div>
            <div className="textcenter margintop5">
              <span className="recivemail-text">You will receive the receipt on your registered email XYZ@gmail.com or you can also download it </span>
              <div ><span className="recivemail-text">from your profile section. In case of any queries you can drop an email to </span></div>
              <div ><span className="recivemail-text">  support@XYZcommunity.com</span></div>
            </div>
            <div className="row margintop">
              <div className="col-1"></div>
              <div className="col-3 textright">
                <span className="transactionid">Transaction Id:</span>
              </div>
              <div className="col-2 textleft">
              <span className="transactionid">12345678</span>
                </div>
              <div className="col-3  textleft">
              <span className="transactionid">Payment Method</span>

              </div>
              <div className="col-3 marginleftmins2 textleft">
              <span className="transactionid">Card Payment</span>

              </div>

            </div>
            <div className="margintop textcenter">
              <button className="marginleftmins2 compl-propilebtn">Complete profile</button>
            </div>
          </div>
          </div>
          </div>
  );
};
 
export default PaymentSuccess;

