import React, { useState, useEffect,useCallback } from "react";
import {  Link } from 'react-router-dom'
import '../Login.css';
import logo from '../../images/Naidus directory.png';
import { Badge,  Button,Tab,Nav, Card, Col, Form, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import axios from "axios";



const TermsConditionsNaiduSangham  =()=>{
   
    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
console.log("authdata11",authlocalstoragedata)

const [uerAuthId, setuerAuthId] = useState(authlocalstoragedata?.userAuthenticationId);
//for session
let history = useHistory();

// const [session ,setSession] = useState(false);

// const gesessiondata = async () => {
//    debugger;
//    axios.get('/checkSession')
    
//    .then(result =>{
//      if(result.data.code == 501){
//        setSession(true)  ;
//         }
//     else{
//          history.push("/Login");

//     }
//      debugger;
//      console.log(result.data.code);
//    }).catch(error =>{
//      console.log(error);
//    })
//  };

//        console.log("checksession on campaign",session);

// useEffect(() => {
//     gesessiondata();
//   }, [session]);

//for new session for userlogin
// const redirecToLandingdata =  () => {
 
//     if(authlocalstoragedata?.kycStatus ==null &&  authlocalstoragedata?.kycStatus ==''){
//       history.push("/");

//     }
//   }
     
//      useEffect(() => {
//       redirecToLandingdata();
//    });
    return(
        <div className="container-fluid  ">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                <Link to='/LandingPage'><img src={logo} style={{width:"187px", height:"77px"}}/></Link>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
           <div className="row margintop3">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
            <Card style={{paddingBottom:"8px",paddingTop:"8px"}}>
            <div className="row">
            <span className="event-sub-detail">
            Terms and Conditions for Naidu Directory Community Platform
            <div className="margintop1">Effective Date: 22 Apr 2023</div>

                        
            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail1">
            Welcome to the Naidu Directory Community Platform ("the Platform"). These Terms and Conditions govern your access to and use of the Platform. By accessing or using the Platform, you agree to be bound by these Terms and Conditions. 
            If you do not agree to these terms, please refrain from using the Platform.
                        
            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Community Membership:           </span>
            <span className="event-sub-detail1 margintop1">
            1.1. Eligibility: To use the Platform, you must be at least 18 years old and legally capable of entering into binding contracts. 
            If you are using the Platform on behalf of an organization, you must have the authority to represent that organization.

            </span>
            <span className="event-sub-detail1 margintop1">
            1.2. Account: You may be required to create a user account to access certain features of the Platform.
             You are responsible for maintaining the confidentiality of your account credentials and are liable for all activities conducted through your account.

            </span>
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            User Conduct:           </span>
            <span className="event-sub-detail1 margintop1">
            2.1. Compliance: You agree to comply with all applicable laws, regulations, and these Terms and Conditions while using the Platform.

             
            </span>
            <span className="event-sub-detail1 margintop1">
            2.2. Prohibited Content: You must not upload, post, or share content that is unlawful, defamatory, abusive, offensive, discriminatory, or infringing on the rights of others.
             
            </span>
            <span className="event-sub-detail1 margintop1">
            2.3. Respectful Interaction: Users are expected to engage with each other respectfully and refrain from engaging in any behavior that may be harmful, harassing, or disruptive to others.

            </span>
           </div>
           
           
           <div className="row margintop3">
            <span className="event-sub-detail">
            Intellectual Property:
       </span>
            <span className="event-sub-detail1 margintop1">
            3.1. Ownership: Naidu Sangham retains all rights, title, and interest in and to the Platform, including its content, logos, trademarks, and other intellectual property.

            </span>
            <span className="event-sub-detail1 margintop1">
            3.2. Limited License: You are granted a limited, non-exclusive, and non-transferable license to use the Platform solely for personal, non-commercial purposes. You may not copy, modify, distribute, or reproduce the Platform or its content without prior written consent.

            </span>
           </div>


           <div className="row margintop3">
            <span className="event-sub-detail">
            Community Guidelines:
    </span>
            <span className="event-sub-detail1 margintop1">
            4.1. Community Values: The Platform is dedicated to fostering an inclusive community that upholds Naidu Sangham's values of respect, cultural preservation, and knowledge sharing. 
            Users are encouraged to abide by the Community Pledge and respect the Indiscrimination Clause.
            </span>
            <span className="event-sub-detail1 margintop1">
            4.2. Moderation: Naidu Sangham reserves the right to moderate content and interactions on the Platform. We may remove or restrict access to any content that violates these Terms and Conditions or is deemed inappropriate.

            </span>
           </div>

           <div className="row margintop3">
            <span className="event-sub-detail">
            Third-Party Links and Content:   </span>
            
            <span className="event-sub-detail1 margintop1">
            The Platform may include links to third-party websites or content for informational purposes. Naidu Sangham does not endorse or assume responsibility for the content or practices of any third-party sites.

            </span>
           </div>
           
           <div className="row margintop3">
            <span className="event-sub-detail">
            Disclaimer of Warranties:
    </span>
            <span className="event-sub-detail1 margintop1">
            The Platform is provided "as is," without any warranties or representations, express or implied. Naidu Sangham disclaims all warranties, including but not limited to merchantability, fitness for a particular purpose, and non-infringement.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Limitation of Liability:
  </span>
            <span className="event-sub-detail1 margintop1">
            In no event shall Naidu Sangham be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use the Platform or its content.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Indemnification:
             </span>
            <span className="event-sub-detail1 margintop1">
            You agree to indemnify and hold harmless Naidu Sangham, its officers, directors, employees, and affiliates from any claims, losses, liabilities, damages, or expenses (including legal fees) arising out of or related to your use of the Platform or any violation of these Terms and Conditions.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Termination:
:  </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham reserves the right to suspend or terminate your access to the Platform at any time, with or without cause, without prior notice.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Changes to Terms and Conditions:
 </span>
            <span className="event-sub-detail1 margintop1">
            Naidu Sangham may update or modify these Terms and Conditions at any time. Changes will be effective upon posting on this page, and your continued use of the Platform constitutes acceptance of the modified terms.

            </span>
            
           </div>
           <div className="row margintop3">
            <span className="event-sub-detail">
            Governing Law and Jurisdiction:
 </span>
            <span className="event-sub-detail1 margintop1">
            These Terms and Conditions shall be governed by and construed in accordance with the laws of Pune jurisdiction. Any disputes arising from or related to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts in Pune jurisdiction.

            If you have any questions or concerns regarding these Terms and Conditions, please contact us at support@naidusangham.com.



            </span>
            
           </div>

           <div className="row margintop1">
            <span className="event-sub-detail">
            If you have any questions or concerns regarding these Terms and Conditions, please contact us at support@naidusangham.com.    
                    </span>
            
            
           </div>
          

            </Card>
            <div className="row margintop"></div>

            <div className="row textcenter">
        <div className="col-lg-4 "></div>
        <div className="col-lg-3 col-sm-12 col-md-12"><Link to='/LandingPage'><button className="cancelbtn">Back</button></Link>  </div>

        </div>
        <div className="row margintop"></div>
            
            </div>

           </div>
          
            
            
           


        </div>
    );
}
export default TermsConditionsNaiduSangham