import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import moment, { duration } from 'moment'
import {configData} from "../../Config"

const MatrimonyEducation = ({educationDetails,seteducationDetails,handleBack,handleNext}) => {
    
  //for session
  let history = useHistory();

  // const [session ,setSession] = useState(false);

  // const gesessiondata = async () => {
  //    debugger;
  //    axios.get('/checkSession')
      
  //    .then(result =>{
  //      if(result.data.code == 501){
  //        setSession(true)  ;
  //         }
  //     else{
  //          history.push("/Login");

  //     }
  //      debugger;
  //      console.log(result.data.code);
  //    }).catch(error =>{
  //      console.log(error);
  //    })
  //  };

  //        console.log("checksession on campaign",session);

  // useEffect(() => {
  //     gesessiondata();
  //   }, [session]);
  
  
  const [startDate, setStartDate] = useState(new Date());

    const handleChangeDate = (e) => {
        setStartDate(e);
        // setStartDate(preState => ({
        //     ...preState,
        //     "birthday": (moment(e).format("dd-MMM-yyyy")),
    
        //   }))
    
        seteducationDetails(preState => ({
            ...preState,
            "birthDate": (moment(e).format("DD MMM yyyy")),
    
        }))
    
        console.log(educationDetails.birthday);
    }
    
      const [error, setError] = useState(false);
      const submitFormData1 = (e) => {
        debugger
        e.preventDefault();
    
        // checking if value of first name and last name is empty show error else take to step 2
        
        
         if (educationDetails.education==""||educationDetails.education==null) {
          setError(true);
        }
        
        else if (educationDetails.occupation==""||educationDetails.occupation==null) {
            setError(true);
    
        }
        else if (educationDetails.employmentType==""||educationDetails.employmentType==null) {
          setError(true);
  
        }
        else if (educationDetails.annualIncome==""||educationDetails.annualIncome==null) {
          setError(true);
  
        }
        else if (educationDetails.familyValues==""||educationDetails.familyValues==null) {
          setError(true);
  
        }
        else if (educationDetails.diet==""||educationDetails.diet==null) {
          setError(true);
  
        }
        else if (educationDetails.drinks==""||educationDetails.drinks==null) {
          setError(true);
  
        }
        else if (educationDetails.smokes==""||educationDetails.smokes==null) {
          setError(true);
  
        }
        
       
        
    else {
          debugger
          handleNext()
        }
    };



      const handleChange = (e) => {
        debugger;
        const { name, value, type, checked } = e.currentTarget;
  
  
        seteducationDetails(preState => ({
            ...preState,
            [name]: type === 'checkbox' ? checked : value
        }));
    }
    
return(
    
    <div className="container">
    <form >
            <div className="row margintop">
              <div className="col-lg-10 col-sm-12" id="completeprofradio">
                <div className="row textLeft">
                    <div className="col-lg-7 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Education & Career</span>
               </div>
    
    </div>
             <div className="row margintop3">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Education</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='education'
                    value={educationDetails.education}
                    onChange={handleChange} >
                    <option selected value="B.Arch.">B.Arch.</option>
                    <option  value="B.Com">B.Com</option>
                    <option  value="B.E.">B.E.</option>
                    <option  value="B.Ed.">B.Ed.</option>
                    <option  value="B.Pharma">B.Pharma</option>
                    <option  value="B.Tech">B.Tech</option>
                    <option  value="BAMS">BAMS</option>
                    <option  value="BBA">BBA</option>
                    <option  value="BCA">BCA</option>
                    <option  value="BDS">BDS</option>
                    <option  value="BFA">BFA</option>
                    <option  value="BHM">BHM</option>
                    <option  value="BHMS">BHMS</option>
                    <option  value="High School">High School</option>
                    <option  value="ICWA">ICWA</option>
                    <option  value="M.Arch.">M.Arch.</option>
                    <option  value="M.Com.">M.Com.</option>
                    <option  value="M.D.">M.D.</option>
                    <option  value="M.Des.">M.Des.</option>
                    <option  value="M.E.">M.E.</option>
                    <option  value="M.Ed.">M.Ed.</option>
                    <option  value="M.Phil.">M.Phil.</option>
                    <option  value="M.S(Medicine)">M.S(Medicine)</option>
                    <option  value="M.Tech">M.Tech</option>
                    <option  value="MBA">MBA</option>
                    <option  value="MBBS">MBBS</option>
                    <option  value="Ph.d">Ph.d</option>
                    <option  value="Trade School">Trade School</option>
                    <option  value="Others">Others</option>

                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.education == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Education is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>

             <div className="row margintop">
    <div className="col-lg-4 col-sm-12 ccol-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Occupation</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='occupation'
                    value={educationDetails.occupation}

                    onChange={handleChange} >
                    <option selected value="">Select Occupation</option>
                    <option  value="Physician">Physician</option>
                    <option  value="Teacher">Teacher</option>
                    <option  value="Architect">Architect</option>
                    <option  value="Engineer">Engineer</option>
                    <option  value="Scientist">Scientist</option>
                    <option  value="Accountant">Accountant</option>
                    <option  value="Electrician">Electrician</option>
                    <option  value="Pharmacist">Pharmacist</option>
                    <option  value="Waiting Staff">Waiting Staff</option>
                    <option  value="Dentist">Dentist</option>
                    <option  value="Designer">Designer</option>
                    <option  value="Others">FireFighter</option>
                    <option  value="Librarian">Librarian</option>
                    <option  value="Journalist">Journalist</option>
                    <option  value="Farmer">Farmer</option>
                    <option  value="Hotel Manager">Hotel Manager</option>
                    <option  value="Others">Other</option>

                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.occupation == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Occupation is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
             
             <div className="row margintop">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Empoyment Type</span>
    </div>
    <div className="col-lg-7 col-md-7 col-sm-12 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='employmentType'
                    value={educationDetails.employmentType}

                    onChange={handleChange} >
                    <option selected value="">Select Employment Type</option>
                    <option  value="Government Employee">Government Employee</option>
                    <option  value="Contract">Contract</option>
                    <option  value="Self Employed">Self Employed</option>
                    
                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.employmentType == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            EmploymentType is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
             <div className="row margintop">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Anual Income</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='annualIncome'
                    value={educationDetails.annualIncome}

                    onChange={handleChange} >
                    <option selected value="">Select</option>
                    <option  value="less than 60,000">less than 60,000</option>
                    <option  value="60,000 to 1,00,000">60,000 to 1,00,000</option>
                    <option  value="1,00,000 to 3,00,000">1,00,000 to 3,00,000</option>
                    <option  value="3,00,000 to 5,00,000">3,00,000 to 5,00,000</option>
                    <option  value="5,00,000 to 8,00,000">5,00,000 to 8,00,000</option>
                    <option  value="8,00,000 to 10,00,000">8,00,000 to 10,00,000</option>
                    <option  value="10,00,000 to 15,00,000">10,00,000 to 15,00,000</option>
                    <option  value="15,00,000 to 18,00,000">15,00,000 to 18,00,000</option>
                    <option  value="18,00,000 to 20,00,000">18,00,000 to 20,00,000</option>
                    <option  value="20,00,000 to 25,00,000">20,00,000 to 25,00,000</option>
                    <option  value="25,00,000 to 30,00,000">25,00,000 to 30,00,000</option>
                    <option  value="30,00,000 to 35,00,000">30,00,000 to 35,00,000</option>
                    <option  value="35,00,000 to 40,00,000">35,00,000 to 40,00,000</option>
                    <option  value="40,00,000 to 50,00,000">40,00,000 to 50,00,000</option>
      </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.annualIncome == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Annual Income is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
            
             <div className="row margintop textLeft">
                    <div className="col-lg-7 col-sm-12-col-md-12"> 
                         <span className="lbl popinsfont"style={{fontSize:'14px'}} >Life Style</span>
               </div>
    
    </div>
             <div className="row margintop3">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Family Values</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='familyValues'
                    value={educationDetails.familyValues}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Modern">Modern</option>
                    <option  value="Moderate">Moderate</option>
                    <option  value="Traditional">Traditional</option>
                    
                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.familyValues == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            family Values  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
             <div className="row margintop">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Diet</span>
    </div>
    <div className="col-7 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='diet'
                    value={educationDetails.diet}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Vegetarian">Vegetarian</option>
                    <option  value="Non Vegetarian">Non Vegetarian</option>
                    <option  value="Vegan">Vegan</option>
                    
                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.diet == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Diet Values  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
             
             <div className="row margintop">
    <div className="col-lg-4 col-sm-12 col-md-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Smoke</span>
    </div>
    <div className="col-lg-7 col-md-12 col-sm-12 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='smokes'
                    value={educationDetails.smokes}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Yes">Yes</option>
                    <option  value="No">No</option>
                    <option  value="Occasionally">Occasionally</option>
                    
                    </select>    
                    </div>
             </div>
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.smokes == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Smoke Values  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
      
             <div className="row margintop">
    <div className="col-lg-4 col-md-12 col-sm-12 textright" style={{marginTop:"5px"}}>
      <span className="lbl popinsfont"style={{fontSize:'13px'}} >Drink</span>
    </div>
    <div className="col-lg-7 col-sm-12 col-md-12 textleft">
    <select   id="inputState"  
                    className=" form-control "
                    name='drinks'
                    value={educationDetails.drinks}

                    onChange={handleChange} >
                    <option selected value="">Select </option>
                    <option  value="Yes">Yes</option>
                    <option  value="No">No</option>
                    <option  value="Occasionally">Occasionally</option>
                    
                    </select>    
                    </div>
             </div>
             
             <div className='row '>
    <div className='col-4'></div>
    <div className=" col-4 ">
                                    {error && educationDetails.drinks == null ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Drink  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                </div>
             
      
            
              </div>  
              <div className="margintop3 row">
            <div className="col-lg-3 col-sm-12 col-md-3"></div>
            <div className="col-3 margintop3 textright">
            <button
               className="prevbtn "
              type="submit"
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-sm-12 col-md-3 margintop3">
          <button
            className="nextbtn1 "
              variant="contained"
              color="primary"
              onClick={submitFormData1}
            >
               Next
            </button>
            
            
          </div>
          <div className="margintop2  row"></div>

    
          </div>   
          </div>
    </form>
        </div>
)
}
export default MatrimonyEducation